<div class="row justify-content-center">
    <div class="text-center pt-5 col-12">
        <cp-logo maxWidth="50px" [translate]="'account.text.request_new_invite'">
            Request a new invitation
        </cp-logo>
    </div>

    <div class="pt-4">
        <div *ngIf="requestSuccesful; else requestButton" class="alert alert-success">
            <span [translate]="'account.text.invitation_resend_request_success_message'">
                You have succesfully requested a new invitation. You will receive one as soon as the organisation has
                confirmed!
            </span>
        </div>
        <ng-template #requestButton>
            <div class="text-center pb-2 px-3">
                <div class="pb-4">
                    <span [translate]="'account.text.invitation_resend_info_message'">
                        Your invite has expired. Click the button below to request a new one from the organisation.
                    </span>
                </div>
                <button mat-raised-button class="cp-button cp-button-glow cp-light col-12 mt-5" (click)="goHome()">
                    <mat-icon> home </mat-icon>
                    <span [translate]="'account.button.to_home'">
                        To home page
                    </span>
                </button>
            </div>
            <div *ngIf="requestError" class="alert alert-danger">
                <span [translate]="'account.text.invitation_resend_request_error_message'">
                    Something went wrong while trying to request a new invitation. Please try again!
                </span>
            </div>
        </ng-template>
    </div>
</div>
