export interface NotificationOverviewModel {
    eventName: string;
    eventDate?: Date;
    eventEndDate?: Date;
    eventHasTime: boolean;

    urgentNotifications: NotificationModel[];
    unreadNotifications: NotificationModel[];
    readNotifications: NotificationModel[];
    totalReadNotifications: number;
}

export interface NotificationPollResponse {
    urgentNotifications: NotificationModel[];
    unreadNotifications: NotificationModel[];
}

export interface NotificationModel {
    id: number;
    type: NotificationType;
    sentOn: Date;
    readOn?: Date;
    titleKey: string;
    titleParams: string[];
    contentKey: string;
    contentParams: string[];
    ctaParams: string[];

    opened?: boolean;
}

export type NotificationType = 'EVAL_NEW' | 'EVAL_EXPIRE' | 'EVAL_REMINDER';
