import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StylingModel } from '@core/models';
import { take } from 'rxjs/operators';
import { BriefingDataService } from '@briefing/services';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BriefingEvaluationModel } from '@briefing/models';

@Component({
    selector: 'cp-evaluation-page',
    templateUrl: './evaluation-page.component.html',
    styleUrls: ['./evaluation-page.component.scss']
})
export class EvaluationPageComponent implements OnInit {
    @Input()
        organisationId!: number;

    @Input()
        briefingImageId?: string;

    @Input()
        taskId!: number;

    @Input()
        eventId!: number;

    @Input()
        organisationStyling!: StylingModel;

    @Input()
        evaluationRating?: number;

    @Input()
        evaluationReason?: string;

    public readonly starsArray = new Array(5);

    finishing = false;
    errorFinishing = false;
    onGivingFeedback = false;

    readonly = false;

    reasonControl: UntypedFormControl;
    evaluationForm: UntypedFormGroup;

    constructor(
        fb: UntypedFormBuilder,
        private data: BriefingDataService,
        private router: Router,
    ) {
        this.reasonControl = fb.control(null);

        this.evaluationForm = fb.group({
            reason: this.reasonControl,
        });
    }

    ngOnInit(): void {
        if (this.evaluationRating !== undefined && this.evaluationRating !== null) {
            this.readonly = true;
            this.reasonControl.setValue(this.evaluationReason);
            this.reasonControl.disable();
            this.onGivingFeedback = true;
        }
        else {
            this.evaluationRating = undefined; // So we don't have to check for both undefined and null
        }
    }

    onClickFeedback(): void {
        this.onGivingFeedback = true;
    }

    onClickFinish(): void {
        this.finishing = true;
        this.errorFinishing = false;

        this.data.finishBriefingTask(this.taskId, this.organisationId).pipe(take(1)).subscribe(_ => {
            this.finishing = false;
            this.returnToTaskOverview();
        }, _ => {
            this.errorFinishing = true;
            this.finishing = false;
        });
    }

    onClickStar(starIndex: number): void {
        if (this.readonly) {
            return;
        }

        this.evaluationRating = starIndex + 1;
    }

    onSaveEvaluation(): void {
        if (!this.evaluationForm.valid || this.evaluationRating === undefined || this.readonly) {
            return;
        }

        this.finishing = true;
        this.errorFinishing = false;

        const evaluation = new BriefingEvaluationModel(this.evaluationRating, this.reasonControl.value);

        this.data.saveEvaluation(this.taskId, this.organisationId, evaluation).pipe(take(1)).subscribe(_ => {
            this.finishing = false;
            this.router.navigate([this.organisationId, this.eventId, 'tasks']);
        }, _ => {
            this.errorFinishing = true;
            this.finishing = false;
        });
    }

    returnToTaskOverview(): void {
        this.router.navigate([this.organisationId, this.eventId, 'tasks']);
    }
}
