import { TranslateService } from '@ngx-translate/core';
import { PipeTransform, Pipe } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
    name: 'localizedDate',
    pure: false
})
export class LocalizedDatePipe implements PipeTransform {
    constructor(private translateService: TranslateService, private datePipe: DatePipe) { }

    // date pipe can take any as value.

    transform(value: any, pattern: string = 'mediumDate'): string | null {
        return this.datePipe.transform(value, pattern, undefined, this._getCurrentLocale());
    }

    private _getCurrentLocale(): string {
        const currentLanguage = this.translateService.currentLang;
        switch (currentLanguage) {
        case 'nl':
            return 'nl';
        case 'es':
            return 'es-ES';
        case 'gb':
            return 'en-GB';
        default:
            return 'en-US';
        }
    }
}
