import { QuestionContentType } from '@core/constants';
import { take } from 'rxjs/operators';
import { IBriefingResultQuestion } from '@briefing/models';
import { StylingModel } from '@core/models';
import { Router } from '@angular/router';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { RadialProgressBarModel } from '@shared/components/radial-progress/models/radial-progress-bar-model';
import { BriefingDataService } from '@briefing/services';

@Component({
    selector: 'cp-score-page',
    templateUrl: './score-page.component.html',
    styleUrls: ['./score-page.component.scss']
})
export class ScorePageComponent implements OnInit {
    @Input()
        organisationStyling!: StylingModel;

    @Input()
        organisationId!: number;

    @Input()
        taskId!: number;

    @Input()
        briefingImageId?: string;

    @Output()
        goToNext: EventEmitter<void> = new EventEmitter<void>();

    loading = true;
    error = false;

    questionTypes = QuestionContentType;

    hasScore!: boolean;
    score = 0;

    progressBar: RadialProgressBarModel[] = [];

    incorrectQuestions: IBriefingResultQuestion[] = [];

    constructor(private data: BriefingDataService, private router: Router) { }

    get scoreAsTenScale(): string {
        const score = ((9 * this.score) / 100) + 1;
        return score.toFixed(1);
    }

    ngOnInit(): void {
        this.loading = true;

        this.data.getBriefingResult(this.taskId, this.organisationId)
            .pipe(take(1))
            .subscribe(
                (result) => {
                    this.hasScore = result.hasScore;

                    // If there is no score to show immediately go to the feedback page
                    if (this.hasScore === false) {
                        this.goToNext.emit();
                        return;
                    }

                    this.score = result.score;
                    this.progressBar = [new RadialProgressBarModel(this.score === 0 ? 1 : this.score, this.score >= 50 ? 'green' : 'red')];
                    this.incorrectQuestions = result.incorrectQuestions;

                    this.loading = false;
                },
                () => this.error = true
            );
    }

    isCorrectAnswer(answer: string, correctAnswers: string[]): boolean {
        return correctAnswers.some(correctAnswer => correctAnswer === answer);
    }

    getAnswerClasses(answer: string, question: IBriefingResultQuestion, corrects: boolean): { [klass: string]: boolean } {
        const isImage = question.questionType === this.questionTypes.Image;
        const listLength = corrects
            ? question.correctAnswers.length
            : question.chosenAnswers.length;

        return {
            answer: true,
            'correct-answer': this.isCorrectAnswer(answer, question.correctAnswers),
            'col-6 imageAnswer': isImage,
            'offset-3': isImage && listLength === 1,
            'col-12': !isImage,
        };
    }

    onClickNext(): void {
        this.goToNext.emit();
    }
}
