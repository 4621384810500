import { environment } from '@env';

// Endpoints to use on deployed environments
export const endpoints = {
    account: environment.apiLink + '/account',
    identity: environment.apiLink + '/identity',
    tasks: environment.apiLink + '/user-tasks',
    image: environment.apiLink + '/image',
    translation: environment.apiLink + '/translation',
    organisation: environment.apiLink + '/organisation',
    events: environment.apiLink + '/events',
    wiki: environment.apiLink + '/wiki',
    checklists: environment.apiLink + '/checklists',
    chat: environment.apiLink + '/chat',
    notifications: environment.apiLink + '/notifications',
    briefing: environment.apiLink + '/briefing',
    documents: environment.apiLink + '/documents',
    reports: environment.apiLink + '/reports',
    video: environment.apiLink + '/videoserving',
    tracking: environment.apiLink + '/tracking'
};
