<div *ngIf='loading; else content'>
    <div class='loading-info'>
        <div class='col-12'>
            <div class='d-flex'>
                <cp-delayed-spinner class='m-auto'></cp-delayed-spinner>
            </div>
        </div>
    </div>
</div>

<ng-template #content>
    <div class='task-info' @fadeInFromBottom *ngIf='hasReportInvites'>
        <div class='mb-2'>
            <span class='title' [translate]='"reports.text.report_widget_title"'>
                Reports
            </span>
        </div>
        <div class='col-12'>
            <div class='task row' [class.urgent]='isReportDeadlineExpired()' [routerLink]='getReportsLink()' matRipple
                id="report-card" [matRippleColor]='getRippleColor(isReportDeadlineExpired(), false)'>
                <div class='pl-0 pr-1 task-container d-flex justify-content-center flex-column'
                    [class.col-12]="reportWidgetDetails?.groupName" [class.col-10]="!reportWidgetDetails?.groupName">
                    <div class='task-title w-100'>
                        <span *ngIf="!reportWidgetDetails?.groupName; else reportGroupNameRef"
                            [translate]='reportWidgetKey'>
                            Input required
                        </span>
                        <ng-template #reportGroupNameRef>
                            <span>
                                {{ reportWidgetDetails?.groupName}}
                            </span>
                        </ng-template>
                    </div>
                    <div class='task-details row mx-0 w-100' *ngIf='reportWidgetDetails?.deadline'>
                        <div>
                            <span class='medium-grey-text' [translate]="'general.text.deadline'">
                                Deadline
                            </span>
                            <span class="text-bold" [class.dark-blue-text]='!isReportDeadlineExpired()'
                                [class.warn-text]="isReportDeadlineExpired()">
                                {{ reportWidgetDetails?.deadline | localizedDate:'mediumDate' }}
                            </span>
                        </div>
                    </div>
                </div>
                <div *ngIf="!reportWidgetDetails?.groupName"
                    class='col-2 px-0 icon-container d-flex justify-content-center align-items-center'>
                    <div class='invite-counter'>
                        {{ getInviteTotal() }}
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class='task-info' @fadeInFromBottom *ngIf='hasTasks || (!hasTasks && !hasChecklists && !hasReportInvites)'>
        <div class='mb-2'>
            <span class='title' [translate]='"general.text.assignments"'>
                Assignments
            </span>
            <span class='task-count ml-1' *ngIf='totalTasks > 0'>
                ({{ totalTasks }})
            </span>
            <a *ngIf='hasTasks' class='float-right show-everything-link' [routerLink]='["tasks"]'>
                <span [translate]='"general.text.show_everything"'>
                    Show everything
                </span>
            </a>
        </div>
        <div *ngIf='!hasTasks; else tasksRef' class='no-tasks-message'>
            <span [translate]="'event.text.no_tasks_message'">
                No briefings, evaluations or tests available.
            </span>
        </div>
        <ng-template #tasksRef>
            <div class='col-12'>
                <div class='task row' *ngFor='let task of tasks; let index = index;' [class.urgent]='task.isUrgent'
                    [routerLink]='[convertTaskTypeToRouterLink(task), task.taskId]' [class.finished]='task.isCompleted'
                    [class.knowledge-task]='task.type === taskTypes.KnowledgeTest'
                    [attr.id]="index === 0 ? 'first-task': null"
                    [class.evaluation-task]='task.type === taskTypes.Evaluation' matRipple
                    [matRippleColor]='getRippleColor(task.isUrgent, task.isCompleted)'>
                    <div class='col-10 pl-0 pr-1 task-container'>
                        <div class='task-title'>
                            <span *ngIf="task.type !== taskTypes.Briefing; else taskTitleRef" [translate]='task.type === taskTypes.KnowledgeTest
                        ? "general.text.knowledge_test"
                        : "general.text.evaluation"'>
                                Knowledge test
                            </span>
                            <ng-template #taskTitleRef>
                                {{ task.title }}
                            </ng-template>
                        </div>
                        <div class='task-details row mx-0'>
                            <div class='col-5 px-0' [class.invisible]='!task.deadlineOn'>
                                <span [translate]='"general.text.due_date"'>
                                    Due date
                                </span>
                                <span class='indicator-text'>
                                    {{ task.deadlineOn | localizedDate:'mediumDate' }}
                                </span>
                            </div>
                            <div class='col-7 px-0 text-right' *ngIf="task.progressPercentage">
                                <span class='indicator-text'>
                                    {{ task.progressPercentage }}%
                                </span>
                                <span [translate]='"general.text.completed"'>
                                    Completed
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class='col-2 px-0 icon-container'>
                        <cp-icon *ngIf='!task.isUrgent; else urgentIcon' strokeSize='small' size='small' name='eye'>
                        </cp-icon>

                        <ng-template #urgentIcon>
                            <cp-icon size='small' name='alert-circle' fill='warn' color='white'
                                class='transparent-circle-icon'>
                            </cp-icon>
                        </ng-template>
                    </div>
                </div>
            </div>
        </ng-template>
    </div>

    <div class='task-info' @fadeInFromBottom *ngIf='hasChecklists'>
        <div class='mb-2'>
            <span class='title' [translate]='"general.text.checklist"'>
                Checklist
            </span>
        </div>
        <div class='col-12'>
            <div class='task row' [class.urgent]='urgentChecklist > 0' [routerLink]='["tasks"]' fragment='checklists'
                id="checklist-card" [class.finished]='completedChecklist > 0 && todoChecklist === 0' matRipple
                [matRippleColor]='getRippleColor(urgentChecklist > 0, completedChecklist > 0 && todoChecklist === 0)'>
                <div class='col-10 pl-0 pr-1 task-container'>
                    <div class='task-title'>
                        <span [translate]='"checklist.text.your_tasks"'>
                            Your tasks
                        </span>
                    </div>
                    <div class='task-details row mx-0'>
                        <div *ngIf='todoChecklist > 0'>
                            <span class='dark-blue-text'>
                                {{ todoChecklist }}
                            </span>
                            <span class='medium-grey-text'>
                                to do
                            </span>
                        </div>
                        <div class='counter-divider'
                            *ngIf='todoChecklist > 0 && (urgentChecklist > 0 || (completedChecklist > 0 && todoChecklist > 0))'>
                            |
                        </div>
                        <div *ngIf='urgentChecklist > 0'>
                            <span class='warn-text'>
                                {{ urgentChecklist }}
                            </span>
                            <span class='medium-grey-text'>
                                urgent
                            </span>
                        </div>
                        <div class='counter-divider'
                            *ngIf='urgentChecklist > 0 && (completedChecklist > 0 && todoChecklist > 0)'>
                            |
                        </div>
                        <div *ngIf='completedChecklist > 0 && todoChecklist > 0'>
                            <span class='dark-blue-text'>
                                {{ completedChecklist }}
                            </span>
                            <span class='medium-grey-text'>
                                completed
                            </span>
                        </div>
                        <div *ngIf='completedChecklist > 0 && todoChecklist === 0' class='primary-text'>
                            <span class='medium-grey-text'>
                                All completed
                            </span>
                        </div>
                    </div>
                </div>
                <div class='col-2 px-0 icon-container'>
                    <cp-icon *ngIf='urgentChecklist === 0; else alternateIcons' strokeSize='small' size='small'
                        name='arrow-right'>
                    </cp-icon>

                    <ng-template #alternateIcons>
                        <cp-icon size='small' name='alert-circle' fill='warn' color='white'
                            class='transparent-circle-icon' *ngIf='urgentChecklist > 0; else doneIcon'>
                        </cp-icon>

                        <ng-template #doneIcon></ng-template>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
</ng-template>
