<cp-no-connection-message *ngIf='offlineOnLoad; else loader'>
</cp-no-connection-message>

<ng-template #loader>
    <div *ngIf='loading; else content' class='loading-container'>
        <cp-delayed-spinner class='m-auto'></cp-delayed-spinner>
    </div>
</ng-template>

<ng-template #content>
    <div class='header'>
        <div class='title'>
            {{ eventName }}
        </div>
        <div class='channels-list'>
            <div>
                <span class='dark-blue-text'>
                    {{ priorityChatGroups.length + normalChatGroups.length }}
                </span>
                <span [translate]='"chat.text.groups"'>
                    Groups
                </span>
            </div>
            <div>
                <span class='primary-text'>
                    {{ unreadMessages }}
                </span>
                <span [translate]='"chat.text.unread"'>
                    Unread
                </span>
            </div>
            <div>
                <span class='warn-text'>
                    {{ unreadAlerts }}
                </span>
                <span>
                    Alert
                </span>
            </div>
        </div>
    </div>
    <div class='no-chats-message'
        *ngIf='(!priorityChatGroups || priorityChatGroups.length === 0) && (!normalChatGroups || normalChatGroups.length === 0) && !errorLoading'>
        <span [translate]='"chat.text.no_active_app_chats"'>
            There are currently no active chat groups for this event.
        </span>
    </div>
    <div class='alert alert-danger error-chats' *ngIf='errorLoading'>
        <span [translate]='"chat.error.loading_active_chats"'>
            Something went wrong loading active chats, please try again later.
        </span>
    </div>
    <div class='chat-section' *ngIf='priorityChatGroups && priorityChatGroups.length > 0'>
        <div class='priority-title title'>
            <span [translate]='"chat.text.high_priority_chat_group_s"'>
                High Priority chat group(s)
            </span>
        </div>
        <cp-chat-card *ngFor='let priorityChatGroup of priorityChatGroups' [chatGroup]='priorityChatGroup'
            [eventId]='eventId' [disabled]='offline'>
        </cp-chat-card>
    </div>
    <div class='chat-section' *ngIf='normalChatGroups && normalChatGroups.length > 0'>
        <div class='title'>
            <span [translate]='"chat.text.general_chat_group_s"'>
                General chat group(s)
            </span>
        </div>
        <cp-chat-card *ngFor='let normalChatGroup of normalChatGroups' [chatGroup]='normalChatGroup'
            [eventId]='eventId' [disabled]='offline'>
        </cp-chat-card>
    </div>
</ng-template>
