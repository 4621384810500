<div class='timeline-container'>
    <mat-slider class='mini-timeline-slider w-100' disabled [max]='SIZE' [min]='MIN' 
        [value]='positionOnSlider' [class]='getClass()'>
    </mat-slider>

    <ul class='pips'>
        <li *ngFor='let pip of pips; let index = index' class='pip-container'>
            <div class='pip' [class]='getClass()'>
                <div *ngIf='index === 0 || index === SIZE - 1' class='inner-pip'>
                </div>
            </div>
        </li>
    </ul>
</div>