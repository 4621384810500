import { SafeHtml, SafeUrl } from '@angular/platform-browser';
import { TextColor } from '@core/models';

export interface PlanningContent {
    blocks: PlanningBlock[];
}

export interface HighlightedTitle {
    title: string;

    color: string;
    textColor: TextColor;

    images: PlanningImageModel[];

    isExpanded:boolean;
}

export class PlanningBlock {
    identifier = '';
    title = '';
    subtitle = '';
    highlightedTitles: HighlightedTitle[] = [];
    images: PlanningImageModel[] = [];

    // Also includes start time.
    startDate?: Date;

    endTime?: Date;

    hasSeconds = false;

    location = '';

    details = '';
    _sanitizedDetails?: SafeHtml;

    isPast = false;
    isExpanded = false;

    constructor() { }
}

export interface PlanningImageModel {
    position: number;
    blobId: string;
    placeholder: string;

    loading?: boolean;
    imageUrl?: SafeUrl;
}
