<mat-dialog-content class="mat-typography mx-3">
    <span [translate]='"general.text.screensize_message"'>
        User notice! The Catchphrase App is optimized for use on your smartphone. So please use your smartphone at all times for the optimal user experience.
    </span>
</mat-dialog-content>
<mat-dialog-actions align='end'>
    <button mat-stroked-button class="cp-button m-auto" mat-dialog-close mat-dialog-close>
        <span [translate]='"general.text.ok"'>
            Ok
        </span>
    </button>
</mat-dialog-actions>
