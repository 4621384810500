import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { BriefingSectionType, IBriefingSectionModel } from '@core/models';
import {
    ExternalLinkSectionModel, MapSectionModel, OtherContent, PdfSectionModel
} from '@wiki/models';
import { AnalyticsService, ConnectionService, FeatureToggleService } from '@core/services';
import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DividerSectionModel, DropdownSectionModel, ImageTextSectionModel } from '@shared/components';

@Component({
    selector: 'cp-other-wiki-tab-view',
    templateUrl: './other-wiki-tab-view.component.html',
    styleUrls: ['./other-wiki-tab-view.component.scss']
})
export class OtherWikiTabViewComponent implements OnInit, OnDestroy {
    @Input()
        content!: OtherContent;
    @Input()
        organisationId!: number;
    @Input()
        eventId!: number;
    @Input()
        wikiTabId!: number;
    @Input()
        introductionText?: SafeHtml;
    @Input()
        code?: string;

    sectionType = BriefingSectionType;

    openedDropdowns: { id: string; open: boolean }[] = [];

    canViewPdfs = false;
    canViewVideos = false;
    isOffline = false;

    dropdownChildSections: {index: number; sections?: IBriefingSectionModel[]}[] = [];

    private destroy$ = new Subject<boolean>();

    constructor(
        private connection: ConnectionService,
        private features: FeatureToggleService,
        private sanitizer: DomSanitizer,
        private analytics: AnalyticsService,
    ) { }

    get imageClasses(): { [klass: string]: boolean } {
        return {
            'img-fluid': true,
            'py-3': true
        };
    }

    get pageTrackingInformation(): unknown {
        return {
            type: 'wiki',
            wikiTabId: this.wikiTabId
        };
    }

    ngOnInit(): void {
        this.isOffline = !this.connection.online;

        this.features.isFeatureActive(this.organisationId, 'PdfUpload').pipe(take(1)).subscribe(enabled => {
            this.canViewPdfs = enabled;
        });

        this.features.isFeatureActive(this.organisationId, 'Videos').pipe(take(1)).subscribe(enabled => {
            this.canViewVideos = enabled;
        });

        this.connection.onlineState$.pipe(takeUntil(this.destroy$)).subscribe(connected => {
            this.isOffline = !connected;
        });

        this._parseComplexContent(this.content.sections);
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    onOpenPdf(document: {id: number; title?: string}) {
        this.analytics.track('OpenPdfFile', {
            wikiTabId: this.wikiTabId,
            documentId: document.id,
            documentTitle: document.title,
        }, this.organisationId, this.eventId);
    }

    onOpenDropdown(dropdown: {id?: string; title?: string}) {
        if (dropdown.id) {
            this.analytics.track('OpenDropdown', {
                wikiTabId: this.wikiTabId,
                dropdownId: dropdown.id,
                dropdownTitle: dropdown.title
            }, this.organisationId, this.eventId);
        }
    }

    onDropdownToggle(event: {id?: string; state: boolean}): void {
        if (event.id) {
            const element = this.openedDropdowns.find(x => x.id === event.id);

            if (element) {
                element.open = event.state;
            } else {
                this.openedDropdowns.push({ id: event.id, open: event.state});
            }
        }
    }

    isDropdownOpen(id?: string): boolean {
        return this.openedDropdowns.some(x => x.id === id && x.open);
    }

    getChildSections(index: number): IBriefingSectionModel[] {
        return this.dropdownChildSections.find(x => x.index === index)?.sections || [];
    }

    sectionAsPdfSection(section: IBriefingSectionModel): PdfSectionModel {
        return new PdfSectionModel(section.content);
    }

    sectionAsMapSection(section: IBriefingSectionModel): MapSectionModel {
        return new MapSectionModel(section.content);
    }

    sectionAsExternalLinkSection(section: IBriefingSectionModel): ExternalLinkSectionModel {
        return new ExternalLinkSectionModel(section.content);
    }

    sectionAsDropdownSection(section: IBriefingSectionModel): DropdownSectionModel {
        return new DropdownSectionModel(section.content);
    }

    private _parseComplexContent(sections?: IBriefingSectionModel[]): void {
        if (!sections) {
            return;
        }

        sections.forEach((x, index) => {
            switch (x.type) {
            case BriefingSectionType.text:
                x._sanitizedContent = this._trustContent(x.content);
                break;
            case BriefingSectionType.pdf:
                const pdfSection = x as PdfSectionModel;
                pdfSection._content = JSON.parse(pdfSection.content || '{}');
                break;
            case BriefingSectionType['image-text']:
                const imageTextSection = x as ImageTextSectionModel;
                imageTextSection._content = JSON.parse(imageTextSection.content || '{}');
                break;
            case BriefingSectionType.map:
                const mapSection = x as MapSectionModel;
                mapSection._content = JSON.parse(mapSection.content || '{}');
                break;
            case BriefingSectionType.divider:
                const dividerSection = x as DividerSectionModel;
                dividerSection._content = JSON.parse(dividerSection.content || '{}');
                break;
            case BriefingSectionType['external-link']:
                const externalLinkSection = x as ExternalLinkSectionModel;
                externalLinkSection._content = JSON.parse(externalLinkSection.content || '{}');
                break;
            case BriefingSectionType.dropdown:
                const dropdownSection = x as DropdownSectionModel;
                dropdownSection._content = JSON.parse(dropdownSection.content || '{}');
                this._parseComplexContent(dropdownSection._content?.childSections);
                this.dropdownChildSections.push({index, sections: dropdownSection._content?.childSections});
                break;
            }
        });
    }

    private _trustContent(content: string | undefined): SafeHtml {
        if (content) {
            return this.sanitizer.bypassSecurityTrustHtml(content);
        }
        return '';
    }
}
