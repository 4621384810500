import { NgModule } from '@angular/core';

import { FeatherModule } from '@feather/feather.module';

import {
    FileCheck, ExportFileIos,
    Plus, ArrowLeft, Eye, EyeOff, CheckCircle,
    AlertTriangle, ArrowRight, Check, X, Home,
    CheckSquare, Bell, MessageCircle, Settings,
    AlertCircle, Save, Info, Star, Play,
    Flag, FileText, ThumbsUp, ThumbsDown,
    ChevronDown, Mail, Mic, Phone, User, Edit,
    Clock, Users, Layout, BookOpen, Search, Send,
    RefreshCw, Trash, ArrowDownCircle, Smartphone,
    Archive, BellOff, WifiOff, Book, HelpCircle,
    Edit3, ArrowUp, ArrowDown, Tool, Camera,
    ZoomIn, Paperclip, Maximize, Calendar,
    MapPin, ExternalLink
} from '@shared/icon/icons';

// Select some icons (use an object, not an array)
const icons = {
    Plus, ArrowLeft, Eye, EyeOff, CheckCircle,
    AlertTriangle, ArrowRight, Check, X, Home,
    CheckSquare, Bell, MessageCircle, Settings,
    AlertCircle, Save, Info, Star, Play,
    Flag, FileText, FileCheck, ThumbsUp, ThumbsDown,
    ChevronDown, Mail, Mic, Phone, User, Edit,
    Clock, Users, Layout, BookOpen, Search, Send,
    RefreshCw, Trash, ArrowDownCircle, Smartphone,
    Archive, BellOff, WifiOff, ExportFileIos, Book,
    HelpCircle, Edit3, ArrowUp, ArrowDown, Tool,
    Camera, ZoomIn, Paperclip, Maximize, Calendar,
    MapPin, ExternalLink
};

@NgModule({
    imports: [
        FeatherModule.pick(icons)
    ],
    exports: [
        FeatherModule
    ],
    declarations: []
})
export class IconModule { }
