<div class="row justify-content-center">
    <div class="text-center pt-5 col-12">
        <cp-logo maxWidth="50px" [translate]="'account.text.used_invite'">
            Invite already used
        </cp-logo>
    </div>

    <div class="pt-4">
        <div class="text-center pb-2 px-3">
            <div class="pb-4">
                <span [translate]="'account.text.invitation_already_used'">
                    The invite has already been used. If you already created an account please login from the home page by clicking the button below.
                </span>
            </div>
            <button mat-raised-button class="cp-button cp-button-glow cp-light col-12 mt-5" (click)="goHome()">
                <mat-icon> home </mat-icon>
                <span [translate]="'account.button.to_home'">
                    To home page
                </span>
            </button>
        </div>
    </div>
</div>
