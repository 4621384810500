<div class='wrapper' [class.webkit]='isWebkit' [class.safari]='isSafari' [class.ios]='isIOS'
    *ngIf='!initialisingLanguage'>
    <div class='settings-container' *ngIf='showSettings' [@slideInBottomAnimationPositioned] cp-settings-panel>
    </div>

    <div *ngIf='header' class='header-container' [@slideInTopAnimation] [class.invisible]='showSettings'>
        <cp-header [model]='header' [organisationId]='organisationId' [eventId]='eventId'></cp-header>
    </div>

    <div class='no-connection-bar' *ngIf='isOffline && !hideBanners && !showSettings'>
        <cp-no-connection-bar></cp-no-connection-bar>
    </div>

    <div *ngIf='showUpdateWindow && !isOffline' [@slideInTopAnimation] class='update-card cursor-pointer'
        (click)='updateApp()'>
        <cp-icon size='small' class='mr-1' name='smartphone'></cp-icon>
        <span [translate]='"general.text.install_banner"'>
            A new update is available. Tap here to install!
        </span>
    </div>

    <div class="container-fluid content-container" [class.footer-margin]='showFooter'
        [class.standalone-ios]='isStandaloneiOS' [class.invisible]='showSettings'
        [@routeAnimations]="prepareRoute(outlet)">
        <router-outlet (activate)='resolveActivatedComponent($event)' #outlet="outlet"></router-outlet>
    </div>

    <div *ngIf='showFooter' class='footer-container' [@slideInBottomAnimation] [class.invisible]='showSettings'
        [class.standalone-ios]='isStandaloneiOS'>
        <cp-footer [organisationId]='organisationId' [eventId]='eventId' [standaloneiOS]='isStandaloneiOS'></cp-footer>
    </div>
</div>

<cp-guided-tour></cp-guided-tour>

<ng-template #updateDialog>
    <mat-dialog-content class="mat-typography pb-0">
        <span [translate]='"general.text.new_update"'>
            A new update is available!
        </span>
    </mat-dialog-content>
    <mat-dialog-actions class="px-4 pb-4 pt-3">
        <button mat-flat-button color='warn' mat-dialog-close [disabled]='updatingApp'>
            <span [translate]='"general.text.ignore"'>
                Ignore
            </span>
        </button>
        <button mat-flat-button color='primary' class='ml-auto' mat-dialog-close='true' [loading]='updatingApp'>
            <span [translate]='"general.text.install"'>
                Install
            </span>
        </button>
    </mat-dialog-actions>
</ng-template>
