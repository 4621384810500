import { BriefingSectionType, IBriefingSectionModel } from '@core/models';

export interface OtherContent {
    sections: IBriefingSectionModel[];
}

export class PdfSectionModel implements IBriefingSectionModel {
    type: BriefingSectionType = BriefingSectionType.pdf;

    content?: string;

    _content?: PdfSectionContent;

    constructor(content?: string) {
        if (!content) {
            this._content = {
                pdfId: undefined,
                title: ''
            };
        } else {
            this._content = JSON.parse(content);
        }
    }
}

export interface PdfSectionContent {
    pdfId?: number;
    title?: string;
}

export class MapSectionModel implements IBriefingSectionModel {
    type: BriefingSectionType = BriefingSectionType.map;

    content?: string;

    _content?: MapSectionContent;

    constructor(content?: string) {
        if (!content) {
            this._content = {
                mapType: 'Google',
                title: '',
                height: 352
            };
        } else {
            this._content = JSON.parse(content);
            if (this._content && !this._content.mapType) {
                this._content.mapType = 'Google';
            }
        }
    }
}

export interface MapSectionContent {
    identifier?: string;
    mapType?: MapType;
    title: string;
    height?: number;
    mapUrl?: string;
}

export type MapType = 'Google' | 'Wizzy';

export class ExternalLinkSectionModel implements IBriefingSectionModel {
    type: BriefingSectionType = BriefingSectionType['external-link'];

    content?: string;

    _content?: ExternalLinkSectionContent;

    constructor(content?: string) {
        if (!content) {
            this._content = {
                identifier: '',
                name: '',
                url: ''
            };
        } else {
            this._content = JSON.parse(content);
        }
    }
}

export interface ExternalLinkSectionContent {
    identifier?: string;
    name?: string;
    url?: string;
}
