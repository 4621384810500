import { Component, AfterViewInit, OnDestroy } from '@angular/core';

@Component({
    template: ''
})
export abstract class DarkBodyComponent implements AfterViewInit, OnDestroy {
    ngAfterViewInit(): void {
        document.querySelector('body')?.classList.add('cp-dark');
    }

    ngOnDestroy(): void {
        document.querySelector('body')?.classList.remove('cp-dark');
    }
}
