import { Component, DoCheck, Input } from '@angular/core';
import { IBriefingPageModel } from '@briefing/models';
import { BriefingDataService } from '@briefing/services';
import { take } from 'rxjs/operators';
import { QuestionContentType } from '@core/constants';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'cp-quiz-page-view',
    templateUrl: './quiz-page-view.component.html',
    styleUrls: ['./quiz-page-view.component.scss']
})
export class QuizPageViewComponent implements DoCheck {
    @Input()
        quizPage?: IBriefingPageModel;
    @Input()
        briefingTaskId!: number;
    @Input()
        organisationId!: number;
    @Input()
        isQuizVisible!: boolean;

    @Input()
        transitioningFeedback!: boolean;

    questionTypes = QuestionContentType;

    answering = false;

    private readonly TRANSITION_TIME = 300;
    private _transitioningPages = false;
    private _oldQuestionIndex!: number;

    constructor(private data: BriefingDataService, private sanitizer: DomSanitizer) { }

    ngDoCheck(): void {
        if (this.quizPage?.currentQuestionIndex !== this._oldQuestionIndex) {
            if (this.quizPage?.currentQuestionIndex === undefined) {
                return;
            }

            this._markAnswers(this.quizPage?.currentQuestionIndex);

            this._transitioningPages = true;
            setTimeout(() => {
                this._transitioningPages = false;
            }, this.TRANSITION_TIME);

            this._oldQuestionIndex = this.quizPage.currentQuestionIndex;
        }
    }

    selectAnswer(questionIndex: number, answerIndex: number): void {
        if (this.answering) {
            return;
        }

        const question = this.quizPage?.questions?.find(q => q.position === questionIndex);
        if (question === undefined || question.finished) {
            return;
        }

        // If answer is already selected, deselect it
        if (question.selectedAnswers?.includes(answerIndex)) {
            question.selectedAnswers = question.selectedAnswers.filter(index => index !== answerIndex);
        }
        else {
            if (question.multipleChoice) {
                // If the question is multiple choice, add it to the selected answers if it's not there already
                if (question.selectedAnswers === undefined) {
                    question.selectedAnswers = [answerIndex];
                }
                else if (question.selectedAnswers.findIndex(index => index === answerIndex) < 0) {
                    question.selectedAnswers.push(answerIndex);
                }
            }
            else {
                // If the question is single choice, overwrite the currently selected answer
                question.selectedAnswers = [answerIndex];
            }
        }
    }

    confirmAnswer(): void {
        if (this.quizPage === undefined) {
            return;
        }

        const question = this.quizPage?.questions?.find(q => q.position === this.quizPage?.currentQuestionIndex);
        if (question === undefined || question.selectedAnswers === undefined) {
            return;
        }

        const answers = question.selectedAnswers;

        this.answering = true;

        this.data.answerBriefingQuestion(this.briefingTaskId, this.organisationId, this.quizPage.position,
            question.position, answers).pipe(take(1)).subscribe((response) => {
            // Mark all correct answers as correct
            question.correctAnswers = response.correctAnswerPositions;
            this._markAnswers(question.position);

            question.feedback = response.feedback;

            // Mark all incorrectly selected answers as incorrect
            if (!response.correctAnswer) {
                answers.forEach(index => {
                    if (!response.correctAnswerPositions.includes(index)) {
                        question.answers[index].isCorrect = false;
                    }
                });
            }

            this.answering = false;
            question.finished = true;
        });
    }

    getQuizClasses(questionIndex: number): { [klass: string]: boolean} {
        if (this.quizPage === undefined || this.quizPage.currentQuestionIndex === undefined) {
            return {};
        }

        if (questionIndex === this.quizPage.currentQuestionIndex) {
            return {
                'current-question': true,
                'position-fixed': this._transitioningPages,
                invisible: !this.isQuizVisible,
            };
        }

        if (questionIndex < this.quizPage.currentQuestionIndex) {
            return {
                'past-question': true,
                invisible: !this.transitioningFeedback &&
                    (!this._isInQuestionRange(this.quizPage.currentQuestionIndex) || !this.isQuizVisible),
            };
        }

        if (questionIndex > this.quizPage.currentQuestionIndex) {
            return {
                'future-question': true,
                invisible: !this._isInQuestionRange(this.quizPage.currentQuestionIndex) || !this.isQuizVisible,
            };
        }

        return {};
    }

    isSelected(questionIndex: number, answerIndex: number): boolean {
        const question = this.quizPage?.questions?.find(q => q.position === questionIndex);
        if (question === undefined || question.selectedAnswers === undefined) {
            return false;
        }
        return question.selectedAnswers.includes(answerIndex);
    }

    isConfirmEnabled(questionIndex: number): boolean {
        const question = this.quizPage?.questions?.find(q => q.position === questionIndex);
        if (question === undefined || question.selectedAnswers === undefined) {
            return false;
        }
        return question.selectedAnswers.length > 0;
    }

    trustContent(content: string | undefined): SafeHtml {
        if (content) {
            return this.sanitizer.bypassSecurityTrustHtml(content);
        }
        return '';
    }

    private _markAnswers(questionIndex: number): void {
        const foundQuestion = this.quizPage?.questions?.find(question => question.position === questionIndex);
        if (foundQuestion === undefined || foundQuestion.correctAnswers === undefined) {
            return;
        }

        foundQuestion.correctAnswers.forEach(index => {
            foundQuestion.answers[index].isCorrect = true;
        });

        foundQuestion.selectedAnswers?.forEach(index => {
            if (!foundQuestion.correctAnswers?.includes(index)) {
                foundQuestion.answers[index].isCorrect = false;
            }
        });
    }

    private _isInQuestionRange(index: number): boolean {
        if (this.quizPage === undefined || this.quizPage.questions === undefined) {
            return false;
        }

        return index >= 0 && index < this.quizPage.questions?.length;
    }
}
