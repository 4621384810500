<div class="row">
    <div [formGroup]="formGroup">
        <div *ngFor="let character of inputValues; let index = index" class="character-input">
            <input [defaultValue]='inputValues[index]' type='text' maxlength="1" placeholder="*" [formControlName]="index"
                [id]="index" (keyup)="onKeyUp($event)" onfocus="this.placeholder = ''" onblur="this.placeholder = '*'"
                (paste)='onPaste($event)'
                #inputField>
        </div>
    </div>
</div>
