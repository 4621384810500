import { BriefingPageProgressModel } from './briefing-page-progress-model';

export class BriefingViewModel implements IBriefingViewModel {
    constructor(
        public briefingId: number,
        public title: string,
        public subTitle: string,
        public totalPages: number,
        public progress: BriefingPageProgressModel[],
        public imageBlobId?: string,
        public showMediaWarning?: boolean,
        public deadlineOn?: Date,
        public evaluationRating?: number,
        public evaluationReason?: string,
    ) { }
}

export interface IBriefingViewModel {
    briefingId: number;
    title: string;
    subTitle: string;
    imageBlobId?: string;
    totalPages: number;
    showMediaWarning?: boolean;
    deadlineOn?: Date;
    progress: BriefingPageProgressModel[];
    evaluationRating?: number;
    evaluationReason?: string;
}
