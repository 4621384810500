import { GeneralDataService } from '@core/services';
import { EndPoint } from '@core/constants';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import {
    IBriefingViewModel, IBriefingPageModel, IQuestionAnswerResponseModel, IBriefingResult, IBriefingEvaluationModel
} from '@briefing/models';

@Injectable()
export class BriefingDataService extends GeneralDataService {
    getBriefingView(briefingTaskId: number, organisationId: number): Observable<IBriefingViewModel> {
        const languageId = this.getUserLanguageId();
        return this.getGenericItem(EndPoint.tasks, `${organisationId}/${briefingTaskId}/${languageId}`);
    }

    getBriefingPages(briefingId: number, organisationId: number, pageIndex: number): Observable<IBriefingPageModel[]> {
        const languageId = this.getUserLanguageId();
        return this.getGenericItem(EndPoint.tasks, `${organisationId}/${briefingId}/${languageId}/pages/${pageIndex}`);
    }

    getBriefingPage(briefingId: number, organisationId: number, pageIndex: number): Observable<IBriefingPageModel[]> {
        const languageId = this.getUserLanguageId();
        return this.getGenericItem(EndPoint.tasks, `${organisationId}/${briefingId}/${languageId}/page/${pageIndex}`);
    }

    getBriefingResult(briefingTaskId: number, organisationId: number): Observable<IBriefingResult> {
        const languageId = this.getUserLanguageId();
        return this.getGenericItem(EndPoint.tasks, `${organisationId}/${briefingTaskId}/${languageId}/result`);
    }

    finishBriefingPage(briefingTaskId: number, organisationId: number, pageIndex: number): Observable<IBriefingPageModel | undefined> {
        const languageId = this.getUserLanguageId();
        return this.getGenericItem(EndPoint.tasks, `${organisationId}/${briefingTaskId}/${languageId}/complete/${pageIndex}`);
    }

    answerBriefingQuestion(briefingTaskId: number, organisationId: number, pageIndex: number, questionIndex: number, answers: number[]):
        Observable<IQuestionAnswerResponseModel> {
        const languageId = this.getUserLanguageId();
        return this.postGenericItemWithResponse<number[], IQuestionAnswerResponseModel>(EndPoint.tasks,
            `${organisationId}/${briefingTaskId}/${languageId}/answer/${pageIndex}/${questionIndex}`, answers);
    }

    saveEvaluation(briefingTaskId: number, organisationId: number, evaluation: IBriefingEvaluationModel):
        Observable<IBriefingEvaluationModel> {
        const languageId = this.getUserLanguageId();
        return this.postGenericItem(EndPoint.tasks, `${organisationId}/${briefingTaskId}/${languageId}/evaluate`, evaluation);
    }

    finishBriefingTask(briefingTaskId: number, organisationId: number): Observable<void> {
        const languageId = this.getUserLanguageId();
        return this.getGenericItem(EndPoint.tasks, `${organisationId}/${briefingTaskId}/${languageId}/finish`);
    }
}
