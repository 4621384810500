import { SafeHtml } from '@angular/platform-browser';

export interface ProcedureContent {
    procedures: ProcedureBlock[];
}

export class ProcedureBlock {
    title = '';
    subtitle?: string;
    details = '';
    _sanitizedDetails?: SafeHtml;

    isExpanded = false;
}
