<div class='app-icons'>
    <div class='app-icon empty-app'></div>
    <div class='app-icon empty-app'></div>
    <div class='app-icon catchphrase-app'>
        <cp-logo class='my-auto'></cp-logo>
    </div>
    <div class='app-icon empty-app'></div>
    <div class='app-icon empty-app'></div>
</div>
<div class='explainer'>
    <div class='header'>
        <span [translate]='"general.text.install_the_app"'
            [translateParams]='{ _default: "Install the Catchphrase App" }'>
            Install the Catchphrase App
        </span>
    </div>
    <div class='content'>
        <span [translate]='"general.text.install_the_app_reason"'
            [translateParams]='{ _default: "Install this application on your homescreen for the best experience and quick & easy access on the go." }'>
            Install this application on your homescreen for the best experience and quick & easy access on the go.
        </span>
    </div>
    <div class='how-to'>
        <span [translate]='"general.text.just_tap"' [translateParams]='{ _default: "Just tap" }'>
            Just tap
        </span>
        <span class='icon'>
            <cp-icon name='export-file-ios' size='small' strokeSize='small'></cp-icon>
        </span>
        <span [translate]='"general.text.then_add_to_homescreen"'
            [translateParams]='{ _default: "then Add to homescreen" }'>
            then 'Add to homescreen'
        </span>
    </div>
</div>
<div class='cancel-button'>
    <div mat-dialog-close class='close-button mx-auto'>
        <span [translate]='"general.button.cancel"' [translateParams]='{ _default: "Cancel" }'>
            Cancel
        </span>
    </div>
</div>
<div class='arrow-pointer'>
</div>
