<div class="row justify-content-center">
    <div class="col-xl-4 col-lg-6 col-m-8 col-sm-12">
        <div class="text-center pt-5 col-12">
            <cp-logo maxWidth="50px" [translate]="'account.text.reset_password'">
                Reset your password
            </cp-logo>
        </div>

        <div class="pt-5">
            <div class="row text-center pb-2" *ngIf='!passwordReset'>
                <span class="transparent-text col-12" [translate]="'account.text.reset_password_with_form_below'">
                    Change your password using the form below
                </span>
            </div>

            <form [formGroup]="passwordForgotForm" (ngSubmit)="resetPassword()" *ngIf='!passwordReset; else resetFinished'>
                <cp-password-field formControlName="password" required hideRequiredMarker [validate]='true' [colSize]="10" [colOffset]="1">
                </cp-password-field>

                <cp-password-field formControlName="confirmPassword" hideRequiredMarker confirmField [colSize]="10" [colOffset]="1"
                    [labelKey]="'account.text.confirm_password'">
                </cp-password-field>

                <div *ngIf="resetFailed" class="row text-center pb-4 warn-text" role="alert">
                    <div *ngIf="incorrectToken" class="col-10 offset-1">
                        <span [translate]="'account.text.password_reset_invalid_token'">
                            The link you used is not valid, you might have to perform a new password forgotten request.
                        </span>
                    </div>
                    <div *ngIf="incorrectPassword" class="col-10 offset-1">
                        <!-- Only admins should ever get this error because of validation on the input, but kept generic just in case -->
                        <span [translate]="'account.error.password_reset_invalid_new_password'">
                            The new password you entered is not valid, make sure it's at least 6 characters long (15 for admins).
                        </span>
                    </div>
                </div>

                <div class="col-10 offset-1 mt-2">
                    <button mat-raised-button [loading]="resetting" class="cp-button cp-button-glow col-12" color="accent">
                        <span [translate]="'account.text.reset_password'">
                            Reset your password
                        </span>
                    </button>
                </div>
            </form>

            <ng-template #resetFinished>
                <div class="row text-center pb-2">
                    <div class='col-10 offset-1'>
                        <span [translate]="'account.text.password_reset_set'">
                            Your password has been reset.
                        </span>
                        <div class='mt-3'>
                            <cp-icon name='check-circle' size='large' color='primary'></cp-icon>
                        </div>
                    </div>
                </div>


                <div class='col-10 offset-1 mt-2'>
                    <a mat-raised-button class='cp-button cp-button-glow col-12' color='primary' [routerLink]='["/sign-in"]'>
                        <span [translate]="'registration.button.signin'">
                            Sign In
                        </span>
                    </a>
                </div>
            </ng-template>

        </div>
    </div>
</div>
