import { SafeHtml } from '@angular/platform-browser';

export interface GlossaryTerm {
    id: number;
    term: string;
    description: string;
    _sanitizedDescription: SafeHtml;
    imageBlobId?: string;
    lastSavedOn: Date;
    expanded: boolean;
}
