import { EndPoint } from '@core/constants';
import { AlertService } from './alert.service';
import { environment } from '@env';
import { map, take } from 'rxjs/operators';
import { GeneralDataService } from './general-data.service';
import { Injectable } from '@angular/core';
import { SwPush } from '@angular/service-worker';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import {
    ConfirmDialogComponent,
    ConfirmDialogResult,
    ConfirmDialogData
} from '@shared/components/dialogs/confirm-dialog/confirm-dialog.component';

@Injectable({
    providedIn: 'root'
})
export class PushService {
    constructor(
        private pushService: SwPush, private dialog: MatDialog,
        private alert: AlertService, private data: GeneralDataService) { }

    get hasPushNotificationEnabled(): boolean {
        return 'PushManager' in window && this.pushService.isEnabled;
    }

    hasPushNotificationRegistered(): Observable<boolean> {
        return this.pushService.subscription.pipe(map(result => result !== null));
    }

    activatePushNotifications(): void {
        this.hasPushNotificationRegistered().pipe(take(1))
            .subscribe(hasSubscription => {
                if (!hasSubscription) {
                    this.pushService.requestSubscription({
                        serverPublicKey: environment.vapidKey
                    }).then(x => {
                        this._registerSubscription(x);
                    }).catch(_ =>
                        this.alert.postMessage('notifications.error.subscription_failed', 'Error', 10000, 'general.text.ok')
                    );
                }
            });
    }

    requestPushNotification(): void {
        if (!this.hasPushNotificationEnabled) {
            return;
        }

        const confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: new ConfirmDialogData(
                'notification.text.push_request',
                undefined,
                'primary',
                undefined,
                false,
                'notification.text.allow_push',
                'notification.text.disallow_push',
                undefined,
                true
            )
        });

        confirmDialogRef.afterClosed().pipe(take(1))
            .subscribe(
                (result: ConfirmDialogResult) => {
                    if (result === 'continue') {
                        this.activatePushNotifications();
                    }

                    localStorage.setItem('push_request', 'true');
                }
            );
    }

    revokePushNotifications(): void {
        this.pushService.subscription.pipe(take(1))
            .subscribe(result => {
                if (result) {
                    this.pushService.unsubscribe().then(() => {
                        this._unregisterSubscription(result);
                        this.alert.postMessage('notification.text.unsubscribed_push', 'Light', 10000, 'general.text.ok');
                    });
                }
            });
    }

    private _registerSubscription(subscription: PushSubscription): void {
        this.data.postGenericItem(EndPoint.account, 'settings/push/subscribe', { subscription }).pipe(take(1))
            .subscribe(
                () => {
                    this.alert.postMessage('notification.text.push_notifications_enabled', 'Light', 3000, 'general.text.ok');
                },
                () => {
                    this.alert.postMessage('notification.error.subscription_failed', 'Error', 10000, 'general.text.ok');
                    this.pushService.unsubscribe().then(() => { });
                }
            );
    }

    private _unregisterSubscription(subscription: PushSubscription): void {
        this.data.postGenericItem(EndPoint.account, 'settings/push/unsubscribe', { subscriptionId: subscription.endpoint }).pipe(take(1))
            .subscribe();
    }
}
