import { GlobalsService, AlertService, ConnectionService } from '@core/services';
import { Subject, Subscription, timer } from 'rxjs';
import { ChatDataService } from '@chat/services';
import {
    ActivatedRoute
} from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ChatGroup } from '@chat/models';
import { take, concatMap, takeUntil } from 'rxjs/operators';

@Component({
    templateUrl: './chat-overview.component.html',
    styleUrls: ['./chat-overview.component.scss']
})
export class ChatOverviewComponent implements OnInit, OnDestroy {
    loading = false;
    errorLoading = false;
    pollingPriorityFailCount = 0;
    pollingNormalFailCount = 0;

    priorityChatGroups: ChatGroup[] = [];
    normalChatGroups: ChatGroup[] = [];
    eventName = '';
    eventId!: number;
    organisationId!: number;

    offline = false;
    offlineOnLoad = false;

    private destroy$ = new Subject<boolean>();
    private readonly _priorityPollingDelay = 3000;
    private readonly _normalPollingDelay = 5000;
    private _priorityPoll?: Subscription;
    private _normalPoll?: Subscription;

    constructor(
        private route: ActivatedRoute,
        private data: ChatDataService,
        private alert: AlertService,
        private globals: GlobalsService,
        private connection: ConnectionService) { }

    get unreadAlerts(): number {
        return this.priorityChatGroups.map(x => x.unreadMessages).reduce((a, b) => a + b, 0);
    }

    get unreadMessages(): number {
        return this.unreadAlerts + this.normalChatGroups.map(x => x.unreadMessages).reduce((a, b) => a + b, 0);
    }

    ngOnInit(): void {
        this.globals.showFooter();
        this.globals.changeHeader({
            titleKey: 'chat.text.chat_groups'
        });

        this.eventId = this.route.snapshot.params.eventId;
        this.organisationId = this.route.snapshot.params.organisationId;

        this.offline = !this.connection.online;
        this.offlineOnLoad = this.offline;

        if (!this.offlineOnLoad) {
            this._loadChatGroups();
        }

        this.connection.onlineState$.pipe(takeUntil(this.destroy$)).subscribe(
            (result) => {
                if (result && this.offlineOnLoad) {
                    this._loadChatGroups();
                    this.offlineOnLoad = false;
                }

                this.offline = !result;

                if (this.offline) {
                    this._priorityPoll?.unsubscribe();
                    this._normalPoll?.unsubscribe();

                    this._priorityPoll = undefined;
                    this._normalPoll = undefined;
                } else {
                    this._pollNormalChats();
                    this._pollPriorityChats();
                }
            }
        );
    }

    ngOnDestroy(): void {
        this._priorityPoll?.unsubscribe();
        this._normalPoll?.unsubscribe();

        this._priorityPoll = undefined;
        this._normalPoll = undefined;
    }

    private _loadChatGroups(): void {
        this.loading = true;
        this.errorLoading = false;

        this.data.getChatGroupsForEvent(this.organisationId, this.eventId)
            .pipe(take(1))
            .subscribe(
                (result) => {
                    this.loading = false;
                    this.eventName = result.eventName;
                    this.priorityChatGroups = result.highPriorityChats;
                    this.normalChatGroups = result.normalPriorityChats;

                    this._pollPriorityChats();
                    this._pollNormalChats();
                },
                () => {
                    this.loading = false;
                    this.errorLoading = true;
                });
    }

    private _pollPriorityChats(): void {
        this._priorityPoll = timer(this._priorityPollingDelay)
            .pipe(
                take(1),
                concatMap(() => this.data.pollChatGroups(this.organisationId, this.eventId, 'High'))
            ).subscribe(
                (result) => {
                    this.priorityChatGroups = result;
                    this.pollingPriorityFailCount = 0;
                    this._pollPriorityChats();
                }, () => {
                    this.pollingPriorityFailCount++;

                    if (this.pollingPriorityFailCount >= 5) {
                        this.alert.postMessage('chat.error.exceeded_priority_polling_fail_count', 'Error', 3000);

                        // Timeout for a minute before retrying.
                        setTimeout(() => {
                            this._pollPriorityChats();
                        }, 60 * this._priorityPollingDelay);
                    }
                });
    }

    private _pollNormalChats(): void {
        this._normalPoll = timer(this._normalPollingDelay)
            .pipe(
                take(1),
                concatMap(() => this.data.pollChatGroups(this.organisationId, this.eventId, 'Normal'))
            ).subscribe(
                (result) => {
                    this.normalChatGroups = result;
                    this.pollingNormalFailCount = 0;
                    this._pollNormalChats();
                }, () => {
                    this.pollingNormalFailCount++;

                    if (this.pollingNormalFailCount >= 5) {
                        this.alert.postMessage('chat.error.exceeded_normal_polling_fail_count', 'Error', 3000);

                        // Timeout for 5 minutes before retrying.
                        setTimeout(() => {
                            this._pollNormalChats();
                        }, 60 * this._normalPollingDelay);
                    }
                });
    }
}
