import { QuestionProgressModel } from './question-progress-model';

export class BriefingPageProgressModel implements IBriefingPageProgressModel {

    constructor(
        public position: number,
        public finished: boolean,
        public questions: QuestionProgressModel[],
    ) { }
}

export interface IBriefingPageProgressModel {
    position: number;
    finished: boolean;
    questions: QuestionProgressModel[];
}
