import { Component, Input, OnInit } from '@angular/core';
import { AnalyticsService } from '@core/services';
import { GlossaryTerm } from '@wiki/models';

@Component({
    selector: 'cp-wiki-glossary-view',
    templateUrl: './wiki-glossary-view.component.html',
    styleUrls: ['./wiki-glossary-view.component.scss']
})
export class WikiGlossaryViewComponent implements OnInit {

    @Input()
        organisationId!: number;

    @Input()
        eventId!: number;

    @Input()
        glossaryTerms?: GlossaryTerm[];

    termGroups?: TermGroup[];

    loading = false;
    error = false;

    filter?: string;

    constructor(private analytics: AnalyticsService) {}

    ngOnInit(): void {
        this._makeTermGroups();
    }

    getFilteredGroups(): TermGroup[] {
        if (!this.termGroups) {
            return [];
        }

        if (!this.filter) {
            return this.termGroups;
        }

        return this.termGroups.filter(x => x.terms.some(y => y.term.toLowerCase().includes(this.filter?.toLowerCase() || '')));
    }

    getFilteredTerms(group: TermGroup): GlossaryTerm[] {
        if (!this.filter) {
            return group.terms;
        }

        return group.terms.filter(x => x.term.toLowerCase().includes(this.filter?.toLowerCase() || ''));
    }

    toggleGroup(group: TermGroup): void {
        if (group.expanded) {
            group.terms.forEach(x => x.expanded = false);
        }

        group.expanded = !group.expanded;
    }

    onClickTermHeader(term: GlossaryTerm): void {
        term.expanded = !term.expanded;

        if (term.expanded) {
            this.analytics.track('GlossaryWordOpen', {
                wordId: term.id,
                word: term.term,
            }, this.organisationId, this.eventId);
        }
    }

    private _makeTermGroups(): void {
        this.termGroups = [];

        if (!this.glossaryTerms) {
            return;
        }

        let searchTerms = [...this.glossaryTerms || []];

        if (this.filter) {
            searchTerms = searchTerms.filter(x => x.term.toLowerCase().includes(this.filter?.toLowerCase() || ''));
        }

        searchTerms.forEach(term => {
            const termGroupName = term.term.toUpperCase().charAt(0);
            const termGroup = this.termGroups?.find(x => x.groupName === termGroupName);
            if (termGroup === undefined) {
                this.termGroups?.push({ groupName: termGroupName, expanded: false, terms: [term] });
            }
            else {
                termGroup.terms.push(term);
            }
        });

        this.termGroups.sort((a, b) => this._sort(a.groupName, b.groupName));

        this.termGroups.forEach((group) => {
            group.terms.sort((a, b) => this._sort(a.term, b.term));
        });
    }

    private _sort(a: string, b: string): number {
        if (a < b) {
            return -1;
        }
        if (a > b) {
            return 1;
        }
        return 0;
    }
}

export interface TermGroup {
    groupName: string;
    expanded: boolean;
    terms: GlossaryTerm[];
}
