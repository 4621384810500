<div *ngIf="loading; else error" class="spinner-container d-flex justify-content-center align-items-center">
    <cp-delayed-spinner></cp-delayed-spinner>
</div>

<ng-template #error>
    <div *ngIf="errorLoading; else content" class="d-flex flex-column justify-content-center">
        <span class="warn-text text-center d-inline-block mt-5" [translate]="'knowledge_test.text.test_load_error'">
            Something went wrong while trying to load your knowledge test. You will be redirected to the overview page.
        </span>
    </div>
</ng-template>

<ng-template #content>
    <div class="content-container row">
        <div class="title-page" [class.off-screen]="questionIndex !== undefined || !onTitlePage">
            <header class='header-container' [class.h-0]='loadingImage' [class.invisible]='loadingImage'>
                <div *ngIf='!eventImage || errorLoadingImage; else imageHeader' class='no-image'
                    [style.backgroundColor]='organisationStyling.colorTwo'
                    [class.darkText]='organisationStyling.colorTwoText === "Dark"'
                    [class.lightText]='organisationStyling.colorTwoText === "Light"'>
                    <div class="header-content-container px-5 py-3">
                        <h1>
                            <span>
                                {{ eventName }}
                            </span>
                        </h1>
                        <div *ngIf='deadline'>
                            <div class='float-left'>
                                <span [translate]='"general.text.finish_before"'>
                                    Finish before
                                </span>
                            </div>
                            <div class='float-right'>
                                <span>
                                    {{ deadline | localizedDate:DATE_FORMAT }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <ng-template #imageHeader>
                    <cp-image [blobId]='eventImage' [organisationId]="organisationId" (loaded)='loadingImage = false'
                        [width]="'100%'" [showOverlay]="true" (loadingError)='errorLoadingImage = true'>
                    </cp-image>
                    <div class="header-content-container px-5 py-3">
                        <h1>
                            <span>
                                {{ eventName }}
                            </span>
                        </h1>
                        <div *ngIf='deadline'>
                            <div class='float-left'>
                                <span [translate]='"general.text.finish_before"'>
                                    Finish before
                                </span>
                            </div>
                            <div class='float-right'>
                                <span>
                                    {{ deadline | localizedDate:DATE_FORMAT }}
                                </span>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </header>
            <div class="bottom-container">
                <div class="px-4 my-5">
                    <span [translate]="'knowledge_test.text.knowledge_test_info_message'">
                        This test will determine how prepared you are for the upcoming event.
                        Depending on how many questions you answer incorrectly you may need to retake some
                        briefings.
                        After starting the test, you will not be able to leave until you finished it.
                    </span>
                </div>
                <div class="d-flex flex-column px-4 mb-4">
                    <button mat-raised-button class="cp-button cp-button-glow mb-2" color="primary"
                        (click)="startTest()">
                        <span [translate]="'knowledge_test.button.start_test'">
                            Start knowledge test
                        </span>
                    </button>
                </div>
            </div>
        </div>
        <div class='top-bar' [style.background-color]='organisationStyling.colorOne'
            [class.off-screen]="questionIndex === undefined" [class.transitioning]="changingPages"
            [class.darkText]='organisationStyling.colorOneText === "Dark"'
            [class.lightText]='organisationStyling.colorOneText === "Light"'>
            <span [translate]="'general.text.knowledge_test'">
                Knowledge test
            </span>
        </div>
        <div class="view-container" [class.position-fixed]="transitioningTitle"
            [class.off-screen]="questionIndex === undefined">
            <div class='no-connection-bar' *ngIf='offline'>
                <cp-no-connection-bar></cp-no-connection-bar>
            </div>
            <div class="view-content row flex-column">
                <div *ngFor="let question of questions; let questionIndex = index" class="question col-12"
                    [ngClass]="getQuizClasses(questionIndex)">
                    <div *ngIf="question.imageBlobId"
                        class="question-image-container w-100 d-flex justify-content-center">
                        <cp-image [blobId]="question.imageBlobId" [organisationId]="organisationId" width="100%"
                            height="auto" [canOverlayZoom]='true'></cp-image>
                    </div>
                    <div class='px-3 mt-2 pt-2 question-wrapper'>
                        <div class="small-text">Question {{question.position + 1}} of {{questions.length}}</div>
                        <div class="mb-3" [innerHTML]="question.questionText"></div>
                        <div class="mb-1 small-text font-italic">
                            <span
                                [innerHTML]="(question.multipleChoice ? 'general.text.multiple_answers_possible' : 'general.text.only_one_answer_possible') | translate">
                            </span>
                        </div>
                        <div class="row" [ngClass]="{'selection-made': question?.selectedAnswers?.length || 0 > 0}"
                            [ngSwitch]="question.questionType">
                            <ng-container *ngSwitchCase="questionTypes.Text">
                                <div class="col-12 text-answer" *ngFor="let answer of question.answers; let answerIndex = index">
                                    <button mat-stroked-button class="cp-button text-answer mb-2 w-100"
                                        [disableRipple]='question.finished === true'
                                        (click)="selectAnswer(questionIndex, answerIndex)"
                                        [class.selected]="isSelected(questionIndex, answerIndex)">
                                        <div class='answer-text' [innerHTML]="answer.content"></div>
                                    </button>
                                    <div *ngIf="isSelected(questionIndex, answerIndex)" class="selected-indicator">
                                        <cp-icon color="white" name="check" size="small"></cp-icon>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngSwitchCase="questionTypes.Image">
                                <div class="col-6 image-answer-container mb-3"
                                    *ngFor="let answer of question.answers; let answerIndex = index">
                                    <a class="image-answer" (click)="selectAnswer(questionIndex, answerIndex)" matRipple
                                        [matRippleDisabled]='question.finished === true'
                                        [class.selected]="isSelected(questionIndex, answerIndex)">
                                        <cp-image [blobId]="answer.content" [organisationId]="organisationId"
                                            height="calc(50vw - 30px)" width="100%"></cp-image>
                                        <div *ngIf="isSelected(questionIndex, answerIndex)" class="selected-indicator">
                                            <cp-icon color="white" name="check" size="x-small"></cp-icon>
                                        </div>
                                    </a>
                                </div>
                            </ng-container>
                        </div>
                        <div *ngIf="!question.finished" class="d-flex justify-content-center">
                            <button mat-flat-button class="cp-button mt-3" color="primary" (click)="confirmAnswer()"
                                [loading]="answering" [disabled]="!isConfirmEnabled(questionIndex)">
                                <span [translate]="'briefing.button.confirm_answer'">
                                    Confirm your answer
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="score-page" [class.off-screen]="questionIndex !== undefined || !onResultPage">
            <cp-knowledge-test-score-page *ngIf='onResultPage' [organisationId]=' organisationId' [eventId]='eventId'
                [knowledgeTaskId]='knowledgeTaskId'>
            </cp-knowledge-test-score-page>
        </div>
        <footer class="view-footer pt-3 pb-4" [class.off-screen]="questionIndex === undefined"
            [class.transitioning]="changingPages">
            <nav class="d-flex justify-content-center mb-3">
                <button mat-stroked-button class="cp-button d-flex justify-content-center px-3"
                    (click)="goToPreviousQuestion()" [disabled]="!isPreviousButtonEnabled(questionIndex)">
                    <cp-icon name="arrow-left" size="small"></cp-icon>
                    <span [translate]="'briefing.button.previous_screen'" class="d-inline-block ml-1">
                        Previous
                    </span>
                </button>
                <div class="page-indicator mx-3">
                    <span><span class="current-page-index">{{(questionIndex || 0) + 1}}</span> /
                        {{questions.length}}</span>
                </div>
                <button mat-raised-button class="cp-button d-flex justify-content-center" color="primary"
                    (click)="goToNextQuestion()" [disabled]="!isNextButtonEnabled(questionIndex)">
                    <span [translate]="'briefing.button.next_screen'" class="d-inline-block mr-1">
                        Next
                    </span>
                    <cp-icon name="arrow-right" size="small"></cp-icon>
                </button>
            </nav>
            <div class="w-100 d-flex justify-content-center px-1">
                <div *ngFor="let pip of progressPipLengthArray; let i = index" class="progress-pip"
                    [style.width]="progressPipWidth">
                    <div class="progress-pip-inner" [class.active]="questionIndex === i"
                        [class.past]="(questionIndex || 0) > i"></div>
                </div>
            </div>
        </footer>
    </div>
</ng-template>
