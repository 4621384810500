import { Injectable } from '@angular/core';
import { EndPoint } from '@core/constants';
import { GeneralDataService } from '@core/services';
import { Observable } from 'rxjs';
import { KnowledgeTestResultResponseModel, KnowledgeTestViewResponseModel } from '@knowledge-test/models';

@Injectable()
export class KnowledgeTestDataService extends GeneralDataService {
    getKnowledgeTestView(organisationId: number, eventId: number, knowledgeTaskId: number): Observable<KnowledgeTestViewResponseModel> {
        return this.getGenericItem<KnowledgeTestViewResponseModel>(EndPoint.tasks, `${organisationId}/${eventId}/test/${knowledgeTaskId}`);
    }

    checkKnowledgeTestQuestionAnswer(
        organisationId: number, eventId: number, knowledgeTaskId: number, questionIndex: number, givenAnswers: number[]): Observable<void> {
        return this.postGenericItemWithResponse<number[], void>(EndPoint.tasks,
            `${organisationId}/${eventId}/test/${knowledgeTaskId}/check?questionIndex=${questionIndex}`, givenAnswers);
    }

    getKnowledgeTestResult(organisationId: number, eventId: number, knowledgeTaskId: number): Observable<KnowledgeTestResultResponseModel> {
        return this.getGenericItem<KnowledgeTestResultResponseModel>(EndPoint.tasks,
            `${organisationId}/${eventId}/test/${knowledgeTaskId}/result`);
    }

    finishKnowledgeTest(organisationId: number, eventId: number, knowledgeTaskId: number): Observable<void> {
        return this.getGenericItem<void>(EndPoint.tasks, `${organisationId}/${eventId}/test/${knowledgeTaskId}/finish`);
    }
}
