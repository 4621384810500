<header class="header-container" [style.background-color]='organisationStyling.colorTwo'
    [class.darkText]='organisationStyling.colorTwoText === "Dark"'
    [class.lightText]='organisationStyling.colorTwoText === "Light"' *ngIf="!briefingImageId; else imageHeader">
    <div class="header-content-container">
        <div class='title'>
            <span [translate]='"general.text.evaluation"'>
                Evaluation
            </span>
        </div>
        <div class='subtitle'>
            <span [translate]='"briefing.text.evaluation_subtitle"'>
                Your opinion matters
            </span>
        </div>
    </div>
</header>
<ng-template #imageHeader>
    <header class='image-container'>
        <cp-image [blobId]='briefingImageId || ""' [organisationId]="organisationId" [width]="'100%'"
            [showOverlay]="true">
        </cp-image>

        <div class="header-content-container">
            <div class='header-content'>
                <div class='title'>
                    <span [translate]='"general.text.evaluation"'>
                        Evaluation
                    </span>
                </div>
                <div class='subtitle'>
                    <span [translate]='"briefing.text.evaluation_subtitle"'>
                        Your opinion matters
                    </span>
                </div>
            </div>
        </div>
    </header>
</ng-template>
<ng-container *ngIf="!onGivingFeedback; else feedbackForm">
    <div class="col-12 mt-4">
        <div>
            <span [translate]='"briefing.text.feedback_request_message"'>
                Help us to create better briefings. Do you want to provide us with feedback about whether this briefing
                helped you to get better prepared for the next event?
            </span>
        </div>

        <button mat-flat-button class="cp-button col-12 mt-3" color="primary" (click)="onClickFeedback()"
            [disabled]="finishing">
            <span [translate]='"general.button.yes"'>
                Yes
            </span>
        </button>

        <button mat-flat-button class="cp-button col-12 mt-2" color="accent" (click)="onClickFinish()"
            [loading]="finishing">
            <span [translate]='"briefing.button.no_close_briefing"'>
                No, finish briefing
            </span>
        </button>
        <div *ngIf="errorFinishing">
            <span [translate]="'briefing.text.finish_error_message'">
                Something went wrong while trying to finish your briefing. Please try again later.
            </span>
        </div>
    </div>
</ng-container>
<ng-template #feedbackForm>
    <form [formGroup]="evaluationForm">
        <div class="col-12 mt-3">
            <div class="text-left">
                <label [translate]='"briefing.text.your_rating"'>
                    Your rating
                </label>
            </div>
            <div class="rating-buttons mt-2">
                <ng-container *ngFor="let star of starsArray; let index = index;">
                    <button mat-icon-button class="cp-button mx-2" [class.disabled]="readonly"
                        [class.active]="index < (evaluationRating || 0)" (click)="onClickStar(index)">
                        <cp-icon name="star" color="currentColor"
                            [fill]="index < (evaluationRating || 0) ? 'currentColor' : 'transparent'"></cp-icon>
                    </button>
                </ng-container>
            </div>
        </div>
        <div class="col-12 mt-3 reason-field" [class.hide]="evaluationRating === undefined">
            <label class="float-left" [translate]='"general.text.reason"'>
                Reason
            </label>
            <label class="float-right">
                (<span [translate]='"general.text.optional"'>
                    Optional
                </span>)
            </label>
            <mat-form-field class="mt-2 w-100" appearance="outline">
                <textarea matInput formControlName="reason" cdkTextareaAutosize cdkAutosizeMinRows="3"></textarea>
            </mat-form-field>
        </div>
    </form>
    <div class="fixed-bottom pb-5">
        <button *ngIf="!readonly; else returnButton" mat-flat-button class="cp-button" color="primary"
            (click)="onSaveEvaluation()" [loading]="finishing">
            <span [translate]='"briefing.button.confirm_evaluation"'>
                Confirm evaluation and finish briefing
            </span>
        </button>
        <ng-template #returnButton>
            <button mat-flat-button class="cp-button" color="primary" (click)="returnToTaskOverview()">
                <span [translate]='"briefing.button.return_to_task_overview"'>
                    Return to task overview
                </span>
            </button>
        </ng-template>
    </div>
</ng-template>
