export class RGB {
    constructor(public r: number, public g: number, public b: number) { }

    get toString(): string {
        return `rgb(${this.r}, ${this.g}, ${this.b})`;
    }

    calculateMidPoint(otherColour: RGB, percentage: number): RGB {
        const r = this._calculateIndividualMidPoint(this.r, otherColour.r, percentage);
        const g = this._calculateIndividualMidPoint(this.g, otherColour.g, percentage);
        const b = this._calculateIndividualMidPoint(this.b, otherColour.b, percentage);
        return new RGB(r, g, b);
    }

    private _calculateIndividualMidPoint(color1: number, color2: number, percentage: number): number {
        const scale = color2 - color1;
        return color1 + Math.round(scale * (percentage / 100));
    }
}
