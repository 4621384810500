import { take } from 'rxjs/operators';
import { EndPoint } from '@core/constants';
import { GeneralDataService } from '@core/services';
import { DarkBodyComponent } from '@global/base-components';
import { Component, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent extends DarkBodyComponent {
    @ViewChild('usernameField')
        usernameField!: ElementRef;

    usernameControl: UntypedFormControl;
    passwordForgotForm: UntypedFormGroup;

    submitting = false;
    requested = false;
    error = false;

    constructor(fb: UntypedFormBuilder, private data: GeneralDataService) {
        super();
        this.usernameControl = fb.control('', {
            validators: [Validators.required]
        });
        this.passwordForgotForm = fb.group({
            username: this.usernameControl
        });
    }

    submitRequest(): void {
        if (!this.passwordForgotForm.valid) {
            this.usernameField.nativeElement.focus();
            return;
        }

        this.submitting = true;
        this.requested = false;
        this.error = false;

        this.data.postGenericItemWithResponse(EndPoint.account, 'password/reset/code', {
            username: this.usernameControl.value
        }).pipe(take(1))
            .subscribe({
                next: () => {
                    this.submitting = false;
                    this.requested = true;
                },
                error: () => {
                    this.submitting = false;
                    this.error = true;
                }
            });
    }
}
