export class BriefingEvaluationModel implements IBriefingEvaluationModel {
    constructor(
        public rating: number,
        public reason?: string,
    ) { }
}

export interface IBriefingEvaluationModel {
    rating: number;
    reason?: string;
}
