<ng-container *ngIf="loading; else content">
    <div class="centering">
        <mat-spinner color="primary"></mat-spinner>
    </div>
</ng-container>
<ng-template #content>
    <div class="page-container row justify-content-center">
        <div class="text-center pt-5 col-12">
            <cp-logo maxWidth="50px" [translate]="'account.text.accept_invitation'"
            [translateParams]='{ organisationName: organisationName }'>
                Accept your invitation
            </cp-logo>
        </div>

        <div class="pt-4">
            <div class="row text-center pb-2">
                <div *ngIf="anonymousAccess" class="col-10 offset-1">
                    <button mat-raised-button class="cp-button cp-light col-12" (click)="continueWithoutAccount()"
                    [loading]="loadingAnonymous">
                        <span [translate]="'account.button.access_without_account'">
                            Access without account
                        </span>
                    </button>
                </div>

                <div class="col-10 offset-1" [class.pt-4]="anonymousAccess">
                    <a [routerLink]="['/account/register']" queryParamsHandling="preserve">
                        <button mat-raised-button class="cp-button cp-light col-12">
                            <mat-icon> add </mat-icon>
                            <span [translate]="'account.button.create_account'">
                                Create new account
                            </span>
                        </button>
                    </a>
                </div>

                <div class="col-10 offset-1 pt-4">
                    <button mat-raised-button class="cp-button cp-light col-12" (click)="linkAccount()">
                        <mat-icon> person_add </mat-icon>
                        <span [translate]="'account.button.link_account'">
                            Link existing account
                        </span>
                    </button>
                </div>

                <div class="col-10 offset-1 pt-4">
                    <button mat-raised-button class="cp-button cp-light col-12" (click)="goHome()">
                        <mat-icon> home </mat-icon>
                        <span [translate]="'account.button.to_home'">
                            To home page
                        </span>
                    </button>
                </div>

                <div *ngIf="attemptedManagerLink" class="col-10 offset-1 pt-3 text-danger">
                    <span [translate]="'account.text.manager_account_link_error_message'"
                    [translateParams]="{ email: email }"></span>
                </div>
            </div>
        </div>
    </div>
</ng-template>
