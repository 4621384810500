export class QuestionAnswerResponseModel implements IQuestionAnswerResponseModel {
    constructor(
        public correctAnswer: boolean,
        public correctAnswerPositions: number[],
        public questionWideFeedback: boolean,
        public feedback: string,
    ) { }
}

export interface IQuestionAnswerResponseModel {
    correctAnswer: boolean;
    correctAnswerPositions: number[];
    questionWideFeedback: boolean;
    feedback: string;
}
