import { Subject, Subscription, timer } from 'rxjs';
import { GlobalsService, AlertService, ConnectionService, FeatureToggleService } from '@core/services';
import { StylingModel } from '@core/models';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { concatMap, take, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'cp-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {
    @Input() organisationId?: number;
    @Input() eventId?: number;
    @Input() standaloneiOS = false;

    chatPollingFailCount = 0;
    notificationPollingFailCount = 0;
    reportPollingFailCount = 0;
    hasUnreadNotifications = false;
    hasUnreadChatMessages = false;
    hasReportNotifications = false;

    loadingReports = true;
    showReports = false;

    styling: StylingModel;

    private destroy$ = new Subject<boolean>();
    private readonly _pollingDelay = 10000;
    private _chatNotificationPoll?: Subscription;
    private _notificationPoll?: Subscription;
    private _reportsPoll?: Subscription;
    private _offline = false;

    constructor(
        private globals: GlobalsService,
        private alert: AlertService,
        private connection: ConnectionService,
        private feature: FeatureToggleService
    ) {
        this.styling = globals.stylingDefaults;
    }

    ngOnInit(): void {
        this._offline = !this.connection.online;

        this.connection.onlineState$.pipe(takeUntil(this.destroy$))
            .subscribe((connected) => {
                if (connected && this._offline) {
                    this.globals.pollUserChatMessages(this.organisationId, this.eventId)
                        .pipe(takeUntil(this.destroy$))
                        .subscribe(response => {
                            this.hasUnreadChatMessages = response;
                            this._pollChatMessages();
                        });

                    this.globals.pollUserNotifications(this.organisationId, this.eventId)
                        .pipe(takeUntil(this.destroy$))
                        .subscribe(response => {
                            this.hasUnreadNotifications = response;
                            this._pollNotifications();
                        });

                    if (this.organisationId) {
                        if (this.loadingReports) {
                            this.feature.isReportsActive(this.organisationId).pipe(take(1))
                                .subscribe(result => {
                                    this.showReports = result;
                                    this.loadingReports = false;

                                    if (result) {
                                        this.globals.pollUserReports(this.organisationId, this.eventId)
                                            .pipe(takeUntil(this.destroy$))
                                            .subscribe(response => {
                                                this.hasReportNotifications = response;
                                                this._pollReports();
                                            });
                                    }
                                }, () => {
                                    this.loadingReports = false;
                                    this.showReports = false;
                                });
                        }
                    }
                } else if (!connected) {
                    this._chatNotificationPoll?.unsubscribe();
                    this._chatNotificationPoll = undefined;

                    this._notificationPoll?.unsubscribe();
                    this._notificationPoll = undefined;

                    this._reportsPoll?.unsubscribe();
                    this._reportsPoll = undefined;
                }

                this._offline = !connected;
            });

        if (this.organisationId) {
            this.globals.getStyling(this.organisationId, this.eventId)
                .pipe(takeUntil(this.destroy$))
                .subscribe(styling => this.styling = styling);

            if (!this._offline) {
                this.globals.pollUserChatMessages(this.organisationId, this.eventId)
                    .pipe(takeUntil(this.destroy$))
                    .subscribe(response => {
                        this.hasUnreadChatMessages = response;
                        this._pollChatMessages();
                    });

                this.globals.pollUserNotifications(this.organisationId, this.eventId)
                    .pipe(takeUntil(this.destroy$))
                    .subscribe(response => {
                        this.hasUnreadNotifications = response;
                        this._pollNotifications();
                    });

                this.feature.isReportsActive(this.organisationId).pipe(take(1))
                    .subscribe(result => {
                        this.showReports = result;
                        this.loadingReports = false;

                        if (result) {
                            this.globals.pollUserReports(this.organisationId, this.eventId)
                                .pipe(takeUntil(this.destroy$))
                                .subscribe(response => {
                                    this.hasReportNotifications = response;
                                    this._pollReports();
                                });
                        }
                    }, () => {
                        this.loadingReports = false;
                        this.showReports = false;
                    });
            }
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();

        this._chatNotificationPoll?.unsubscribe();
        this._chatNotificationPoll = undefined;

        this._notificationPoll?.unsubscribe();
        this._notificationPoll = undefined;

        this._reportsPoll?.unsubscribe();
        this._reportsPoll = undefined;
    }

    onSettingsClick(): void {
        this.globals.showSettings();
    }

    inactiveMessage(): void {
        this.alert.postMessage('general.text.coming_soon', 'Light', 3000, 'general.text.ok');
    }

    private _pollChatMessages(): void {
        this._chatNotificationPoll = timer(this._pollingDelay)
            .pipe(
                take(1),
                concatMap(() => this.globals.pollUserChatMessages(this.organisationId, this.eventId))
            ).subscribe(
                (result) => {
                    this.hasUnreadChatMessages = result;

                    this.chatPollingFailCount = 0;
                    this._pollChatMessages();
                }, () => {
                    this.chatPollingFailCount++;

                    if (this.chatPollingFailCount >= 5) {
                        this.alert.postMessage('account.error.exceeded_chat_notification_polling_fail_count', 'Error', 3000);

                        // Timeout for 10 minutes before retrying.
                        setTimeout(() => {
                            this._pollChatMessages();
                        }, 60 * this._pollingDelay);
                    }
                });
    }

    private _pollNotifications(): void {
        this._notificationPoll = timer(this._pollingDelay)
            .pipe(
                take(1),
                concatMap(() => this.globals.pollUserNotifications(this.organisationId, this.eventId))
            ).subscribe(
                (result) => {
                    this.hasUnreadNotifications = result;

                    this.notificationPollingFailCount = 0;
                    this._pollNotifications();
                }, () => {
                    this.notificationPollingFailCount++;

                    if (this.notificationPollingFailCount >= 5) {
                        this.alert.postMessage('account.error.exceeded_notification_polling_fail_count', 'Error', 3000);

                        // Timeout for 10 minutes before retrying.
                        setTimeout(() => {
                            this._pollNotifications();
                        }, 60 * this._pollingDelay);
                    }
                });
    }

    private _pollReports(): void {
        this._reportsPoll = timer(this._pollingDelay)
            .pipe(
                take(1),
                concatMap(() => this.globals.pollUserReports(this.organisationId, this.eventId))
            ).subscribe(
                (result) => {
                    this.hasReportNotifications = result;

                    this.reportPollingFailCount = 0;
                    this._pollReports();
                }, () => {
                    this.reportPollingFailCount++;

                    if (this.reportPollingFailCount >= 5) {
                        // Timeout for 10 minutes before retrying.
                        setTimeout(() => {
                            this._pollReports();
                        }, 60 * this._pollingDelay);
                    }
                });
    }
}
