import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { GlobalsService } from '@core/services';

@Component({
    templateUrl: './pdf-viewer.component.html',
    styleUrls: ['./pdf-viewer.component.scss']
})
export class PdfViewerOverlayComponent implements OnInit {

    @Input()
        pdf!: Blob;

    @Output()
        closed = new EventEmitter<void>();

    loadingPdf = true;

    constructor(private globals: GlobalsService) { }

    ngOnInit(): void {
        this.globals.unlockScreenOrientation();
    }

    closeOverlay(): void {
        this.globals.lockScreenOrientation();
        this.closed.emit();
    }
}
