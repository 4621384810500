export interface ContactsContent {
    emergencyNumber?: string;

    contacts: ContactBlock[];
}

export class ContactBlock {
    firstName = '';
    middleName?: string;
    lastName = '';

    profilePictureBlobId?: string;

    jobTitle?: string;
    companyName?: string;

    phonenumber?: string;
    phoneIsPrimary = false;

    email?: string;
    emailIsPrimary = false;

    other?: string;
    otherIsPrimary = false;

    isExpanded = false;
}
