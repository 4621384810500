import { take } from 'rxjs/operators';
import { EndPoint } from '@core/constants';
import { GeneralDataService } from '@core/services';
import { DarkBodyComponent } from '@global/base-components';
import { Component, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
    templateUrl: './forgot-username.component.html',
    styleUrls: ['./forgot-username.component.scss']
})
export class ForgotUsernameComponent extends DarkBodyComponent {
    @ViewChild('emailField')
        emailField!: ElementRef;

    emailControl: UntypedFormControl;
    usernameForgotForm: UntypedFormGroup;

    submitting = false;
    requested = false;
    error = false;

    constructor(fb: UntypedFormBuilder, private data: GeneralDataService) {
        super();
        this.emailControl = fb.control('', {
            validators: [Validators.required]
        });
        this.usernameForgotForm = fb.group({
            email: this.emailControl
        });
    }

    submitRequest(): void {
        if (!this.usernameForgotForm.valid || this.submitting) {
            this.emailField.nativeElement.focus();
            return;
        }

        this.submitting = true;
        this.requested = false;
        this.error = false;

        this.data.postGenericItemWithResponse(EndPoint.account, 'password/username', {
            email: this.emailControl.value
        }).pipe(take(1))
            .subscribe({
                next: () => {
                    this.submitting = false;
                    this.requested = true;
                },
                error: () => {
                    this.submitting = false;
                    this.error = true;
                }
            });
    }
}
