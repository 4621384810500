import { NgModule, ModuleWithProviders, Optional } from '@angular/core';
import { FeatherComponent } from './feather.component';
import { Icons } from './icons.provider';


@NgModule({
    declarations: [
        FeatherComponent
    ],
    exports: [
        FeatherComponent
    ]
})
export class FeatherModule {
    constructor(
    @Optional() private icons: Icons
    ) {
        if ( !this.icons ) {
            throw new Error(
                `No icon provided. Make sure to use 'FeatherModule.pick({ ... })' when importing the module`
            );
        }
    }

    static pick(icons: {[key: string]: string}): ModuleWithProviders<FeatherModule> {
        return {
            ngModule: FeatherModule,
            providers: [
                { provide: Icons, multi: true, useValue: icons }
            ]
        };
    }
}
