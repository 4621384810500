export interface OverviewEvent {
    id: number;

    eventName: string;

    eventStartDate?: Date;
    hasTime: boolean;

    endDate?: Date;
    evaluationEndDate?: Date;

    progress?: number;
    isUrgent: boolean;

    hasBriefings: boolean;
    hasUnreadNotifications: boolean;
}
