export class DateUtils {
    public static CalculateDifferenceInHoursBetweenDates(date1: Date, date2: Date): number {
        if (!date1.getTime) {
            date1 = new Date(date1 + 'Z');
        }

        if (!date2.getTime) {
            date2 = new Date(date2 + 'Z');
        }

        const date1Timestamp = date1.getTime();
        const date2Timestamp = date2.getTime();
        const diff = date1Timestamp - date2Timestamp;

        const hours = Math.ceil((diff) / (1000 * 60 * 60));
        return Math.abs(hours);
    }

    public static CalculateDifferenceInDaysBetweenDates(date1: Date, date2: Date): number {
        if (!date1.getTime) {
            date1 = new Date(date1 + 'Z');
        }

        if (!date2.getTime) {
            date2 = new Date(date2 + 'Z');
        }

        // Set both dates to Noon because time is irrelevant for this calculation.
        const date1Noon = new Date(date1);
        date1Noon.setHours(12, 0, 0, 0);
        const date2Noon = new Date(date2);
        date2Noon.setHours(12, 0, 0, 0);

        const date1Timestamp = date1Noon.getTime();
        const date2Timestamp = date2Noon.getTime();
        const diff = date1Timestamp - date2Timestamp;

        // Calculate the difference divided by the amount of milliseconds in a day.
        const days = Math.ceil((diff) / (1000 * 60 * 60 * 24));

        return days;
    }

    public static IsSameDate(date1: Date, date2: Date): boolean {
        return date1.getFullYear() === date2.getFullYear()
            && date1.getMonth() === date2.getMonth()
            && date1.getDate() === date2.getDate();
    }

    public static SortDates(date1: Date | undefined, date2: Date | undefined, checkSeconds1: boolean, checkSeconds2: boolean): number {
        let date1ToCheck: Date | undefined;
        if (date1) {
            date1ToCheck = new Date(date1);

            // If seconds should be ignored set them to 0 so they will not be taken into account.
            if (!checkSeconds1) {
                date1ToCheck.setSeconds(0);
            }
        }

        let date2ToCheck: Date | undefined;
        if (date2) {
            date2ToCheck = new Date(date2);

            // If seconds should be ignored set them to 0 so they will not be taken into account.
            if (!checkSeconds2) {
                date2ToCheck.setSeconds(0);
            }
        }

        return (date1ToCheck?.getTime() || Number.MAX_SAFE_INTEGER) - (date2ToCheck?.getTime() || Number.MAX_SAFE_INTEGER);
    }

    public static SortTime(date1: Date | undefined, date2: Date | undefined, checkSeconds1: boolean, checkSeconds2: boolean): number {
        // Ensure both dates have the same date so that only the time is a factor.
        const timeDate1 = new Date();
        const timeDate2 = new Date(timeDate1);

        if (date1) {
            timeDate1.setHours(date1.getHours());
            timeDate1.setMinutes(date1.getMinutes());
            // Only set seconds if required, otherwise default to 0.
            timeDate1.setSeconds(checkSeconds1 ? date1.getSeconds() : 0);
        }

        if (date2) {
            timeDate2.setHours(date2.getHours());
            timeDate2.setMinutes(date2.getMinutes());
            // Only set seconds if required, otherwise default to 0.
            timeDate2.setSeconds(checkSeconds2 ? date2.getSeconds() : 0);
        }

        // If dates are undefined just pass the undefined value, sort dates knows how to handle this.
        return this.SortDates(date1 ? timeDate1 : date1, date2 ? timeDate2 : date2, checkSeconds1, checkSeconds2);
    }

    public static IsBeforeDate(date1: Date, date2: Date): boolean {
        const _date1 = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate());
        const _date2 = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate());
        return _date1 < _date2;
    }

    public static IsBefore(date1: Date, date2: Date, equalCounts: boolean, checkSeconds: boolean): boolean {
        // If the hour is currently before the end time hour it's before
        return date1.getHours() < date2.getHours() ||
            // If the hour is the same, but minute is before it's before
            (date1.getHours() === date2.getHours()
                // If equality counts as before check equal, otherwise just smaller than, but only if seconds dont count
                // otherwise the equality is on the second level
                && ((equalCounts && !checkSeconds)
                    ? date1.getMinutes() <= date2.getMinutes()
                    : date1.getMinutes() < date2.getMinutes())) ||
            // If the hour and minutes are the same, but seconds are before and the block cares about seconds it's before
            (checkSeconds
                && date1.getHours() === date2.getHours()
                && date1.getMinutes() === date2.getMinutes()
                // If equality counts as before check equal, otherwise just small than
                && (equalCounts
                    ? date1.getSeconds() <= date2.getSeconds()
                    : date1.getSeconds() < date2.getSeconds()));
    }
}
