import { EndPoint } from '@core/constants';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { GeneralDataService } from '@core/services';
import { ChatGroupsInOverview, ChatGroup, Priority, ChatPageResponse, MessageSentResponse, ChatMessage } from '@chat/models';

@Injectable()
export class ChatDataService extends GeneralDataService {
    getChatGroupsForEvent(organisationId: number, eventId: number): Observable<ChatGroupsInOverview> {
        return this.getGenericItem<ChatGroupsInOverview>(EndPoint.chat, `${organisationId}/${eventId}`);
    }

    pollChatGroups(organisationId: number, eventId: number, priority: Priority): Observable<ChatGroup[]> {
        return this.getGenericItem<ChatGroup[]>(EndPoint.chat, `${organisationId}/${eventId}/polling?priority=${priority}`);
    }

    getChatMessages(
        organisationId: number, eventId: number, groupId: number,
        pageSize: number, oldestMessage?: Date): Observable<ChatPageResponse> {
        return this.postGenericItem(EndPoint.chat, `${organisationId}/${eventId}/${groupId}`,
            {
                oldestMessage,
                pageSize
            });
    }

    getNewMessages(organisationId: number, eventId: number, groupId: number, newestMessage?: number): Observable<ChatMessage[]> {
        return this.postGenericItem(EndPoint.chat, `${organisationId}/${eventId}/${groupId}/new`,
            {
                newestMessage
            });
    }

    markChatAsRead(organisationId: number, eventId: number, groupId: number): Observable<void> {
        return this.postGenericItem(EndPoint.chat, `${organisationId}/${eventId}/${groupId}/read`, {});
    }

    sendChatMessage(organisationId: number, eventId: number, groupId: number, message: string): Observable<MessageSentResponse> {
        return this.postGenericItem(EndPoint.chat, `${organisationId}/${eventId}/${groupId}/send`, { message });
    }
}
