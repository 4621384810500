import { Component, EventEmitter, Input, Output } from '@angular/core';
import { QuestionResponse } from '@evaluation/models';

@Component({
    selector: 'cp-star-input',
    templateUrl: './star-input.component.html',
    styleUrls: ['./star-input.component.scss']
})
export class StarInputComponent {
    @Input()
        questionResponse?: QuestionResponse;

    @Output()
        touched = new EventEmitter<void>();

    public readonly starsArray = new Array(5);

    get value(): number {
        if (this.questionResponse === undefined || this.questionResponse.rating === undefined) {
            return 0;
        }

        return this.questionResponse.rating || 0;
    }

    onClickStar(index: number): void {
        if (this.questionResponse === undefined) {
            return;
        }

        this.questionResponse.rating = (index + 1) * 20;
        this.touched.emit();
    }
}
