import {
    Directive, ElementRef, AfterViewInit, OnInit, Input, Injector, Renderer2, ViewContainerRef
} from '@angular/core';
import { GlossaryTerm } from '@core/models';
import { GlossaryInjectionService, FeatureToggleService } from '@core/services';
import { GlossaryInjectionTooltipComponent } from '@shared/components';
import { take } from 'rxjs/operators';

@Directive({
    selector: '[glossary-injection]'
})
export class GlossaryInjectionDirective implements OnInit, AfterViewInit {
    @Input()
        organisationId!: number;

    @Input()
        eventId!: number;

    @Input()
        pageInformation?: unknown;

    readyForInjection = false;
    private terms: GlossaryTerm[] = [];

    constructor(
        private elementRef: ElementRef<HTMLElement>,
        private data: GlossaryInjectionService,
        private injector: Injector,
        private renderer: Renderer2,
        private feature: FeatureToggleService,
        private viewContainerRef: ViewContainerRef
    ) { }

    ngOnInit(): void {
        if (this.organisationId === undefined || this.eventId === undefined) {
            return;
        }

        this.feature.isFeatureActive(this.organisationId, 'GlossaryInjection').pipe(take(1), take(1)).subscribe(active => {
            if (active) {
                this.data.getGlossaryTermsOverview(this.organisationId, this.eventId).pipe(take(1)).subscribe(response => {
                    this.terms = response;
                    if (this.readyForInjection) {
                        this._handleInjection();
                    }
                });
            }
        });
    }

    ngAfterViewInit(): void {
        if (this.terms.length) {
            this._handleInjection();
        }
        else {
            this.readyForInjection = true;
        }
    }

    private _handleInjection(): void {
        const usedTerms: GlossaryTerm[] = [];
        this.terms.forEach(term => {
            if (new RegExp(term.term, 'gi').test(this.elementRef.nativeElement.innerHTML)) {
                // If the term includes spaces, replace them with non-breaking-spaces
                // And adjust any occurence in the text of the (full) term similarly
                if (term.term.includes(' ')) {
                    const originalTerm = term.term;
                    term.term = term.term.replace(/ /g, '\u00A0');
                    this.elementRef.nativeElement.innerHTML = this.elementRef.nativeElement.innerHTML.replace(originalTerm, term.term);
                }
                usedTerms.push(term);
            }
        });

        if (usedTerms.length) {
            this._replaceTerms(this.elementRef.nativeElement, usedTerms);
        }
    }

    private _replaceTerms(parent: HTMLElement, terms: GlossaryTerm[]): HTMLElement {
        const newChildren: HTMLElement[] = [];
        parent.childNodes.forEach(child => {
            if (child.nodeType === Node.TEXT_NODE) {
                const textContent = child.textContent;
                if (textContent) {
                    child.textContent = '';
                    textContent.split(' ').forEach(word => {
                        const sanitizedWord = word.replace(/[^\w\s]/g, '');
                        const foundTerm = terms.find(x => sanitizedWord.toUpperCase() === x.term.toUpperCase());
                        if (foundTerm) {
                            const ref = this.viewContainerRef.createComponent(GlossaryInjectionTooltipComponent);
                            let shownText = word;
                            // Turn any non-breaking spaces replaced for matching purposes back to regular spaces
                            if (word.includes('\u00A0')) {
                                shownText = shownText.replace('\u00A0', ' ');
                            }
                            ref.instance.SetValues(this.organisationId, this.eventId, foundTerm, shownText, this.pageInformation);
                            newChildren.push(ref.location.nativeElement);
                        }
                        else {
                            newChildren.push(this.renderer.createText(`${word} `));
                        }
                    });
                }
            }
            else if (child.nodeType === Node.ELEMENT_NODE) {
                const element = this._replaceTerms(child as HTMLElement, terms);
                newChildren.push(element);
            }
        });
        parent.innerText = '';
        newChildren.forEach(x => {
            this.renderer.appendChild(parent, x);
        });
        return parent;
    }
}
