<div class='head-block' [ngStyle]='color'>
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 250 250">
        <g transform="translate(159 -128)">
            <circle class="head" cx="-34" cy="253" r="125" />
            <g transform="translate(-221)">
                <circle class="eye" cx="120" [attr.cy]='254 + yOffset' r="5" />
                <circle class="eye" cx="255" [attr.cy]='254 + yOffset' r="5" />
                <g transform="translate(20.731 29.825)">
                    <g>
                        <path [attr.d]='mouthPath' class='mouth'>
                        </path>
                    </g>
                </g>
            </g>
        </g>
    </svg>

</div>
<div class='evaluation-block'>
    <div class='header'>
        <div class='supertitle' *ngIf='eventName'>
            <span>
                {{ eventName }}
            </span>
        </div>
        <div class='title'>
            <span [translate]='"evaluation.text.how_is_your_mood"'>
                How is your mood today?
            </span>
        </div>
        <div class='text'>
            <span [translate]='"evaluation.text.how_are_you_doing"'>
                How are you doing today? How did you enjoy the event? Swipe to set your mood.
            </span>
        </div>
    </div>
    <div class='input-container d-flex'>
        <input type='range' min='0' max='100' [ngStyle]='inputStyling' [(ngModel)]='evaluationData.mood'
            (change)='touched.emit()'>
    </div>

    <div class="alert alert-danger" *ngIf='error'>
        <span [translate]="'evaluation.text.evaluation_feedback_saving_error_message'">
            Something went wrong while trying to save your feedback. Please try again!
        </span>
    </div>
</div>
