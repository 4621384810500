<header class='header-container' [class.h-0]='loadingImage' [class.invisible]='loadingImage'>
    <div *ngIf='!eventImage || errorLoadingImage; else imageHeader' class='no-image'
        [style.backgroundColor]='organisationStyling.colorTwo'
        [class.darkText]='organisationStyling.colorTwoText === "Dark"'
        [class.lightText]='organisationStyling.colorTwoText === "Light"'>
        <div class="header-content-container px-5 py-3">
            <h1>
                <span>
                    {{ eventName }}
                </span>
            </h1>
            <div *ngIf='deadline'>
                <div class='float-left'>
                    <span [translate]='"general.text.finish_before"'>
                        Finish before
                    </span>
                </div>
                <div class='float-right'>
                    <span>
                        {{ deadline | localizedDate:DATE_FORMAT }}
                    </span>
                </div>
            </div>
        </div>
    </div>
    <ng-template #imageHeader>
        <cp-image [blobId]='eventImage' [organisationId]="organisationId" (loaded)='loadingImage = false'
            [width]="'100%'" [showOverlay]="true" (loadingError)='errorLoadingImage = true'>
        </cp-image>
        <div class="header-content-container px-5 py-3">
            <h1>
                <span>
                    {{ eventName }}
                </span>
            </h1>
            <div *ngIf='deadline'>
                <div class='float-left'>
                    <span [translate]='"general.text.finish_before"'>
                        Finish before
                    </span>
                </div>
                <div class='float-right'>
                    <span>
                        {{ deadline | localizedDate:DATE_FORMAT }}
                    </span>
                </div>
            </div>
        </div>
    </ng-template>
</header>
<div class="bottom-container">
    <div class="px-4 my-5">
        <div>
            <span [translate]="'evaluation.text.evaluation_info_message'" [translateParams]='{ eventName: eventName }'>
                Welcome to the evaluation.
                We would love to hear what you thought of the event and how we could improve ourselves.
            </span>
        </div>
        <div>
            <span class='mt-3 d-block' [translate]='"evaluation.text.evaluation_explanation_message"'>
                Start the evaluation by clicking the button below. It will only take a few minutes of your time,
                but will make a big difference for everyone.
            </span>
        </div>
    </div>
    <div class="d-flex flex-column px-4 mb-4">
        <button mat-raised-button class="cp-button cp-button-glow mb-2" color="primary" (click)="startEvaluation()">
            <span [translate]="'evaluation.button.start'">
                Start evaluation
            </span>
        </button>
    </div>
</div>
