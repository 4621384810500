import { PdfSectionContent } from '@wiki/models';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DocumentDataService } from '@core/services';
import { take } from 'rxjs/operators';
import { ReportTypePdfSection } from '@reports/models';
import { OverlayService } from '../overlay';

@Component({
    selector: 'cp-pdf-section',
    templateUrl: './pdf-section.component.html',
    styleUrls: ['./pdf-section.component.scss']
})
export class PdfSectionComponent {

    @Input()
        organisationId!: number;

    @Input()
        isOffline!: boolean;

    @Input()
        content?: PdfSectionContent | ReportTypePdfSection;

    @Output()
        opened = new EventEmitter<{id: number; title?: string}>();

    downloading = false;
    errorDownloading = false;
    private _pdf?: Blob;

    constructor(
        private data: DocumentDataService,
        private overlay: OverlayService,
    ) { }

    onClickPdf(): void {
        if (!this.content?.pdfId || this.isOffline || this.downloading) {
            return;
        }

        this.opened.emit({id: this.content.pdfId, title: this.content.title});

        // If the pdf is already downloaded simply open the overlay again
        if (this._pdf) {
            this._openPdfOverlay(this._pdf);
            return;
        }

        this.downloading = true;
        this.errorDownloading = false;
        this.data.downloadPdf(this.organisationId, this.content?.pdfId).pipe(take(1)).subscribe({
            next: response => {
                this._pdf = response;
                this._openPdfOverlay(this._pdf);
                this.downloading = false;
            }, error: () => {
                this.errorDownloading = true;
                this.downloading = false;
            }
        });
    }

    private _openPdfOverlay(pdf: Blob): void {
        const overlayRef = this.overlay.OpenPdfOverlay();
        overlayRef.instance.pdf = pdf;
        overlayRef.instance.closed.subscribe(() => {
            overlayRef.destroy();
            this.overlay.ResetUserZoom();
        });
    }
}
