import { VerificationCodeInputComponent } from '@shared/components';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DarkBodyComponent } from '@global/base-components';
import { ActivatedRoute } from '@angular/router';
import { AccountDataService } from '@account/services';
import { AuthenticationService, GlobalsService } from '@core/services';

@Component({
    templateUrl: './confirm.component.html',
    styleUrls: ['./confirm.component.scss'],
})
export class ConfirmComponent extends DarkBodyComponent implements OnInit {
    @ViewChild('input')
        verificationComponent!: VerificationCodeInputComponent;

    code = '';

    confirming = false;
    error = false;
    redirected = false;
    resending = false;
    resentConfirmation = false;
    resendError = false;
    confirmed = false;

    constructor(
        private route: ActivatedRoute,
        private data: AccountDataService,
        private auth: AuthenticationService,
        globals: GlobalsService
    ) {
        super();
        globals.hideHeaderAndFooter();
    }

    ngOnInit(): void {
        this.route.queryParams.subscribe((params) => {
            if (params.confirm_code && params.uid) {
                localStorage.setItem('uid', params.uid);
                this.submitCode(params.confirm_code);
            }

            // If no uid is recognized at all we need to start authenticating, because it will be impossible to confirm.
            if (!params.uid && !this.auth.claims?.sub && !localStorage.getItem('uid')) {
                this.auth.startAuthentication();
            }

            this.redirected = params.redirected;
        });
    }

    onSubmittedCode(submittedCode: string): void {
        this.submitCode(submittedCode);
    }

    submitCode(code: string): void {
        if (this.confirming || this.resending) {
            return;
        }

        let userId: string | null | undefined = this.auth.claims?.sub;

        if (!userId) {
            userId = localStorage.getItem('uid');
        }

        if (userId) {
            this.confirming = true;
            this.data.postEmailConfirmation(code, userId).subscribe({
                next: () => {
                    localStorage.removeItem('uid');
                    this.confirming = false;
                    this.confirmed = true;
                    this.error = false;

                    this.auth.startAuthentication();
                }, error: () => {
                    this.confirming = false;
                    this.error = true;
                    this.verificationComponent.clearValue();
                }
            });
        }
    }

    resendCode(): void {
        let userId: string | null | undefined = this.auth.claims?.sub;

        if (!userId) {
            userId = localStorage.getItem('uid');
        }
        if (userId) {
            this.resending = true;
            this.data.postResendEmailConfirmationRequest(userId).subscribe({
                next: () => {
                    this.resentConfirmation = true;
                    this.resending = false;
                }, error: () => {
                    this.resending = false;
                    this.resendError = true;
                }
            });
        }
    }
}
