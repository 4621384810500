import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    templateUrl: './two-factor-dialog.component.html',
    styleUrls: ['./two-factor-dialog.component.scss']
})
export class TwoFactorDialogComponent {
    code?: string;

    constructor(
        private dialogRef: MatDialogRef<TwoFactorDialogComponent, string>,
    ) { }

    verify(): void {
        if (!this.code) {
            return;
        }

        this.dialogRef.close(this.code);
    }
}
