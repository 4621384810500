import { ThemePalette } from '@angular/material/core';
import { Subject, from } from 'rxjs';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { delay, takeUntil, take } from 'rxjs/operators';

@Component({
    selector: 'cp-delayed-spinner',
    templateUrl: './delayed-spinner.component.html',
    styleUrls: ['./delayed-spinner.component.scss']
})
// Spinner component that only shows the spinner after a configurable delay (in ms)
export class DelayedSpinnerComponent implements OnInit, OnDestroy {
    @Input() delay = 500;
    @Input() class!: string;
    @Input() color?: ThemePalette;
    @Input() diameter = 100;

    show = false;

    destroy$ = new Subject<boolean>();

    ngOnInit(): void {
        const observable = from([true])
            .pipe(
                delay(this.delay)
            );

        observable.pipe(takeUntil(this.destroy$), take(1))
            .subscribe(value => this.show = value);
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
