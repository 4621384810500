<header class="header-container" [style.background-color]='organisationStyling.colorTwo'
    [class.darkText]='organisationStyling.colorTwoText === "Dark"'
    [class.lightText]='organisationStyling.colorTwoText === "Light"' *ngIf="errorLoadingImage; else imageHeader">
    <div class="header-content-container">
        <div class='title'>
            <span [translate]='"knowledge_test.text.score_title"'>
                Your result
            </span>
        </div>
    </div>
</header>
<ng-template #imageHeader>
    <header class="header-container" [style.background-color]='organisationStyling?.colorTwo'
    [class.darkText]='organisationStyling?.colorTwoText === "Dark"'
    [class.lightText]='organisationStyling?.colorTwoText === "Light"' *ngIf="loadingImage">
        <div class="header-content-container">
            <div class='title'>
                <span [translate]='"knowledge_test.text.score_title"'>
                    Your result
                </span>
            </div>
        </div>
    </header>
    <header class='image-container' [class.h-0]='loadingImage' [class.invisible]='loadingImage' >
        <cp-image [blobId]='eventImage' [organisationId]="organisationId"
            [width]="'100%'" [showOverlay]="true" (loaded)='loadingImage = false'
            (loadingError)="errorLoadingImage = true">
        </cp-image>

        <div class="header-content-container">
            <div class='header-content'>
                <div class='title'>
                    <span [translate]='"knowledge_test.text.score_title"'>
                        Your result
                    </span>
                </div>
            </div>
        </div>
    </header>
</ng-template>

<div class='score-container'>
    <div class='score-block'>
        <h2>
            <span [translate]='"knowledge_test.text.your_score_label"'>
                Your result for this knowledge test
            </span>
        </h2>
        <div class='d-flex justify-content-center' *ngIf="!errorLoading && !loading">
            <cp-radial-progress [size]="100" [bars]="progressBar" label='custom' [customLabel]='scoreAsTenScale'>
            </cp-radial-progress>
        </div>
    </div>

    <div *ngIf='errorLoading; else loader'>
        <div class='alert alert-warning'>
            <span [translate]="'knowledge_test.text.result_load_error'">
                Something went wrong while trying to load your results. Please try again later.
            </span>
        </div>
    </div>

    <ng-template #loader>
        <div *ngIf='loading; else feedbackBlock'
            class='loader-container d-flex justify-content-center align-items-center'>
            <cp-delayed-spinner></cp-delayed-spinner>
        </div>
        <ng-template #feedbackBlock>
            <div class='feedback-block'>
                <div *ngIf='!hasAdvisedRetakes && !hasForcedRetakes; else feedback'>
                    <span [translate]='"knowledge_test.text.no_retakes_prompt"'>
                        Well done! You have answered all questions correctly! You are very well prepared.
                    </span>
                </div>

                <ng-template #feedback>
                    <div *ngIf="hasForcedRetakes">
                        <div class='mb-3'>
                            <span [translate]='"knowledge_test.text.forced_retakes_prompt"'>
                                You answered too many questions about these briefings incorrectly. You will receive tasks to retake them.
                            </span>
                        </div>
                        <ul>
                            <li *ngFor="let name of forcedRetakeBriefings">{{name}}</li>
                        </ul>
                    </div>
                    <div *ngIf="hasAdvisedRetakes">
                        <div class='mb-3'>
                            <span [translate]='"knowledge_test.text.advised_retakes_prompt"'>
                                You answered some questions about these briefings incorrectly. Please consider taking another look at them.
                            </span>
                        </div>
                        <ul>
                            <li *ngFor="let name of advisedRetakeBriefings">{{name}}</li>
                        </ul>
                    </div>
                </ng-template>
            </div>
        </ng-template>
    </ng-template>

    <button mat-flat-button class="cp-button" color="primary" (click)="finishTest()" [loading]="finishing" connection-dependant>
        <span [translate]='"knowledge_test.button.finish_test"'>
            Finish test
        </span>
    </button>
</div>
