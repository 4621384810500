<mat-form-field [hideRequiredMarker]="hideRequiredMarker" [ngClass]="classes">
    <mat-label>
        {{ labelKey | translate }}
    </mat-label>
    <input matInput [value]='value' [required]='required' #formField [type]="showPassword ? 'text' : 'password'"
        (input)="triggerOnChange($event.target)" [errorStateMatcher]="errorStateMatcher" [formControl]="_control"
        (blur)="onBlur()" (input)="changed.emit()">
    <button type="button" mat-icon-button matSuffix (click)='showPassword = !showPassword;'
        [attr.aria-label]="'Hide password'" [attr.aria-pressed]="!showPassword" tabindex="-1">
        <mat-icon class="cp-icon" [class.light]='light'>
            {{ showPassword ? 'visibility' : 'visibility_off' }}
        </mat-icon>
    </button>
    <mat-hint>
        <ng-content></ng-content>
    </mat-hint>
    <mat-error *ngIf="validate && _control?.errors && !_confirmField">
        <span *ngIf="required && _control.errors?.required" [translate]="'account.text.password_required_error'">
            Please enter a password
        </span>
        <span *ngIf="_control.errors?.passwordErrors?.noLowercase"
            [translate]="'account.text.password_lowercase_error'">
            The password should contain a lowercase letter
        </span>
        <span *ngIf="_control.errors?.passwordErrors?.noUppercase"
            [translate]="'account.text.password_uppercase_error'">
            The password should contain a capital letter
        </span>
        <span *ngIf="_control.errors?.passwordErrors?.noDigit" [translate]="'account.text.password_digit_error'">
            The password should contain a digit
        </span>
        <span *ngIf="_control.errors?.passwordErrors?.noSymbol" [translate]="'account.text.password_symbol_error'">
            The password should contain a symbol
        </span>
        <span *ngIf="_control.errors?.minlength" [translate]="'account.text.password_length_error'">
            The password should contain at least 6 characters
        </span>
    </mat-error>
    <mat-error *ngIf="_confirmField">
        <span [translate]="'account.text.password_confirm_doesnt_match'">
            Passwords do not match
        </span>
    </mat-error>
</mat-form-field>
