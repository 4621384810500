<cp-no-connection-message *ngIf='offlineOnLoad; else loader'>
</cp-no-connection-message>

<ng-template #loader>
    <div class='loading-panel' *ngIf='loading; else taskOverviewRef'>
        <cp-delayed-spinner class='m-auto'></cp-delayed-spinner>
    </div>
</ng-template>

<ng-template #taskOverviewRef>
    <div *ngIf='error; else noTasksRef'>
        <div class='mt-3 alert alert-danger'>
            <span [translate]='"tasks.error.could_not_load_tasks"'>
                Your tasks could not be loaded at this moment.
            </span>
        </div>
    </div>
    <ng-template #noTasksRef>
        <div *ngIf='!taskOverview?.tasks || taskOverview.tasks.length === 0; else tasksRef'
            class='alert alert-primary mt-3 mx-1'>
            <span [translate]='"tasks.text.no_tasks_message"'>
                There are currently no tasks available for you.
            </span>
        </div>
        <ng-template #tasksRef>
            <div class='container overview-container pb-1'>
                <header class='py-3' [@fadeInAnimation]>
                    <span class='to-do pr-2'>
                        {{amountToDo}}
                        <span class='text ml-1' [translate]='"general.text.to_do"'>
                            to do
                        </span>
                    </span>
                    <span class='urgent ml-2 mr-3'>
                        <cp-icon name='alert-circle' class='transparent-circle-icon' fill='warn' color='white'
                            size='small'></cp-icon>
                        {{amountUrgent}}
                        <span class='text ml-1' [translate]='"general.text.urgent"'>
                            urgent
                        </span>
                    </span>
                    <span>
                        {{amountCompleted}}
                        <span class='text ml-1' [translate]='"general.text.completed"'>
                            completed
                        </span>
                    </span>
                    <span class='float-right text'>
                        ({{taskOverview.tasks.length}})
                    </span>
                </header>

                <div class='tasks-list' [@fadeInListAnimation]='taskOverview.tasks.length'>
                    <div class='card-container' *ngFor='let task of uncompletedTasks'>
                        <div class='task-card container' (click)='openTask(task)' [class.urgent]='task.isUrgent'
                            [class.disabled]='offline' [class.knowledge-task]='task.type === taskTypes.KnowledgeTest'
                            [class.evaluation-task]='task.type === taskTypes.Evaluation' matRipple
                            [matRippleDisabled]='offline' [matRippleColor]='getRippleColor(task)'>
                            <div class='row'>
                                <div class='col-10 left-side'>
                                    <div class='title-container'>
                                        <div class='title'>
                                            <span *ngIf="task.type !== taskTypes.Briefing; else taskTitleRef"
                                                [translate]='task.type === taskTypes.KnowledgeTest
                                            ? "general.text.knowledge_test"
                                            : "general.text.evaluation"'>
                                                Knowledge test
                                            </span>
                                            <ng-template #taskTitleRef>
                                                {{ task.title }}
                                            </ng-template>
                                        </div>
                                    </div>
                                    <div class='info-container'>
                                        <div>
                                            <div class='date-container col-7' [class.invisible]='!task.deadlineOn'>
                                                <span class='due-text' [translate]='"general.text.due_date"'>
                                                    Due date
                                                </span>
                                                <span class='due-date'>
                                                    {{ task.deadlineOn | localizedDate:'mediumDate' }}
                                                </span>
                                            </div>
                                            <div class='progress-container col-5'
                                                *ngIf="task.type === taskTypes.Briefing">
                                                <span class='progress-percentage'>
                                                    {{ task.progressPercentage }}%
                                                </span>
                                                <span class='progress-text' [translate]='"general.text.completed"'>
                                                    Completed
                                                </span>
                                            </div>
                                        </div>
                                        <div *ngIf="task.type === taskTypes.Briefing">
                                            <mat-progress-bar [value]='task.progressPercentage' class='cp-progress-bar'
                                                [class.warn]='task.isUrgent'>
                                            </mat-progress-bar>
                                        </div>
                                    </div>
                                </div>
                                <div class='col-2'>
                                    <div class='task-button d-flex align-items-center'>
                                        <cp-icon [name]='offline ? "eye-off" : "eye"' strokeSize='small'></cp-icon>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class='completed-title'>
                        <span>
                            {{amountCompleted}}
                            <span class='text' [translate]='"general.text.completed"'>
                                completed
                            </span>
                        </span>
                    </div>
                    <div class='card-container' *ngFor='let task of completedTasks'>
                        <div class='task-card done container' (click)='openTask(task)' matRipple
                            [matRippleDisabled]='offline' [class.disabled]='offline'
                            [matRippleColor]='getRippleColor(task)'>
                            <div class='row'>
                                <div class='col-10 left-side'>
                                    <div class='title-container'>
                                        <div class='title'>
                                            {{ task.title }}
                                        </div>
                                    </div>
                                    <div class='info-container'>
                                        <div>
                                            <div class='date-container col-7' [class.invisible]='!task.deadlineOn'>
                                                <span class='due-text' [translate]='"general.text.due_date"'>
                                                    Due date
                                                </span>
                                                <span class='due-date'>
                                                    {{ task.deadlineOn | localizedDate:'mediumDate' }}
                                                </span>
                                            </div>
                                            <div class='progress-container col-5'>
                                                <span class='progress-percentage'>
                                                    {{ task.progressPercentage }}%
                                                </span>
                                                <span class='progress-text' [translate]='"general.text.completed"'>
                                                    Completed
                                                </span>
                                            </div>
                                        </div>
                                        <div>
                                            <mat-progress-bar [value]='task.progressPercentage'
                                                class='cp-progress-bar primary'>
                                            </mat-progress-bar>
                                        </div>
                                    </div>
                                </div>
                                <div class='col-2'>
                                    <div class='task-button d-flex align-items-center'>
                                        <cp-icon [name]='offline ? "eye-off" : "eye"' strokeSize='small'></cp-icon>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </ng-template>
</ng-template>
