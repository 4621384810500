import { IBriefingSectionModel } from '@core/models';
import { IQuestionModel } from './question-model';

export class BriefingPageModel implements IBriefingPageModel {
    imageBlobId?: string;
    finished?: boolean;
    currentQuestionIndex?: number;

    constructor(
        public type: string,
        public position: number,
        public title: string,
        public subtitle: string,
        public sections?: IBriefingSectionModel[],
        public questions?: IQuestionModel[],
    ) { }
}

export interface IBriefingPageModel {
    type: string;
    position: number;
    title: string;
    subtitle: string;
    sections?: IBriefingSectionModel[];
    questions?: IQuestionModel[];
    imageBlobId?: string;

    finished?: boolean;
    currentQuestionIndex?: number;
}
