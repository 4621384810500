import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ContactMethodType, IEvaluationData } from '@evaluation/models';
import { AuthenticationService } from '@core/services';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AccountDataService } from '@account/services';
import { pairwise, startWith } from 'rxjs/operators';
import { IntlTelInputComponent } from '@shared/intl-tel-input';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';

@Component({
    selector: 'cp-open-feedback-page',
    templateUrl: './open-feedback-page.component.html',
    styleUrls: ['./open-feedback-page.component.scss']
})
export class OpenFeedbackPageComponent {
    @Input()
        evaluationData!: IEvaluationData;

    @Input()
        eventName!: string;

    @Input()
        active = false;

    @Input()
        error!: boolean;

    @Input()
        generalFeedback!: boolean;

    @Input()
        followup!: boolean;

    @Input()
        requireFollowupReason!: boolean;

    @ViewChild(IntlTelInputComponent)
        phoneInput!: IntlTelInputComponent;

    @ViewChild(CdkTextareaAutosize)
        autoSize!: CdkTextareaAutosize;

    @Output()
        formInvalid = new EventEmitter<string>();

    contactByPhone = false;

    phonenumberControl: UntypedFormControl;
    feedbackForm: UntypedFormGroup;

    private readonly VALID_STRING = 'VALID';

    constructor(
        fb: UntypedFormBuilder,
        accountData: AccountDataService,
        private auth: AuthenticationService
    ) {
        this.phonenumberControl = fb.control('');

        this.feedbackForm = fb.group({
            phonenumber: this.phonenumberControl
        });

        this.feedbackForm.statusChanges.subscribe(result => {
            this.formInvalid.emit(result);
        });

        this.phonenumberControl.valueChanges.pipe(startWith(''), pairwise()).subscribe(([prev, next]: [string, string]) => {
            this.evaluationData.phonenumber = next;
        });
    }

    onRequireFollowupChanged(checked: boolean): void {
        if (checked === false) {
            this.formInvalid.emit(this.VALID_STRING);
        }

        if (checked === true && !this.evaluationData.followupMethod) {
            this.evaluationData.followupMethod = ContactMethodType.Email;
        }
    }

    onContactMethodChange(checked: boolean): void {
        this.contactByPhone = checked;

        if (checked) {
            this.evaluationData.followupMethod = ContactMethodType.Phone;
        }
        else {
            this.evaluationData.followupMethod = ContactMethodType.Email;
            this.formInvalid.emit(this.VALID_STRING);
        }
    }

    hasDefinedPhonenumber(): boolean {
        return !!this.auth.claims?.phone_number;
    }
}
