import { EndPoint } from '@core/constants';
import { UrlResolver } from '@core/utils';
import { HttpClient } from '@angular/common/http';

import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';

export class TranslationLoader implements TranslateLoader {

    constructor(private http: HttpClient) { }

    public getTranslation(lang: string): Observable<any> {
        if (lang === 'us' || lang === 'gb') {
            lang = 'en';
        }
        return this.http.get(UrlResolver.getUrl(EndPoint.translation, `app/${lang}`));
    }
}
