import { OverviewDataService } from '@overview/services';
import { filter, switchMap } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FeatureToggleService, GlobalsService, LoggingService } from '@core/services';
import { errorLogTap } from '@core/handlers';

@Component({
    templateUrl: './task-hub.component.html',
    styleUrls: ['./task-hub.component.scss']
})
export class TaskHubComponent implements OnInit {

    showTabs = false;
    finishedLoadingChecklists = false;
    finishedLoadingTasks = false;

    organisationId!: number;
    eventId!: number;

    selectedTabIndex = 0;
    currentTab: TabType = 'Briefings';
    hasTasks = false;

    constructor(
        globals: GlobalsService,
        public featureData: FeatureToggleService,
        private overview: OverviewDataService,
        public route: ActivatedRoute,
        private logging: LoggingService,
    ) {
        globals.showFooter();
        globals.changeHeader({ titleKey: 'tasks.text.my_tasks' });
    }

    get loadingTabs(): boolean {
        return !this.finishedLoadingTasks && !this.finishedLoadingChecklists;
    }

    ngOnInit(): void {
        this.organisationId = this.route.snapshot.params.organisationId;
        this.eventId = this.route.snapshot.params.eventId;
    }

    onClickBriefings(): void {
        this.currentTab = 'Briefings';
        this.selectedTabIndex = 0;
    }


    onClickChecklist(): void {
        this.currentTab = 'Checklist';
        this.selectedTabIndex = 1;
    }

    tasksLoaded(hasTasks: boolean): void {
        this.finishedLoadingTasks = true;
        this.hasTasks = hasTasks;
        this.checkChecklists();
    }

    private checkChecklists(): void {
        this.featureData.isFeatureActive(this.organisationId, 'Checklists')
            .pipe(
                filter((isActive) => isActive),
                switchMap(() => this.overview.hasChecklists(this.organisationId, this.eventId)),
                errorLogTap(this.logging, this.organisationId)
            )
            .subscribe({
                next: (isActive) => {
                    this.showTabs = isActive;

                    if (this.showTabs && !this.hasTasks || this.showTabs && this.route.snapshot.fragment === 'checklists') {
                        this.onClickChecklist();
                    }

                    setTimeout(() => {
                        this.finishedLoadingChecklists = true;
                    }, 500);
                },
                error: () => {
                    this.showTabs = false;
                    this.finishedLoadingChecklists = true;
                }
            });
    }
}

type TabType = 'Briefings' | 'Checklist';
