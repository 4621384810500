<div class='planning-searchbar' [class.off-screen]='!showingSearchbar' [style.top]='95 + headerHeight + "px"'>
    <mat-form-field class='cp-light-thin-form-field w-100 background' [class.long-suffix]='query'>
        <mat-label [translate]="'general.text.search'">
            Search...
        </mat-label>
        <input matInput type="text" [(ngModel)]='query' (ngModelChange)="onQueryChanged($event)" (keydown.enter)='onSearchDown()' #queryInputRef>
        <div matTextSuffix class='buttons d-flex justify-content-between'>
            <ng-container *ngIf='query'>
                <div class='counter pr-1'>
                    {{ currentQueryIndex !== undefined ? currentQueryIndex + 1 : '0' }} / {{ queryResults?.length || 0 }}
                </div>
                <div class='button' [class.active]='canSearchUp' (click)='onSearchUp($event)'>
                    <cp-icon name='chevron-down' [rotated]='true' size='x-small' color='currentColor'></cp-icon>
                </div>
                <div class='button' [class.active]='canSearchDown' (click)='onSearchDown($event)'>
                    <cp-icon name='chevron-down' size='x-small' color='currentColor'></cp-icon>
                </div>
            </ng-container>
            <div class='button active' (click)='onClickCloseSearchbar($event)'>
                <cp-icon name='x' size='x-small' color='currentColor'></cp-icon>
            </div>
        </div>
    </mat-form-field>
</div>
<div class='introduction-text' *ngIf='introductionText' [innerHTML]="introductionText"
    glossary-injection [organisationId]="organisationId" [eventId]="eventId"
    [pageInformation]="pageTrackingInformation">
</div>
<div class='blocks-list'>
    <div class='time-bar'>
    </div>
    <div class='block' *ngFor='let block of visibleContent; let index = index' #block [attr.data-index]="index"
        [class.active]='!block.isPast && isActiveBlock(block)' [class.expanded]='block.isExpanded' [class.search-item]='index === searchActiveIndex'
        [class.past]='block.isPast || isPastBlock(block)' (click)='onClickBlock(block)'>
        <div *ngIf='!block.isPast && isActiveBlock(block)' class='active-time-bar'></div>
        <div *ngIf='hasDateBreak(block, index)' class='date-break'>
            <span class='day'>
                {{ block.startDate | localizedDate:'EEEE'}}
            </span>
            <span class='date'>
                {{ block.startDate | localizedDate:'longDate'}}
            </span>
        </div>
        <div class='collapsed-info'>
            <div class='flag' [class.flagged]='isFlagged(block)' *ngIf='block.identifier' (click)='flagBlock($event, block)'>
                <mat-icon>
                    {{ isFlagged(block) ? 'label' : 'label_outlined' }}
                </mat-icon>
            </div>
            <div class='time-indicator'>
                <span class='pip'></span>
                <span class='time' [class.short-time]="!isUSLocale" *ngIf='block.startDate'>
                    {{ block.startDate | localizedDate:'shortTime' }}
                </span>
            </div>
            <div class='content'>
                <div class='title'>
                    <span *ngIf='block.title; else notitle' glossary-injection [organisationId]="organisationId" [eventId]="eventId"
                    [pageInformation]="pageTrackingInformation">
                        {{ block.title }}
                    </span>
                    <ng-template #notitle>
                        <span [translate]='"general.text.title"'>
                            Title
                        </span>
                    </ng-template>
                </div>
                <div class='subtitle' *ngIf='block.subtitle' glossary-injection [organisationId]="organisationId" [eventId]="eventId"
                [pageInformation]="pageTrackingInformation">
                    {{ block.subtitle }}
                </div>
                <div class='times'>
                    <div class='time-block' *ngIf='block.startDate'>
                        {{ block.startDate | localizedDate:getTimeFormatForBlock(block) }}
                        <div *ngIf='block.endTime'>
                            &nbsp;-&nbsp;{{ block.endTime | localizedDate:getTimeFormatForBlock(block) }}
                        </div>
                    </div>
                    <div class='location-block' *ngIf='block.location' glossary-injection [organisationId]="organisationId"
                    [eventId]="eventId"[pageInformation]="pageTrackingInformation">
                        , {{ block.location }}
                    </div>
                </div>
                <ng-container *ngFor='let highlightedTitle of block.highlightedTitles'>
                    <div class='highlighted-title' [style.backgroundColor]='highlightedTitle.color'
                        [class.darkText]='highlightedTitle.textColor === "Dark"'
                        [class.lightText]='highlightedTitle.textColor === "Light"' *ngIf='highlightedTitle.title'
                        glossary-injection [organisationId]="organisationId" [eventId]="eventId"
                        [pageInformation]="pageTrackingInformation"
                        (click)='onClickExpandTitle(highlightedTitle, $event)'>
                        <div class="w-100 d-flex justify-content-between">
                            {{ highlightedTitle.title }}
                            <div *ngIf="highlightedTitle.images && highlightedTitle.images.length" class="buttons in-title">
                                <div class='button'>
                                    <cp-icon size='x-small' class='chevron' [class.flipped]='highlightedTitle.isExpanded' name='chevron-down'>
                                    </cp-icon>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="highlightedTitle.isExpanded && highlightedTitle.images && highlightedTitle.images.length" class="d-flex image-row">
                            <div *ngFor='let image of highlightedTitle.images; let index = index' class='col-3 image-wrapper in-title'
                            (click)="onClickImage(image, $event)">
                                <div class="image-container mt-2">
                                    <div class="zoom-button">
                                        <cp-icon name='zoom-in' color='currentColor' size="x-small"></cp-icon>
                                    </div>
                                    <img [src]='image.placeholder' />
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
            <div class='buttons' *ngIf='hasContent(block)'>
                <div class='button'>
                    <cp-icon size='x-small' class='chevron' [class.flipped]='block.isExpanded' name='chevron-down'>
                    </cp-icon>
                </div>
            </div>
        </div>
        <div class='expanded-content' *ngIf='hasContent(block) && block.isExpanded' (click)='$event.stopPropagation()'>
            <div *ngIf='block._sanitizedDetails' [innerHTML]="block._sanitizedDetails" class="text-section mb-2"
                glossary-injection [organisationId]="organisationId" [eventId]="eventId"
                [pageInformation]="pageTrackingInformation">
            </div>
    
            <div *ngIf="block.images && block.images.length" class="row">
                <div *ngFor='let image of block.images; let index = index' class='col-3 image-wrapper'
                (click)="onClickImage(image, $event)">
                    <div class="image-container mt-2">
                        <cp-delayed-spinner *ngIf='image.loading; else zoomButtonRef' [diameter]='25'>
                        </cp-delayed-spinner>
                        <ng-template #zoomButtonRef>
                            <div class="zoom-button">
                                <cp-icon name='zoom-in' color='white' size="x-small"></cp-icon>
                            </div>
                        </ng-template>
                        <img [src]='image.placeholder' />
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
