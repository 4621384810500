import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { GlobalsService } from '@core/services';

@Component({
    templateUrl: './image-viewer.component.html',
    styleUrls: ['./image-viewer.component.scss']
})
export class ImageViewerOverlayComponent implements OnInit {

    @Input()
        imageUrl!: string | SafeUrl;

    @Output()
        closed = new EventEmitter<void>();

    constructor(private globals: GlobalsService) { }

    ngOnInit(): void {
        this.globals.unlockScreenOrientation();
    }

    closeOverlay(): void {
        this.globals.lockScreenOrientation();
        this.closed.emit();
    }
}
