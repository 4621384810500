import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ThemePalette } from '@angular/material/core';

export class ConfirmDialogData {
    constructor(
        public translationKey: string,
        // has to be any because of ngx-translate definition

        public translationParams?: any,
        public continueColor: ThemePalette | undefined = 'primary',
        public continueAndColor: ThemePalette | undefined = 'primary',
        public continueAnd = false,
        public confirmKey = 'general.button.continue',
        public cancelKey = 'general.button.cancel',
        public continueAndKey = 'general.button.save_and_continue',
        public bigKeys = false
    ) {}
}

export type ConfirmDialogResult = 'cancel' | 'continue' | 'continueAnd';

@Component({
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {

    constructor(@Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData) {}

}
