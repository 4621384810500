<div class='settings-panel'>
    <div class='settings-header'>
        <span [translate]='"account.text.account_settings"'>
            Account settings
        </span>
        <div class="close-button">
            <button mat-icon-button (click)='closeSettings()'>
                <cp-icon name='x'></cp-icon>
            </button>
        </div>
    </div>

    <div class='settings-body cp-light'>
        <cp-no-connection-message *ngIf='offlineBeforeLoad; else settingsBody'>
        </cp-no-connection-message>

        <ng-template #settingsBody>
            <div class='no-connection-bar' *ngIf='offline'>
                <cp-no-connection-bar></cp-no-connection-bar>
            </div>

            <div *ngIf='loadingSettings; else settingsPanel' class='loading-panel'>
                <cp-delayed-spinner class='m-auto'></cp-delayed-spinner>
            </div>

            <ng-template #settingsPanel>
                <div *ngIf='loadingSettingsFailed; else settingsPanelOpened' class='mt-3 alert alert-danger'>
                    <span [translate]='"account.error.could_not_retrieve_settings"'>
                        Something went wrong retrieving your account settings, please try again later.
                    </span>
                </div>

                <ng-template #settingsPanelOpened>
                    <ng-container *ngIf='showNotificationOptIn'>
                        <h4>
                            <span [translate]='"notification.text.push_notifications"'>
                                Push notifications
                            </span>
                        </h4>

                        <div class='px-3 mt-2'>
                            <div class='col-12 push-explainer'>
                                <span [translate]='"notification.text.push_notification_enable_explainer"'>
                                    Currently you have push notifications turned off, we use push notifications to bring
                                    you
                                    up to speed with any changes within your events. Press the button below to turn them
                                    on.
                                </span>
                            </div>
                            <div class='col-12'>
                                <button mat-raised-button class='col-12 cp-button' color='primary' connection-dependant
                                    (click)='onOptInPushNotification()'>
                                    <cp-icon class='mr-2' name='bell' size='x-small'></cp-icon>
                                    <span [translate]='"notification.text.enable_push_notifications"'>
                                        Enable push notifications
                                    </span>
                                </button>
                            </div>
                        </div>
                    </ng-container>

                    <h4>
                        <span [translate]='"general.text.preferred_language"'>
                            Preferred Language
                        </span>
                    </h4>

                    <div class='px-3 mt-2'>
                        <mat-form-field class='col-12 no-padding'>
                            <mat-select [(value)]='currentLanguage' (selectionChange)='setLanguage($event)'
                                connection-dependant>
                                <mat-option [value]='"gb"'>
                                    English (EU)
                                </mat-option>
                                <mat-option [value]='"us"'>
                                    English (US)
                                </mat-option>
                                <mat-option [value]='"nl"'>
                                    Nederlands (Dutch)
                                </mat-option>
                                <mat-option [value]='"es"'>
                                    Español (Spanish)
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <h4>
                        <span [translate]='"general.text.personal_information"'>
                            Personal Information
                        </span>
                    </h4>
                    <div *ngIf='changedEmail; else settingsForm'>
                        <div class='p-3'>
                            <div class='alert alert-warning col-12'>
                                <span
                                    [translate]='justChanged ? "account.text.email_change_message" : "account.text.email_change_pending"'
                                    [translateParams]='{ newEmail: changedEmail }'></span>
                            </div>

                            <div class="row text-center mb-4 mx-2 d-flex justify-content-center">
                                <cp-verification-code-input #input class="cp-light"
                                    (submittedCode)="onSubmittedCode($event)">
                                </cp-verification-code-input>
                            </div>

                            <div class='alert alert-danger mt-3' *ngIf='resetFailed'>
                                <span [translate]='"account.error.could_not_reset_mail"'>
                                    Something went wrong resetting your email, please try again later.
                                </span>
                            </div>

                            <div class='alert alert-danger mt-3' *ngIf='confirmFailed'>
                                <span [translate]='"account.error.invalid_token"'>
                                    The supplied code was incorrect, your email address was not changed.
                                </span>
                            </div>

                            <div class='alert alert-danger mt-3' *ngIf='resendFailed'>
                                <span [translate]='"account.error.could_not_resend_code"'>
                                    Something went wrong sending a new code, please try again later.
                                </span>
                            </div>

                            <button class='col-12' mat-raised-button color='primary' connection-dependant
                                [loading]='resendingEmailConfirmationCode'
                                [disabled]='confirmingNewEmail || resettingChangedEmail' (click)='onRequestNewCode()'>
                                <span [translate]='"general.text.resend_token"'>
                                    Nieuwe bevestigings code aanvragen
                                </span>
                            </button>

                            <button class='col-12 mt-2' mat-raised-button color='accent'
                                [loading]='resettingChangedEmail' connection-dependant
                                [disabled]='resendingEmailConfirmationCode || confirmingNewEmail'
                                (click)='onResetChangedEmail()'>
                                <span [translate]='"account.button.keep_current_mail"'>
                                    Huidige adres behouden
                                </span>
                            </button>
                        </div>
                    </div>

                    <ng-template #settingsForm>
                        <form class='p-3' [formGroup]='accountSettingsForm' (ngSubmit)='submitSettings()'>
                            <mat-form-field class="col-12">
                                <mat-label [translate]="'account.text.first_name'">
                                    First Name
                                </mat-label>
                                <input matInput formControlName="firstName" maxlength='100' autocomplete='given-name' />
                                <mat-error *ngIf="firstNameControl.touched && firstNameControl.errors">
                                    <span [translate]="'account.error.first_name_required'"
                                        *ngIf='firstNameControl.errors?.required'>
                                        A first name is required
                                    </span>
                                    <span [translate]="'account.error.first_name_max_size'"
                                        *ngIf='firstNameControl.errors?.required'>
                                        You can enter at most 100 characters for your first name
                                    </span>
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field class="col-12">
                                <mat-label [translate]="'general.text.middle_name'">
                                    Middle Name
                                </mat-label>
                                <input matInput formControlName="middleName" maxlength='100'
                                    autocomplete='additional-name' />
                                <mat-error *ngIf="middleNameControl.touched && middleNameControl.errors">
                                    <span [translate]="'account.error.first_name_max_size'"
                                        *ngIf='middleNameControl.errors?.maxlength'>
                                        You can enter at most 100 characters for your middle name
                                    </span>
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field class="col-12">
                                <mat-label [translate]="'account.text.last_name'">
                                    Last Name
                                </mat-label>
                                <input matInput formControlName="lastName" maxlength='100' autocomplete="family-name" />
                                <mat-error *ngIf="lastNameControl.touched && lastNameControl.errors">
                                    <span [translate]="'account.error.last_name_required'"
                                        *ngIf='lastNameControl.errors?.required'>
                                        A last name is required
                                    </span>
                                    <span [translate]="'account.error.last_name_max_size'"
                                        *ngIf='lastNameControl.errors?.required'>
                                        You can enter at most 100 characters for your last name
                                    </span>
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field class="col-12 mt-1">
                                <mat-label [translate]="'general.text.email'">
                                    E-mailaddress
                                </mat-label>
                                <input matInput formControlName="email" maxlength='256' autocomplete="email" />
                                <mat-error *ngIf="emailControl.touched && emailControl.errors">
                                    <span *ngIf="emailControl.errors?.required"
                                        [translate]="'account.text.email_required'">
                                        Please enter your e-mailaddress
                                    </span>
                                    <span *ngIf="emailControl.errors?.email" [translate]="'account.text.email_invalid'">
                                        Please enter a valid e-mailaddress
                                    </span>
                                    <span *ngIf="emailControl.errors?.emailInUse"
                                        [translate]="'account.text.email_in_use'">
                                        That e-mailaddress is already in use
                                    </span>
                                    <span *ngIf='emailControl.errors?.maxlength'
                                        [translate]="'account.error.email_max_size'">
                                        You can enter at most 256 characters for your e-mailaddress
                                    </span>
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field class="col-12 mt-1" *ngIf='requiresConfirmEmail'>
                                <mat-label [translate]="'account.text.confirm_email'">
                                    Confirm e-mailaddress
                                </mat-label>
                                <input matInput formControlName="emailConfirm" [errorStateMatcher]="emailFieldMatcher"
                                    autocomplete="off" />
                                <mat-error *ngIf="emailConfirmControl.touched">
                                    <span *ngIf="accountSettingsForm.errors?.emailsDontMatch"
                                        [translate]="'account.text.confirm_email_doesnt_match'">
                                        E-mailaddresses do not match
                                    </span>
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field class='phone-field col-12 mt-1' floatLabel="always"
                                [class.large-error-offset]='phonenumberControl.errors?.validatePhoneNumber'>
                                <mat-label [translate]="'general.text.phone'">
                                    Phone number
                                </mat-label>
                                <cp-intl-tel-input class='cp-tel-input' [preferredCountries]="['nl', 'gb', 'us', 'es', 'de']"
                                    format='national' [enableSearch]='true' name='phonenumber'
                                    formControlName='phonenumber'
                                    [searchPlaceholder]='"general.text.search" | translate'>
                                </cp-intl-tel-input>
                                <mat-error *ngIf="phonenumberControl.touched && phonenumberControl.errors">
                                    <span [translate]='"account.text.phone_invalid"'
                                        [translateParams]='{ format: phoneInput.selectedCountry?.placeHolder || "" }'
                                        *ngIf='phonenumberControl.errors?.validatePhoneNumber'>
                                        The number you entered is not a valid phone number for the selected country.
                                        Example
                                        of
                                        accepted format:
                                    </span>
                                </mat-error>
                            </mat-form-field>

                            <cp-password-field formControlName="newPassword" [validate]='!!newPasswordControl.value'
                                class='mt-1' required='false' [light]='true' [labelKey]="'account.text.new_password'">
                            </cp-password-field>

                            <cp-password-field formControlName="newPasswordConfirm" class='mt-1' hideRequiredMarker [light]='true'
                                *ngIf='requiresConfirmPassword' confirmField
                                [labelKey]="'account.text.confirm_new_password'">
                            </cp-password-field>

                            <cp-password-field formControlName="currentPassword"
                                [labelKey]="'account.text.current_password'" class='mt-1 mb-3' hideRequiredMarker
                                [light]='true' [validate]='true' *ngIf='requiresPassword'>
                                <span [translate]='"account.text.password_required"'>
                                    With the changes you made it is required to enter your current password.
                                </span>
                            </cp-password-field>

                            <div class='alert alert-danger mt-3' *ngIf='errorSaving'>
                                <span [translate]='"account.error.could_not_save_settings"'>
                                    Your settings could not be saved, please try again later.
                                </span>
                            </div>

                            <div class='alert alert-danger mt-3' *ngIf='incorrectPassword'>
                                <span [translate]='"account.error.incorrect_password"'>
                                    You entered an incorrect password, your account settings were not changed
                                </span>
                            </div>

                            <div class='offset-sm-8 col-12 col-sm-4' [class.mt-3]='requiresPassword'>
                                <button [loading]="savingSettings" mat-raised-button color="primary"
                                    connection-dependant class="cp-button col-12">
                                    <cp-icon size='small' class='mr-1' name='save'></cp-icon>
                                    <span [translate]="'general.button.save'">
                                        Save
                                    </span>
                                </button>
                            </div>
                        </form>
                    </ng-template>

                    <h4>
                        <span [translate]='"general.routes.signout"'>
                            Sign out
                        </span>
                    </h4>

                    <div class='p-3'>
                        <div class='col-12'>
                            <button mat-raised-button class='col-12 cp-button' color='accent' (click)='signout()'
                                connection-dependant>
                                <span [translate]='"general.routes.signout"'>
                                    Sign out
                                </span>
                            </button>
                        </div>
                    </div>

                    <ng-container *ngIf="showResetTour">
                        <h4>
                            <span [translate]='"general.text.guided_tour"'>
                                Guided tour
                            </span>
                        </h4>

                        <div class='px-3 mt-2'>
                            <div class='col-12 push-explainer'>
                                <span [translate]='"general.text.reset_tour_explainer"'>
                                    Here you can reset the tour walkthrough of the Catchphrase app if you need a refresher.
                                </span>
                            </div>
                            <div class='col-12'>
                                <button mat-raised-button class='col-12 cp-button' color='warn'
                                    (click)='resetTour()'>
                                    <span [translate]='"general.text.reset_tour"'>
                                        Reset tour
                                    </span>
                                </button>
                            </div>
                        </div>
                    </ng-container>

                    <ng-container *ngIf='showNotificationOptOut'>
                        <h4>
                            <span [translate]='"notification.text.push_notifications"'>
                                Push notifications
                            </span>
                        </h4>

                        <div class='px-3 mt-2'>
                            <div class='col-12 push-explainer'>
                                <span [translate]='"notification.text.push_notification_disable_explainer"'>
                                    Currently you have push notifications turned on, we use push notifications to bring
                                    you
                                    up to speed with any changes within your events.
                                </span>
                            </div>
                            <div class='col-12'>
                                <button mat-raised-button class='col-12 cp-button' color='warn' connection-dependant
                                    (click)='onOptOutPushNotification()'>
                                    <cp-icon class='mr-2' name='bell-off' size='x-small'></cp-icon>
                                    <span [translate]='"notification.text.disable_push_notifications"'>
                                        Disable push notifications
                                    </span>
                                </button>
                            </div>
                        </div>
                    </ng-container>

                    <h4>
                        <span [translate]='"account.text.request_account_deletion"'>
                            GDPR
                        </span>
                    </h4>

                    <div class='p-3 mb-5'>
                        <div class='col-12 data-text'>
                            <span [translate]='"account.text.request_account_deletion_message"'>
                                For data related questions you can send an e-mail to: gdpr@catchphrase.io
                            </span>
                        </div>
                    </div>
                </ng-template>
            </ng-template>
        </ng-template>
    </div>
</div>
