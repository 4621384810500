export class EvaluationViewResponseModel {
    constructor(
        public eventName: string,
        public evaluationPoints: IEvaluationPoint[],
        public allowGeneralFeedback: boolean,
        public allowFollowup: boolean,
        public requireFollowupReason: boolean,
        public deadlineOn?: Date,
    ) { }
}

export interface IEvaluationPoint {
    id: number;
    name: string;
    description: string;
    position: number;
    hasNumberRating: boolean;
    hasOpenFeedback: boolean;
}
