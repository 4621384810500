import { Injectable } from '@angular/core';
import { AuthenticationService, GeneralDataService } from '@core/services';
import { Observable, of } from 'rxjs';
import { IWikiTabResponseModel, WikiTabNoteModel } from '@wiki/models';
import { EndPoint } from '@core/constants';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class WikiDataService extends GeneralDataService {
    private readonly WIKI_NOTE_CACHE_DURATION = 1000 * 60 * 60; // One hour

    constructor(private auth: AuthenticationService, http: HttpClient) {
        super(http);
    }

    getWikiTabs(organisationId: number, eventId: number): Observable<IWikiTabResponseModel> {
        return this.getGenericItem<IWikiTabResponseModel>(EndPoint.wiki, `${organisationId}/${eventId}/tabs`);
    }

    getWikiTabNote(organisationId: number, eventId: number, tabId: number, cacheOnly: boolean): Observable<WikiTabNoteModel> {
        const noteKey = `wiki-note-${organisationId}-${eventId}-${this.auth.claims?.sub}-${tabId}`;

        const cachedResult = localStorage.getItem(noteKey);

        // Return cached result
        if (cachedResult !== null) {
            const cachedModel = JSON.parse(cachedResult) as CachedNoteModel;
            if (new Date().getTime() - new Date(cachedModel.cachedOn).getTime() <= this.WIKI_NOTE_CACHE_DURATION) {
                return of(cachedModel.note);
            }
        }

        if (cacheOnly) {
            return of({ note: '' });
        }

        return this.getGenericItem<WikiTabNoteModel>(EndPoint.wiki, `${organisationId}/${eventId}/note/${tabId}`)
            .pipe(map(result => {
                const newModel = { note: result, cachedOn: new Date() };
                localStorage.setItem(noteKey, JSON.stringify(newModel));
                return result;
            }));
    }

    postWikiTabNote(organisationId: number, eventId: number, tabId: number, note: string, cacheOnly: boolean): Observable<unknown> {
        const noteKey = `wiki-note-${organisationId}-${eventId}-${this.auth.claims?.sub}-${tabId}`;
        const cacheModel = { note: new WikiTabNoteModel(note), cachedOn: new Date() };
        localStorage.setItem(noteKey, JSON.stringify(cacheModel));

        if (cacheOnly) {
            return of(false);
        }

        return this.postGenericItemWithResponse<WikiTabNoteModel, void>
        (EndPoint.wiki, `${organisationId}/${eventId}/note/${tabId}`, { note });
    }
}

interface CachedNoteModel {
    note: WikiTabNoteModel;
    cachedOn: Date;
}
