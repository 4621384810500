import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { IntlTelInputComponent } from '@shared/intl-tel-input';
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import {
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
    FormGroupDirective,
    NgForm,
    AbstractControl,
} from '@angular/forms';
import { DarkBodyComponent } from '@global/base-components';
import { ErrorStateMatcher } from '@angular/material/core';
import { ValidatePassword, UniqueEmailValidator, UniqueUsernameValidator } from '@account/validators';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountDataService } from '@account/services';
import { GlobalsService } from '@core/services';
import { MatSelectChange } from '@angular/material/select';
import { ConfirmDialogComponent, ConfirmDialogData, ConfirmDialogResult } from '@shared/components';
import { take } from 'rxjs/operators';

@Component({
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss'],
})
export class RegisterComponent extends DarkBodyComponent implements OnInit, AfterViewInit {
    @ViewChild(IntlTelInputComponent) phoneInput!: IntlTelInputComponent;

    loading = true;

    fromInviteLink = false;

    registering = false;
    registeringFailed = false;

    showEmailConfirmField = false;
    showPasswordConfirmField = false;

    organisationName!: string;

    languageControl: UntypedFormControl;
    usernameControl: UntypedFormControl;
    emailControl: UntypedFormControl;
    confirmEmailControl: UntypedFormControl;
    phonenumberControl: UntypedFormControl;
    firstNameControl: UntypedFormControl;
    middleNameControl: UntypedFormControl;
    lastNameControl: UntypedFormControl;
    passwordControl: UntypedFormControl;
    confirmPasswordControl: UntypedFormControl;
    registerForm: UntypedFormGroup;

    emailFieldMatcher = new ConfirmEmailFieldErrorStateMatcher();
    private _organisationId?: number;
    private _invitationCode!: string;

    constructor(
        fb: UntypedFormBuilder,
        private route: ActivatedRoute,
        private data: AccountDataService,
        private router: Router,
        private translate: TranslateService,
        private dialog: MatDialog,
        globals: GlobalsService
    ) {
        super();
        this.languageControl = fb.control(translate.currentLang, { validators: [Validators.required] });
        this.usernameControl = fb.control('', {
            validators: [Validators.required, Validators.maxLength(50), Validators.pattern('[A-z0-9!?_@\.\-]*')],
            asyncValidators: [UniqueUsernameValidator(data)],
            updateOn: 'blur',
        });
        this.emailControl = fb.control('', {
            validators: [Validators.required, Validators.email, Validators.maxLength(256)],
            asyncValidators: [UniqueEmailValidator(data)],
        });
        this.confirmEmailControl = fb.control('');
        this.phonenumberControl = fb.control('');
        this.firstNameControl = fb.control('', [Validators.required, Validators.maxLength(100)]);
        this.middleNameControl = fb.control('', [Validators.maxLength(100)]);
        this.lastNameControl = fb.control('', [Validators.required, Validators.maxLength(100)]);
        this.passwordControl = fb.control('', [
            Validators.required,
            Validators.minLength(6),
            ValidatePassword(),
        ]);
        this.confirmPasswordControl = fb.control('');
        this.registerForm = fb.group(
            {
                language: this.languageControl,
                username: this.usernameControl,
                email: this.emailControl,
                confirmEmail: this.confirmEmailControl,
                phonenumber: this.phonenumberControl,
                firstName: this.firstNameControl,
                middleName: this.middleNameControl,
                lastName: this.lastNameControl,
                password: this.passwordControl,
                confirmPassword: this.confirmPasswordControl,
            },
            {
                validators: [
                    this.checkEmailConfirm,
                    this.checkPasswordConfirm,
                ],
            }
        );
        globals.hideHeaderAndFooter();
    }

    ngOnInit(): void {
        if (this.route.snapshot.queryParams.organisationId) {
            this.fromInviteLink = true;
            this._organisationId = this.route.snapshot.queryParams.organisationId;
            this._invitationCode = this.route.snapshot.queryParams.token;

            this.usernameControl.setAsyncValidators(UniqueUsernameValidator(this.data, this._invitationCode, this._organisationId));
            this.emailControl.setAsyncValidators(UniqueEmailValidator(this.data, false, this._invitationCode, this._organisationId));

            this.data.validateInviteLink(this._invitationCode, this._organisationId || -1).pipe(take(1))
                .subscribe({
                    next: (result) => {
                        if (!result?.valid) {
                            this.router.navigate(['/account/request-invite']);
                        }
                        this.organisationName = result.organisationName;
                        this.loading = false;

                        setTimeout(() => {
                            this.phoneInput.matMenu.panelClass = 'cp-intl-tel-menu';
                            if (!this.phoneInput.numberInstance) {
                                this.phoneInput.onPhoneNumberChange();
                            }
                        }, 200);
                    },
                    error: () => {
                        this.router.navigate(['/account/request-invite']);
                    }
                });
        } else {
            this.route.queryParams.subscribe((params) => {
                this._invitationCode = params.token;

                this.usernameControl.setAsyncValidators(UniqueUsernameValidator(this.data, this._invitationCode));
                this.emailControl.setAsyncValidators(UniqueEmailValidator(this.data, false, this._invitationCode));

                this.data.getInvitationUserDetails(params.token).subscribe({
                    next: (result) => {
                        this.organisationName = result.organisationName;
                        this.firstNameControl.setValue(result.firstName);
                        this.middleNameControl.setValue(result.middleName);
                        this.lastNameControl.setValue(result.lastName);

                        this.emailControl.setValue(result.email);
                        this.confirmEmailControl.setValue(result.email);
                        this.loading = false;

                        setTimeout(() => {
                            this.phoneInput.matMenu.panelClass = 'cp-intl-tel-menu';
                            if (!this.phoneInput.numberInstance) {
                                this.phoneInput.onPhoneNumberChange();
                            }
                        }, 200);
                    },
                    error: () => {
                        this.loading = false;
                    }});
            });
        }
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit();
        if (this.phoneInput) {
            this.phoneInput.matMenu.panelClass = 'cp-intl-tel-menu';
        }
    }

    checkEmailConfirm(group: AbstractControl): { [key: string]: boolean } | null {
        const email = group.get('email');
        const confirmEmail = group.get('confirmEmail');

        return email?.value?.toUpperCase() === confirmEmail?.value?.toUpperCase()
            ? null
            : { emailsDontMatch: true };
    }

    checkPasswordConfirm(group: AbstractControl): { [key: string]: boolean } | null {
        const password = group.get('password');
        const confirmPassword = group.get('confirmPassword');

        return password?.value === confirmPassword?.value
            ? null
            : { passwordsDontMatch: true };
    }

    register(): void {
        if (this.registerForm.invalid) {
            return;
        }

        if (!this.phonenumberControl.value) {
            const confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
                data: new ConfirmDialogData(
                    'account.text.phonenumber_explainer',
                    { organisationName: this.organisationName },
                    'primary',
                    undefined,
                    false,
                    'account.text.enter_phonenumber',
                    'account.text.dont_enter_phonenumber',
                    undefined,
                    true
                ),
                disableClose: true,
                autoFocus: false
            });

            confirmDialogRef.afterClosed().pipe(take(1))
                .subscribe(
                    (result: ConfirmDialogResult) => {
                        if (result === 'continue') {
                            this.phoneInput.focus();
                        } else {
                            this._registerInternal();
                        }
                    }
                );
        } else {
            this._registerInternal();
        }
    }

    setLanguage(code: MatSelectChange): void {
        this.translate.use(code.value);
        localStorage.setItem('lang', code.value);
    }

    onBlurEmailInput(): void {
        if (this.confirmEmailControl.value !== this.emailControl.value) {
            this.showEmailConfirmField = true;
        }
    }

    onBlurConfirmEmailInput(): void {
        if (this.confirmEmailControl.value === this.emailControl.value) {
            this.showEmailConfirmField = false;
        }
    }

    onBlurPasswordInput(): void {
        if (this.confirmPasswordControl.value !== this.passwordControl.value) {
            this.showPasswordConfirmField = true;
        }
    }

    onBlurConfirmPasswordInput(): void {
        if (this.confirmPasswordControl.value === this.passwordControl.value) {
            this.showPasswordConfirmField = false;
        }
    }

    private _registerInternal(): void {
        this.registeringFailed = false;
        this.registering = true;

        this.data.postRegistration(
            this.usernameControl.value,
            this.emailControl.value,
            this.firstNameControl.value,
            this.middleNameControl.value,
            this.lastNameControl.value,
            this.phonenumberControl.value,
            this.passwordControl.value,
            this._invitationCode,
            this.languageControl.value,
            this.fromInviteLink,
            this._organisationId
        ).subscribe({
            next: (data) => {
                localStorage.setItem('uid', data.value);
                this.router.navigate(['/account/confirm']);
                this.registering = false;
            },
            error: () => {
                this.registeringFailed = true;
                this.registering = false;
            }
        });
    }
}

export class ConfirmEmailFieldErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(
        control: UntypedFormControl | null,
        form: FormGroupDirective | NgForm | null
    ): boolean {
        const invalidCtrl = control && control.touched && control.invalid;
        const invalidParent =
            control &&
            control.parent &&
            control.parent.invalid &&
            (control.touched || (form && form.submitted)) &&
            control.parent.hasError('emailsDontMatch');

        return !!(invalidCtrl || invalidParent);
    }
}
