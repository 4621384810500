import { BriefingSectionType, IBriefingSectionModel } from '@core/models';

export class DividerSectionModel implements IBriefingSectionModel {
    type: BriefingSectionType = BriefingSectionType.divider;

    content?: string;

    _content?: DividerSectionContent;

    constructor(content?: DividerSectionContent) {
        if (!content) {
            this._content = {
                height: 1,
                colour: '#000000',
                style: DividerStyle.solid
            };
        } else {
            this._content = {
                height: content.height,
                colour: content.colour,
                style: content.style
            };
        }
    }
}

export interface DividerSectionContent {
    height: number;
    colour: string;
    style: DividerStyle;
}

export enum DividerStyle {
    'solid',
}
