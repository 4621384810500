import { Component, Input } from '@angular/core';

@Component({
    selector: 'cp-no-connection-message',
    templateUrl: './no-connection-message.component.html',
    styleUrls: ['./no-connection-message.component.scss']
})
export class NoConnectionMessageComponent {
    @Input()
        shouldCache = false;
}
