import { environment } from '@env';
import { filter, take } from 'rxjs/operators';
import { UrlResolver } from '@core/utils';
import { EndPoint } from '@core/constants';
import { AuthenticationService, ConnectionService } from '@core/services';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { retry } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LoggingService {
    constructor(
        private http: HttpClient,
        private connection: ConnectionService,
        private auth: AuthenticationService,
    ) {}

    public logError(error: any, organisationId?: number, bePath?: string): void {
        if (!error) {
            error = {};
        }
        error.callTime = new Date();

        if (this.auth.isAnonymous) {
            error.anonymousUserCode = this.auth.claims?.anonymous_code;
        }

        const navigator: any = window.navigator;
        const userInPwa: boolean = ('standalone' in window.navigator) && navigator['standalone']

        const entry: ErrorLogEntry = {
            organisationId,
            userInPwa,
            fePath: window.location.href,
            bePath,
            error
        };

        // If currently offline schedule the tracking event for as soon as internet is restored
        if (!this.connection.online) {
            this.connection.onlineState$.pipe(filter(x => x), take(1)).subscribe(
                () => { this._send(entry); }
            );
        } else {
            this._send(entry);
        }

    }

    private _send(entry: ErrorLogEntry): void {
        const url = UrlResolver.getUrl(EndPoint.tracking, 'error');
        this.http.post(url, entry).pipe(retry(3), take(1)).subscribe();
    }
}

interface ErrorLogEntry {
    organisationId?: number;
    userInPwa: boolean;
    fePath: string;
    bePath?: string;
    error: unknown;
}