import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    SimpleChanges,
    ViewChildren,
    HostListener,
    ViewChild
} from '@angular/core';
import { DomSanitizer, SafeHtml, SafeUrl } from '@angular/platform-browser';
import { EMPTY_IMAGE } from '@core/constants';
import { ImageDataService } from '@core/services';
import { DateUtils } from '@core/utils';
import { TranslateService } from '@ngx-translate/core';
import { OverlayService } from '@shared/components';
import { SecurePipe } from '@shared/pipes';
import { HighlightedTitle, PlanningBlock, PlanningContent, PlanningImageModel } from '@wiki/models';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'cp-planning-wiki-tab-view',
    templateUrl: './planning-wiki-tab-view.component.html',
    styleUrls: ['./planning-wiki-tab-view.component.scss']
})
export class PlanningWikiTabViewComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
    @Input()
        content!: PlanningContent;

    @Input()
        contentId!: number;

    @Input()
        active!: boolean;

    @Input()
        filter = false;

    @Input()
        organisationId!: number;

    @Input()
        eventId!: number;

    @Input()
        wikiTabId!: number;

    @Input()
        code?: string;

    @Input()
        introductionText?: SafeHtml;

    @Input()
        showingSearchbar!: boolean;

    @Input()
        headerHeight!: number;

    @Output()
        headerTimeChanged = new EventEmitter<Date>();

    @Output()
        closeSearchbar = new EventEmitter<void>();

    @ViewChild('queryInputRef')
        queryInputRef!: ElementRef<HTMLInputElement>;

    @ViewChildren('block')
        blockElements!: QueryList<ElementRef<HTMLElement>>;

    sortedContent!: PlanningBlock[];
    headerTime!: Date;

    flaggedIdentifiers: string[] = [];

    query = '';
    queryChanged$: Subject<string> = new Subject<string>();
    currentQueryIndex?: number;
    queryResults?: number[];

    private destroy$: Subject<boolean> = new Subject<boolean>();


    private scrollTimeout?: any;

    private readonly SCROLL_TOP_MARGIN = 120;

    private _canCallScroll = true;

    public constructor(
        private translate: TranslateService,
        private overlay: OverlayService,
        private sanitizer: DomSanitizer,
        private securePipe: SecurePipe,
        private imageData: ImageDataService,
    ) { }

    get canSearchUp(): boolean {
        return (this.currentQueryIndex === undefined && !!this.queryResults?.length) || !!this.currentQueryIndex;
    }

    get canSearchDown(): boolean {
        return (this.currentQueryIndex === undefined && !!this.queryResults?.length) ||
            (this.currentQueryIndex !== undefined && this.currentQueryIndex < ((this.queryResults?.length || 0) - 1));
    }

    get visibleContent(): PlanningBlock[] {
        if (this.filter) {
            return this.sortedContent.filter(x => this.isFlagged(x));
        }

        return this.sortedContent;
    }

    get isUSLocale(): boolean {
        return this.translate.currentLang === 'us';
    }

    get searchActiveIndex(): number | undefined {
        if (!this.queryResults || this.currentQueryIndex === undefined) {
            return undefined;
        }

        return this.queryResults[this.currentQueryIndex];
    }

    get pageTrackingInformation(): unknown {
        return {
            type: 'wiki',
            wikiTabId: this.wikiTabId
        };
    }

    private get _cacheKey(): string {
        return `flag-cache-${this.organisationId}-${this.eventId}-${this.contentId}`;
    }

    @HostListener('window:scroll', ['$event'])
    onScroll(event: Event): void {
        const element = event.target as Element;
        const atExtreme = element.scrollTop === 0 ||
            element.scrollTop + element.clientHeight === element.scrollHeight;

        if ((this._canCallScroll || atExtreme) && this.active) {
            this._canCallScroll = false;
            this.scrollTimeout = setTimeout(() => {
                this._canCallScroll = true;
            }, 100);
            this._calculateHeaderTime();
        }
    }

    ngOnInit(): void {
        this.headerTime = new Date();
        this.headerTimeChanged.emit(this.headerTime);
        this._initializeBlocks();
        this._sortContent();

        this.queryChanged$.pipe(
            takeUntil(this.destroy$),
            debounceTime(400),
            distinctUntilChanged())
            .subscribe(query => {
                this.query = query;
                this._search();
            });

        const flagCache = localStorage.getItem(this._cacheKey);

        if (flagCache) {
            this.flaggedIdentifiers = JSON.parse(flagCache);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.active && changes.active.previousValue !== changes.active.currentValue) {
            setTimeout(() => {
                if (this.active) {
                    this._calculateHeaderTime();
                    this._scrollToActiveBlock();
                }
            }, 100);
        }

        if (changes.showingSearchbar && changes.showingSearchbar.previousValue !== changes.showingSearchbar.currentValue) {
            if (changes.showingSearchbar.currentValue === true) {
                this.queryInputRef?.nativeElement?.focus();
            }
        }
    }

    ngAfterViewInit(): void {
        this._scrollToActiveBlock();
    }

    ngOnDestroy(): void {
        if (this.scrollTimeout === undefined) {
            clearTimeout(this.scrollTimeout);
        }

        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    onClickCloseSearchbar(event: MouseEvent): boolean {
        event.stopPropagation();
        this.queryInputRef?.nativeElement?.blur();
        this.closeSearchbar.emit();
        this.query = '';
        this.currentQueryIndex = undefined;
        this.queryResults = undefined;
        return false;
    }

    onSearchUp(event?: MouseEvent): boolean {
        if (!this.query || !this.queryResults || !this.canSearchUp) {
            return true;
        }

        if (event) {
            if (this.queryInputRef) {
                this.queryInputRef.nativeElement.blur();
            }
            event.stopPropagation();
        }

        if (this.currentQueryIndex !== undefined) {
            this.currentQueryIndex -= 1;
        }
        else {
            this.currentQueryIndex = this.queryResults.length - 1;
        }

        const blockIndex = this.queryResults[this.currentQueryIndex];
        if (blockIndex) {
            this._scrollToIndex(blockIndex);
        }

        return false;
    }

    onSearchDown(event?: MouseEvent): boolean {
        if (!this.query || !this.queryResults || ! this.canSearchDown) {
            return true;
        }

        if (event) {
            if (this.queryInputRef) {
                this.queryInputRef.nativeElement.blur();
            }
            event.stopPropagation();
        }

        if (this.currentQueryIndex !== undefined) {
            this.currentQueryIndex += 1;
        }
        else {
            this.currentQueryIndex = 0;
        }

        const blockIndex = this.queryResults[this.currentQueryIndex];
        if (blockIndex) {
            this._scrollToIndex(blockIndex);
        }

        return false;
    }

    onQueryChanged(value: string): void {
        this.queryChanged$.next(value);
    }

    onClickBlock(block: PlanningBlock): void {
        if (!this.hasContent(block)) {
            block.isExpanded = false;
            return;
        }

        block.isExpanded = !block.isExpanded;
    }

    hasDateBreak(block: PlanningBlock, index: number): boolean {
        const previousBlock = this.visibleContent[index - 1];

        if (previousBlock && block.startDate && previousBlock.startDate) {
            return !DateUtils.IsSameDate(block.startDate, previousBlock.startDate);
        }

        return false;
    }

    hasContent(block: PlanningBlock): boolean {
        return !!block.details || (!!block.images && block.images.length > 0);
    }

    flagBlock(event: MouseEvent, block: PlanningBlock): boolean {
        event.stopPropagation();
        const index = this.flaggedIdentifiers.indexOf(block.identifier);

        if (index === -1) {
            this.flaggedIdentifiers.push(block.identifier);
        } else {
            this.flaggedIdentifiers.splice(index, 1);
        }

        try {
            localStorage.setItem(this._cacheKey, JSON.stringify(this.flaggedIdentifiers));
        } catch { }

        return false;
    }

    onClickImage(image: PlanningImageModel, event: MouseEvent): boolean {
        event.preventDefault();
        event.stopPropagation();

        if (image.imageUrl) {
            this._openImageOverlay(image.imageUrl);
            return false;
        }

        image.loading = true;

        const url = this.imageData.getCachedImageUrl(image.blobId, this.organisationId);

        this.securePipe.transform(url).subscribe((result) => {
            if (result !== EMPTY_IMAGE) {
                const trustedUrl = this.sanitizer.bypassSecurityTrustUrl(result);
                image.imageUrl = trustedUrl;
                this._openImageOverlay(image.imageUrl);
            }
            image.loading = false;
        });

        return false;
    }

    onClickExpandTitle(title: HighlightedTitle, event: MouseEvent): boolean {
        event.preventDefault();
        event.stopPropagation();

        title.isExpanded = !title.isExpanded

        return false;
    }

    isFlagged(block: PlanningBlock): boolean {
        return this.flaggedIdentifiers.some(x => x === block.identifier);
    }

    isActiveBlock(block: PlanningBlock): boolean {
        if (!block.startDate || !block.endTime) {
            return false;
        }

        const currentDate = new Date();

        // See if the end time for the block is in the day after the start time of the block
        const isEndTimeNextDay = DateUtils.IsBefore(block.endTime, block.startDate, true, block.hasSeconds);

        // Get the day after the start date
        const dayAfterStartDate = new Date(block.startDate.getFullYear(), block.startDate.getMonth(), block.startDate.getDate() + 1);

        // If the end time is the day after the start time we need to check if we're currently in that day,
        // instead of today, to calculate the 'end time'
        const isEndDay = isEndTimeNextDay ? DateUtils.IsSameDate(currentDate, dayAfterStartDate)
            : DateUtils.IsSameDate(currentDate, block.startDate);

        const isBeforeEndTime = DateUtils.IsBefore(currentDate, block.endTime, false, block.hasSeconds);
        // Equality counts for start time because we want to start on the time, not slightly after.
        const isAfterStartTime = DateUtils.IsBefore(block.startDate, currentDate, true, block.hasSeconds);

        return isAfterStartTime && isBeforeEndTime && isEndDay;
    }

    isPastBlock(block: PlanningBlock): boolean {
        if (block.isPast) {
            return true;
        }

        if ((block.startDate?.getTime() || 0) > new Date().getTime()) {
            return false;
        }

        const result = !this.isActiveBlock(block);

        if (result) {
            block.isPast = result;
            this._sortContent();
        }

        return result;
    }

    getTimeFormatForBlock(block: PlanningBlock): string {
        return block.hasSeconds ? 'mediumTime' : 'shortTime';
    }

    private _initializeBlocks(): void {
        if (this.content.blocks === undefined) {
            return;
        }

        let foundActive = false;

        this.content.blocks.forEach(block => {
            if (block.startDate) {
                block.startDate = new Date(block.startDate);
            }

            if (block.endTime) {
                block.endTime = new Date(block.endTime);
            }

            if ((block.startDate?.getTime() || 0) > new Date().getTime()) {
                foundActive = true;
            }

            if (this.isActiveBlock(block)) {
                foundActive = true;
            }

            block.isPast = !foundActive;
            block.isExpanded = false;
        });
    }

    private _sortContent(): void {
        if (!this.content.blocks) {
            this.sortedContent = [];
            return;
        }

        this.sortedContent = this.content.blocks.sort((a, b) => {
            // Ensure that active items (even if they start before now inactive items) get sorted underneath
            if (a.isPast && !b.isPast) {
                return -1;
            }

            if (b.isPast && !a.isPast) {
                return 1;
            }

            return DateUtils.SortDates(a.startDate, b.startDate, a.hasSeconds, b.hasSeconds)
                || DateUtils.SortTime(a.endTime || a.startDate, b.endTime || b.startDate, a.hasSeconds, b.hasSeconds);
        });
    }

    private _calculateHeaderTime(): void {
        if (!this.blockElements) {
            return;
        }

        for (let i = 0; i < this.blockElements.length; i++) {
            const blockElement = this.blockElements.find((_, index) => index === i);
            if (!blockElement) { continue; }

            const elementPosition = blockElement.nativeElement.getBoundingClientRect().bottom - this.SCROLL_TOP_MARGIN;

            if (elementPosition >= 0) {
                const block = this.sortedContent[i];
                if (block && block.startDate) {
                    this.headerTime = block.startDate;
                    this.headerTimeChanged.emit(this.headerTime);
                    return;
                }
            }
        }
    }

    private _scrollToActiveBlock(): void {
        const foundIndex = this.visibleContent.findIndex(block => this.isActiveBlock(block));
        let foundElement;
        if (foundIndex >= 0) {
            foundElement = this.blockElements.find(el => el.nativeElement.dataset.index === foundIndex.toString());
        }
        else {
            // In case no active element exists, scroll to the first future element, but only do so if
            // there are past elements to scroll by, otherwise don't scroll at all.
            if (this.blockElements.some(el => el.nativeElement.classList.contains('past'))) {
                foundElement = this.blockElements.find(el => !el.nativeElement.classList.contains('past'));
            }
        }

        if (foundElement) {
            const elementPosition = foundElement.nativeElement.getBoundingClientRect().top;
            setTimeout(() => {
                document.scrollingElement?.scrollTo({
                    top: elementPosition - this.SCROLL_TOP_MARGIN,
                    behavior: 'smooth'
                });
            }, 1000);
        }
    }

    private _scrollToIndex(index: number): void {
        const foundElement = this.blockElements.find(el => el.nativeElement.dataset.index === index.toString());

        if (foundElement) {
            setTimeout(() => {
                document.scrollingElement?.scrollTo({
                    // Not sure why this calculation needs to be different than _scrollToActiveBlock, but for some reason it does
                    top: foundElement.nativeElement.offsetTop + (this.SCROLL_TOP_MARGIN * 0.5),
                    behavior: 'smooth'
                });
            }, 0);
        }
    }

    private _search(): void {
        this.currentQueryIndex = undefined;
        if (this.query === '') {
            this.queryResults = undefined;
            return;
        }

        this.queryResults = [];
        this.visibleContent.forEach((block, index) => {
            if (block.title.toUpperCase().includes(this.query.toUpperCase()) ||
            block.highlightedTitles?.some(title => title.title.toUpperCase().includes(this.query.toUpperCase()))) {
                this.queryResults?.push(index);
            }
        });

        if (this.queryResults.length > 0) {
            this.onSearchDown();
        }
    }

    private _openImageOverlay(imageUrl: SafeUrl | string): void {
        const overlayRef = this.overlay.OpenImageOverlay();
        overlayRef.instance.imageUrl = imageUrl;
        overlayRef.instance.closed.subscribe(() => {
            this.overlay.ResetUserZoom();
            overlayRef.destroy();
        });
    }
}
