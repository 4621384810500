import { VerificationCodeInputComponent } from '@shared/components';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DarkBodyComponent } from '@global/base-components';
import { ActivatedRoute } from '@angular/router';
import { AccountDataService } from '@account/services';
import { AuthenticationService, GlobalsService } from '@core/services';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ValidatePassword } from '@account/validators';

@Component({
    templateUrl: './activate-cms-account.component.html',
    styleUrls: ['./activate-cms-account.component.scss']
})
export class ActivateCmsAccountComponent extends DarkBodyComponent implements OnInit {
    @ViewChild('input')
        verificationComponent?: VerificationCodeInputComponent;

    passwordControl: UntypedFormControl;
    confirmPasswordControl: UntypedFormControl;
    passwordForm: UntypedFormGroup;

    userId?: string;
    username?: string;
    code = '';
    codePrefilled = false;
    showCode = false;

    confirming = false;
    error = false;
    redirected = false;
    resending = false;
    resentConfirmation = false;
    resendError = false;
    confirmed = false;

    constructor(
        private route: ActivatedRoute,
        private data: AccountDataService,
        private auth: AuthenticationService,
        globals: GlobalsService,
        fb: UntypedFormBuilder
    ) {
        super();
        globals.hideHeaderAndFooter();

        this.passwordControl = fb.control('', [
            Validators.required,
            Validators.minLength(6),
            ValidatePassword(),
        ]);
        this.confirmPasswordControl = fb.control('');
        this.passwordForm = fb.group(
            {
                password: this.passwordControl,
                confirmPassword: this.confirmPasswordControl,
            },
            {
                validators: [
                    this.checkPasswordConfirm,
                ],
            }
        );
    }

    ngOnInit(): void {
        this.route.queryParams.subscribe((params) => {
            if (params.confirm_code && params.uid) {
                this.userId = params.uid;
                this.username = params.uname;
                this.code = params.confirm_code;
                this.codePrefilled = true;
            }

            this.redirected = params.redirected;
        });
    }

    checkPasswordConfirm(group: AbstractControl): { [key: string]: boolean } | null {
        const password = group.get('password');
        const confirmPassword = group.get('confirmPassword');

        return password?.value === confirmPassword?.value
            ? null
            : { passwordsDontMatch: true };
    }

    onSubmittedCode(submittedCode: string): void {
        this.code = submittedCode;
    }

    submit(): void {
        if (this.confirming || !this.passwordForm.valid || !this.code) {
            if (!this.code) {
                this.showCode = true;
            }
            return;
        }

        if (this.userId) {
            this.confirming = true;
            this.data.activateCMSAccount(this.code, this.userId, this.passwordControl.value).subscribe({
                next: () => {
                    this.confirming = false;
                    this.confirmed = true;
                    this.error = false;

                    this.auth.revokeAuthentication();
                },
                error: () => {
                    this.confirming = false;
                    this.error = true;
                    this.verificationComponent?.clearValue();
                }
            });
        }
    }

    resendCode(): void {
        if (this.userId) {
            this.resending = true;
            this.data.requestNewCMSAccountToken(this.userId).subscribe({
                next: () => {
                    this.showCode = true;
                    this.resentConfirmation = true;
                    this.resending = false;
                }, error: () => {
                    this.resending = false;
                    this.resendError = true;
                }
            });
        }
    }
}
