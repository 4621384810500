import { MatFormFieldControl } from '@angular/material/form-field';
import {
    Component,
    DoCheck,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    OnDestroy,
    OnInit,
    Optional,
    Output,
    Self,
    ViewChild
} from '@angular/core';

import { FormGroupDirective, NG_VALIDATORS, NgControl, NgForm } from '@angular/forms';
import { CountryCode, Examples } from '@shared/intl-tel-input/data';
import { phoneNumberValidator } from '@shared/intl-tel-input/intl-tel-input.validator';
import { Country, PhoneNumberFormat } from '@shared/intl-tel-input/model';
import { AsYouType, CountryCode as CC, getExampleNumber, NationalNumber, parsePhoneNumberFromString, PhoneNumber } from 'libphonenumber-js';

import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Subject } from 'rxjs';
import { FocusMonitor } from '@angular/cdk/a11y';
import { CanUpdateErrorState, ErrorStateMatcher, mixinErrorState } from '@angular/material/core';
import { MatMenu } from '@angular/material/menu';

class IntlTelInputBase {
    stateChanges = new Subject<void>();

    constructor(
        public _defaultErrorStateMatcher: ErrorStateMatcher,
        public _parentForm: NgForm,
        public _parentFormGroup: FormGroupDirective,
        public ngControl: NgControl) {
    }
}

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'cp-intl-tel-input',
    templateUrl: './intl-tel-input.component.html',
    styleUrls: ['./intl-tel-input.component.scss'],
    providers: [
        CountryCode,
        { provide: MatFormFieldControl, useExisting: IntlTelInputComponent },
        {
            provide: NG_VALIDATORS,
            useValue: phoneNumberValidator,
            multi: true,
        }
    ]
})

export class IntlTelInputComponent extends  mixinErrorState(IntlTelInputBase)
    implements OnInit, OnDestroy, DoCheck, CanUpdateErrorState, MatFormFieldControl<any> {
    static nextId = 0;

    @Input() preferredCountries: Array<string> = [];
    @Input() enablePlaceholder = true;
    @Input() cssClass!: string;
    @Input() name!: string;
    @Input() onlyCountries: Array<string> = [];
    @Input() errorStateMatcher!: ErrorStateMatcher;
    @Input() enableSearch = false;
    @Input() searchPlaceholder!: string;

    @ViewChild('focusable') input!: ElementRef;
    @ViewChild(MatMenu) matMenu!: MatMenu;

    @Output()
        countryChanged: EventEmitter<Country> = new EventEmitter<Country>();

    @HostBinding() id = `intl-tel-input-${IntlTelInputComponent.nextId++}`;

    stateChanges = new Subject<void>();
    focused = false;
    describedBy = '';
    phoneNumber: NationalNumber = '';
    allCountries: Array<Country> = [];
    preferredCountriesInDropDown: Array<Country> = [];
    selectedCountry: Country | undefined;
    numberInstance: PhoneNumber | undefined;

    // Allow any typing

    value: any;

    searchCriteria!: string;

    private previousFormattedNumber!: string;
    private _format: PhoneNumberFormat = 'default';
    private _placeholder!: string;
    private _required = false;
    private _disabled = false;

    constructor(
        private countryCodeData: CountryCode,
        private fm: FocusMonitor,
        private elRef: ElementRef<HTMLElement>,
        @Optional() @Self() public ngControl: NgControl,
        @Optional() _parentForm: NgForm,
        @Optional() _parentFormGroup: FormGroupDirective,
        _defaultErrorStateMatcher: ErrorStateMatcher,
    ) {
        super(_defaultErrorStateMatcher, _parentForm, _parentFormGroup, ngControl);
        fm.monitor(elRef, true).subscribe(origin => {
            if (this.focused && !origin) {
                this.onTouched();
            }
            this.focused = !!origin;
            this.stateChanges.next();
        });
        this.fetchCountryData();

        if (this.ngControl != null) {
            this.ngControl.valueAccessor = this;
        }
    }

    @Input()
    get format(): PhoneNumberFormat {
        return this._format;
    }
    set format(value: PhoneNumberFormat) {
        this._format = value;
        this.phoneNumber = this.formattedPhoneNumber;
        this.stateChanges.next();
    }

    @Input()
    get required(): boolean {
        return this._required;
    }
    set required(value: boolean) {
        this._required = coerceBooleanProperty(value);
        this.stateChanges.next();
    }

    @Input()
    get disabled(): boolean {
        return this._disabled;
    }
    set disabled(value: boolean) {
        this._disabled = coerceBooleanProperty(value);
        this.stateChanges.next();
    }

    @HostBinding('class.ngx-floating')
    get shouldLabelFloat(): boolean {
        return this.focused || !this.empty;
    }

    @Input()
    get placeholder(): string {
        return this._placeholder;
    }
    set placeholder(value: string) {
        this._placeholder = value;
        this.stateChanges.next();
    }

    get empty(): boolean {
        return !this.phoneNumber;
    }

    private get formattedPhoneNumber(): string {
        if (!this.numberInstance) {
            return this.phoneNumber.toString();
        }
        switch (this.format) {
        case 'national':
            return this.numberInstance.formatNational();
        case 'international':
            return this.numberInstance.formatInternational();
        default:
            return this.numberInstance.nationalNumber.toString();
        }
    }

    // requires acceptance of multiple types

    static getPhoneNumberPlaceHolder(countryISOCode: any): string {
        try {
            return getExampleNumber(countryISOCode, Examples)?.number?.toString() || '';
        } catch (e) {
            throw e;
        }
    }

    onTouched = () => { };

    // Any allowed from lib

    propagateChange = (_: any) => { };

    ngOnInit(): void {
        if (this.preferredCountries.length) {
            this.preferredCountries.forEach(iso2 => {
                const preferredCountry = this.allCountries.filter((c) => c.iso2 === iso2);
                this.preferredCountriesInDropDown.push(preferredCountry[0]);
            });
        }
        if (this.onlyCountries.length) {
            this.allCountries = this.allCountries.filter(c => this.onlyCountries.includes(c.iso2));
        }
        if (this.numberInstance && this.numberInstance.country) {
            // If an existing number is present, we use it to determine selectedCountry
            this.selectedCountry = this.allCountries.find(c => c.iso2 === this.numberInstance?.country?.toLowerCase())
                || this.preferredCountriesInDropDown[0]
                || this.allCountries[0];
        } else {
            if (this.preferredCountriesInDropDown.length) {
                this.selectedCountry = this.preferredCountriesInDropDown[0];
            } else {
                this.selectedCountry = this.allCountries[0];
            }
        }
        this.countryChanged.emit(this.selectedCountry);
    }

    ngDoCheck(): void {
        if (this.ngControl) {
            this.updateErrorState();
        }
    }

    public onPhoneNumberChange(): void {
        try {
            this.numberInstance = parsePhoneNumberFromString(this.phoneNumber.toString(), this.selectedCountry?.iso2.toUpperCase() as CC);
            this.formatAsYouTypeIfEnabled();
            this.value = this.numberInstance?.number;
            if (this.numberInstance && this.numberInstance.isValid()) {
                if (this.phoneNumber !== this.formattedPhoneNumber) {
                    this.phoneNumber = this.formattedPhoneNumber;
                }
                if (this.selectedCountry?.iso2 !== this.numberInstance.country) {
                    this.selectedCountry = this.getCountry(this.numberInstance?.country);
                }
            }
        } catch (e) {
            // if no possible numbers are there,
            // then the full number is passed so that validator could be triggered and proper error could be shown
            this.value = this.phoneNumber.toString();
        }
        this.propagateChange(this.value);
    }

    public onCountrySelect(country: Country, el: HTMLElement): void {
        if (this.phoneNumber && this.numberInstance) {
            this.phoneNumber = this.numberInstance.nationalNumber;
        }
        this.selectedCountry = country;
        this.countryChanged.emit(this.selectedCountry);
        this.onPhoneNumberChange();
        el.focus();
    }

    public getCountry(code: CC | undefined): Country | undefined {
        return this.allCountries.find(c => c.iso2 === code?.toLowerCase());
    }

    public onInputKeyPress(event: KeyboardEvent): void {
        const pattern = /[0-9+\- ]/;
        if (!pattern.test(event.key)) {
            event.preventDefault();
        }
    }


    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }


    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }


    writeValue(value: any): void {
        if (value) {
            this.numberInstance = parsePhoneNumberFromString(value);
            if (this.numberInstance) {
                const countryCode = this.numberInstance.country;
                this.phoneNumber = this.formattedPhoneNumber;
                if (!countryCode) {
                    return;
                }
                setTimeout(() => {
                    this.selectedCountry = this.allCountries.find(c => c.iso2 === countryCode.toLowerCase());

                    if (this.selectedCountry) {
                        this.preferredCountriesInDropDown.push(this.selectedCountry);
                        this.countryChanged.emit(this.selectedCountry);
                    }
                }, 1);
            } else {
                this.phoneNumber = value;
            }
        }
    }

    setDescribedByIds(ids: string[]): void {
        this.describedBy = ids.join(' ');
    }

    onContainerClick(event: MouseEvent): void {
        if ((event.target as Element).tagName.toLowerCase() !== 'input') {
            this.elRef.nativeElement.querySelector('input')!.focus();
        }
    }

    reset(): void {
        this.phoneNumber = '';
        this.propagateChange(null);
    }

    focus(): void {
        this.input.nativeElement.focus();
    }

    ngOnDestroy(): void {
        this.stateChanges.complete();
        this.fm.stopMonitoring(this.elRef);
    }

    openMenu(event: MouseEvent): boolean {
        event.stopPropagation();
        return false;
    }

    fixDisappearIOSBug(): void {
        const styleNode = document.createElement('style');
        styleNode.type = 'text/css';
        styleNode.id = 'panel-fix';
        styleNode.appendChild(document.createTextNode('.mat-menu-panel{overflow: initial !important;}'));
        document.getElementsByTagName('head')[0].appendChild(styleNode);
        setTimeout(() => {
            styleNode.remove();
        }, 500);
    }

    protected fetchCountryData(): void {
        this.countryCodeData.allCountries.forEach(c => {
            const country: Country = {
                name: c[0].toString(),
                iso2: c[1].toString(),
                dialCode: c[2].toString(),
                priority: +c[3] || 0,
                areaCodes: c[4] as string[] || undefined,
                flagClass: c[1].toString().toUpperCase(),
                placeHolder: ''
            };

            if (this.enablePlaceholder) {
                country.placeHolder = IntlTelInputComponent.getPhoneNumberPlaceHolder(country.iso2.toUpperCase());
            }

            this.allCountries.push(country);
        });
    }

    private formatAsYouTypeIfEnabled(): void {
        if (this.format === 'default') {
            return;
        }
        const asYouType: AsYouType = new AsYouType(this.selectedCountry?.iso2.toUpperCase() as CC);
        // To avoid caret positioning we apply formatting only if the caret is at the end:
        if (this.phoneNumber.toString().startsWith(this.previousFormattedNumber || '')) {
            this.phoneNumber = asYouType.input(this.phoneNumber.toString());
        }
        this.previousFormattedNumber = this.phoneNumber.toString();
    }
}
