import { Component, OnDestroy, OnInit } from '@angular/core';
import { GlobalsService } from '@core/services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'cp-settings-button',
    templateUrl: './settings-button.component.html',
    styleUrls: ['./settings-button.component.scss']
})
export class SettingsButtonComponent implements OnInit, OnDestroy {
    showingSettings = false;

    private destroy$ = new Subject<boolean>();

    constructor(private globals: GlobalsService) { }

    ngOnInit(): void {
        this.globals.settingsChanged$.pipe(takeUntil(this.destroy$))
            .subscribe(value => this.showingSettings = value);
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    onClickSettingsButton(): void {
        this.globals.showSettings();
    }

}
