<div class='no-connection-bar' #noConnectionBar>
    <div class='m-auto pr-3'>
        <cp-icon name='wifi-off' size='x-small' color='white'></cp-icon>
    </div>
    <div class='text-container'>
        <span [translate]='"general.text.no_internet_connection_bar"'>
            Currently you have no internet connection. Some features won't work until you regain a connection.
        </span>
    </div>
</div>
