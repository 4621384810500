import { AlertService, AuthenticationService, GlobalsService, TwoFaRequiredModel } from '@core/services';
import { ToggleablePasswordInputFieldComponent } from '@shared/components';
import { DarkBodyComponent } from '@global/base-components';
import { Component, ViewChild, ElementRef, AfterViewInit, OnDestroy, ChangeDetectorRef, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from '@env';
import { StringModel } from '@core/models';
import { MatDialog } from '@angular/material/dialog';
import { TwoFactorDialogComponent } from '../dialogs';

// Untyped 3rd party object (@typings didn't work)

declare let grecaptcha: any;

declare global {
    interface Window {
        ng2recaptchaloaded: () => void;
    }
}

@Component({
    templateUrl: './sign-in-page.component.html',
    styleUrls: ['./sign-in-page.component.scss']
})
export class SignInPageComponent extends DarkBodyComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('passwordField')
        passwordField!: ToggleablePasswordInputFieldComponent;

    @ViewChild('usernameField')
        usernameField!: ElementRef;

    destroy$: Subject<boolean> = new Subject<boolean>();

    readonly DATE_FORMAT = 'longDate';
    readonly TIME_FORMAT = 'shortTime';

    usernameControl: UntypedFormControl;
    passwordControl: UntypedFormControl;
    signinForm: UntypedFormGroup;

    maintenanceActive = false;
    loadingAnonymous = false;

    signingIn = false;
    stServerErrored = false;

    organisationBlocked = false;

    lockoutError = false;
    lockoutEnd?: Date;

    readonly returnUrlParameter = 'ReturnUrl';
    readonly tokenParameter = 'token';
    readonly errorIdParameter = 'errorId';
    returnUrl: string;
    returnRoute = '';

    constructor(
        fb: UntypedFormBuilder,
        private authService: AuthenticationService,
        route: ActivatedRoute,
        private cdr: ChangeDetectorRef,
        private globals: GlobalsService,
        private dialog: MatDialog,
        private alert: AlertService,
    ) {
        super();
        this.returnUrl = route.snapshot.queryParams[this.returnUrlParameter];

        // Redirect back to invitation accept page after logging in if token is present
        const token = route.snapshot.queryParams[this.tokenParameter];
        const orgId = route.snapshot.queryParams.organisationId || undefined;

        let anonymousModelString = sessionStorage.getItem('anonymous');
        if (anonymousModelString !== null) {
            this.loadingAnonymous = true;
            let anonymousModel = JSON.parse(anonymousModelString);
            this.authService.anonymousLogin(anonymousModel.invitationCode, anonymousModel.organisationId, this.returnUrl).pipe(take(1)).subscribe({
                next: (data) => {
                    window.location.href = (data as StringModel).value;
                }, error: () => {
                    this.alert.postMessage('account.error.anonymous_login', 'Error', 5000);
                    this.loadingAnonymous = false;
                }
            });
        }

        if (token) {
            if (orgId) {
                this.returnRoute = `/account/invite?token=${token}&redirected=true&organisationId=${orgId}`;
            } else {
                this.returnRoute = `/account/invite?token=${token}&redirected=true`;
            }
        }

        const errorId = route.snapshot.queryParams[this.errorIdParameter];

        if (!this.returnUrl && !errorId) {
            // If we're on an error this was most likely caused by the id server being down/having connectivity issues.
            // If this is the case prevent automatic reauthorization event because this will cause an infinite loop.
            this.authService.startAuthentication(this.returnRoute)
                .catch(_ => this.stServerErrored = true);
        } else if (errorId) {
            this.stServerErrored = true;
        }

        this.authService.clearStaleAuthentication();

        this.usernameControl = fb.control('');
        this.passwordControl = fb.control('');
        this.signinForm = fb.group({
            username: this.usernameControl,
            password: this.passwordControl
        });
    }

    ngOnInit(): void {
        this.globals.underMaintenance$.pipe(takeUntil(this.destroy$))
            .subscribe(maintenance => {
                this.maintenanceActive = maintenance && maintenance.maintenanceActive;
                this.cdr.detectChanges();
            });
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit();
        this._loadRecaptcha();
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();

        // Ensure captcha leaves nothing behind
        if (environment.useCaptcha) {
            grecaptcha.reset();
        }

        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    reattemptAuthorize(): void {
        this.authService.startAuthentication();
    }

    executeCaptcha(): void {
        if (!this.usernameControl.value) {
            this.usernameField.nativeElement.focus();
            return;
        }

        if (!this.passwordControl.value) {
            this.passwordField.focusOnField();
            return;
        }

        if (this.signingIn) {
            return;
        }

        if (environment.useCaptcha) {
            grecaptcha.reset();
            grecaptcha.execute();
        }
        else {
            this._signin();
        }
    }

    private _signin(token?: string, twoFactorToken?: string, recaptchaVerifiedToken?: string): void {
        this.authService.login(this.usernameControl.value, this.passwordControl.value, this.returnUrl, token, twoFactorToken, recaptchaVerifiedToken)
            .pipe(take(1)).subscribe({
                next: (data) => {
                    if ((data as TwoFaRequiredModel)?.twoFactorRequired) {
                        const dialogRef = this.dialog.open(TwoFactorDialogComponent, {
                            panelClass: 'small-dialog',
                            disableClose: true
                        });
                        const verifiedToken = (data as TwoFaRequiredModel)?.captchaVerifiedToken;
                        dialogRef.afterClosed().pipe(take(1)).subscribe((result) => {
                            if (result) {
                                this._signin(token, result, verifiedToken);
                            }
                        });
                        return;
                    }
                    window.location.href = (data as StringModel).value;
                    this.signingIn = false;
                },
                error: (error: HttpErrorResponse) => {
                    this.signingIn = false;
                    if (error.status === 401) {
                        this.lockoutError = true;
                        this.lockoutEnd = error.error.unauthorized.lockoutEnd;
                    } else if (error.status === 423) {
                        this.organisationBlocked = true;
                    }
                    else {
                        this.usernameControl.setErrors({ invalidCredentials: true });
                        this.passwordControl.setErrors({ invalidCredentials: true });
                        this.usernameField.nativeElement.focus();
                    }
                    this.cdr.detectChanges();
                }
            });
    }

    private _loadRecaptcha(): void {
        if (!environment.useCaptcha) {
            return;
        }

        window.ng2recaptchaloaded = () => {
            grecaptcha.render('recaptcha', {
                callback: (response: string) => {
                    this.signingIn = true;
                    this.lockoutError = false;
                    this._signin(response);
                },
                sitekey: '6Ld9l94ZAAAAAC-o1sdafWoq2sgwb3pFvsB2K3PE',
                size: 'invisible',
            });
        };

        const script = document.createElement('script');
        script.innerHTML = '';
        const baseUrl = 'https://www.google.com/recaptcha/api.js';
        script.src = `${baseUrl}?render=explicit&onload=ng2recaptchaloaded`;
        script.async = true;
        script.defer = true;
        document.head.appendChild(script);
    }
}
