import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { TranslatePipe } from '@ngx-translate/core';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class AlertService {
    constructor(private translatePipe: TranslatePipe, private snackbar: MatSnackBar) { }

    public postMessage(message: string, type: AlertType, duration?: number, action?: string): MatSnackBarRef<unknown> {
        const translatedMessage = this.translatePipe.transform(message);
        const translatedAction = action ? this.translatePipe.transform(action) : undefined;

        return this._postMessage(translatedMessage, type, duration, translatedAction);
    }

    public postMessageWithParams(
        message: string,
        translateParams: any,
        type: AlertType, duration?: number, action?: string): MatSnackBarRef<unknown> {
        const translatedMessage = this.translatePipe.transform(message, translateParams);
        const translatedAction = action ? this.translatePipe.transform(action) : undefined;
        return this._postMessage(translatedMessage, type, duration, translatedAction);
    }

    private _postMessage(message: string, type: AlertType, duration?: number, action?: string): MatSnackBarRef<unknown> {
        return this.snackbar.open(message, action, {
            duration,
            panelClass: this.determinePanelClass(type)
        });
    }

    private determinePanelClass(type: AlertType): string {
        switch (type) {
        case 'Light':
            return 'light-snackbar';
        case 'Dark':
            return 'dark-snackbar';
        case 'Error':
            return 'error-snackbar';
        }
    }
}

export type AlertType = 'Light' | 'Dark' | 'Error';
