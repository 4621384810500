import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { GeneralDataService } from '@core/services';
import { EndPoint } from '@core/constants';

@Injectable()
export class MediaDataService extends GeneralDataService {
    getVideo(organisationId: number, videoId: number): Observable<Blob> {
        return this.getGenericFile(EndPoint.video, `${organisationId}/${videoId}`);
    }

    getVideoThumbnail(organisationId: number, videoId: number): Observable<VideoThumbnailModel> {
        return this.getGenericItem(EndPoint.video, `${organisationId}/${videoId}/thumbnail`);
    }
}

export interface VideoThumbnailModel {
    thumbnail?: string;
}
