import { Component, Input } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { ProcedureContent } from '@wiki/models';

@Component({
    selector: 'cp-procedure-wiki-tab-view',
    templateUrl: './procedure-wiki-tab-view.component.html',
    styleUrls: ['./procedure-wiki-tab-view.component.scss']
})
export class ProcedureWikiTabViewComponent {

    @Input()
        organisationId!: number;

    @Input()
        eventId!: number;

    @Input()
        wikiTabId!: number;

    @Input()
        content!: ProcedureContent;

    @Input()
        introductionText?: SafeHtml;

    get pageTrackingInformation(): unknown {
        return {
            type: 'wiki',
            wikiTabId: this.wikiTabId
        };
    }
}
