import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { of, Subject } from 'rxjs';
import { switchMap, take, catchError, takeUntil } from 'rxjs/operators';
import { KnowledgeTestDataService } from '@knowledge-test/services';
import { KnowledgeTestQuestionModel, KnowledgeTestQuestionProgressModel } from '@knowledge-test/models';
import { StylingModel } from '@core/models';
import { QuestionContentType } from '@core/constants';
import { ConnectionService, GlobalsService } from '@core/services';

@Component({
    templateUrl: './knowledge-test.component.html',
    styleUrls: ['./knowledge-test.component.scss']
})
export class KnowledgeTestComponent implements OnInit, OnDestroy {

    timeout$?: any;

    readonly questionTypes = QuestionContentType;
    readonly DATE_FORMAT = 'shortDate';

    progressPipLengthArray!: void[];
    progressPipWidth!: string;

    organisationStyling!: StylingModel;
    organisationId!: number;
    eventId!: number;
    knowledgeTaskId!: number;
    eventName!: string;

    deadline!: Date;
    questions!: KnowledgeTestQuestionModel[];
    progress?: KnowledgeTestQuestionProgressModel[];

    loading = true;
    errorLoading = false;
    loadingImage = true;
    errorLoadingImage = false;
    onTitlePage = true;
    onResultPage = false;
    changingPages = false;
    transitioningTitle = false;
    transitioningScore = false;
    answering = false;
    questionIndex?: number;
    offline = false;

    private readonly TRANSITION_TIME = 300;
    private destroy$ = new Subject<boolean>();

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private data: KnowledgeTestDataService,
        private globals: GlobalsService,
        private connection: ConnectionService
    ) {
        globals.changeHeader({ titleKey: 'general.text.knowledge_test' });
        globals.hideFooter();

        // Ensure we have something loaded in if styling is not cached yet.
        this.organisationStyling = globals.stylingDefaults;
    }

    get eventImage(): string {
        return `event/${this.eventId}`;
    }

    get firstUnfinishedQuestionIndex(): number {
        let i = 0;

        while (i < this.questions.length) {
            if (this.questions[i].finished) {
                i++;
            }
            else {
                break;
            }
        }

        return i;
    }

    ngOnInit(): void {
        this.route.params.pipe(switchMap(params => {
            this.organisationId = params.organisationId;
            this.eventId = params.eventId;
            this.knowledgeTaskId = params.knowledgeTaskId;

            this.globals.getStyling(this.organisationId, this.eventId)
                .pipe(take(1))
                .subscribe(styling => this.organisationStyling = styling,
                    styling => this.organisationStyling = styling);

            return this.data.getKnowledgeTestView(this.organisationId, this.eventId, this.knowledgeTaskId)
                .pipe(switchMap(response => {
                    this.eventName = response.eventName;
                    this.questions = response.questions;
                    // For some reason empty value here gets interpreted as null instead of undefined, so handle.
                    this.progress = response.progress || undefined;

                    this._applyProgress();

                    this.progressPipLengthArray = new Array(this.questions.length);
                    this.progressPipWidth = `${100 / (this.questions.length || 1)}%`;

                    this.loading = false;

                    return of();
                }), catchError(_ => {
                    // If the test can't be loaded, navigate the user back to the overview
                    this.loading = false;
                    this.errorLoading = true;

                    this.timeout$ = setTimeout(() => {
                        this.router.navigate([this.organisationId, this.eventId, 'tasks']);
                    }, 2000);

                    return of();
                }));
        }), take(1)).subscribe();

        this.connection.onlineState$.pipe(takeUntil(this.destroy$)).subscribe(
            (connected) => this.offline = !connected
        );
    }

    ngOnDestroy(): void {
        if (this.timeout$) {
            clearTimeout(this.timeout$);
        }

        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    startTest(): void {
        this.globals.hideHeader();
        this.transitioningTitle = true;
        this.onTitlePage = false;
        const index = this.firstUnfinishedQuestionIndex;

        if (index >= this.questions.length) {
            this.onResultPage = true;
        }
        else {
            this.questionIndex = this.firstUnfinishedQuestionIndex;
        }

        // Wait until the animation is done
        this.timeout$ = setTimeout(() => {
            this.transitioningTitle = false;
        }, this.TRANSITION_TIME);
    }

    goToNextQuestion(): void {
        if (this.questionIndex !== undefined) {
            if (this.questionIndex < this.questions.length - 1) {
                this.questionIndex++;
            }
            else {
                this.transitioningScore = true;
                this.questionIndex = undefined;
                this.onResultPage = true;
                // Wait until the animation is done
                this.timeout$ = setTimeout(() => {
                    this.transitioningScore = false;
                }, this.TRANSITION_TIME);
            }
        }
    }

    goToPreviousQuestion(): void {
        if (this.questionIndex && this.questionIndex > 0) {
            this.questionIndex--;
        }
    }

    selectAnswer(questionIndex: number, answerIndex: number): void {
        if (this.answering) {
            return;
        }

        const question = this.questions?.find(q => q.position === questionIndex);
        if (question === undefined || question.finished) {
            return;
        }

        // If answer is already selected, deselect it
        if (question.selectedAnswers?.includes(answerIndex)) {
            question.selectedAnswers = question.selectedAnswers.filter(index => index !== answerIndex);
        }
        else {
            if (question.multipleChoice) {
                // If the question is multiple choice, add it to the selected answers if it's not there already
                if (question.selectedAnswers === undefined) {
                    question.selectedAnswers = [answerIndex];
                }
                else if (question.selectedAnswers.findIndex(index => index === answerIndex) < 0) {
                    question.selectedAnswers.push(answerIndex);
                }
            }
            else {
                // If the question is single choice, overwrite the currently selected answer
                question.selectedAnswers = [answerIndex];
            }
        }
    }

    confirmAnswer(): void {
        if (this.questionIndex === undefined) {
            return;
        }

        const foundQuestion = this.questions.find(question => question.position === this.questionIndex);

        if (foundQuestion === undefined || foundQuestion.selectedAnswers === undefined) {
            return;
        }

        this.answering = true;

        this.data.checkKnowledgeTestQuestionAnswer(
            this.organisationId, this.eventId, this.knowledgeTaskId, this.questionIndex, foundQuestion.selectedAnswers
        ).pipe(take(1)).subscribe(_ => {
            foundQuestion.finished = true;
            this.answering = false;
        });
    }

    isPreviousButtonEnabled(questionIndex: number | undefined): boolean {
        if (questionIndex === undefined || this.offline) {
            return false;
        }

        return questionIndex > 0;
    }

    isNextButtonEnabled(questionIndex: number | undefined): boolean {
        if (questionIndex === undefined || this.offline) {
            return false;
        }

        const foundQuestion = this.questions.find(question => question.position === questionIndex);

        if (foundQuestion === undefined) {
            return false;
        }

        return !!foundQuestion.finished;
    }

    isSelected(questionIndex: number, answerIndex: number): boolean {
        const foundQuestion = this.questions.find(question => question.position === questionIndex);

        if (foundQuestion === undefined || foundQuestion.selectedAnswers === undefined) {
            return false;
        }

        return foundQuestion.selectedAnswers.includes(answerIndex);
    }

    isConfirmEnabled(questionIndex: number): boolean {
        if (this.answering || this.offline) {
            return false;
        }

        const foundQuestion = this.questions.find(question => question.position === questionIndex);

        if (foundQuestion === undefined || foundQuestion.selectedAnswers === undefined) {
            return false;
        }

        return foundQuestion.selectedAnswers.length > 0;
    }

    getQuizClasses(questionIndex: number): { [klass: string]: boolean } {
        if (this.questions === undefined || this.questionIndex === undefined) {
            return {};
        }

        if (questionIndex === this.questionIndex) {
            return {
                'current-question': true,
                'position-fixed': this.changingPages,
            };
        }

        if (questionIndex < this.questionIndex) {
            return {
                'past-question': true,
                invisible: !this.transitioningScore,
            };
        }

        if (questionIndex > this.questionIndex) {
            return {
                'future-question': true,
            };
        }

        return {};
    }

    private _applyProgress(): void {
        if (this.progress === undefined) {
            return;
        }

        this.progress.forEach(progress => {
            const foundQuestion = this.questions.find(question => question.position === progress.position);
            if (foundQuestion !== undefined) {
                foundQuestion.finished = progress.answered;
                foundQuestion.selectedAnswers = progress.givenAnswerPositions;
            }
        });
    }
}
