<ng-container *ngIf="!loadingAnonymous; else redirectRef">
    <div class="row justify-content-center">
        <div class="col-xl-4 col-lg-6 col-m-8 col-sm-12">
            <div class="text-center pt-5 col-12">
                <cp-logo maxWidth="50px" [translate]="'account.text.signin-to-catchphrase'">
                    Sign in with Catchphrase
                </cp-logo>
            </div>
    
            <div class="pt-5" *ngIf='returnUrl'>
                <div class="row text-center pb-2">
                    <span class="transparent-text col-12" [translate]="'account.text.useform'">
                        Sign in using the form below
                    </span>
                </div>
    
                <form [formGroup]='signinForm' (ngSubmit)='executeCaptcha();'>
                    <mat-form-field class="col-10 offset-1">
                        <mat-label [translate]="'general.text.username'">
                            Username
                        </mat-label>
                        <input #usernameField matInput formControlName="username">
                        <mat-error *ngIf='usernameControl.hasError("invalidCredentials")'>
                            <span [translate]="'account.text.incorrect-credentials'">
                                Incorrect sign in or password
                            </span>
                        </mat-error>
                    </mat-form-field>
    
                    <cp-password-field #passwordField formControlName="password" required hideRequiredMarker [colSize]="10" [colOffset]="1">
                    </cp-password-field>
    
                    <div id="recaptcha"></div>
    
                    <div class='col-10 offset-1' *ngIf='lockoutError'>
                        <div class='alert alert-danger'>
                            <span [translate]='"account.error.sign_in_locked_out"'
                            [translateParams]='{ lockoutEndDate: lockoutEnd | localizedDate:DATE_FORMAT, lockoutEndTime: lockoutEnd | localizedDate:TIME_FORMAT }'>
                                You have too many failed login attempts and have been temporarily locked out. The lockout will end soon.
                            </span>
                        </div>
                    </div>
    
                    <div class='col-10 offset-1' *ngIf='organisationBlocked'>
                        <div class='alert alert-danger'>
                            <span [translate]='"account.error.organisation_blocked"'>
                                The organisation you're trying to log in for is currently disabled. If you think this incorrect please contact your Catchphrase representative.
                            </span>
                        </div>
                    </div>
    
                    <div class='col-10 offset-1' *ngIf='maintenanceActive'>
                        <div class='alert alert-info'>
                            <span [translate]='"general.text.maintenance_login_message"'>
                                Currently we're performing maintenance on the platform. You can login, but until maintenance has completed you cannot use the platform.
                            </span>
                        </div>
                    </div>
    
                    <div class="col-10 offset-1">
                        <button mat-raised-button [loading]='signingIn' class="cp-button cp-button-glow col-12" color='primary' type="submit">
                            <span [translate]="'registration.button.signin'">
                                Sign In
                            </span>
                        </button>
                    </div>
                </form>
                <div class="text-center pt-4">
                    <a [routerLink]="['forgot-username']" queryParamsHandling="preserve">
                        <span [translate]="'account.text.forgot_username'">
                            Forgot username
                        </span>?
                    </a>
                </div>
                <div class="text-center pt-4">
                    <a [routerLink]="['forgot-password']" queryParamsHandling="preserve" >
                        <span [translate]="'account.text.forgot_password'">
                            Forgot password
                        </span>?
                    </a>
                </div>
            </div>
        </div>
    
        <div class="pt-5" *ngIf='stServerErrored'>
            <div class="row text-center pb-2">
                <span class="transparent-text col-12" [translate]="'sign_in.text.connection_error'">
                    Could not establish a connection to the Catchphrase login services. If the problem persists please contact
                </span> <a href='mailto:info@catchphrase.io'>info@catchphrase.io</a>.
            </div>
    
            <div class="col-10 offset-1 mt-3">
                <button mat-raised-button class="cp-button cp-button-glow col-12" color='accent' type="button" (click)='reattemptAuthorize();'>
                    <mat-icon>cached</mat-icon>
                    <span translate='sign_in.button.connection_error'>
                        Retry connection
                    </span>
                </button>
            </div>
        </div>
    </div>
</ng-container>
<ng-template #redirectRef>
    <div style="height: 100vh;">
        <div class="text-center col-12" style="top: 50%; transform: translateY(-50%);">
            <cp-logo maxWidth="50px" [translate]="'general.text.redirect_shortly'">
                You will be redirected shortly
            </cp-logo>
        </div>
    </div>
</ng-template>