<div class='expanded-content' *ngIf='item.opened'>
    <div *ngIf="item.content" class="text-section editor-bounds mt-1"
        [innerHTML]='trustContent(item.content)'>
    </div>

    <div *ngIf="item.pdfId" class='pdf-item' (click)='onClickPdf(item, $event)'>
        <div class='d-flex title-container'>
            <cp-icon [name]='isOffline ? "wifi-off" : "file-text"' size='x-small' class='file-icon d-block mr-1'></cp-icon>
    
            <div class='title'>
                {{ item.pdfTitle }}
            </div>
        </div>
    
        <div class='d-flex pr-2' *ngIf='!isOffline'>
            <cp-delayed-spinner *ngIf='downloadingPdf; else touchIconRef' class='my-auto' [diameter]='20'>
            </cp-delayed-spinner>
            <ng-template #touchIconRef>
                <i class="material-icons-outlined tap-icon">touch_app</i>
            </ng-template>
        </div>
    </div>
    
    <div *ngIf='errorDownloadingPdf' class='alert alert-danger mt-2'>
        <span [translate]='"document.error.downloading_pdf"'>
            Something went wrong trying to download the PDF. Please try again later.
        </span>
    </div>
    
    <div *ngIf="item.images && item.images.length" class="row">
        <div *ngFor='let image of item.images; let index = index' class='col-3 image-wrapper'
        (click)="onClickImage(image, $event)">
            <div class="image-container mt-2">
                <cp-delayed-spinner *ngIf='image.loading; else zoomButtonRef' [diameter]='25'>
                </cp-delayed-spinner>
                <ng-template #zoomButtonRef>
                    <div class="zoom-button">
                        <cp-icon name='zoom-in' color='white' size="x-small"></cp-icon>
                    </div>
                </ng-template>
                <img [src]='image.placeholder' />
            </div>
        </div>
    </div>
</div>