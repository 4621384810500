<div class="row justify-content-center">
    <div class="col-xl-4 col-lg-6 col-m-8 col-sm-12">
        <div class="text-center pt-5 col-12">
            <cp-logo maxWidth="50px" [translate]="'account.text.confirm_account'">
                Confirm your account
            </cp-logo>
        </div>

        <div class="pt-4">
            <div class="row text-center mb-4 mx-2 justify-content-center">
                <span [translate]="'account.text.confirm_message_email'">
                    Please fill in the confirmation code that has been sent to your email address below
                </span>
            </div>
            <div class="row text-center mb-4 mx-2 d-flex justify-content-center">
                <cp-verification-code-input #input class="cp-light" (submittedCode)="onSubmittedCode($event)">
                </cp-verification-code-input>
            </div>
            <div *ngIf="error" class="row text-center mb-4 justify-content-center">
                <span class="warn-text" [translate]="'account.text.confirmation_error'">
                    Something went wrong with your confirmation. Please check your code and try again.
                </span>
            </div>
            <div *ngIf='confirmed' class="row text-center mb-4 justify-content-center">
                <span [translate]="'account.text.confirmation_complete'">
                    Your account has been confirmed! You will be redirected shortly.
                </span>
            </div>
            <div class="row text-center mb-4">
                <div class="col-10 offset-1">
                    <button mat-raised-button class="cp-button cp-button-glow col-12" (click)="submitCode(code)"
                        [loading]="confirming" color="accent">
                        <span [translate]="'general.button.confirm'">
                            Confirm
                        </span>
                    </button>
                </div>
            </div>
            <div *ngIf="redirected || error" class="row text-center">
                <div class="col-10 offset-1">
                    <button *ngIf="!resentConfirmation && !resendError; else resendInfo" mat-raised-button
                        class="cp-button cp-light col-12" (click)="resendCode()" [loading]="resending">
                        <span [translate]="'account.button.resend_confirmation_request'">
                            Send new confirmation code?
                        </span>
                    </button>
                    <ng-template #resendInfo>
                        <span *ngIf="resendError" class="warn-text"
                            [translate]="'account.text.resend_confirmation_error'">
                            Something went wrong with trying to send a new confirmation token. Please try again later.
                        </span>
                        <span *ngIf="resentConfirmation" [translate]="'account.text.resent_confirmation_message'">
                            A new confirmation code has been sent to your email address.
                        </span>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
</div>
