import { MatDialog } from '@angular/material/dialog';
import { take, takeUntil } from 'rxjs/operators';
import { TourStep } from '@core/models';
import { Component, AfterViewInit, OnDestroy, Inject, ViewChild, ElementRef } from '@angular/core';
import { GuidedTourService, GlobalsService } from '@core/services';
import { fromEvent, Subject } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { ConfirmDialogComponent, ConfirmDialogData, ConfirmDialogResult } from '../dialogs';

@Component({
    selector: 'cp-guided-tour',
    templateUrl: './guided-tour.component.html',
    styleUrls: ['./guided-tour.component.scss']
})
export class GuidedTourComponent implements AfterViewInit, OnDestroy {
    @ViewChild('tourtip') tourTip?: ElementRef;

    guidedTourActive = false;
    currentStep?: TourStep;
    selectedElementRect?: DOMRect;

    private destroy$: Subject<boolean> = new Subject<boolean>();

    constructor(
        private tour: GuidedTourService, @Inject(DOCUMENT) private dom: Document, private dialog: MatDialog,
        private globals: GlobalsService) { }

    public get overlayTop(): number {
        if (this.selectedElementRect) {
            return this.selectedElementRect.top - this._getPadding();
        }
        return 0;
    }

    public get topPosition(): number | null {
        const paddingAdjustment = this._getPadding();

        if (!this.selectedElementRect) {
            return null;
        }


        let topPosition = 0;

        if (this.currentStep?.belowHighlight) {
            topPosition = this.selectedElementRect.top + this.selectedElementRect.height + paddingAdjustment;
        }
        else {
            topPosition = this.selectedElementRect.top - this._getPadding();
        }

        return topPosition;
    }

    public get overlayLeft(): number {
        if (this.selectedElementRect) {
            return this.selectedElementRect.left - this._getPadding();
        }
        return 0;
    }

    public get leftPosition(): number | null {
        if (!this.selectedElementRect) {
            return null;
        }

        if (this._calculatedLeftPosition >= 0) {
            return this._calculatedLeftPosition;
        }
        const adjustment = Math.max(0, -this._calculatedLeftPosition);
        const maxAdjustment = Math.min(200, adjustment);
        return this._calculatedLeftPosition + maxAdjustment;
    }

    public get overlayHeight(): number {
        if (this.selectedElementRect) {
            return this.selectedElementRect.height + (2 * this._getPadding());
        }
        return 0;
    }

    public get overlayWidth(): number {
        if (this.selectedElementRect) {
            return this.selectedElementRect.width + (2 * this._getPadding());
        }
        return 0;
    }

    public get transform(): string | null {
        if (!this.selectedElementRect || this.currentStep?.belowHighlight) {
            return null;
        } else {
            return 'translateY(-100%)';
        }
    }

    public get nextButtonIcon(): string {
        return this.tour.onLastStep ? 'flag' : 'arrow-right';
    }

    public get showPrevious(): boolean {
        return !this.tour.onFirstStep;
    }

    public get arrowLeftPosition(): number {
        if (!this.selectedElementRect) {
            return 0;
        }

        let leftPosition = this._calculatedLeftPosition;

        if (leftPosition < 0) {
            leftPosition = 0;
        }

        return this.selectedElementRect.right - (this.selectedElementRect.width / 2) - leftPosition;
    }

    private get _calculatedLeftPosition(): number {
        if (!this.selectedElementRect) {
            return 0;
        }

        let leftPosition = (this.selectedElementRect.right - (this.selectedElementRect.width / 2)
            - ((this.tourTip?.nativeElement.clientWidth || 0) / 2));
        // Because the window is always width 80% take the width of the window * 0.8
        const overflowRight = (leftPosition + (window.outerWidth * 0.8)) - window.outerWidth;

        if (overflowRight > 0) {
            leftPosition -= overflowRight + 15;
        }
        return leftPosition;
    }

    ngAfterViewInit(): void {
        this.tour.tourStatus$.pipe(takeUntil(this.destroy$)).subscribe(
            (status) => { this.guidedTourActive = status; }
        );

        this.tour.currentStep$.pipe(takeUntil(this.destroy$)).subscribe(
            (step) => {
                this._initializeStep(step);
            }
        );

        this.tour.recalculateWindow$.pipe(takeUntil(this.destroy$)).subscribe(
            () => {
                this._calculateSelectorRect();
            }
        );

        fromEvent(window, 'resize').pipe(takeUntil(this.destroy$)).subscribe(
            () => { this._calculateSelectorRect(); }
        );

        fromEvent(window, 'scroll').pipe(takeUntil(this.destroy$)).subscribe(
            () => { this._calculateSelectorRect(); }
        );
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    onNextClick(): void {
        this.tour.nextTourStep();
    }

    onPreviousClick(): void {
        this.tour.previousTourStep();
    }

    onSkipClick(): void {
        const confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: new ConfirmDialogData(
                'tour.text.skip_confirm',
                undefined,
                'accent',
                'primary',
                true,
                'tour.text.skip_all',
                undefined,
                'tour.text.just_this_page',
                true
            )
        });

        confirmDialogRef.afterClosed().pipe(take(1))
            .subscribe(
                (result: ConfirmDialogResult) => {
                    if (result && result !== 'cancel') {
                        this.tour.skipTour(result !== 'continueAnd');
                    }
                }
            );
    }

    handleSpotlightClick(): void {
        if (this.currentStep?.clickAction) {
            this.tour.pageChanged();
            this.currentStep.clickAction();
        }
    }

    private _initializeStep(step: TourStep): void {
        this.currentStep = step;
        this._calculateSelectorRect();

        // Wait for render and then scroll element into view if needed
        setTimeout(() => {
            if (!this._isTourOnScreen() && this.selectedElementRect) {
                if (this.currentStep?.belowHighlight) {
                    // Scroll so the element is on the top of the screen.
                    const topPos = window.scrollY + this.selectedElementRect.top;
                    try {
                        window.scrollTo({
                            left: undefined,
                            top: topPos,
                            behavior: 'smooth'
                        });
                    } catch (err) {
                        if (err instanceof TypeError) {
                            window.scroll(0, topPos);
                        } else {
                            throw err;
                        }
                    }
                } else {
                    // Scroll so the element is on the bottom of the screen.
                    let topPos = (window.scrollY + this.selectedElementRect.top + this.selectedElementRect.height)
                        - window.innerHeight;

                    // If the footer is shown account for the extra room required
                    if (this.globals.footerShown) {
                        topPos += 80;
                    }
                    try {
                        window.scrollTo({
                            left: undefined,
                            top: topPos,
                            behavior: 'smooth'
                        });
                    } catch (err) {
                        if (err instanceof TypeError) {
                            window.scroll(0, topPos);
                        } else {
                            throw err;
                        }
                    }
                }
            }
        });
    }

    private _isTourOnScreen(): boolean {
        if (!this.currentStep || !this.currentStep.selector) {
            return true;
        }

        return this.currentStep
            && this._elementInViewport(this.dom.querySelector(this.currentStep.selector));
    }

    private _calculateSelectorRect(): void {
        if (this.currentStep && this.currentStep.selector) {
            const selectedElement = this.dom.querySelector(this.currentStep.selector);

            if (selectedElement) {
                this.selectedElementRect = selectedElement.getBoundingClientRect();
            } else {
                this.selectedElementRect = undefined;
            }
        } else {
            this.selectedElementRect = undefined;
        }
    }

    private _getPadding(): number {
        return this.currentStep?.customPadding || 8;
    }

    private _elementInViewport(element: HTMLElement | null): boolean {
        if (!element) {
            return true;
        }

        let top = element.offsetTop;
        const height = element.offsetHeight;

        while (element.offsetParent) {
            element = (element.offsetParent as HTMLElement);
            top += element.offsetTop;
        }
        return (
            top >= window.pageYOffset
            && (top + height) <= (window.pageYOffset + window.innerHeight - (this.globals.footerShown ? 80 : 0))
        );
    }
}
