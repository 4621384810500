<div class="url-button" (click)="openLink()" *ngIf="validURL && content?.name">
    <div class="d-flex title-container">
        <cp-icon name="external-link" size="x-small" class="icon mr-2"></cp-icon>
        <span class="name">
            {{ content?.name }}
        </span>
    </div>

    <div class="d-flex">
        <i class="material-icons-outlined tap-icon">touch_app</i>
    </div>
</div>

<ng-template #dialog>
    <mat-dialog-content class="mat-typography">
        <span [translate]="'wiki.text.about_to_open_external_link'" [translateParams]='{ url: content?.url }'>
            You are about to open an external link
        </span>
    </mat-dialog-content>
    <mat-dialog-actions align='end'>
        <a mat-flat-button class="cp-button col-12 mt-2" color="primary" [href]="content?.url" rel="noopener noreferrer"
            target="_blank" (click)="linkResolved(true)">
            <span [translate]='"general.text.open_link"'>
                Open link
            </span>
        </a>
        <button mat-flat-button class="cp-button col-12 mt-2" (click)="linkResolved(false)">
            <span [translate]='"general.button.cancel"'>
                Cancel
            </span>
        </button>
    </mat-dialog-actions>
</ng-template>
