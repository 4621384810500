import { AccountDataService } from '@account/services';
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { take } from 'rxjs/operators';

@Component({
    templateUrl: './invite-link-modal.component.html',
    styleUrls: ['./invite-link-modal.component.scss']
})
export class InviteLinkModalComponent {

    saving = false;
    errorSaving = false;

    constructor(
        private dialogRef: MatDialogRef<InviteLinkModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: InviteLinkModalData,
        private dataService: AccountDataService,
    ) { }

    get confirmTranslationCode(): string {
        if (this.data.fromInviteLink) {
            if (this.data.roleName) {
                return 'account.text.link_account_from_invite_link_confirmation_with_role';
            }
            return 'account.text.link_account_from_invite_link_confirmation';
        }

        return 'account.text.invite_account_link_confirmation';
    }

    onClickCancel(): void {
        this.dialogRef.close(false);
    }

    onClickConfirm(): void {
        this.saving = true;
        this.errorSaving = false;

        this.dataService.postLinkAccount(this.data.invitationCode, this.data.fromInviteLink, this.data.organisationId)
            .pipe(take(1)).subscribe({
                next: () => {
                    this.saving = false;
                    this.dialogRef.close(true);
                },
                error: () => {
                    this.errorSaving = true;
                    this.saving = false;
                }});
    }
}

export interface InviteLinkModalData {
    invitationCode: string;
    organisationName: string;
    eventName?: string;
    roleName?: string;
    organisationId?: number;
    fromInviteLink: boolean;
}

