<div class='card cursor-pointer' [class.priority]='chatGroup.priority === "High"'
    [class.has-unread]='chatGroup.unreadMessages > 0' (click)='openGroup()' [class.disabled]='disabled'>
    <div class='profile mr-2 my-auto'>
        <div class='m-auto'>
            {{ profileLetter }}
        </div>
    </div>
    <div class='content'>
        <div class='d-flex'>
            <div class='name' [class.cap-width]='chatGroup.latestSentOn'>
                {{ chatGroup.groupName }}
            </div>

            <div class='ml-auto timestamp pr-1' *ngIf='chatGroup.latestSentOn'>
                <span *ngIf='wasSentToday; else yesterday' class='time'>
                    {{ chatGroup.latestSentOn | localizedDate:'shortTime' }}
                </span>
                <ng-template #yesterday>
                    <span *ngIf='wasSentYesterday; else date' [translate]='"general.text.yesterday"'></span>
                    <ng-template #date>
                        <span>
                            {{ chatGroup.latestSentOn | localizedDate:'mediumDate' }}
                        </span>
                    </ng-template>
                </ng-template>
            </div>

            <div class='unread-indicator' *ngIf='chatGroup.unreadMessages > 0'>
                <div class='m-auto'>
                    {{ chatGroup.unreadMessages > 99 ? '99+' : chatGroup.unreadMessages }}
                </div>
            </div>
        </div>
        <div *ngIf='chatGroup.latestMessage; else nomessage' class='message'>
            <div class='message-content'>
                <span class='sender'>
                    {{ senderName }}:
                </span>
                {{ chatGroup.latestMessage }}
            </div>
        </div>
        <ng-template #nomessage>
            <div class='message'>
                <span class='no-message' [translate]='"chat.text.no_messages_yet"'>
                    No messages yet.
                </span>
            </div>
        </ng-template>
    </div>
</div>
