import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { TourProgress, TourStep } from '@core/models';

@Injectable()
export class GuidedTourService {
    private readonly _tourVersion = 1;

    private _tourStatusChanged = new Subject<boolean>();
    tourStatus$ = this._tourStatusChanged.asObservable();
    private _currentStep = new Subject<TourStep>();
    currentStep$ = this._currentStep.asObservable();
    private _recalculateWindow = new Subject<boolean>();
    recalculateWindow$ = this._recalculateWindow.asObservable();

    private _tourSteps: TourStep[] = [];
    private _currentStepIndex = 0;
    private _currentTourStatus = false;
    private _progress?: TourProgress;

    get onFirstStep(): boolean {
        return this._currentStepIndex === 0;
    }

    get onLastStep(): boolean {
        return (this._tourSteps.length - 1) === this._currentStepIndex;
    }

    get hasProgress(): boolean {
        return this.progress.versionFinished !== undefined || this.progress.pagesFinished.length > 0;
    }


    private get progress(): TourProgress {
        if (this._progress) {
            return this._progress;
        }

        const cache = localStorage.getItem('tour-progress');

        if (cache) {
            this._progress = JSON.parse(cache);
        }

        if (!this._progress) {
            this._progress = {
                pagesFinished: []
            };
        }
        return this._progress;
    }

    addTourStep(
        step: number, page: number, version: number,
        textKey: string, selector?: string,
        clickAction?: () => void, clickChangesPage = false, belowHighlight = false, nextDisabled = false, startTour = false): void {
        const tourStep: TourStep = {
            step, page, version, selector, textKey, clickAction, clickChangesPage, belowHighlight, nextDisabled
        };
        this._tryAddTourStep(tourStep);

        if (startTour && !this._currentTourStatus) {
            this.startTour();
        }
    }

    startTour(): void {
        if (this._tourSteps && this._tourSteps.length > 0 && !this._currentTourStatus) {
            this._currentTourStatus = true;
            this._tourStatusChanged.next(this._currentTourStatus);
            this._currentStepIndex = 0;
            this._currentStep.next(this._tourSteps[this._currentStepIndex]);
        }
    }

    stopTour(): void {
        if (this._currentTourStatus) {
            this._tourSteps = [];
            this._currentStepIndex = 0;
            this._currentTourStatus = false;
            this._tourStatusChanged.next(this._currentTourStatus);
        }
    }

    nextTourStep(): void {
        this._completeStep(this._tourSteps[this._currentStepIndex]);
        if (!this.onLastStep) {
            this._currentStepIndex++;
            this._currentStep.next(this._tourSteps[this._currentStepIndex]);
        } else {
            this._finishTourPage();
        }
    }

    previousTourStep(): void {
        if (!this.onFirstStep) {
            this._currentStepIndex--;
            this._currentStep.next(this._tourSteps[this._currentStepIndex]);
        }
    }

    pageChanged(): void {
        this._completeStep(this._tourSteps[this._currentStepIndex]);
        this._tourSteps = [];
        this._currentTourStatus = false;
        this._tourStatusChanged.next(this._currentTourStatus);
    }

    skipTour(skipVersion = false): void {
        if (!skipVersion) {
            this._skipTourPage();
        } else {
            this.progress.versionFinished = this._tourVersion;
            this._cacheProgress();
            this._tourSteps = [];
            this._currentTourStatus = false;
            this._currentStepIndex = 0;
            this._tourStatusChanged.next(this._currentTourStatus);
        }
    }

    resetTour(): void {
        this._progress = {
            pagesFinished: []
        };
        this._cacheProgress();
    }

    viewPortChanged(): void {
        this._recalculateWindow.next(true);
    }

    private _skipTourPage(): void {
        const currPage = this._tourSteps[this._currentStepIndex].page;

        const pageProgress = this.progress.pagesFinished.find(x => x.page === currPage);

        if (pageProgress) {
            pageProgress.fullyFinished = true;
            pageProgress.fullyFinishedVersion = this._tourVersion;
        } else {
            this.progress.pagesFinished.push({
                page: currPage,
                fullyFinished: true,
                fullyFinishedVersion: this._tourVersion,
                steps: []
            });
        }

        this._cacheProgress();
        this._finishTourPage();
    }

    private _finishTourPage(): void {
        this._tourSteps = [];
        this._currentTourStatus = false;
        this._currentStepIndex = 0;
        this._tourStatusChanged.next(this._currentTourStatus);
    }

    private _tryAddTourStep(step: TourStep): void {
        // Check if this entire step version has been marked as finished.
        if (this.progress.versionFinished && this.progress.versionFinished >= step.version) {
            return;
        }

        const pageProgress = this.progress.pagesFinished.find(x => x.page === step.page);

        // Check if this page has been finished for this version / the current step has already been finished.
        if (pageProgress) {
            if (pageProgress.fullyFinished
                && pageProgress.fullyFinishedVersion
                && pageProgress.fullyFinishedVersion >= step.version) {
                return;
            }

            if (pageProgress.steps.some(x => x === step.step)) {
                return;
            }
        }

        // If the step has not already been completed or skipped add it to the tour.
        this._tourSteps.push(step);
    }

    private _completeStep(step: TourStep): void {
        const pageProgress = this.progress.pagesFinished.find(x => x.page === step.page);

        if (pageProgress) {
            if (!pageProgress.steps.find(x => x === step.step)) {
                pageProgress.steps.push(step.step);
            }
        } else {
            this.progress.pagesFinished.push({
                page: step.page,
                fullyFinished: false,
                steps: [step.step]
            });
        }

        this._cacheProgress();
    }

    private _cacheProgress(): void {
        const serialized = JSON.stringify(this._progress);
        localStorage.setItem('tour-progress', serialized);
    }
}
