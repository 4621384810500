import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ChatMessage } from '@chat/models';

@Component({
    templateUrl: './resend-message-dialog.component.html',
    styleUrls: ['./resend-message-dialog.component.scss']
})
export class ResendMessageDialogComponent {

    constructor(
        @Inject(MAT_DIALOG_DATA) public message: ChatMessage
    ) { }
}
