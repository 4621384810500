import { StringModel } from '@core/models';
import { GeneralDataService } from '@core/services';
import { EndPoint } from '@core/constants';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { IInvitationUserResult, IInvitationResult, IAccountSettings, InviteLinkModel } from '@account/models';

@Injectable()
export class AccountDataService extends GeneralDataService {
    getInvitationDetails(token: string): Observable<IInvitationResult> {
        return this.getGenericItem(EndPoint.account, `invite?invitationToken=${token}`);
    }

    getInvitationUserDetails(token: string): Observable<IInvitationUserResult> {
        return this.getGenericItem(EndPoint.account, `invite/user?invitationToken=${token}`);
    }

    requestInvitationResend(invitationCode: string): Observable<void> {
        const resendData = { invitationCode };
        const jsonData = JSON.stringify(resendData);
        return this.postJSON<void>(EndPoint.account, `invite/resend`, jsonData);
    }

    postLinkAccount(invitationCode: string, fromInviteLink: boolean, organisationId?: number): Observable<void> {
        const linkAccountData = { invitationCode, fromInviteLink, organisationId };
        const jsonData = JSON.stringify(linkAccountData);
        return this.postJSON<void>(EndPoint.account, 'invite/link', jsonData);
    }

    validateCode(token: string): Observable<boolean> {
        return this.getGenericItem(EndPoint.account, `invite/validateCode?invitationToken=${token}`);
    }

    validateInviteLink(code: string, organisationId: number): Observable<InviteLinkModel> {
        return this.getGenericItem(EndPoint.account, `invite/validateInviteLink?invitationToken=${code}&organisationId=${organisationId}`);
    }

    postRegistration(username: string, email: string, firstName: string, middleName: string,
        lastName: string, phoneNumber: string, password: string, invitationCode: string, language: string, fromInviteLink: boolean,
        organisationId?: number):
        Observable<StringModel> {
        const registerData = {
            username, email, firstName, middleName, lastName,
            phoneNumber, password, invitationCode, languageCode: language,
            fromInviteLink, organisationId
        };
        const jsonData = JSON.stringify(registerData);
        return this.postJSON<StringModel>(EndPoint.account, 'registration', jsonData);
    }

    postEmailConfirmation(confirmationToken: string, userId: string): Observable<void> {
        const confirmData = { confirmationToken, userId };
        const jsonData = JSON.stringify(confirmData);
        return this.postJSON<void>(EndPoint.account, 'registration/confirm', jsonData);
    }

    postResendEmailConfirmationRequest(userId: string): Observable<void> {
        const resendConfirmationData = { userId };
        const jsonData = JSON.stringify(resendConfirmationData);
        return this.postJSON<void>(EndPoint.account, 'registration/resendConfirm', jsonData);
    }

    isUniqueUsername(username: string, invitationCode?: string, organisationId?: number): Observable<boolean> {
        let path = `registration/isUniqueUsername?username=${username}`;
        if (invitationCode !== undefined) {
            path = path + `&invitationToken=${invitationCode}`;
        }
        if (organisationId !== undefined) {
            path = path + `&organisationId=${organisationId}`;
        }

        return this.getGenericItem(EndPoint.account, path);
    }

    isUniqueEmail(email: string, excludeSelf: boolean, invitationCode?: string, organisationId?: number): Observable<boolean> {
        let path = `registration/isUniqueEmail?email=${email}&excludeSelf=${excludeSelf}`;
        if (invitationCode !== undefined) {
            path = path + `&invitationToken=${invitationCode}`;
        }
        if (organisationId !== undefined) {
            path = path + `&organisationId=${organisationId}`;
        }

        return this.getGenericItem(EndPoint.account, path);
    }

    getAccountSettings(): Observable<IAccountSettings> {
        return this.getGenericItem<IAccountSettings>(EndPoint.account, 'settings');
    }

    setAccountSettings(settings: IAccountSettings): Observable<unknown> {
        return this.postGenericItem(EndPoint.account, 'settings', settings);
    }

    setSystemLanguage(language: string): Observable<void> {
        return this.postGenericItem(EndPoint.account, 'settings/language', { languageCode: language });
    }

    confirmNewEmail(code: string): Observable<unknown> {
        return this.postGenericItem(EndPoint.account, 'settings/mail/confirm', { confirmationToken: code });
    }

    resendConfirmationEmailCode(): Observable<unknown> {
        return this.postGenericItem(EndPoint.account, 'settings/mail/resend', null);
    }

    revertChangedEmail(): Observable<unknown> {
        return this.postGenericItem(EndPoint.account, 'settings/mail/revert', null);
    }

    activateCMSAccount(confirmationToken: string, userId: string, password: string): Observable<void> {
        const confirmData = { confirmationToken, userId, password };
        const jsonData = JSON.stringify(confirmData);
        return this.postJSON<void>(EndPoint.account, 'registration/cms/activate', jsonData);
    }

    requestNewCMSAccountToken(userId: string): Observable<void> {
        const resendConfirmationData = { userId };
        const jsonData = JSON.stringify(resendConfirmationData);
        return this.postJSON<void>(EndPoint.account, 'registration/cms/resend', jsonData);
    }
}
