import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { EventOverview, OverviewEvent } from '@overview/models';
import { OverviewDataService } from '@overview/services';
import { switchMap, takeUntil, take, concatMap } from 'rxjs/operators';
import { Subject, Subscription, timer } from 'rxjs';
import { GlobalsService, AuthenticationService, FeatureToggleService, GuidedTourService, MediaDataService, LoggingService } from '@core/services';
import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { trigger, transition, style, animate } from '@angular/animations';
import { DateUtils } from '@core/utils';
import { ScreensizeDialogComponent } from '@shared/components';
import { IOSInstallDialogComponent } from './sub-components';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { errorLogTap } from '@core/handlers';

@Component({
    templateUrl: './event-overview.component.html',
    styleUrls: ['./event-overview.component.scss'],
    animations: [
        trigger('initialFadeIn', [
            transition('* <=> *', [
                style({ opacity: '0', 'margin-top': '100px' }),
                animate('.5s ease-out', style({ opacity: '1', 'margin-top': '0px' })),
            ]),
        ]),
        trigger('fadeIn', [
            transition(':enter', [
                style({ opacity: '0' }),
                animate('.5s ease-out', style({ opacity: '1' })),
            ]),
        ]),
    ]
})
export class EventOverviewComponent implements OnInit, OnDestroy {
    destroy$: Subject<boolean> = new Subject<boolean>();

    organisationId!: number;

    defaultImageColor?: string;

    loading = false;
    loadingVideo = false;
    error = false;
    errorLoadingVideo = false;

    videoActive = false;

    overview!: EventOverview;

    public readonly TIME_FORMAT = 'shortTime';
    private readonly _promptKey = 'install-prompt';
    private readonly INSTALL_PROMPT_DELAY_TIMER = 1000 * 60 * 60 * 24; // One day

    private readonly EVENTS_POLLING_DELAY = 30000;
    private _eventsPoll?: Subscription;

    constructor(
        private route: ActivatedRoute,
        private data: OverviewDataService,
        private router: Router,
        private auth: AuthenticationService,
        private translate: TranslateService,
        private dialog: MatDialog,
        private globals: GlobalsService,
        private feature: FeatureToggleService,
        private tour: GuidedTourService,
        private logging: LoggingService,
    ) {
        globals.hideFooter();

        // Set a temporary english header at first, to prevent the header from suddenly popping in later.
        globals.changeHeader({ title: `Hello ${auth.claims?.given_name}`, buttonName: 'x' });

        globals.onHeaderButtonClick()
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                () => {
                    this.router.navigate([''], { queryParams: { manual: true } });
                }
            );
    }

    get noEvents(): boolean {
        if (this.loading) { return false; }

        return this.overview.upcomingEvents.length === 0
            && this.overview.evaluationEvents.length === 0
            && this.overview.finishedEvents.length === 0;
    }

    get hasEvaluations(): boolean {
        return this.overview.evaluationEvents && this.overview.evaluationEvents.length > 0;
    }

    get hasUpcomingEvents(): boolean {
        return this.overview.upcomingEvents && this.overview.upcomingEvents.length > 0;
    }

    get hasFinishedEvents(): boolean {
        return this.overview.finishedEvents && this.overview.finishedEvents.length > 0;
    }

    get showTimeline(): boolean {
        if (!this.overview || !this.overview.upcomingEvents) {
            return false;
        }

        const firstUpcomingEvent = this.overview.upcomingEvents[0];

        if (!firstUpcomingEvent || !firstUpcomingEvent.eventStartDate) {
            return false;
        }

        const dateDifference = DateUtils.CalculateDifferenceInDaysBetweenDates(firstUpcomingEvent.eventStartDate, new Date());
        return dateDifference <= 14 && dateDifference >= 0;
    }

    get shouldShowInstallPrompt(): boolean {
        const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
        const standalone = 'standalone';
        // ts will not let me index on standalone without making this any

        const _navigator: any = window.navigator;
        const isStandAlone = ('standalone' in window.navigator) && _navigator[standalone];
        return isIOS && !isStandAlone;
    }

    ngOnInit(): void {
        this.loading = true;

        if (this.shouldShowInstallPrompt) {
            const cachedInstallDate = localStorage.getItem(this._promptKey);

            if (cachedInstallDate) {
                try {
                    const cachedModel = JSON.parse(cachedInstallDate) as InstallPrompted;
                    if (!cachedModel) {
                        this._openInstallPrompt();
                    }

                    if (new Date().getTime() - new Date(cachedModel.promptedOn).getTime() > this.INSTALL_PROMPT_DELAY_TIMER) {
                        this._openInstallPrompt();
                    }
                } catch {
                    this._openInstallPrompt();
                }
            } else {
                this._openInstallPrompt();
            }
        }

        this.translate.get('general.text.greeting', { name: this.auth.claims?.given_name })
            .pipe(takeUntil(this.destroy$))
            .subscribe(value => {
                this.globals.changeHeader({ title: value, buttonName: 'x' });
            });

        this.route.params.pipe(
            takeUntil(this.destroy$),
            switchMap(params => {
                this.organisationId = params.organisationId;

                // Get whether reports are active for this organisation to get it cached before showing the toolbar.
                this.feature.isReportsActive(this.organisationId).pipe(take(1)).subscribe();

                this.globals.getStyling(this.organisationId)
                    .pipe(takeUntil(this.destroy$))
                    .subscribe({
                        next: styling => this.defaultImageColor = styling.colorOne,
                        error: () => this.defaultImageColor = this.globals.stylingDefaults.colorOne
                    });

                return this.data.getEvents(params.organisationId);
            })
        ).subscribe({
            next: (result) => {
                setTimeout(() => {
                    this.overview = result;
                    this.loading = false;

                    this._pollEvents();

                    if (screen.width > 500 && !sessionStorage.getItem('screen-size-warning')) {
                        sessionStorage.setItem('screen-size-warning', 'true');
                        this.dialog.open(ScreensizeDialogComponent, {
                            panelClass: 'small-dialog'
                        });
                    }

                    this.tour.addTourStep(1, 1, 1, 'tour.text.welcome_message');
                    if (this.overview.upcomingEvents && this.overview.upcomingEvents.length > 0) {
                        this.tour.addTourStep(2, 1, 1, 'tour.text.event_list_message', '#first-event');
                    }
                    if (this.overview.evaluationEvents && this.overview.evaluationEvents.length > 0) {
                        this.tour.addTourStep(3, 1, 1, 'tour.text.evaluation_list_message', '#first-eval-event');
                    }
                    this.tour.addTourStep(4, 1, 1, 'tour.text.settings_message', '#settings-button');
                    this.tour.addTourStep(5, 1, 1, 'tour.text.return_button_message', '#header-button', undefined, false, true);
                    // Allow animations to finish before starting the tour.
                    setTimeout(() => {
                        this.tour.startTour();
                    }, 500);
                }, 1000);
            },
            error: () => {
                this.loading = false;
                this.error = true;

                setTimeout(() => {
                    this.router.navigate(['']);
                }, 2000);
            }}
        );

        this.feature.isFeatureActive(this.organisationId, 'Videos')
        .pipe(take(1), errorLogTap(this.logging, this.organisationId)).subscribe({
            next: (result) => {
                this.videoActive = result;
            }, error: () => {
                this.videoActive = false;
            }
        })
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
        this._eventsPoll?.unsubscribe();
        this._eventsPoll = undefined;
    }

    getRippleColorForEvent(event: OverviewEvent): string {
        if (event.isUrgent) {
            // Transparent warn-red.
            return 'rgba(244, 67, 54, 0.2)';
        } else if (event.progress === 100) {
            // Transparent catchphrase-green.
            return 'rgba(130, 186, 122, 0.2)';
        } else {
            // Transparent black.
            return 'rgba(0, 0, 0, 0.2)';
        }
    }

    toOrganisationSelection(): void {
        this.router.navigate(['']);
    }

    getShowEndDate(event: OverviewEvent): boolean {
        if (!event.eventStartDate || !event.endDate) {
            return false;
        }

        return !DateUtils.IsSameDate(event.eventStartDate, event.endDate);
    }

    getDaysLeft(event: OverviewEvent): number {
        if (event.eventStartDate) {
            const today = new Date();
            return DateUtils.CalculateDifferenceInDaysBetweenDates(event.eventStartDate, today);
        }
        return 0;
    }

    private _openInstallPrompt(): void {
        const installPrompt: InstallPrompted = { promptedOn: new Date() };
        localStorage.setItem(this._promptKey, JSON.stringify(installPrompt));
        this.dialog.open(IOSInstallDialogComponent, {
            disableClose: true,
            backdropClass: 'darkened-backdrop',
            panelClass: 'install-prompt',
            position: { bottom: '36px' }
        });
    }

    private _pollEvents(): void {
        this._eventsPoll = timer(this.EVENTS_POLLING_DELAY)
            .pipe(
                take(1),
                concatMap(() => this.data.pollEvents(this.organisationId)
                )).subscribe(
                (result) => {
                    this._handlePollResult(result);
                    this._pollEvents();
                }
            );
    }

    private _handlePollResult(result: EventOverview): void {
        if (result) {
            this._handleList(this.overview.upcomingEvents, result.upcomingEvents);
            this._handleList(this.overview.evaluationEvents, result.evaluationEvents);
            this._handleList(this.overview.finishedEvents, result.finishedEvents);
        }
    }

    private _handleList(originalEvents: OverviewEvent[], newEvents: OverviewEvent[]): void {
        const itemsToRemove = originalEvents.filter(e => !newEvents.some(ne => ne.id === e.id));
        originalEvents.forEach(e => {
            if (itemsToRemove.some(i => i.id === e.id)) {
                const index = originalEvents.indexOf(e);
                originalEvents.splice(index, 1);
            }
        });

        originalEvents.forEach(e => {
            const updatedEvent = newEvents.find(ne => ne.id === e.id);

            if (updatedEvent) {
                e.eventName = updatedEvent?.eventName;
                e.eventStartDate = updatedEvent?.eventStartDate;
                e.endDate = updatedEvent?.endDate;
                e.evaluationEndDate = updatedEvent?.evaluationEndDate;
                e.progress = updatedEvent?.progress;
                e.isUrgent = updatedEvent?.isUrgent;
                e.hasBriefings = updatedEvent?.hasBriefings;
                e.hasUnreadNotifications = updatedEvent?.hasUnreadNotifications;
            }
        });

        originalEvents.push(...newEvents.filter(ne => !originalEvents.some(e => e.id === ne.id)));
    }
}

interface InstallPrompted {
    promptedOn: Date;
}
