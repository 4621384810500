<div class='background'></div>

<cp-no-connection-message [shouldCache]='true' *ngIf='offlineNoCache; else loaderRef'>
</cp-no-connection-message>
<ng-template #loaderRef>
    <div class='loader-container' *ngIf='loading; else contentRef'>
        <cp-delayed-spinner></cp-delayed-spinner>
    </div>
</ng-template>
<ng-template #contentRef>
    <div class='container foreground'>
        <div class='logo-container mt-3 mb-4'>
            <cp-logo color='white' maxHeight='40px' maxWidth='40px'></cp-logo>
        </div>
        <div class='title-container'>
            <h1>
                <span [translate]='"general.text.welcome"'>
                    Welcome
                </span>
                <span>
                    {{ firstName }}
                </span>
            </h1>
            <h2>
                -
                <span [translate]='"organisation.text.select_screen_slogan"'>
                    Do you also want to peak at the right moment?
                </span>
            </h2>
        </div>
        <div class='intro'>
            <span [translate]='"organisation.text.intro_text"'>
                Below you will find a list of organizations that
                have invited you. If your next event takes place within
                two weeks, the timeline will show how many
                days you have left to prepare yourself.
            </span>
        </div>
        <div *ngIf='singularEventOrganisations && singularEventOrganisations.length > 0'>
            <div class='title mt-3 mb-2'>
                <span [translate]='"organisation.text.singular_events"'>
                    Singular events
                </span>
            </div>
            <div *ngFor='let organisation of singularEventOrganisations' class='card p-2 my-3'
                [class.showMessageLabel]='organisation.hasUnreadNotifications'
                (click)='onClickCard(organisation, false)'>
                <div class='image-container mr-2' [class.no-styling]='!organisation.backgroundColour'
                    [style.backgroundColor]='organisation.backgroundColour'>
                    <cp-logo *ngIf='!organisation.hasLogo; else organisationLogo' class='default-image' color='white'
                        maxHeight='40px' maxWidth='40px'>
                    </cp-logo>

                    <ng-template #organisationLogo>
                        <img [src]='globals.getOrganisationLogoUri(organisation.id)' class='image'>
                    </ng-template>
                </div>
                <div class='content'>
                    <div class='organisation-name'>
                        {{ organisation.name }}
                    </div>
                    <ng-container *ngIf='organisation.amountPlannedEvents; else noEventsRef'>
                        <div class='event-name'>
                            {{ organisation.eventName }}
                        </div>
                        <div class='event-line'>
                            <ng-container *ngIf='!organisation.inEvaluationPeriod; else evaluationEndDateRef'>
                                <div class='small-text'>
                                    {{ organisation.eventDate | localizedDate: DATE_FORMAT }}
                                </div>
                                <div *ngIf='daysLeft(organisation) !== undefined' class='small-text'>
                                    <span *ngIf='(daysLeft(organisation) || 0) <= 1; else daysLeftRef'
                                        [translate]='daysLeft(organisation) === 1 ? "general.text.tomorrow" : "general.text.today"'>
                                    </span>
                                    <ng-template #daysLeftRef>
                                        <span
                                            [innerHTML]='"general.text.days_left" | translate:{ daysLeft: daysLeft(organisation) }'>
                                            .. days left
                                        </span>
                                    </ng-template>
                                </div>
                            </ng-container>
                            <ng-template #evaluationEndDateRef>
                                <div class='small-text'>
                                    <ng-container *ngIf='daysLeft(organisation) !== 0; else evaluationEndTodayRef'>
                                        <span [translate]='"evaluation.text.evaluation_expires_on"'>
                                            Evaluation expires on
                                        </span>
                                        {{ organisation.evaluationEndDate | localizedDate: 'longDate' }}
                                    </ng-container>
                                    <ng-template #evaluationEndTodayRef>
                                        <span [translate]='"evaluation.text.evaluation_expires_today"'>
                                            Evaluation expires today
                                        </span>
                                    </ng-template>
                                </div>
                            </ng-template>
                        </div>
                        <div *ngIf='daysLeft(organisation) !== undefined && (daysLeft(organisation) || 15) < 15 && organisation.inEvaluationPeriod === false'
                            class='pt-2'>
                            <cp-mini-timeline-slider [daysLeft]='daysLeft(organisation) || 0'
                                [color]='(daysLeft(organisation) || 0) <= 0 ? "primary" : "blue"'>
                            </cp-mini-timeline-slider>
                        </div>
                        <div class='card-bottom pt-1 mt-2'>
                            <div class='d-inline-block small-text mr-2'>
                                <span class='amount'>
                                    {{ organisation.amountPlannedEvents }}
                                </span>
                                <span
                                    [translate]='organisation.amountPlannedEvents === 1 ? "organisation.text.planned_event" : "organisation.text.planned_events"'>
                                    Planned events
                                </span>
                            </div>
                            <div class='d-inline-block evaluation-tasks small-text pl-2'>
                                <span class='amount'>
                                    {{ organisation.amountEvaluationTasks }}
                                </span>
                                <span
                                    [translate]='organisation.amountEvaluationTasks === 1 ? "general.text.evaluation_task" : "general.text.evaluation_tasks"'>
                                    Evaluation tasks
                                </span>
                            </div>
                        </div>
                    </ng-container>
                    <ng-template #noEventsRef>
                        <div class='small-text'>
                            <span class='amount'>
                                0
                            </span>
                            <span
                                [translate]='organisation.amountPlannedEvents === 1 ? "organisation.text.planned_event" : "organisation.text.planned_events"'>
                                Planned events
                            </span>
                        </div>
                        <div class='no-events-disclaimer pt-1 mt-2'>
                            <span [translate]='"organisation.text.empty_organisation_disclaimer"'>
                                If this is incorrect, please contact the organisation
                            </span>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
        <div *ngIf='ongoingEventOrganisations && ongoingEventOrganisations.length > 0'>
            <div class='title mt-3 mb-2'>
                <span [translate]='"organisation.text.ongoing_events"'>
                    Ongoing events
                </span>
            </div>
            <div *ngFor='let organisation of ongoingEventOrganisations' class='card p-2 my-3'
                [class.showMessageLabel]='organisation.hasUnreadNotifications'
                (click)='onClickCard(organisation, true)'>
                <div class='image-container mr-2' [class.no-styling]='!organisation.backgroundColour'
                    [style.backgroundColor]='organisation.backgroundColour'>
                    <cp-logo *ngIf='!organisation.hasLogo; else organisationLogo' class='default-image' color='white'
                        maxHeight='40px' maxWidth='40px'>
                    </cp-logo>

                    <ng-template #organisationLogo>
                        <img [src]='globals.getOrganisationLogoUri(organisation.id)' class='image'>
                    </ng-template>
                </div>
                <div class='content'>
                    <div class='organisation-name'>
                        {{ organisation.name }}
                    </div>
                    <div class='event-name'>
                        {{ organisation.eventName }}
                    </div>
                    <div class='card-bottom pt-1 mt-2'>
                        <div class='d-inline-block small-text'>
                            <span class='amount'>
                                {{ organisation.amountEvaluationTasks }}
                            </span>
                            <span
                                [translate]='organisation.amountEvaluationTasks === 1 ? "general.text.evaluation_task" : "general.text.evaluation_tasks"'>
                                Evaluation tasks
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<cp-settings-button></cp-settings-button>
