import { CelebrateService } from '@core/services';

import { Component, EventEmitter, Output, Input } from '@angular/core';

@Component({
    selector: 'cp-thank-you-page',
    templateUrl: './thank-you-page.component.html',
    styleUrls: ['./thank-you-page.component.scss']
})
export class ThankYouPageComponent {

    @Input()
        loading!: boolean;

    @Input()
        error!: boolean;

    @Output()
        finishEvaluation = new EventEmitter<void>();

    loadingImage = true;
    imageNotFound = false;

    constructor(private celebrateService: CelebrateService) { }

    spawnConfetti(duration: number): void {
        this.celebrateService.startCelebration(duration);
    }

    onClickFinish(): void {
        this.finishEvaluation.emit();
    }
}
