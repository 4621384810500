import { SafeHtml } from '@angular/platform-browser';

export enum BriefingSectionType {
    'text',
    'image',
    'video',
    'pdf' = 6,
    'image-text' = 7,
    'divider' = 8,
    'map' = 9,
    'dropdown' = 10,
    'external-link' = 11
}

export interface IBriefingSectionModel {
    type: BriefingSectionType;
    content?: string;
    _sanitizedContent?: SafeHtml;
}
