import {
    Component, Input, ElementRef, ViewChild, OnInit, Optional, Self, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl, FormGroupDirective, NgForm, NgControl } from '@angular/forms';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ErrorStateMatcher } from '@angular/material/core';

@Component({
    selector: 'cp-password-field',
    templateUrl: './toggleable-password-input-field.component.html',
    styleUrls: ['./toggleable-password-input-field.component.scss'],
})
export class ToggleablePasswordInputFieldComponent implements OnInit, OnChanges {
    @Input() disabled = false;

    @Input() validate = false;

    @Input() light = false;

    @Input()
        labelKey = 'account.text.password';

    @Input()
        colSize = 12;

    @Input()
        colOffset = 0;

    @Output()
        blurred = new EventEmitter<void>();

    @Output()
        changed = new EventEmitter<void>();

    @ViewChild('formField')
        formField!: ElementRef;

    _control: UntypedFormControl = new UntypedFormControl('');

    value = '';
    showPassword = false;
    errorStateMatcher!: ErrorStateMatcher;

    public _confirmField!: boolean;
    private _required!: boolean;
    private _hideRequiredMarker = false;

    constructor(@Optional() @Self() public ngControl: NgControl) {
        if (this.ngControl != null) {
            this.ngControl.valueAccessor = this;
        }
    }

    @Input()
    get required(): string { return `${this._required}`; }
    set required(value: string) { this._required = coerceBooleanProperty(value); }

    @Input()
    get hideRequiredMarker(): string { return `${this._hideRequiredMarker}`; }
    set hideRequiredMarker(value: string) { this._hideRequiredMarker = coerceBooleanProperty(value); }


    @Input()
    get confirmField(): string { return `${this._confirmField}`; }
    set confirmField(value: string) { this._confirmField = coerceBooleanProperty(value); }

    get classes(): string { return `col-${this.colSize} offset-${this.colOffset}`; }

    ngOnInit(): void {
        this.errorStateMatcher = this._confirmField ? new ConfirmPasswordFieldErrorStateMatcher() : new ErrorStateMatcher();
        const validators = this.ngControl.control?.validator;
        this._control.setValidators(validators ? validators : null);
        if (this.disabled) {
            this._control.disable();
        }
        this._control.updateValueAndValidity();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.disabled) {
            this._control.disable();
        } else {
            this._control.enable();
        }

        if (changes.validate !== undefined) {
            const validators = this.ngControl.control?.validator;
            this._control.setValidators(validators && changes.validate.currentValue ? validators : null);
            this._control.updateValueAndValidity();
        }
    }

    writeValue(value: string): void {
        this.value = value;
        this.onChange(value);
        this.onTouched();
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    registerOnChange(fn: (password: string) => void): void {
        this.onChange = fn;
    }
    onChange(_: string): void { }

    triggerOnChange(eventTarget: EventTarget | null): void {
        this.onChange((eventTarget as HTMLInputElement).value);
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }
    onTouched(): void { }

    onBlur(): void {
        this.blurred.emit();
    }

    public focusOnField(): void {
        if (this.formField) {
            this.formField.nativeElement.focus();
        }
    }

    public clear(): void {
        this.writeValue('');
        if (this.formField) {
            this.formField.nativeElement.value = '';
        }
    }
}

export class ConfirmPasswordFieldErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        const invalidControl = !!(control && control.touched && control.errors);
        const invalidParent = !!(form && form.errors && ((control && control.touched) || (form && form.submitted))
            && form.hasError('passwordsDontMatch'));

        return invalidControl || invalidParent;
    }
}
