import { FaqCategory } from './faq-category.model';
import { GlossaryTerm } from './glossary-term.model';
import { IWikiTabModel } from './wiki-tab.model';

export interface IWikiTabResponseModel {
    eventName: string;
    wikiTabs: IWikiTabModel[];
    glossaryTerms: GlossaryTerm[];
    faqCategories: FaqCategory[];
}
