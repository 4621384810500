import { QuestionContentType } from '@core/constants';

export interface IBriefingResultQuestion {
    questionType: QuestionContentType;

    question: string;
    feedback: string;

    correctAnswers: string[];
    chosenAnswers: string[];
}
