import { ContactMethodType } from './enums';

export class EvaluationData implements IEvaluationData {
    constructor(
        public id: number,
        public mood: number = 50,
        public questionResponses: QuestionResponse[] = [],
        public feedback?: string,
        public requireFollowup?: boolean,
        public followupMethod?: ContactMethodType,
        public phonenumber?: string,
        public followupReason?: string
    ) { }
}

export interface IEvaluationData
{
    id: number;

    mood: number;
    questionResponses: QuestionResponse[];

    feedback?: string;
    requireFollowup?: boolean;
    followupReason?: string;
    followupMethod?: ContactMethodType;
    phonenumber?: string;
}

export interface QuestionResponse
{
    questionId: number;
    rating?: number;
    feedback?: string;
}
