import { HttpClient } from '@angular/common/http';
import { catchError, map, shareReplay, take } from 'rxjs/operators';
import { EndPoint } from '@core/constants';
import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { UrlResolver } from '@core/utils';

export class CatchphraseMissingTranslationHandler implements MissingTranslationHandler {
    private _translationsLoading: { [lang: string]: { [key: string]: Observable<string> } } = {};

    constructor(private http: HttpClient) { }

    handle(params: MissingTranslationHandlerParams): Observable<string> {
        let currentLang = params.translateService.currentLang;
        if (currentLang === 'gb' || currentLang === 'us') {
            currentLang = 'en';
        }
        if (!this._translationsLoading[currentLang]) {
            this._translationsLoading[currentLang] = {};
        }

        if (!this._translationsLoading[currentLang][params.key]) {
            this._translationsLoading[currentLang][params.key] =
                this.http.get<string>(UrlResolver.getUrl(EndPoint.translation, `${currentLang}/${params.key}`))
                    .pipe(
                        take(1),
                        shareReplay(1));
        }

        return this._translationsLoading[currentLang][params.key].pipe(
            map(t => params.translateService.parser.interpolate(t, params.interpolateParams) || ''),
            catchError(() => {
                const _default = '_default';
                const _params: any = params.interpolateParams;
                if (_params && _params[_default]) {
                    return of(_params[_default]);
                }
                // Yes this extra space is intentional, and no I don't know why it is necessary.
                return of(`${params.key} `);
            })
        );
    }
}
