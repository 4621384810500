import { environment } from '@env';
import { OverviewDataService } from '@overview/services';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalsService, PushService, GuidedTourService, FeatureToggleService, LoggingService } from '@core/services';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil, switchMap, take } from 'rxjs/operators';
import { ITask, EventLandingInfo, TaskType } from '@overview/models';
import { RadialProgressBarModel } from '@shared/components/radial-progress/models/radial-progress-bar-model';
import { style, transition, trigger, animate } from '@angular/animations';
import { DateUtils } from '@core/utils';
import { errorLogTap } from '@core/handlers';

@Component({
    templateUrl: './event-landing.component.html',
    styleUrls: ['./event-landing.component.scss'],
    animations: [
        trigger('fadeIn', [
            transition('void <=> *', [
                style({ opacity: '0' }),
                animate('.5s ease-out', style({ opacity: '1' })),
            ])
        ])]
})
export class EventLandingComponent implements OnInit, OnDestroy {
    destroy$: Subject<boolean> = new Subject<boolean>();

    public readonly DATE_FORMAT = 'longDate';
    public readonly TIME_FORMAT = 'shortTime';

    readonly taskTypes = TaskType;

    organisationId!: number;
    eventId!: number;

    event!: EventLandingInfo;

    hasWikiTabs = false;
    videoActive = false;
    videoId?: number;

    progressBar?: RadialProgressBarModel[];
    scoreBar?: RadialProgressBarModel[];

    loading = false;
    loadingImage = true;
    loadingVideo = false;
    error = false;
    errorLoadingVideo = false;
    animating = false;

    constructor(
        private route: ActivatedRoute,
        private data: OverviewDataService,
        private router: Router,
        private globals: GlobalsService,
        private push: PushService,
        private feature: FeatureToggleService,
        private tour: GuidedTourService,
        private logging: LoggingService,
    ) {
        globals.changeHeader({ titleKey: 'events.text.events_dashboard', buttonName: 'x' });
        globals.showFooter();
    }

    get scoreAsTenScale(): string {
        if (this.event.averageScore !== undefined) {
            const score = ((9 * this.event.averageScore || 0) / 100) + 1;
            return score.toFixed(1);
        }

        return '';
    }

    get eventImage(): string {
        return `event/${this.eventId}`;
    }

    get showTimeline(): boolean {
        if (!this.event || !this.event.eventDate) {
            return false;
        }

        const dateDifference = DateUtils.CalculateDifferenceInDaysBetweenDates(this.event.eventDate, new Date());
        return dateDifference <= 14;
    }

    get showEndDate(): boolean {
        if (!this.event || !this.event.eventDate || !this.event.endDate) {
            return false;
        }

        return !DateUtils.IsSameDate(this.event.eventDate, this.event.endDate);
    }

    get completed(): boolean {
        if (!this.event || !this.event.eventDate) {
            return false;
        }

        if (!this.event.endDate) {
            return DateUtils.IsBeforeDate(this.event.eventDate, new Date());
        } else {
            return DateUtils.IsBeforeDate(this.event.endDate, new Date());
        }
    }

    ngOnInit(): void {
        this.globals.onHeaderButtonClick()
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                () => this.router.navigate([this.organisationId])
            );

        this.loading = true;

        this.route.params.pipe(
            takeUntil(this.destroy$),
            switchMap(params => {
                this.organisationId = params.organisationId;
                this.eventId = params.eventId;

                this.feature.isFeatureActive(this.organisationId, 'Videos')
                .pipe(take(1), errorLogTap(this.logging, this.organisationId)).subscribe({
                    next: (result) => {
                        this.videoActive = result;
                    }, error: () => {
                        this.videoActive = false;
                    }
                })

                this.data.hasWikiTabs(this.organisationId, this.eventId).pipe(take(1)).subscribe({
                    next: hasWikiTabs => {
                        this.hasWikiTabs = hasWikiTabs;

                        if (this.hasWikiTabs) {
                            this.tour.addTourStep(5, 2, 1, 'tour.text.wiki_button_message', '#wiki-button');
                        }
                    }, error: () => {
                        this.hasWikiTabs = false;
                    }
                });

                return this.data.getEventLandingInfo(this.organisationId, this.eventId);
            })).subscribe({
            next: result => {
                this.event = result;
                this.videoId = result.videoId;

                if (this.push.hasPushNotificationEnabled) {
                    const hasRequestedPush = localStorage.getItem('push_request');

                    if (!hasRequestedPush) {
                        this.push.hasPushNotificationRegistered().pipe(take(1))
                            .subscribe((hasNotificationSubscription) => {
                                if (!hasNotificationSubscription) {
                                    this.push.requestPushNotification();
                                }
                            });
                    }
                }

                this.progressBar = [{
                    value: result.totalProgress,
                    color: 'blue'
                }];

                if (result.averageScore !== undefined) {
                    this.scoreBar = [{
                        value: result.averageScore === 0 ? 1 : result.averageScore,
                        color: result.averageScore >= 50 ? 'green' : 'red'
                    }];
                }

                this.loading = false;

                this.tour.addTourStep(1, 1, 1, 'tour.text.welcome_message');
                this.tour.addTourStep(1, 2, 1, 'tour.text.task_button_message', '#task-button');
                this.tour.addTourStep(2, 2, 1, 'tour.text.notification_button_message', '#notification-button');
                this.tour.addTourStep(3, 2, 1, 'tour.text.chat_button_message', '#chat-button');

                if (this.feature.reportsActive) {
                    this.tour.addTourStep(4, 2, 1, 'tour.text.report_button_message', '#report-button');
                }

                this.tour.addTourStep(6, 2, 1, 'tour.text.return_to_event_overview_message', '#header-button', undefined, false, true);

                setTimeout(() => {
                    this.tour.startTour();
                }, 500);
            },
            error: () => {
                this.loading = false;
                this.error = true;

                setTimeout(() => {
                    this.router.navigate([this.organisationId]);
                }, 2000);
            }
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    getRippleColorForTask(task: ITask): string {
        if (task.isUrgent) {
            // Transparent warn-red.
            return 'rgba(244, 67, 54, 0.2)';
        } else if (task.isCompleted) {
            // Transparent catchphrase-green.
            return 'rgba(130, 186, 122, 0.2)';
        } else {
            // Transparent black.
            return 'rgba(0, 0, 0, 0.2)';
        }
    }

    convertTaskTypeToRouterLink(task: ITask): string {
        switch (task.type) {
        case TaskType.Briefing:
            return 'briefing';
        case TaskType.KnowledgeTest:
            return 'test';
        case TaskType.Evaluation:
            return 'evaluation';
        default:
            return 'briefing';
        }
    }

    openEventWiki(): void {
        this.animating = true;
        this.router.navigate([this.organisationId, this.eventId, 'wiki']);
    }
}
