<h2 mat-dialog-title>
    <span [translate]="'account.text.2fa_required'">
        Two-factor authentication required
    </span>
</h2>
<mat-dialog-content class="mat-typography mx-3">
    <form (ngSubmit)='verify()'>
        <div class="form-block">
            <div class='explainer'>
                <span [translate]='"account.text.2fa_login_explainer"'>
                    Please fill in an authenticator code or recovery code below to log in to your account.
                </span>
            </div>
            <mat-form-field class="col-12 no-subscript my-3" appearance="outline" hideRequiredMarker="true">
                <input matInput [(ngModel)]='code' name="title" required #titleField='ngModel' />
            </mat-form-field>
            <div class="col-12 d-flex justify-content-center">
                <button mat-flat-button class="cp-button w-100" color='primary' type='submit' [disabled]="!code">
                    <span [translate]="'general.button.verify'">
                        Verify
                    </span>
                </button>
            </div>
        </div>
    </form>
</mat-dialog-content>
