import { Component, Input, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { KnowledgeTestDataService } from '@knowledge-test/services';
import { Router } from '@angular/router';
import { StylingModel } from '@core/models';
import { RadialProgressBarModel } from '@shared/components/radial-progress/models/radial-progress-bar-model';

@Component({
    selector: 'cp-knowledge-test-score-page',
    templateUrl: './knowledge-test-score-page.component.html',
    styleUrls: ['./knowledge-test-score-page.component.scss']
})
export class KnowledgeTestScorePageComponent implements OnInit {

    @Input()
        organisationId!: number;

    @Input()
        eventId!: number;

    @Input()
        knowledgeTaskId!: number;

    @Input()
        organisationStyling!: StylingModel;

    testScore!: number;
    advisedRetakeBriefings!: string[];
    forcedRetakeBriefings!: string[];

    loading = true;
    errorLoading = false;
    loadingImage = true;
    errorLoadingImage = false;
    finishing = false;
    finished = false;

    progressBar: RadialProgressBarModel[] = [];

    constructor(
        private data: KnowledgeTestDataService,
        private router: Router,
    ) { }

    get scoreAsTenScale(): string {
        const score = ((9 * this.testScore) / 100) + 1;
        return score.toFixed(1);
    }

    get eventImage(): string {
        return `event/${this.eventId}`;
    }

    get hasAdvisedRetakes(): boolean {
        return !(this.advisedRetakeBriefings === undefined || this.advisedRetakeBriefings.length === 0);
    }

    get hasForcedRetakes(): boolean {
        return !(this.forcedRetakeBriefings === undefined || this.forcedRetakeBriefings.length === 0);
    }

    ngOnInit(): void {
        this.data.getKnowledgeTestResult(this.organisationId, this.eventId, this.knowledgeTaskId).pipe(take(1)).subscribe(response => {
            this.testScore = response.testScore;
            this.advisedRetakeBriefings = response.advisedRetakeBriefings;
            this.forcedRetakeBriefings = response.forcedRetakeBriefings;

            this.progressBar = [new RadialProgressBarModel(this.testScore, this.testScore >= 50 ? 'green' : 'red')];

            this.loading = false;
        });
    }

    finishTest(): void {
        this.finishing = true;
        this.data.finishKnowledgeTest(this.organisationId, this.eventId, this.knowledgeTaskId).pipe(take(1)).subscribe(_ => {
            this.finished = true;
            this.finishing = false;

            setTimeout(() => {
                this.router.navigate([this.organisationId, this.eventId, 'tasks']);
            }, 1000);
        });
    }
}
