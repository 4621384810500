import { SafeHtml } from '@angular/platform-browser';

export interface FaqCategory {
    id: number;
    title: string;
    publishDate?: Date;
    items: FaqCategoryItem[];
    expanded: boolean;
}

export interface FaqCategoryItem {
    id: number;
    question: string;
    answer: string;
    _sanitizedAnswer?: SafeHtml;
    expanded: boolean;
}
