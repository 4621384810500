import { EmailAlreadyConfirmedGuard, ValidCodeGuard } from '@account/services';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {
    RegisterComponent, InviteComponent, RequestNewInviteComponent,
    ConfirmComponent, AccountComponent, UsedInviteComponent, ActivateCmsAccountComponent
} from './components';

const routes: Routes = [
    {
        path: '',
        component: AccountComponent,
        children: [
            {
                path: 'invite',
                children: [
                    {
                        path: ':organisationId/:code',
                        component: InviteComponent,
                        data: { animation: 'InvitationPage' },
                    },
                    {
                        path: '',
                        component: InviteComponent,
                        canActivate: [ValidCodeGuard],
                        data: { animation: 'InvitationPage' },
                    }
                ]
            },
            {
                path: 'register',
                component: RegisterComponent,
                canActivate: [ValidCodeGuard],
                data: { animation: 'RegistrationPage' },
            },
            {
                path: 'cms/confirm',
                component: ActivateCmsAccountComponent,
            },
            {
                path: 'confirm',
                component: ConfirmComponent,
                canActivate: [EmailAlreadyConfirmedGuard],
                data: { animation: 'ConfirmationPage' },
            },
            { path: 'request-invite', component: RequestNewInviteComponent },
            { path: 'used-invite', component: UsedInviteComponent }
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class AccountRoutingModule { }
