import { DarkBodyComponent } from '@global/base-components';
import { AuthenticationService } from '@core/services';
import { Component, OnInit } from '@angular/core';

@Component({
    templateUrl: './force-logout.component.html',
    styleUrls: ['./force-logout.component.scss']
})
export class ForceLogoutComponent extends DarkBodyComponent implements OnInit {

    constructor(private auth: AuthenticationService) {
        super();
    }

    ngOnInit(): void {
        this.auth.revokeAuthentication();
    }

}
