import { EndPoint } from '@core/constants';
import { GeneralDataService } from '@core/services';
import { Injectable } from '@angular/core';
import {
    ITaskOverview, EventOverview, OverviewEvent, EventLandingInfo, ChecklistModel, TasksForLandingPage,
    ChecklistCountModel, ReportWidgetDetails,
} from '@overview/models';
import { Observable } from 'rxjs';
import { NotificationModel, NotificationOverviewModel, NotificationPollResponse } from '@overview/components';

@Injectable()
export class OverviewDataService extends GeneralDataService {
    getEvents(organisationId: number): Observable<EventOverview> {
        return this.getGenericItem<EventOverview>(EndPoint.events, `${organisationId}/events`);
    }

    pollEvents(
        organisationId: number): Observable<EventOverview> {
        return this.getGenericItem(EndPoint.events, `${organisationId}/events/poll`);
    }

    getEventLandingInfo(organisationId: number, eventId: number): Observable<EventLandingInfo> {
        return this.getGenericItem<EventLandingInfo>(EndPoint.events, `${organisationId}/events/${eventId}/details`);
    }

    getTopTasksForOverview(organisationId: number, eventId: number): Observable<TasksForLandingPage> {
        return this.getGenericItem<TasksForLandingPage>(EndPoint.tasks, `${organisationId}/${eventId}/overview/event`);
    }

    getTasks(organisationId: number, eventId: number): Observable<ITaskOverview> {
        return this.getGenericItem<ITaskOverview>(EndPoint.tasks, `${organisationId}/${eventId}/overview`);
    }

    hasWikiTabs(organisationId: number, eventId: number): Observable<boolean> {
        return this.getGenericItem(EndPoint.wiki, `${organisationId}/${eventId}/hasWikiTabs`);
    }

    hasChecklists(organisationId: number, eventId: number): Observable<boolean> {
        return this.getGenericItem(EndPoint.checklists, `${organisationId}/${eventId}/hasChecklists`);
    }

    getChecklistCounts(organisationId: number, eventId: number): Observable<ChecklistCountModel> {
        return this.getGenericItem(EndPoint.checklists, `${organisationId}/${eventId}`);
    }

    getChecklists(organisationId: number, eventId: number): Observable<ChecklistModel[]> {
        const languageId = this.getUserLanguageId();
        return this.getGenericItem(EndPoint.checklists, `${organisationId}/${eventId}/${languageId}`);
    }

    checkChecklistItem(organisationId: number, eventId: number, checklistId: number, checklistItemId: number, isChecked: boolean):
        Observable<void> {
        const data = { isChecked };
        return this.postGenericItemWithResponse(
            EndPoint.checklists, `${organisationId}/${eventId}/${checklistId}/${checklistItemId}/check`, data);
    }

    getNotificationOverview(organisationId: number, eventId: number): Observable<NotificationOverviewModel> {
        return this.getGenericItem(EndPoint.notifications, `${organisationId}/${eventId}/notifications`);
    }

    loadMoreReadNotifications(organisationId: number, eventId: number, pageIndex: number, pageSize: number, skipLoad?: number):
        Observable<NotificationModel[]> {
        let path = `${organisationId}/${eventId}/notifications/read?pageIndex=${pageIndex}&pageSize=${pageSize}`;
        if (skipLoad !== undefined) {
            path = path + `&skipLoad=${skipLoad}`;
        }
        return this.getGenericItem(EndPoint.notifications, path);
    }

    pollNotifications(organisationId: number, eventId: number): Observable<NotificationPollResponse> {
        return this.getGenericItem(EndPoint.notifications, `${organisationId}/${eventId}/notifications/polling`);
    }

    getReportWidgetDetails(organisationId: number, eventId: number): Observable<ReportWidgetDetails> {
        return this.getGenericItem(EndPoint.reports, `${organisationId}/${eventId}/home`);
    }
}
