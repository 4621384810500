<div class='emergency-block' *ngIf='content.emergencyNumber'>
    <div class='emergency-button' (click)='onClickEmergencyButton()'>
        <cp-icon class='mr-1' name='alert-triangle'></cp-icon>
        <span [translate]='"wiki.text.emergency"'>
            Emergency
        </span>
    </div>
</div>
<div class='introduction-text' *ngIf='introductionText' [innerHTML]="introductionText"
    glossary-injection [organisationId]="organisationId" [eventId]="eventId"
    [pageInformation]="pageTrackingInformation">
</div>
<div class='contacts-list' [class.top-padded]='!content.emergencyNumber'>
    <mat-form-field class='cp-light-thin-form-field w-100'>
        <mat-label [translate]="'general.text.search'">
            Search...
        </mat-label>
        <input matInput type="text" [(ngModel)]='filter'>
        <div matSuffix (click)='filter = ""'>
            <cp-icon [name]='filter ? "x" : "search"' size='x-small'></cp-icon>
        </div>
    </mat-form-field>
    <div class='contact' *ngFor='let contact of filteredContacts; let index = index'
        (click)='toggleContact(contact)'>
        <div class='collapsed-info'>
            <div class='picture-holder d-flex'>
                <div class='m-auto' *ngIf='contact.profilePictureBlobId && !offline; else placeholder'>
                    <cp-image [blobId]='contact.profilePictureBlobId' width='50px' height='50px' [exactSizing]='true'
                        [hasPlaceholder]='true' [spinnerDiameter]='50' [rounded]='true' [code]='code'
                        [organisationId]='organisationId' [overwriteContainerSize]='true'>
                        <div class='placeholder m-auto'>
                            <cp-icon size='small' class='m-auto' name='user'></cp-icon>
                        </div>
                    </cp-image>
                </div>
                <ng-template #placeholder>
                    <div class='placeholder m-auto'>
                        <cp-icon size='small' class='m-auto' name='user'></cp-icon>
                    </div>
                </ng-template>
            </div>
            <div class='content ml-2'>
                <div class='name my-auto'>
                    <span *ngIf='constructNameForContact(contact); else nameholder'>
                        {{ constructNameForContact(contact) }}
                    </span>
                    <ng-template #nameholder>
                        <span class='name-placeholder' [translate]='"general.text.name"'>
                            Name
                        </span>
                    </ng-template>
                </div>
                <div class='job-title my-auto' *ngIf='contact.jobTitle'>
                    <span glossary-injection [organisationId]="organisationId" [eventId]="eventId"
                    [pageInformation]="pageTrackingInformation">
                        {{ contact.jobTitle }}
                    </span>
                </div>
                <div class='company my-auto' *ngIf='contact.companyName'>
                    <span class='label'glossary-injection [organisationId]="organisationId" [eventId]="eventId"
                    [pageInformation]="pageTrackingInformation">
                        {{ contact.companyName }}
                    </span>
                </div>
            </div>
            <div class='buttons'>
                <div class='button'>
                    <cp-icon size='x-small' class='chevron' [class.flipped]='contact.isExpanded' name='chevron-down'>
                    </cp-icon>
                </div>
            </div>
        </div>
        <div class='expanded-content' *ngIf='contact.isExpanded'>
            <div class='contact-buttons'>
                <a *ngIf='contact.phonenumber' class='contact-button' [class.primary]='contact.phoneIsPrimary'
                    [href]='getTelephoneURL(contact.phonenumber)' (click)='onClickContactMethod($event, contact, "Phone")'>
                    <cp-icon size='x-small' name='phone'></cp-icon>
                </a>
                <a *ngIf='contact.email' class='contact-button' [class.primary]='contact.emailIsPrimary'
                    [href]='getMailURL(contact.email)' (click)='onClickContactMethod($event, contact, "Email")'>
                    <cp-icon size='x-small' name='mail'></cp-icon>
                </a>
                <button *ngIf='contact.other' class='contact-button' #tooltip='matTooltip' [matTooltip]='contact.other'
                    matTooltipClass='contact-other-tooltip' matTooltipPosition='above'
                    [class.primary]='contact.otherIsPrimary'
                    (click)='tooltip.toggle(); onClickOtherContactButton($event, contact);'>
                    <cp-icon size='x-small' name='mic'></cp-icon>
                </button>
            </div>
        </div>
    </div>
</div>
