import { AbstractControl, ValidatorFn } from '@angular/forms';

export function ValidatePassword(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: { [key: string]: boolean } } | null => {
        const errorDictionary = { noUppercase: false, noLowercase: false, noDigit: false, noSymbol: false };

        if (!control.value.match(/[A-Z]/g)) {
            errorDictionary.noUppercase = true;
        }
        if (!control.value.match(/[a-z]/g)) {
            errorDictionary.noLowercase = true;
        }
        if (!control.value.match(/[0-9]/g)) {
            errorDictionary.noDigit = true;
        }
        if (!control.value.match(/[^a-zA-Z0-9]/g)) {
            errorDictionary.noSymbol = true;
        }

        return errorDictionary.noDigit || errorDictionary.noLowercase || errorDictionary.noUppercase || errorDictionary.noSymbol ?
            { passwordErrors: errorDictionary } : null;
    };
}
