import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CelebrateService {
    celebrate = new Subject<number>();
    celebrate$ = this.celebrate.asObservable();

    startCelebration(duration: number): void {
        this.celebrate.next(duration);
    }
}
