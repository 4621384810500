import { Router } from '@angular/router';
import { AuthenticationService, AlertService } from '@core/services';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse
} from '@angular/common/http';
import { EMPTY, from, lastValueFrom, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';

@Injectable()
export class SessionHttpInterceptor implements HttpInterceptor {
    private _blockMessage = false;

    constructor(
        private auth: AuthenticationService, private alert: AlertService,
        private router: Router) { }

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => from(this.handle(req, error, next))));
    }

    async handle(req: HttpRequest<any>, error: HttpErrorResponse, next: HttpHandler): Promise<HttpEvent<any>> {
        // If the error was from an unauthorized result check if this caused by an invalid session
        if (error.status === 401 && this.auth.hasSession && req.url.indexOf('identity') === -1) {
            // If the session is active the user simply tried to reach a restricted path so propagate the error.
            if (this.auth.hasActiveSession) {
                return lastValueFrom(throwError(() => error));
            }

            // If there is a session but it is inactive we want to try and refresh it.
            try {
                // Only make a call if one is not already going, otherwise await the same promise
                let postMessage = false;
                if (!this._blockMessage) {
                    this.alert.postMessage('general.text.reestablish_session', 'Light', 3000);
                    postMessage = true;
                    this._blockMessage = true;
                }

                const user = await this.auth.attemptSilentSignIn();

                if (user) {
                    if (postMessage) {
                        this.alert.postMessage('general.text.session_reestablished', 'Light', 3000);
                    }
                    const newRequest = req.clone({
                        headers: this.auth.accessToken
                            ? req.headers.set('authorization', [`Bearer ${this.auth.accessToken}`])
                            : req.headers
                    });
                    this._blockMessage = false;
                    return lastValueFrom(next.handle(newRequest));
                } else {
                    this.auth.startAuthentication(this.router?.routerState?.snapshot?.url);
                    this._blockMessage = false;
                    return lastValueFrom(EMPTY);
                }
            } catch (_) {
                this.auth.startAuthentication(this.router?.routerState?.snapshot?.url);
                this._blockMessage = false;
                return lastValueFrom(EMPTY);
            }
        } else {
            return lastValueFrom(throwError(() => error));
        }
    }
}

