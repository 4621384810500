import { UrlResolver } from './../utils/url-resolver';
import { HeaderModel, MaintenanceState, StylingModel } from '@core/models';
import { Subject, Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { GeneralDataService } from './general-data.service';
import { EndPoint } from '@core/constants';
import { switchMap, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class GlobalsService {
    private static _inMemoryOrganisationId?: number;
    private static _inMemoryOrganisationStyling?: StylingModel;
    private static _inMemoryEventId?: number;
    private static _inMemoryEventStyling?: StylingModel;

    footerShown = false;
    canLockScreenOrientation = true;

    public stylingDefaults: StylingModel = {
        hasLogo: false,
        colorOne: '#82ba7a',
        colorOneText: 'Light',
        colorTwo: '#161C42',
        colorTwoText: 'Light',
        useEventLogo: false
    };

    private _headerChanged = new Subject<HeaderModel | undefined>();
    private _showFooter = new Subject<boolean>();
    private _showSettings = new Subject<boolean>();
    private _headerButtonClicked = new Subject<void>();
    private _hideTooltips = new Subject<void>();
    private _underMaintenance = new Subject<MaintenanceState>();
    private _appVisible = new Subject<boolean>();
    headerChanged$ = this._headerChanged.asObservable();
    footerChanged$ = this._showFooter.asObservable();
    settingsChanged$ = this._showSettings.asObservable();
    underMaintenance$ = this._underMaintenance.asObservable();
    appVisibilityChanged$ = this._appVisible.asObservable();

    constructor(private data: GeneralDataService) { }

    hideHeaderAndFooter(): void {
        this._headerChanged.next(undefined);
        this.footerShown = false;
        this._showFooter.next(false);
    }

    changeHeader(newHeader: HeaderModel): void {
        this._headerChanged.next(newHeader);
    }

    hideHeader(): void {
        this._headerChanged.next(undefined);
    }

    showFooter(): void {
        this.footerShown = true;
        this._showFooter.next(true);
    }

    hideFooter(): void {
        this.footerShown = false;
        this._showFooter.next(false);
    }

    showSettings(): void {
        this._showSettings.next(true);
    }

    hideSettings(): void {
        this._showSettings.next(false);
    }

    getStyling(organisationId: number, eventId?: number, code?: string): Observable<StylingModel> {
        if (eventId === undefined && GlobalsService._inMemoryOrganisationId === +organisationId
            && GlobalsService._inMemoryOrganisationStyling) {
            return of(GlobalsService._inMemoryOrganisationStyling);
        }

        if (eventId !== undefined && GlobalsService._inMemoryOrganisationId === +organisationId
            && GlobalsService._inMemoryEventId === +eventId && GlobalsService._inMemoryEventStyling) {
            return of(GlobalsService._inMemoryEventStyling);
        }

        const path = eventId !== undefined
            ? `${organisationId}/styling/event/${eventId}`
            : code === undefined
                ? `${organisationId}/styling/cached`
                : `${organisationId}/styling/code/${code}`;

        return this.data.getGenericItem<StylingModel>(EndPoint.organisation,
            path)
            .pipe(switchMap((style) => {
                style.colorOne = style.colorOne || this.stylingDefaults.colorOne;
                style.colorOneText = style.colorOneText || this.stylingDefaults.colorOneText;

                style.colorTwo = style.colorTwo || this.stylingDefaults.colorTwo;
                style.colorTwoText = style.colorTwoText || this.stylingDefaults.colorTwoText;

                this._storeInMemoryStyling(organisationId, style, eventId);
                return of(style);
            }));
    }

    headerButtonClick(): void {
        this._headerButtonClicked.next();
    }

    onHeaderButtonClick(): Observable<void> {
        return this._headerButtonClicked.asObservable();
    }

    hideTooltips(): void {
        this._hideTooltips.next();
    }

    onHideTooltips(): Observable<void> {
        return this._hideTooltips.asObservable();
    }

    appVisible(): void {
        this._appVisible.next(true);
    }

    appNotVisible(): void {
        this._appVisible.next(false);
    }

    getOrganisationLogoUri(organisationId: number): string {
        return UrlResolver.getUrl(EndPoint.image, `${organisationId}/logo`);
    }

    getEventLogoUri(organisationId: number, eventId: number): string {
        return UrlResolver.getUrl(EndPoint.image, `${organisationId}/logo/${eventId}`);
    }

    pollUserNotifications(organisationId?: number, eventId?: number): Observable<boolean> {
        if (organisationId === undefined || eventId === undefined) {
            return of(false);
        }

        return this.data.getGenericItem(EndPoint.notifications, `${organisationId}/${eventId}/notifications/hasUnread`);
    }

    pollUserChatMessages(organisationId?: number, eventId?: number): Observable<boolean> {
        if (organisationId === undefined || eventId === undefined) {
            return of(false);
        }

        return this.data.getGenericItem(EndPoint.chat, `${organisationId}/${eventId}/hasUnread`);
    }

    pollUserReports(organisationId?: number, eventId?: number): Observable<boolean> {
        if (organisationId === undefined || eventId === undefined) {
            return of(false);
        }

        return this.data.getGenericItem(EndPoint.reports, `${organisationId}/${eventId}/hasNotifications`);
    }

    pollMaintenance(): Observable<MaintenanceState> {
        return this.data.getGenericItem<MaintenanceState>(EndPoint.account, 'maintenance/status').pipe(
            tap(x => {
                this._underMaintenance.next(x);
            })
        );
    }

    lockScreenOrientation(): void {
        if (screen.orientation?.lock !== undefined && this.canLockScreenOrientation) {
            screen.orientation.lock('portrait').catch(() => { this.canLockScreenOrientation = false; });
        }
    }

    unlockScreenOrientation(): void {
        if (screen.orientation?.unlock !== undefined && this.canLockScreenOrientation) {
            screen.orientation.unlock();
        }
    }

    private _storeInMemoryStyling(organisationId: number, styling: StylingModel, eventId?: number): void {
        GlobalsService._inMemoryOrganisationId = organisationId;
        GlobalsService._inMemoryEventId = eventId;

        if (eventId !== undefined) {
            GlobalsService._inMemoryEventStyling = styling;
        } else {
            GlobalsService._inMemoryOrganisationStyling = styling;
        }
    }
}
