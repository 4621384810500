import { BriefingSectionType, StylingModel } from '@core/models';
import { Component, OnInit, OnDestroy, ViewChildren, QueryList, ElementRef, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { switchMap, take, catchError, takeUntil } from 'rxjs/operators';
import { BriefingDataService } from '@briefing/services';
import { IBriefingViewModel, IBriefingPageModel, IQuestionModel } from '@briefing/models';
import { Observable, of, Subject } from 'rxjs';
import { RadialProgressBarModel } from '@shared/components/radial-progress/models/radial-progress-bar-model';
import { ConnectionService, FeatureToggleService, GlobalsService, LoggingService } from '@core/services';
import { errorLogTap } from '@core/handlers';

@Component({
    templateUrl: './briefing-view.component.html',
    styleUrls: ['./briefing-view.component.scss'],
})
export class BriefingViewComponent implements OnInit, OnDestroy {
    @ViewChildren('page') pageRefs!: QueryList<ElementRef<HTMLElement>>;
    readonly DATE_FORMAT = 'shortDate';

    organisationId!: number;
    briefingTaskId!: number;
    eventId!: number;
    briefingView!: IBriefingViewModel;
    briefingPages: IBriefingPageModel[] = [];
    loadedPages: IBriefingPageModel[] = [];
    organisationStyling: StylingModel;

    progressBar: RadialProgressBarModel[] = [];

    currentPageIndex: number | undefined;

    progressPipLengthArray!: void[];
    progressPipWidth = '0px';

    loading = true;
    loadingPages = false;
    hasStarted = false;
    hasFinished = false;
    errorLoading = false;
    errorLoadingTitleImage = false;
    transitioningTitle = false;
    changingPages = false;
    onTitlePage = true;
    onFeedbackPage = false;
    onEvaluationPage = false;
    transitioningFeedback = false;
    offline = false;

    showVideos = false;

    private _scrollingFinished = false;
    private _videosFinished: { [index: number]: boolean } = {};
    private destroy$: Subject<boolean> = new Subject<boolean>();
    private timeout$!: any;

    private readonly ALLOWED_SCROLL_DELTA = this._isOnMobile() ? 70 : 10;
    private readonly TRANSITION_TIME = 300;
    private readonly PAGE_LOAD_DELAY = 50;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private data: BriefingDataService,
        private globals: GlobalsService,
        private connection: ConnectionService,
        private feature: FeatureToggleService,
        private logging: LoggingService,
    ) {
        globals.hideHeaderAndFooter();

        // Ensure we have something loaded in if styling is not cached yet.
        this.organisationStyling = globals.stylingDefaults;
    }

    @HostListener('window:scroll')
    onScroll(): void {
        if (this._isAtBottom()) {
            this._scrollingFinished = true;
        }
    }

    @HostListener('window:click')
    onClick(): void {
        this.globals.hideTooltips();
    }

    ngOnInit(): void {
        this.route.params.pipe(switchMap(params => {
            this.organisationId = params.organisationId;
            this.briefingTaskId = params.briefingTaskId;
            this.eventId = params.eventId;

            this.globals.getStyling(this.organisationId, this.eventId)
                .pipe(take(1))
                .subscribe(styling => this.organisationStyling = styling);

            return this._initializeBriefingView(params.briefingTaskId);
        }), take(1)).subscribe();

        this.connection.onlineState$.pipe(takeUntil(this.destroy$))
            .subscribe(connected => this.offline = !connected);

        this.feature.isFeatureActive(this.organisationId, 'Videos').pipe(take(1), errorLogTap(this.logging, this.organisationId))
        .subscribe({
            next: (result) => {
                this.showVideos = result;
            }, error: () => {
                this.showVideos = false;
            }
        })
    }

    ngOnDestroy(): void {
        this.globals.hideTooltips();
        clearTimeout(this.timeout$);
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    goBack(): void {
        // If on title page, go back to overview
        if (this.currentPageIndex === undefined) {
            this.goToTaskOverview();
        }
        else { // Else, go back to title page
            this.changingPages = true;
            this.timeout$ = setTimeout(() => {
                this.changingPages = false;
            }, 300);

            this.currentPageIndex = undefined;
            this.onTitlePage = true;

            // Load the page for the resume
            if (this.hasStarted) {
                const unfinishedIndices = this._getFirstUnfinishedPageIndex();
                this._loadPages(unfinishedIndices.pageIndex);
            }
        }
    }

    goToTaskOverview(): void {
        this.router.navigate([this.organisationId, this.eventId, 'tasks']);
    }

    startBriefing(): void {
        this.onTitlePage = false;
        const newIndex = 0;
        this._moveToIndex(newIndex);
    }

    resumeBriefing(): void {
        this.onTitlePage = false;
        const unfinishedIndices = this._getFirstUnfinishedPageIndex();
        this._moveToIndex(unfinishedIndices.pageIndex, unfinishedIndices.questionIndex);
    }

    goToResults(): void {
        if (!this.hasFinished) {
            return;
        }

        this.currentPageIndex = undefined;
        this.onFeedbackPage = true;
        this.onTitlePage = false;
    }

    restartBriefing(): void {
        // Load the pages around the start, as those have not been fetched yet
        const newIndex = 0;
        this._loadPages(newIndex, true);
    }

    goToNextPage(): void {
        if (this.currentPageIndex === undefined) {
            return;
        }

        const index = this.currentPageIndex;
        const currentPage = this.briefingPages.find(page => page.position === index);
        if (currentPage === undefined) {
            return;
        }

        // If the currentpage is a quiz
        if (currentPage.type === 'Quiz' && currentPage.questions && currentPage.currentQuestionIndex !== undefined) {
            currentPage.currentQuestionIndex++;
            // And we're not out of the quiz, don't change pages
            if (currentPage.currentQuestionIndex < currentPage.questions.length) {
                return;
            }
            // Otherwise follow regular page logic
        }

        const newIndex = this.currentPageIndex + 1;
        const foundNextLoadedPage = this.briefingPages.find((page) => page.position === newIndex + 1);

        // Mark the page as finished if it hasn't yet
        if (!currentPage.finished) {
            this.data.finishBriefingPage(this.briefingTaskId, this.organisationId, this.currentPageIndex)
                .pipe(take(1)).subscribe((nextLoadedPage) => {
                    currentPage.finished = true;
                    this._updateProgress();

                    // Add the next page if there is one and it isn't loaded yet
                    if (nextLoadedPage) {
                        nextLoadedPage.finished = this._isPageFinished(nextLoadedPage.position);

                        if (nextLoadedPage.questions) {
                            nextLoadedPage.currentQuestionIndex = 0;
                        }

                        this._addPageIfNew(nextLoadedPage, this.briefingPages);
                        this._addPageIfNew(nextLoadedPage, this.loadedPages);

                        this._initializePageFinish(nextLoadedPage);
                    }

                    if (this.loadedPages.length === 4 || newIndex >= this.briefingView.totalPages - 1) {
                        this.loadedPages.shift();
                    }
                });
        } // If the page is not yet loaded local, request it from the backend
        else if (foundNextLoadedPage === undefined && index + 2 < this.briefingView.totalPages) {
            this.data.getBriefingPage(this.briefingView.briefingId, this.organisationId, index + 2)
                .pipe(take(1)).subscribe((page) => {
                    if (page[0]) {
                        page[0].finished = this._isPageFinished(page[0].position);

                        if (page[0].questions) {
                            page[0].currentQuestionIndex = -1;
                        }

                        this._initializePageFinish(page[0]);

                        this._addPageIfNew(page[0], this.briefingPages);
                        this._addPageIfNew(page[0], this.loadedPages);

                        if (this.loadedPages.length === 4 || newIndex >= this.briefingView.totalPages - 1) {
                            this.loadedPages.shift();
                        }
                    }
                });
        } // Otherwise simply load the page in
        else if (foundNextLoadedPage) {
            this._addPageIfNew(foundNextLoadedPage, this.loadedPages);

            if (foundNextLoadedPage.questions) {
                foundNextLoadedPage.currentQuestionIndex = -1;
            }

            if (this.loadedPages.length === 4 || newIndex >= this.briefingView.totalPages - 1) {
                this.loadedPages.shift();
            }
        }

        // If this isn't the last page, go to the next
        if (this.currentPageIndex < this.briefingView.totalPages - 1) {
            this._moveToIndex(newIndex, undefined, true);
            this.hasStarted = true;
        }
        else {
            if (currentPage) {
                currentPage.finished = true;
            }

            this.globals.hideTooltips();
            this.transitioningFeedback = true;
            // Wait until the animation is done
            this.timeout$ = setTimeout(() => {
                this.transitioningFeedback = false;
            }, this.TRANSITION_TIME);

            this.onFeedbackPage = true;
            this.currentPageIndex = undefined;
        }
    }

    goToPreviousPage(): void {
        if (this.currentPageIndex === undefined) {
            return;
        }

        const currentPage = this.briefingPages.find(page => page.position === this.currentPageIndex);

        // If the currentpage is a quiz
        if (currentPage && currentPage.type === 'Quiz' && currentPage.questions && currentPage.currentQuestionIndex !== undefined) {
            currentPage.currentQuestionIndex--;
            // And we're not out of the quiz, don't change pages
            if (currentPage.currentQuestionIndex >= 0) {
                return;
            }
            // Otherwise follow regular page logic
        }

        // If there is a previous page
        if (this.currentPageIndex > 0) {
            // Go to previous page
            this._moveToIndex(this.currentPageIndex - 1, undefined, true, true);

            // If there is a page before that page
            const pageBeforeIndex = this.currentPageIndex - 1;

            if (pageBeforeIndex >= 0) {
                const foundPage = this.briefingPages.find((page) => page.position === pageBeforeIndex);

                // Fetch the page before if it is not yet fetched
                if (foundPage === undefined) {
                    this.data.getBriefingPage(this.briefingView.briefingId, this.organisationId, pageBeforeIndex).subscribe((page) => {
                        page[0].finished = this._isPageFinished(page[0].position);

                        if (page[0].questions) {
                            page[0].currentQuestionIndex = page[0].questions.length;
                        }

                        this._addPageIfNew(page[0], this.briefingPages);
                        this._addPageIfNew(page[0], this.loadedPages, true);

                        this._initializePageFinish(page[0]);

                        if (this.loadedPages.length === 4 || this.currentPageIndex === 0) {
                            this.loadedPages.pop();
                        }
                    });
                }
                else { // Else add it to the loadedPages
                    if (foundPage.questions) {
                        foundPage.currentQuestionIndex = foundPage.questions.length;
                    }
                    this._addPageIfNew(foundPage, this.loadedPages, true);
                    if (this.loadedPages.length === 4 || this.currentPageIndex === 0) {
                        this.loadedPages.pop();
                    }
                }
            }
            else { // If on pageIndex 0
                this.loadedPages.pop();
            }
        }
    }

    goToFeedbackPage(): void {
        this.globals.hideTooltips();
        this.currentPageIndex = undefined;
        this.onFeedbackPage = false;
        this.onEvaluationPage = true;
    }

    getCurrentPage(): IBriefingPageModel | undefined {
        if (this.currentPageIndex === undefined) {
            return undefined;
        }

        return this.briefingPages.find((page) => page.position === this.currentPageIndex);
    }

    isNextButtonEnabled(index: number | undefined): boolean {
        if (index === undefined) {
            return false;
        }

        const currentPage = this.briefingPages.find(page => page.position === index);
        const nextPageLoaded = this.isPageLoaded(index + 1) || index + 1 === this.briefingView.totalPages;

        if (currentPage && currentPage.type === 'Quiz' && currentPage.questions && currentPage.currentQuestionIndex !== undefined) {
            const currentQuestion = currentPage.questions.find(question => question.position === currentPage.currentQuestionIndex);

            if (currentQuestion) {
                return currentQuestion.finished === true &&
                    (nextPageLoaded || currentPage.currentQuestionIndex < currentPage.questions.length - 1);
            }
        }

        const videosFinished = this._videosFinished[index] || !this._pageHasVideos(index);
        const pageFinished = videosFinished && this._scrollingFinished;
        const savedFinished = this.getCurrentPage()?.finished || false;

        return (pageFinished || savedFinished) && nextPageLoaded;
    }

    isPreviousButtonEnabled(index: number | undefined): boolean {
        if (index === undefined) {
            return false;
        }

        const foundPage = this.briefingPages.find(page => page.position === index);
        if (foundPage && foundPage.type === 'Quiz' && foundPage.questions && foundPage.currentQuestionIndex !== undefined) {
            return foundPage.currentQuestionIndex > 0 || index > 0 && this.isPageLoaded(index - 1);
        }

        return index > 0 && this.isPageLoaded(index - 1);
    }

    isPageLoaded(index: number): boolean {
        const foundPage = this.loadedPages.find(page => page.position === index);
        return foundPage !== undefined;
    }

    getPageClasses(index: number): { [klass: string]: boolean } {
        if (this.currentPageIndex === undefined) {
            if (this.onFeedbackPage && index === this.briefingView.totalPages - 1) {
                return { 'previous-page': true, visible: true };
            }

            return {};
        }

        if (index === this.currentPageIndex - 1) {
            return { 'previous-page': true, visible: this.changingPages };
        }

        if (index === this.currentPageIndex) {
            return { 'current-page': true, 'position-fixed': this.changingPages };
        }

        if (index === this.currentPageIndex + 1) {
            return { 'next-page': true, visible: this.changingPages };
        }

        return {};
    }

    getQuestion(pageIndex: number, questionIndex: number): IQuestionModel | undefined {
        if (pageIndex === undefined || questionIndex === undefined) {
            return;
        }

        const foundPage = this.briefingPages.find(page => page.position === pageIndex);
        if (foundPage === undefined || foundPage.questions === undefined) {
            return;
        }

        return foundPage.questions.find(question => question.position === questionIndex);
    }

    onVideosFinished(index: number): void {
        this._videosFinished[index] = true;
    }

    private _initializeBriefingView(briefingTaskId: number): Observable<unknown> {
        // Fetch the briefing viewmodel
        return this.data.getBriefingView(briefingTaskId, this.organisationId)
            .pipe(switchMap(briefingView => {
                this.briefingView = briefingView;

                this.progressPipLengthArray = new Array(this.briefingView.totalPages);
                this.progressPipWidth = `${100 / this.briefingView.totalPages}%`;

                this._initializeVideoStatuses(briefingView.totalPages);
                this._updateProgress();

                this.hasStarted = briefingView.progress && briefingView.progress.some((pageProgress) => pageProgress.finished);
                this.hasFinished = briefingView.progress.length === briefingView.totalPages
                    && briefingView.progress.every((pageProgress) => pageProgress.finished);

                this.loading = false;

                // If the briefing has been started before, load around the last seen page, otherwise load the first few
                let nextPageIndex = 0;
                if (this.hasStarted) {
                    const unfinishedIndices = this._getFirstUnfinishedPageIndex();
                    nextPageIndex = unfinishedIndices.pageIndex;
                }

                this._loadPages(nextPageIndex);

                return of();
            }),
            catchError(_ => {
                // If the briefing can't be loaded, navigate the user back to the overview
                this.loading = false;
                this.errorLoading = true;

                this.timeout$ = setTimeout(() => {
                    this.router.navigate([this.organisationId, this.eventId, 'tasks']);
                }, 2000);

                return of();
            }));
    }

    private _loadPages(index: number, moveToIndex?: boolean): void {
        this.loadingPages = true;

        const prevPage = this.briefingPages.find(page => page.position === index - 1);
        const currPage = this.briefingPages.find(page => page.position === index);
        const nextPage = this.briefingPages.find(page => page.position === index + 1);
        if (currPage) { // If the pages that need to be loaded are in local memory
            const pages = [];

            if (prevPage) {
                pages.push(prevPage);
            }

            pages.push(currPage);

            if (nextPage) {
                pages.push(nextPage);
            }

            pages.forEach(page => {
                if (page.questions) {
                    page.currentQuestionIndex = page.position > index ? -1 : page.questions.length;
                }
            });

            this.loadedPages = pages;
            this.loadingPages = false;

            if (moveToIndex) {
                this._moveToIndex(index);
            }
        }
        else { // If the pages are not in local memory, get them from the backend
            this.data.getBriefingPages(this.briefingView.briefingId, this.organisationId, index)
                .pipe(take(1)).subscribe((pages) => {
                    pages.forEach((page) => {
                        this._addPageIfNew(page, this.briefingPages);
                        if (page.questions) {
                            page.currentQuestionIndex = page.position >= index ? -1 : page.questions.length;
                        }
                    });
                    this._initializePageFinishes();
                    this.loadedPages = pages;

                    if (moveToIndex) {
                        this._moveToIndex(index);
                    }

                    this.loadingPages = false;
                });
        }
    }

    private _initializeVideoStatuses(totalPages: number): void {
        for (let i = 0; i < totalPages; i++) {
            this._videosFinished[i] = false;
        }
    }

    private _initializePageFinishes(): void {
        if (this.briefingView.progress === undefined) {
            return;
        }

        this.briefingPages.forEach((page) => {
            this._initializePageFinish(page);
        });
    }

    private _initializePageFinish(page: IBriefingPageModel): void {
        if (this.briefingView.progress === undefined) {
            return;
        }

        const foundPageProgress = this.briefingView.progress.find(pageProgress => pageProgress.position === page.position);

        if (foundPageProgress === undefined) {
            if (page.finished === undefined) {
                page.finished = false;
            }

            return;
        }

        page.finished = foundPageProgress.finished;

        if (page.type === 'Quiz' && page.questions) {
            page.questions.forEach(question => {
                const foundQuestionProgress = foundPageProgress.questions.find(progress => progress.position === question.position);
                if (foundQuestionProgress === undefined) {
                    return;
                }
                question.finished = foundQuestionProgress.answered;
                question.selectedAnswers = foundQuestionProgress.givenAnswers.map(answerProgress => answerProgress.position);
                question.correctAnswers = foundQuestionProgress.correctAnswers.map(answerProgress => answerProgress.position);
                question.feedback = foundQuestionProgress.feedback;
            });
        }
    }

    private _moveToIndex(pageIndex: number, questionIndex?: number, pageTransition?: boolean, isBackwards?: boolean): void {
        this._initializeIfQuiz(pageIndex, questionIndex, isBackwards);
        this.globals.hideTooltips();
        this.currentPageIndex = pageIndex;

        if (pageTransition) {
            this.changingPages = true;
            // Wait until the animation is done
            this.timeout$ = setTimeout(() => {
                this.changingPages = false;

                // Timeout before page is fully loaded
                setTimeout(() => {
                    const foundElement = this.pageRefs.find((ref) => ref.nativeElement?.classList?.contains('current-page'));
                    if (foundElement?.nativeElement) {
                        foundElement.nativeElement.scrollTo(0, 0);
                        this._scrollingFinished = this._isAtBottom();
                    }
                }, this.PAGE_LOAD_DELAY);
            }, this.TRANSITION_TIME);
        }
        else {
            this.transitioningTitle = true;
            // Wait until the animation is done
            this.timeout$ = setTimeout(() => {
                this.transitioningTitle = false;

                // Timeout before page is fully loaded
                setTimeout(() => {
                    this._scrollingFinished = this._isAtBottom();
                }, this.PAGE_LOAD_DELAY);
            }, this.TRANSITION_TIME);
        }
    }

    private _initializeIfQuiz(newIndex: number, questionIndex?: number, atEnd?: boolean): void {
        const newPage = this.briefingPages.find(page => page.position === newIndex);

        if (newPage && newPage.type === 'Quiz' && newPage.questions) {
            if (questionIndex !== undefined) {
                newPage.currentQuestionIndex = questionIndex;
            }
            else {
                newPage.currentQuestionIndex = atEnd ? newPage.questions.length - 1 : 0;
            }
        }
    }

    private _addPageIfNew(page: IBriefingPageModel, list: IBriefingPageModel[], atStart?: boolean): void {
        const indexOfNextLoadedPage = list.findIndex(p => p.position === page.position);
        if (indexOfNextLoadedPage < 0) {
            if (atStart) {
                list.unshift(page);
            }
            else {
                list.push(page);
            }
        }
    }

    private _updateProgress(): void {
        if (!this.briefingView?.totalPages || !this.briefingView?.progress) {
            return;
        }

        const localFinishedPages = this.briefingPages.filter(page => page.finished).map(page => page.position);
        const savedFinishedPages = this.briefingView.progress.filter((pageProgress) => pageProgress.finished)
            .map(pageProgress => pageProgress.position);

        const finishedPages = localFinishedPages.concat(savedFinishedPages);
        const filteredPages = new Set(finishedPages);

        const progress = Math.floor(filteredPages.size / this.briefingView.totalPages * 100);

        this.progressBar = [new RadialProgressBarModel(progress, 'green')];
    }

    private _getFirstUnfinishedPageIndex(): { pageIndex: number; questionIndex?: number } {
        for (let i = 0; i < this.briefingView.totalPages; i++) {
            // If the page is marked as finished locally, skip it
            if (this.briefingPages[i] && this.briefingPages[i].finished) {
                continue;
            }

            if (this.briefingView.progress && this.briefingView.progress[i]) {
                // If the page is marked as finished in the progress, skip it
                if (this.briefingView.progress[i].finished) {
                    continue;
                }

                // If the page is not finished, but has questionProgress
                if (this.briefingView.progress[i].questions && this.briefingView.progress[i].questions.length > 0) {
                    const briefingPage = this.briefingPages.find(page => page.position === i);
                    let j = 0;
                    while (j < this.briefingView.progress[i].questions.length) {
                        // If the question is marked as answered in the progress, skip it
                        if (this.briefingView.progress[i].questions[j] && this.briefingView.progress[i].questions[j].answered) {
                            j++;
                            continue;
                        }

                        // If the question is marked as finished locally, skip it
                        if (briefingPage && briefingPage.questions && briefingPage.questions[j] && briefingPage.questions[j].finished) {
                            j++;
                            continue;
                        }

                        // If the question is not marked as finished
                        break;
                    }
                    // If there are no more question progresses saved, return the index after the latest answered one
                    // Or the last question of the quiz if all of them are answered (but the quiz itself is not)
                    if (briefingPage?.questions && j >= briefingPage.questions.length) {
                        return { pageIndex: i, questionIndex: briefingPage.questions.length - 1 };
                    }
                    return { pageIndex: i, questionIndex: j };
                }
            }

            // Check if there is any local question progress
            if (this.briefingPages && this.briefingPages[i] && this.briefingPages[i].questions) {
                const briefingPageQuestions = this.briefingPages[i].questions;
                if (briefingPageQuestions !== undefined) {
                    let j = 0;
                    while (j < briefingPageQuestions.length) {
                        // If the question was answered, skip it
                        if (briefingPageQuestions[j] && briefingPageQuestions[j].finished) {
                            j++;
                            continue;
                        }
                        break;
                    }
                    // If there are no more question progresses saved, return the index after the latest answered one
                    return { pageIndex: i, questionIndex: j };
                }
            }

            // If the page is not marked as finished anywhere
            return { pageIndex: i };
        }

        // If completed the whole briefing
        return { pageIndex: 0 };
    }

    private _pageHasVideos(index: number): boolean {
        if (!this.showVideos) {
            return false;
        }

        const foundPage = this.loadedPages.find(page => page.position === index);
        if (!foundPage || foundPage.sections === undefined) {
            return false;
        }
        return foundPage.sections.some(section => section.type === BriefingSectionType.video);
    }

    private _isPageFinished(index: number): boolean {
        const foundProgress = this.briefingView.progress.find(progress => progress.position === index);

        if (foundProgress === undefined) {
            return false;
        }
        else {
            return foundProgress.finished;
        }
    }

    private _isAtBottom(): boolean {
        if ((window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight - this.ALLOWED_SCROLL_DELTA) {
            return true;
        }
        return false;
    }

    private _isOnMobile(): boolean {
        return /Mobi/gi.test(navigator.userAgent);
    }
}
