import { Component, OnInit } from '@angular/core';
import { DarkBodyComponent } from '@global/base-components';
import { GlobalsService } from '@core/services';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountDataService } from '@account/services';
import { take } from 'rxjs/operators';

@Component({
    selector: 'app-request-new-invite',
    templateUrl: './request-new-invite.component.html',
    styleUrls: ['./request-new-invite.component.scss']
})
export class RequestNewInviteComponent extends DarkBodyComponent implements OnInit {

    requesting = false;
    requestSuccesful = false;
    requestError = false;

    token!: string;

    constructor(
        globals: GlobalsService,
        private route: ActivatedRoute,
        private data: AccountDataService,
        private router: Router
    ) {
        super();
        globals.hideHeaderAndFooter();
    }

    ngOnInit(): void {
        this.token = this.route.snapshot.queryParams.token;
    }

    onClickRequest(): void {
        this.requesting = true;
        this.requestSuccesful = false;
        this.requestError = false;

        this.data.requestInvitationResend(this.token).pipe(take(1)).subscribe({
            next: () => {
                this.requesting = false;
                this.requestSuccesful = true;
            }, error: () => {
                this.requesting = false;
                this.requestError = true;
            }
        });
    }

    goHome(): void {
        this.router.navigate(['']);
    }
}
