<div class='labels d-flex justify-content-between'>
    <div class='label d-inline-block'>
        <span [translate]='label'></span>
    </div>

    <div class='countdown d-inline-block' *ngIf='completed; else countdown'>
        <span [class.warn-text]='color === "warn"' [class.primary-text]='color === "primary"'
            [translate]='"general.text.completed"'></span>
    </div>
    <ng-template #countdown>
        <div class='countdown d-inline-block' *ngIf='daysLeft > 1; else today'>
            <div class='d-inline-block mr-1' [class.warn-text]='color === "warn"'
                [class.primary-text]='color === "primary"'>
                <span>
                    {{ daysLeft }}
                </span>
                <span [translate]='daysLeft !== 1 ? "general.text.days" : "general.text.day"'></span>
            </div>
            <span class='light-label' [translate]='countdownLabel'></span>
        </div>
        <ng-template #today>
            <div class='countdown d-inline-block'>
                <span [class.warn-text]='color === "warn"' [class.primary-text]='color === "primary"'
                    [translate]='daysLeft === 0 ? "general.text.today" : "general.text.tomorrow"'></span>
            </div>
        </ng-template>
    </ng-template>
</div>

<div class='indicator-bar'>
    <mat-slider class='timeline-slider w-100' [class.warn]='color === "warn"' [class.primary]='color === "primary"'
        disabled [max]='size' [min]='1'><input matSliderThumb [value]='positionOnSlider' /></mat-slider>

    <ul class='pips' *ngIf='pips'>
        <li *ngFor='let pip of pips; let index = index'>
            <span class='pip-thumb' *ngIf='pip.isIndicator' [class]='getPipThumbClass(index)'></span>
        </li>
    </ul>

    <ul class='pips mt-1' *ngIf='pips'>
        <li *ngFor='let pip of pips; let index = index'>
            <cp-icon *ngIf='pip.indicatorIcon && !pip.iconTooltip; else toolTipIcon' size='x-small'
                [fill]="pip.iconFilled ? 'currentColor' : undefined" class='timeline-icon'
                [name]="pip.indicatorIcon || ''" [class.previous]='isPreviousPip(index)'></cp-icon>
            <ng-template #toolTipIcon>
                <cp-icon *ngIf='pip.indicatorIcon' size='x-small' [fill]="pip.iconFilled ? 'currentColor' : undefined"
                    class='timeline-icon' [class.active]='pip.isActive' #tooltip='matTooltip'
                    [matTooltip]='getTooltipTranslation(pip)' matTooltipClass='timeline-tooltip'
                    matTooltipPosition='above' (click)='tooltip.show(); activatePip(pip);'
                    [name]="pip.indicatorIcon || ''" [class.previous]='isPreviousPip(index)'></cp-icon>
            </ng-template>
        </li>
    </ul>
</div>
