import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { GlobalsService } from '@core/services';
import { Subject, Subscription } from 'rxjs';
import { HeaderModel, StylingModel } from '@core/models';
import { Component, Input, OnInit, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';

@Component({
    selector: 'cp-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnChanges, OnDestroy {
    @Input() model!: HeaderModel;
    @Input() organisationId?: number;
    @Input() eventId?: number;

    title?: string;
    styling: StylingModel;

    private destroy$ = new Subject<boolean>();
    private headerTranslation$?: Subscription;

    constructor(public globals: GlobalsService, private translate: TranslateService) {
        this.styling = globals.stylingDefaults;
    }

    get logoSrc(): string {
        if (this.styling.useEventLogo && this.eventId !== undefined && this.organisationId !== undefined) {
            return this.globals.getEventLogoUri(this.organisationId, this.eventId);
        } else if (this.organisationId) {
            return this.globals.getOrganisationLogoUri(this.organisationId);
        }

        return '';
    }

    ngOnInit(): void {
        this._getStyling();

        if (this.model.titleKey) {
            this.headerTranslation$ = this.translate.get(this.model.titleKey)
                .pipe(takeUntil(this.destroy$))
                .subscribe(result => this.title = result);
        } else if (this.model.title) {
            this.title = this.model.title;
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.model) {
            if (changes.model.currentValue.titleKey && changes.model.currentValue.titleKey !== changes.model.previousValue?.titleKey) {
                this.headerTranslation$ = this.translate.get(changes.model.currentValue.titleKey)
                    .pipe(takeUntil(this.destroy$))
                    .subscribe(result => this.title = result);
            } else if (changes.model.currentValue.title && changes.model.currentValue.title !== changes.model.previousValue?.title) {
                this.title = changes.model.currentValue.title;
            }
        }

        if (changes.organisationId || changes.eventId) {
            this._getStyling();
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
        this.headerTranslation$?.unsubscribe();
    }

    private _getStyling(): void {
        if (this.organisationId) {
            this.globals.getStyling(this.organisationId, this.eventId, this.model.code).pipe(takeUntil(this.destroy$)).subscribe(
                styling => this.styling = styling
            );
        }
    }
}
