
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ConnectionService {
    online = true;
    connectionBarSize?: number;

    private _connectionState = new Subject<boolean>();
    onlineState$ = this._connectionState.asObservable();

    private _connectionBarSize = new Subject<number | undefined>();
    connectionBarSize$ = this._connectionBarSize.asObservable();

    constructor() {
        this.online = navigator.onLine;
    }

    setOnline(): void {
        this.online = true;
        this._connectionState.next(true);
    }

    setOffline(): void {
        this.online = false;
        this._connectionState.next(false);
    }

    setConnectionBarSize(size?: number): void {
        this.connectionBarSize = size;
        this._connectionBarSize.next(size);
    }
}
