import { SafeHtml } from '@angular/platform-browser';
import { ContactsContent, OtherContent, PlanningContent, ProcedureContent } from './content-types';
import { TabType } from './tab-type.type';

export interface IWikiTabModel {
    id: number;
    type: TabType;
    title: string;
    useIntroduction: boolean;
    introductionText?: string;
    sanitizedIntroductionText?: SafeHtml;
    content: PlanningContent | ContactsContent | ProcedureContent | OtherContent;
    publishDate: Date;
}
