import { BriefingSectionType, IBriefingSectionModel } from '@core/models';

export class ImageTextSectionModel implements IBriefingSectionModel {
    type: BriefingSectionType = BriefingSectionType['image-text'];

    content?: string;

    _content?: ImageTextSectionContent;

    constructor(content?: ImageTextSectionContent) {
        if (!content) {
            this._content = {
                ImageBlobId: undefined,
                TextContent: '',
                HasTextOnRight: true
            };
        } else {
            this._content = content;
        }
    }
}

export interface ImageTextSectionContent {
    ImageBlobId?: string;
    TextContent: string;
    HasTextOnRight: boolean;
}
