import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ImageTextSectionContent, ImageTextSectionModel } from './models';

@Component({
    selector: 'cp-image-text-section',
    templateUrl: './image-text-section.component.html',
    styleUrls: ['./image-text-section.component.scss']
})
export class ImageTextSectionComponent implements OnInit {
    @Input()
        organisationId!: number;

    @Input()
        eventId!: number;

    @Input()
        code?: string;

    @Input()
        section!: ImageTextSectionModel;

    @Input()
        pageInformation?: unknown;

    content?: ImageTextSectionContent;
    safeText?: SafeHtml;

    constructor(private sanitizer: DomSanitizer) { }

    get imageClasses(): { [klass: string]: boolean } {
        return {
            'img-fluid': true,
        };
    }

    ngOnInit(): void {
        this._parseComplexContent();
    }

    trustContent(content: string | undefined): SafeHtml {
        if (content) {
            return this.sanitizer.bypassSecurityTrustHtml(content);
        }
        return '';
    }

    private _parseComplexContent(): void {
        const sectionModel = this.section as ImageTextSectionModel;
        sectionModel._content = JSON.parse(sectionModel.content || '{}');
        this.content = sectionModel._content;
        this.safeText = this.trustContent(this.content?.TextContent);
    }
}
