import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'cp-video-section',
    templateUrl: './video-section.component.html',
    styleUrls: ['./video-section.component.scss']
})
export class VideoSectionComponent implements OnInit {
    @Input()
        content!: string;

    @Input()
        organisationId!: number;
    
    @Input()
        eventId!: number;

    @Input()
        wikiTabId!: number;

    loading = true;
    invalidSection = false;

    videoId?: number;

    ngOnInit(): void {
        if (!this.content) {
            this.invalidSection = true;
        }

        const videoId = parseInt(this.content || '', 10);

        if (Number.isNaN(videoId)) {
            this.invalidSection = true;
        }
        else {
            this.videoId = videoId;
        }
    }
}
