<div class="w-100 mt-3">
    <div class="rating-buttons mx-2">
        <ng-container *ngFor="let star of starsArray; let index = index;">
            <button mat-icon-button class="cp-button"
                [class.active]="value !== 0 && (index + 1) * 20 <= value" (click)="onClickStar(index)">
                <cp-icon name="star" color="currentColor"
                    [fill]="value !== 0 && (index + 1) * 20 <= value ? 'currentColor' : 'transparent'"></cp-icon>
            </button>
        </ng-container>
    </div>
</div>
