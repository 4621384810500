<div class="bottom-container">
    <div class='header'>
        <div class='supertitle' *ngIf='eventName'>
            <span>
                {{ eventName }}
            </span>
        </div>
        <div class='title'>
            <span [translate]='"evaluation.text.open_feedback"'>
                Open feedback
            </span>
        </div>
        <div class='text'>
            <span [translate]="'evaluation.text.open_feedback_info'">
                If you have any additional comments or clarification on your evaluation you wish to send to the event
                organization, tell us here!
            </span>
        </div>
    </div>

    <div class="input-form">
        <mat-form-field class="mt-2 w-100" appearance="outline" *ngIf="generalFeedback">
            <textarea matInput [placeholder]='"evaluation.text.open_feedback_placeholder" | translate'
                class="textarea-input" [(ngModel)]="evaluationData.feedback"></textarea>
        </mat-form-field>
        <div class="col-12" *ngIf="followup">
            <div>
                <mat-checkbox class="cp-checkbox pl-0 pb-2" [(ngModel)]="evaluationData.requireFollowup"
                    (ngModelChange)="onRequireFollowupChanged($event)">
                    <span [translate]="'evaluation.text.request_followup'">
                        Request follow-up?
                    </span>
                </mat-checkbox>
                <div class="hint">
                    <span [translate]="'evaluation.text.request_followup_hint'">
                        If checked, the event organizer will be asked to respond to your evaluation.
                    </span>
                </div>
            </div>

            <div class="followup-reason-container mt-3" *ngIf="evaluationData.requireFollowup">
                <div class='label'>
                    <span class='title' [translate]='"reports.text.reason_for_follow_up"'>
                        Reason for follow up
                    </span>

                    <span class='required-label'
                        [translate]='requireFollowupReason ? "general.text.required" : "general.text.optional"'>
                        Required
                    </span>
                </div>
                <mat-form-field class="mt-2 w-100" appearance="outline">
                    <textarea matInput [required]="requireFollowupReason" class="textarea-input"
                        [(ngModel)]="evaluationData.followupReason"></textarea>
                </mat-form-field>
            </div>

            <div class="followup-method-container mt-3" [class.hidden]="!evaluationData.requireFollowup">
                <div>
                    <span [translate]="'evaluation.text.followup_method_title'">
                        How would you like to be contacted?
                    </span>
                </div>
                <div class="toggle-container">
                    <span class='toggle-text toggle-before cursor-pointer' (click)='onContactMethodChange(false)'
                        [class.selected]='!contactByPhone' [translate]='"general.text.email"'>
                        E-mail
                    </span>
                    <mat-slide-toggle class='mx-2 cp-neutral-toggle' [(ngModel)]="contactByPhone"
                        (ngModelChange)="onContactMethodChange($event)">
                    </mat-slide-toggle>
                    <span class='toggle-text cursor-pointer' (click)='onContactMethodChange(true)'
                        [class.selected]='contactByPhone' [translate]='"general.text.phone"'>
                        Phone
                    </span>
                </div>

                <div class="phonenumber-input-container mt-3"
                    [class.hidden]="!contactByPhone || hasDefinedPhonenumber()">
                    <div>
                        <span>
                            No phone number has been defined for your account yet, please fill one in:
                        </span>
                    </div>
                    <form [formGroup]='feedbackForm'>
                        <mat-form-field class="mt-2 w-100 phone-field" appearance="outline" floatLabel="always"
                            [class.large-error-offset]='phonenumberControl.errors?.validatePhoneNumber'>
                            <mat-label [translate]="'general.text.phone'">
                                Phone number
                            </mat-label>
                            <cp-intl-tel-input class='cp-tel-input' [preferredCountries]="['nl', 'gb', 'us', 'es', 'de']"
                                format='national' [enableSearch]='true' name='phonenumber' formControlName='phonenumber'
                                [searchPlaceholder]='"general.text.search" | translate'>
                            </cp-intl-tel-input>
                            <mat-error *ngIf="phonenumberControl.touched && phonenumberControl.errors">
                                <span [translate]='"account.text.phone_invalid"'
                                    [translateParams]='{ format: phoneInput.selectedCountry?.placeHolder || "" }'
                                    *ngIf='phonenumberControl.errors?.validatePhoneNumber'>
                                    The number you entered is not a valid phone number for the selected country. Example
                                    of
                                    accepted format:
                                </span>
                            </mat-error>
                        </mat-form-field>
                    </form>
                </div>
            </div>
        </div>
    </div>

    <div class="alert alert-danger" *ngIf='error'>
        <span [translate]="'evaluation.text.evaluation_feedback_saving_error_message'">
            Something went wrong while trying to save your feedback. Please try again!
        </span>
    </div>
</div>
