<div class='pdf-item' *ngIf='content?.pdfId && content?.title' (click)='onClickPdf()'>
    <div class='d-flex title-container'>
        <cp-icon [name]='isOffline ? "wifi-off" : "file-text"' size='x-small' class='file-icon d-block mr-1'></cp-icon>

        <div class='title'>
            {{ content?.title }}
        </div>
    </div>

    <div class='d-flex pr-2' *ngIf='!isOffline'>
        <cp-delayed-spinner *ngIf='downloading; else touchIconRef' class='my-auto' [diameter]='20'>
        </cp-delayed-spinner>
        <ng-template #touchIconRef>
            <i class="material-icons-outlined tap-icon">touch_app</i>
        </ng-template>
    </div>
</div>

<div *ngIf='errorDownloading' class='alert alert-danger mt-2'>
    <span [translate]='"document.error.downloading_pdf"'>
        Something went wrong trying to download the PDF. Please try again later.
    </span>
</div>
