import { Router } from '@angular/router';
import { FeatureToggleService, AlertService, ConnectionService, GuidedTourService } from '@core/services';
import { OverviewDataService } from '@overview/services';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { ITask, ReportWidgetDetails, TaskType } from '@overview/models';
import { switchMap, take, takeUntil } from 'rxjs/operators';
import { of, Subject } from 'rxjs';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
    selector: 'cp-tasks-widget',
    templateUrl: './tasks-widget.component.html',
    styleUrls: ['./tasks-widget.component.scss'],
    animations: [
        trigger('fadeInFromBottom', [
            transition('void <=> *', [
                style({ opacity: '0', 'margin-top': '100px' }),
                animate('.5s ease-out', style({ opacity: '1', 'margin-top': '0px' })),
            ]),
        ])]
})
export class TasksWidgetComponent implements OnInit, OnDestroy {
    @Input() organisationId!: number;
    @Input() eventId!: number;

    readonly taskTypes = TaskType;

    loadingReports = true;
    loadingChecklists = true;
    loadingTasks = true;

    tasks!: ITask[];
    totalTasks = 0;

    todoChecklist = 0;
    urgentChecklist = 0;
    completedChecklist = 0;
    hasChecklists = false;

    reportWidgetDetails?: ReportWidgetDetails;
    hasReportInvites = false;

    loadedTasks = false;
    offline = false;

    private destroy$ = new Subject<boolean>();

    constructor(
        private data: OverviewDataService,
        private alert: AlertService,
        private feature: FeatureToggleService,
        private connection: ConnectionService,
        private router: Router,
        private tour: GuidedTourService) { }

    get loading(): boolean {
        return this.loadingChecklists || this.loadingTasks;
    }

    get hasTasks(): boolean {
        return this.tasks && this.tasks.length > 0;
    }

    get reportWidgetKey(): string {
        if (this.reportWidgetDetails) {
            if (this.reportWidgetDetails.amountInvites > 0) {
                if (this.reportWidgetDetails.amountPending > 0) {
                    return 'reports.text.input_required_or_pending';
                }

                return 'general.text.input_required';
            }
        }

        return 'reports.text.pending_reports';
    }

    ngOnInit(): void {
        this._loadTasks();
        this._loadChecklists();
        this._loadReports();
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    getRippleColor(isUrgent: boolean, isCompleted: boolean): string {
        if (isCompleted) {
            // Transparent catchphrase-green.
            return 'rgba(130, 186, 122, 0.2)';
        } else if (isUrgent) {
            // Transparent warn-red.
            return 'rgba(244, 67, 54, 0.2)';
        } else {
            // Transparent black.
            return 'rgba(0, 0, 0, 0.2)';
        }
    }

    getReportsLink(): string[] {
        if (this.reportWidgetDetails?.inviteId) {
            if (this.reportWidgetDetails?.pendingId) {
                return ['reports', 'resume', this.reportWidgetDetails.pendingId.toString(),
                    this.reportWidgetDetails.inviteId.toString()];
            }
            return ['reports', 'create', this.reportWidgetDetails.inviteId.toString()];
        }

        return ['reports'];
    }

    getInviteTotal(): string {
        if (!this.reportWidgetDetails) {
            return '0';
        }

        const total = this.reportWidgetDetails.amountInvites + this.reportWidgetDetails.amountPending;

        if (total > 9) {
            return '9+';
        }

        return total.toString();
    }

    isReportDeadlineExpired(): boolean {
        if (this.reportWidgetDetails?.deadline) {
            return new Date() > this.reportWidgetDetails.deadline;
        }

        return false;
    }

    convertTaskTypeToRouterLink(task: ITask): string {
        switch (task.type) {
        case TaskType.Briefing:
            return 'briefing';
        case TaskType.KnowledgeTest:
            return 'test';
        case TaskType.Evaluation:
            return 'evaluation';
        default:
            return 'briefing';
        }
    }

    private _loadTasks(): void {
        if (!this.connection.online) {
            this.loadingTasks = false;
            this.connection.onlineState$.pipe(takeUntil(this.destroy$))
                .subscribe(connected => {
                    if (connected && !this.loadedTasks) {
                        this._loadTasks();
                    }
                });
        }
        else {
            this.loadingTasks = true;
            this.data.getTopTasksForOverview(this.organisationId, this.eventId)
                .pipe(take(1))
                .subscribe({
                    next: result => {
                        this.tasks = result.tasks;
                        this.totalTasks = result.totalTasks;
                        this.loadingTasks = false;
                        this.loadedTasks = true;

                        if (this.tasks) {
                            switch (this.tasks[0].type) {
                            case TaskType.Briefing:
                                this.tour.addTourStep(7, 2, 1, 'tour.text.briefing_assignment_message', '#first-task');
                                break;
                            case TaskType.Evaluation:
                                this.tour.addTourStep(8, 2, 1, 'tour.text.evaluation_assignment_message', '#first-task');
                                break;
                            case TaskType.KnowledgeTest:
                                this.tour.addTourStep(9, 2, 1, 'tour.text.knowledge_assignment_message', '#first-task');
                                break;
                            }

                            setTimeout(() => {
                                this.tour.startTour();
                            }, 500);
                        }
                    },
                    error: () => {
                        this.loadingTasks = false;
                        this.alert.postMessage('tasks.error.could_not_load_tasks', 'Error', 3000);
                    }});
        }
    }

    private _loadChecklists(): void {
        this.loadingChecklists = true;
        this.feature.isFeatureActive(this.organisationId, 'Checklists')
            .pipe(take(1), switchMap(x => {
                if (x) {
                    this.loadingChecklists = true;
                    return this.data.getChecklistCounts(this.organisationId, this.eventId);
                } else {
                    this.hasChecklists = false;
                    this.loadingChecklists = false;
                    return of(undefined);
                }
            }))
            .subscribe({
                next: (result) => {
                    if (result) {
                        this.hasChecklists = result.completed !== 0 || result.toDo !== 0;
                        this.todoChecklist = result.toDo;
                        this.urgentChecklist = result.urgent;
                        this.completedChecklist = result.completed;

                        if (this.hasChecklists) {
                            this.tour.addTourStep(10, 2, 1, 'tour.text.checklist_message', '#checklist-card');
                            setTimeout(() => {
                                this.tour.startTour();
                            }, 500);
                        }
                    }
                    this.loadingChecklists = false;
                },
                error: () => {
                    this.loadingChecklists = false;
                    if (this.connection.online) {
                        this.alert.postMessage('checklist.error.could_not_load_checklists', 'Error', 3000);
                    } else {
                        this.hasChecklists = false;
                    }
                }});
    }

    private _loadReports(): void {
        this.loadingReports = true;
        this.feature.isFeatureActive(this.organisationId, 'Reports')
            .pipe(take(1), switchMap(x => {
                if (x) {
                    this.loadingReports = true;
                    return this.data.getReportWidgetDetails(this.organisationId, this.eventId);
                } else {
                    this.hasReportInvites = false;
                    this.loadingReports = false;
                    return of(undefined);
                }
            }))
            .subscribe({
                next: (result) => {
                    if (result) {
                        this.reportWidgetDetails = result;
                        this.hasReportInvites = result.amountInvites > 0|| result.amountPending > 0;

                        if (result.requiredId !== undefined && result.requiredId !== null) {
                            setTimeout(() => {
                                // Ensure the tour gets stopped so users don't get a floating tour element.
                                this.tour.stopTour();
                                this.router.navigate([this.organisationId, this.eventId, 'reports', 'create',
                                    result.requiredId?.toString()],
                                {
                                    state: {
                                        requiredReport: true
                                    }
                                });
                            }, 500);
                        }
                    }
                    this.loadingReports = false;

                    if ((this.reportWidgetDetails?.amountInvites || 0) > 0) {
                        this.tour.addTourStep(11, 2, 1, 'tour.text.report_invite_overview_message', '#report-card');
                        setTimeout(() => {
                            this.tour.startTour();
                        }, 500);
                    }
                },
                error: () => {
                    this.loadingReports = false;
                    if (this.connection.online) {
                        this.alert.postMessage('reports.error.could_not_load_reports', 'Error', 3000);
                    } else {
                        this.hasReportInvites = false;
                    }
                }});
    }
}
