import { StylingModel } from '@core/models';
import { Component, Input, OnInit } from '@angular/core';
import { SafeUrl, DomSanitizer } from '@angular/platform-browser';
import { AnalyticsService, GlobalsService } from '@core/services';
import { MapSectionContent } from '@wiki/models';
import { take } from 'rxjs/operators';

@Component({
    selector: 'cp-map-section',
    templateUrl: './map-section.component.html',
    styleUrls: ['./map-section.component.scss']
})
export class MapSectionComponent implements OnInit {
    @Input()
        content?: MapSectionContent;

    @Input()
        eventId!: number;

    @Input()
        organisationId!: number;

    @Input()
        wikiTabId!: number;

    @Input()
        showMap = true;

    @Input()
        code?: string;

    styling?: StylingModel;

    private _src?: SafeUrl;

    constructor(
        private sanitizer: DomSanitizer,
        private globals: GlobalsService,
        private analytics: AnalyticsService,
    ) { }

    get src(): SafeUrl | undefined {
        if (!this.content) {
            return undefined;
        }

        if (!this._src) {
            this._src = this.sanitizer.bypassSecurityTrustResourceUrl(this.content.mapType === 'Google'
                ? this._GoogleUrl
                : this._WizzyUrl);
        }

    	return this._src;
    }

    private get _WizzyUrl(): string {
    	if (this.content && this.content.mapUrl && this.content) {
    		return `https://www.wizzymaps.com/${this.content.mapUrl}`;
    	}

    	return '';
    }

    private get _GoogleUrl(): string {
    	if (this.content && this.content.mapUrl && this.content.mapType === 'Google') {
    		return `https://www.google.com/maps/d/embed?&ehbc=ffffff&${this.content.mapUrl}`;
    	}

    	return '';
    }

    private get _ExternalGoogleUrl(): string {
    	if (this.content && this.content.mapUrl && this.content.mapType === 'Google') {
    		return `https://www.google.com/maps/d/viewer?${this.content.mapUrl}`;
    	}

    	return '';
    }

    ngOnInit(): void {
        this.globals.getStyling(this.organisationId, this.eventId, this.code).pipe(take(1))
            .subscribe((styling) => this.styling = styling);
    }

    maximize(): void {

        const navigator: any = window.navigator;
        const target = 'standalone' in navigator && navigator.standalone
            ? '_top'
            : '_open';

        const url = this.content?.mapType === 'Google'
            ? this._ExternalGoogleUrl
            : this._WizzyUrl;
        window.open(url, target);

        if (this.content?.identifier && url) {
            this.analytics.track('EmbeddedMapOpen', {
                wikiTabId: this.wikiTabId,
                mapUrl: url,
                mapTitle: this.content?.title,
                mapType: this.content?.mapType,
                mapId: this.content?.identifier,
            }, this.organisationId, this.eventId);
        }
    }
}
