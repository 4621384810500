import { Component, OnDestroy, OnInit } from '@angular/core';
import { DarkBodyComponent } from '@global/base-components';
import { AuthenticationService, GlobalsService, LoggingService } from '@core/services';
import { AccountDataService } from '@account/services';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { InviteLinkModalComponent, InviteLinkModalData } from '@account/components/modals';
import { take } from 'rxjs/operators';
import { MANAGER } from '@core/constants';
import { errorLogTap } from '@core/handlers';

@Component({
    templateUrl: './invite.component.html',
    styleUrls: ['./invite.component.scss']
})
export class InviteComponent extends DarkBodyComponent implements OnInit, OnDestroy {
    loading = true;
    loadingAnonymous = false;
    attemptedManagerLink = false;
    fromInviteLink = false;
    anonymousAccess = false;
    organisationName!: string;
    eventId?: number;
    eventName?: string;
    roleName?: string;
    email: string | undefined;

    readonly returnUrlParameter = 'ReturnUrl';
    readonly tokenParameter = 'token';
    readonly errorIdParameter = 'errorId';

    private _dialogRef!: MatDialogRef<InviteLinkModalComponent>;

    private _invitationCode!: string;
    private _organisationId!: number;

    constructor(
        globals: GlobalsService,
        private auth: AuthenticationService,
        private dialog: MatDialog,
        private route: ActivatedRoute,
        private router: Router,
        private data: AccountDataService,
        private logging: LoggingService,
    ) {
        super();
        globals.hideHeaderAndFooter();
    }

    ngOnInit(): void {
        if (this.route.snapshot.queryParams.anonymous && this.route.snapshot.queryParams.redirected) {
            this._organisationId = this.route.snapshot.queryParams.organisationId;
            this._invitationCode = this.route.snapshot.queryParams.token;
            
            let anonymousModelString = sessionStorage.getItem('anonymous');
            if (anonymousModelString !== null) {
                let anonymousModel = JSON.parse(anonymousModelString);
                sessionStorage.removeItem('anonymous');

                this.auth.isLoggedIn().then((isLoggedIn) => {
                    if (isLoggedIn) {
                        this.router.navigate([this._organisationId, anonymousModel.eventId, 'wiki']);
                    }
                });
            }
        }
        else if (this.route.snapshot.queryParams.organisationId) {
            this.fromInviteLink = true;
            this._organisationId = this.route.snapshot.queryParams.organisationId;
            this._invitationCode = this.route.snapshot.queryParams.token;

            this.data.validateInviteLink(this._invitationCode, this._organisationId).pipe(take(1))
                .subscribe({
                    next: (result) => {
                        if (!result?.valid) {
                            this.router.navigate(['/account/request-invite']);
                        }
                        this.anonymousAccess = result.anonymousAccess;
                        this.organisationName = result.organisationName;
                        this.eventId = result.eventId;
                        this.eventName = result.eventName;
                        this.roleName = result.roleName;
                        this.loading = false;

                        if (this.route.snapshot.queryParams.redirected) {
                            this.linkAccount();
                        }
                        else {
                            this.auth.isLoggedIn().then(loggedIn => {
                                if (loggedIn) {
                                    this.linkAccount();
                                }
                            });
                        }
                    },
                    error: () => {
                        this.router.navigate(['/account/request-invite']);
                    }
                });
        }
        else if (this.route.snapshot.params.organisationId) {
            // Move the route parameters to the query params
            this.router.navigate(['/account/invite'], {
                replaceUrl: true,
                queryParams:  {
                    token: this.route.snapshot.params.code,
                    organisationId: this.route.snapshot.params.organisationId,
                }
            });
        }
        else {
            this._invitationCode = this.route.snapshot.queryParams.token;

            this.data.getInvitationDetails(this._invitationCode).pipe(take(1)).subscribe(details => {
                this._organisationId = details.organisationId;
                this.organisationName = details.organisationName;

                this.loading = false;

                if (this.route.snapshot.queryParams.redirected) {
                    this.linkAccount();
                }
            });
        }
    }

    ngOnDestroy(): void {
        if (this._dialogRef) {
            this._dialogRef.close(false);
        }
        super.ngOnDestroy();
    }

    linkAccount(): void {
        if (this.loading) {
            return;
        }

        this.auth.isLoggedIn().then(loggedIn => {
            if (loggedIn) {
                if (this.auth.claims?.role === MANAGER) {
                    this.email = this.auth.claims.email;
                    this.attemptedManagerLink = true;
                    return;
                }

                this.attemptedManagerLink = false;

                this._dialogRef = this.dialog.open(InviteLinkModalComponent,
                    {
                        data: {
                            invitationCode: this._invitationCode,
                            organisationName: this.organisationName,
                            eventName: this.eventName,
                            roleName: this.roleName,
                            organisationId: this._organisationId,
                            fromInviteLink: this.fromInviteLink,
                        } as InviteLinkModalData
                    });

                this._dialogRef.afterClosed().pipe(take(1), errorLogTap(this.logging)).subscribe(result => {
                    if (result === true) {
                        this.router.navigate([this._organisationId]);
                    }
                });
            }
            else {
                if (this.fromInviteLink) {
                    this.router.navigate(['sign-in'], { queryParams: {
                        token: this._invitationCode,
                        organisationId: this._organisationId
                    }});
                } else {
                    this.router.navigate(['sign-in'], { queryParams: {
                        token: this._invitationCode
                    }});
                }
            }
        });
    }

    continueWithoutAccount(): void {
        if (!this.anonymousAccess) {
            return;
        }

        this.loadingAnonymous = true;

        const returnRoute = `/account/invite?token=${this._invitationCode}&redirected=true&organisationId=${this._organisationId}&anonymous=true`;

        const anonymousModel = {
            invitationCode: this._invitationCode,
            organisationId: this._organisationId,
            eventId: this.eventId,
        };

        sessionStorage.setItem('anonymous', JSON.stringify(anonymousModel));

        this.auth.startAuthentication(returnRoute);
    }

    goHome(): void {
        this.router.navigate(['']);
    }
}
