<div *ngIf='styling' class='header' [style.background-color]='styling.colorTwo'
    [class.darkText]='styling.colorTwoText === "Dark"' [class.lightText]='styling.colorTwoText === "Light"'>
    <div class='logo' [style.background-color]='styling.colorOne'>
        <div>
            <cp-logo *ngIf='!styling.hasLogo; else organisationLogo' color='white' maxHeight='40px' maxWidth='40px'>
            </cp-logo>

            <ng-template #organisationLogo>
                <img [src]='logoSrc'>
            </ng-template>
        </div>
    </div>

    <div class='title' *ngIf='title'>
        <span>
            {{ title }}
        </span>
    </div>

    <div class='header-button' id='header-button' (click)='globals.headerButtonClick();' *ngIf='model.buttonName'>
        <cp-icon [name]='model.buttonName'></cp-icon>
    </div>
</div>
