<div class='wiki-container'>
    <cp-no-connection-message [shouldCache]='true' *ngIf='offlineNoCache; else errorRef'></cp-no-connection-message>
    <ng-template #errorRef>
        <div *ngIf='error; else loadingRef' class='mt-5 alert alert-danger'>
            <span [translate]='"wiki.error.could_not_load_wiki"'>
                The wiki could not be loaded at this moment, you will be redirected to the event screen.
            </span>
        </div>
    </ng-template>
    <ng-template #loadingRef>
        <div *ngIf='loading; else contentRef' class='loading-container d-flex'>
            <cp-delayed-spinner class='m-auto'></cp-delayed-spinner>
        </div>
        <ng-template #contentRef>
            <header class='tab-header' [style.top]='moving ? "initial" : headerHeight + "px"'>
                <nav class='tabs-container' #tabList *ngIf='!onOverview' [style.background-color]='styling.colorOne'>
                    <button mat-flat-button class='overview-button' [style.background-color]='styling.colorTwo'
                        [style.color]='colorTwoText' (click)='onClickToOverview()'>
                        <cp-icon name='arrow-left' size='x-small' color='currentColor'></cp-icon>
                    </button>
                    <div class='left-spacer'></div>
                    <button mat-flat-button *ngFor='let tab of wikiTabs; let index = index' class='tab'
                        [style.border-color]='isActive(index) ? styling.colorTwo : colorOneText'
                        [style.color]='isActive(index) ? colorTwoText : colorOneText'
                        [style.background-color]='isActive(index) ? styling.colorTwo : "transparent"'
                        [attr.data-index]='index'
                        (click)='onClickTab(index)' #tab>
                        <span>
                            {{ getTabTitle(tab.title) }}
                        </span>
                    </button>
                    <button *ngIf='hasGlossaryTerms' mat-flat-button class='tab' (click)='onClickToGlossary()'
                    [style.border-color]='onGlossary ? styling.colorTwo : colorOneText'
                    [style.color]='onGlossary ? colorTwoText : colorOneText'
                    [style.background-color]='onGlossary ? styling.colorTwo : "transparent"' #glossaryTab>
                        <span [translate]="'wiki.text.glossary'">
                            Glossary
                        </span>
                    </button>
                    <button *ngIf='hasFAQCategories' mat-flat-button class='tab' (click)='onClickToFaq()'
                    [style.border-color]='onFaq ? styling.colorTwo : colorOneText'
                    [style.color]='onFaq ? colorTwoText : colorOneText'
                    [style.background-color]='onFaq ? styling.colorTwo : "transparent"' #faqTab>
                        <span [translate]='"wiki.text.faq"'>
                            FAQ
                        </span>
                    </button>
                    <div class='right-spacer'></div>
                </nav>
                <div class='title p-3 d-flex justify-content-between'
                    [class.planning]='currentTab?.type === "Planning"'>
                    <div class='details-block'>
                        <div class='event-name'>
                            {{ eventName }}
                        </div>
                        <div class='active-date' *ngIf='currentTab?.type === "Planning" && planningHeaderTime'>
                            <span class='day'>
                                {{ planningHeaderTime | localizedDate:'EEEE'}}
                            </span>
                            <span class='date'>
                                {{ planningHeaderTime | localizedDate:'longDate'}}
                            </span>
                        </div>
                    </div>
                    <div *ngIf='currentTab?.type === "Planning"'>
                        <span class='toggle-label'
                            [translate]='filterFlags ? "wiki.text.your_items" : "general.text.everything"'>
                        </span>
                        <mat-slide-toggle color='primary' [(ngModel)]='filterFlags'></mat-slide-toggle>
                        <div class='search-button d-flex justify-content-center ml-auto mt-1'
                            (click)='showingPlanningSearchbar = true'>
                            <cp-icon name='search' size='x-small'></cp-icon>
                        </div>
                    </div>
                </div>
            </header>
            <div class='overview mb-5' [class.off-screen]='!onOverview' [class.animating]='animating'
                [class.ios]='isIOS'>
                <div class='intro-text'>
                    <span [translate]='"wiki.text.explainer_text"'>
                        This event wiki contains all the information you will need during the event in your role.
                        Navigate
                        using the buttons below or the tabs above the pages
                    </span>
                </div>
                <div class='wiki-tab-container' [class.bottom-line]='hasGlossaryTerms || hasFAQCategories'>
                    <div *ngFor='let tab of wikiTabs' class='overview-tab py-1 px-3'
                        (click)='onClickOverviewTab(tab.id)'>
                        <div>
                            <span class='title'>
                                {{ tab.title }}
                            </span>
                        </div>
                        <div class='saved-on-line'>
                            <span>
                                <span [translate]='"general.text.last_change"' class='saved-on-text'>
                                    Last change
                                </span>
                                <span class='saved-on-date'>
                                    {{ tab.publishDate | localizedDate:DATE_FORMAT }}
                                </span>
                            </span>
                        </div>
                        <div class='icon-container pr-2 pl-3'>
                            <cp-icon [name]='getIconForTabType(tab.type)' size='small'></cp-icon>
                        </div>
                    </div>
                </div>
                <div *ngIf='hasGlossaryTerms' class='my-3'>
                    <div class='overview-tab py-1 px-3 my-2' (click)='onClickToGlossary()'>
                        <div>
                            <span class='title' [translate]='"wiki.text.glossary"'>
                                Glossary
                            </span>
                        </div>
                        <div class='saved-on-line'>
                            <span>
                                <span [translate]='"general.text.last_change"' class='saved-on-text'>
                                    Last change
                                </span>
                                <span class='saved-on-date'>
                                    {{ glossaryLastChangedOn | localizedDate:DATE_FORMAT }}
                                </span>
                            </span>
                        </div>
                        <div class='icon-container pr-2 pl-3'>
                            <cp-icon name='book' size='small'></cp-icon>
                        </div>
                    </div>
                </div>
                <div *ngIf='hasFAQCategories' class='my-3'>
                    <div class='overview-tab py-1 px-3 my-2' (click)='onClickToFaq()'>
                        <div>
                            <span class='title' [translate]='"wiki.text.frequently_asked_questions"'>
                                Frequently Asked Questions
                            </span>
                        </div>
                        <div class='saved-on-line' *ngIf='faqLastChangedOn'>
                            <span>
                                <span [translate]='"general.text.last_change"' class='saved-on-text'>
                                    Last change
                                </span>
                                <span class='saved-on-date'>
                                    {{ faqLastChangedOn | localizedDate:DATE_FORMAT }}
                                </span>
                            </span>
                        </div>
                        <div class='icon-container pr-2 pl-3'>
                            <cp-icon name='help-circle' size='small'></cp-icon>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-content" [class.collapsed]='notesOpen'
                [class.off-screen]='onOverview || onGlossary || onFaq' [class.animating]='animating'>
                <div *ngFor='let tab of renderedTabs; let index = index' class='tab'
                    [class.past]='index < (selectedWikiTabIndex || 0) || onGlossary || onFaq'
                    [class.future]='(index > (selectedWikiTabIndex || 0) || selectedWikiTabIndex === undefined) && !onFaq && !onGlossary'
                    [class.animating]='animating && (index === previousWikiTabIndex || index === selectedWikiTabIndex)'>
                    <ng-container *ngIf='tab' [ngSwitch]='tab.type'>
                        <div *ngSwitchCase='"Planning"' class='planning'>
                            <cp-planning-wiki-tab-view [content]="getExpectedContent(tab)"
                                [organisationId]='organisationId' [eventId]='eventId'
                                [contentId]='tab.id' [wikiTabId]="tab.id"
                                [active]='index === selectedWikiTabIndex' [filter]='filterFlags'
                                [introductionText]='tab.useIntroduction ? tab.sanitizedIntroductionText : undefined'
                                [showingSearchbar]='showingPlanningSearchbar' [headerHeight]='headerHeight'
                                (closeSearchbar)='showingPlanningSearchbar = false'
                                (headerTimeChanged)='planningHeaderTime = $event'>
                            </cp-planning-wiki-tab-view>
                        </div>
                        <div *ngSwitchCase='"Contact"'>
                            <cp-contact-wiki-tab-view [content]="getExpectedContent(tab)"
                                [organisationId]="organisationId" [eventId]='eventId' [wikiTabId]="tab.id"
                                [introductionText]='tab.useIntroduction ? tab.sanitizedIntroductionText : undefined'>
                            </cp-contact-wiki-tab-view>
                        </div>
                        <div *ngSwitchCase='"Procedure"'>
                            <cp-procedure-wiki-tab-view [content]="getExpectedContent(tab)"
                                [organisationId]="organisationId" [eventId]='eventId' [wikiTabId]="tab.id"
                                [introductionText]='tab.useIntroduction ? tab.sanitizedIntroductionText : undefined'>
                            </cp-procedure-wiki-tab-view>
                        </div>
                        <div *ngSwitchCase='"Other"'>
                            <cp-other-wiki-tab-view [content]="getExpectedContent(tab)"
                                [organisationId]="organisationId" [eventId]='eventId' [wikiTabId]="tab.id"
                                [introductionText]='tab.useIntroduction ? tab.sanitizedIntroductionText : undefined'>
                            </cp-other-wiki-tab-view>
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class='glossary' [class.off-screen]='!onGlossary' [class.animating]='animating' [class.left]='onFaq'>
                <cp-wiki-glossary-view [glossaryTerms]='glossaryTerms' [organisationId]='organisationId'
                    [eventId]="eventId">
                </cp-wiki-glossary-view>
            </div>
            <div class='faq' [class.off-screen]='!onFaq' [class.animating]='animating'>
                <cp-wiki-faq-view [categories]='faqCategories' [organisationId]='organisationId'
                    [eventId]="eventId">
                </cp-wiki-faq-view>
            </div>
        </ng-template>
    </ng-template>
</div>
<div *ngIf="!auth.isAnonymous" class='notes-container'>
    <cp-wiki-notes *ngIf='!loading' [organisationId]='organisationId' [eventId]='eventId'
        [enabled]='!onOverview && !onGlossary && !onFaq' [wikiTabId]='currentTab?.id || 0'
        [wikiTabTitle]='currentTab?.title || ""' [headerHeight]='headerHeight' (notesOpen)='notesOpen = $event;'>
    </cp-wiki-notes>
</div>
<button *ngIf="!auth.isAnonymous && hasUnreadChat" class='unread-chat-button' (click)='goToChat()' [@slideInFromSide]>
    <cp-icon strokeSize='small' color='white' name='message-circle'>
    </cp-icon>
</button>
