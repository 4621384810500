import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { FeatureToggleService } from '@core/services';
import { Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FeatureToggleGuard  {
    constructor(private featureService: FeatureToggleService) { }

    canActivate(route: ActivatedRouteSnapshot, _: RouterStateSnapshot): Observable<boolean> {
        if (!route.data.feature) {
            return of(true);
        }

        if (!route.params.organisationId) {
            return of(false);
        }

        return this.featureService.isFeatureActive(route.params.organisationId, route.data.feature);
    }
}
