<mat-dialog-content class="mat-typography" [class.mx-3]='!data.bigKeys'>
    <span [translate]='data.translationKey' [translateParams]='data.translationParams'>

    </span>
</mat-dialog-content>
<mat-dialog-actions align='end'>
    <ng-container *ngIf='!data.bigKeys; else bigKeys'>
        <button mat-button class="cp-button" mat-dialog-close mat-dialog-close='cancel'>
            <span [translate]='data.cancelKey'>
                Cancel
            </span>
        </button>
        <button mat-flat-button class="cp-button" mat-dialog-close mat-dialog-close='continue'
            [color]='data.continueColor'>
            <span [translate]='data.confirmKey'>
                Continue
            </span>
        </button>
        <button *ngIf='data.continueAnd' mat-flat-button class="cp-button" mat-dialog-close='continueAnd'
            [color]='data.continueAndColor'>
            <span [translate]='data.continueAndKey'>
                Save and continue
            </span>
        </button>
    </ng-container>

    <ng-template #bigKeys>
        <button *ngIf='data.continueAnd' mat-flat-button class="cp-button col-12" mat-dialog-close='continueAnd'
            [color]='data.continueAndColor'>
            <span [translate]='data.continueAndKey'>
                Save and continue
            </span>
        </button>
        <button mat-flat-button class="cp-button col-12 mt-2" mat-dialog-close mat-dialog-close='continue'
            [color]='data.continueColor'>
            <span [translate]='data.confirmKey'>
                Continue
            </span>
        </button>
        <button mat-flat-button class="cp-button col-12 mt-2" mat-dialog-close mat-dialog-close='cancel'>
            <span [translate]='data.cancelKey'>
                Cancel
            </span>
        </button>
    </ng-template>
</mat-dialog-actions>
