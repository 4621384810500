import { environment } from '@env';
import { filter, take } from 'rxjs/operators';
import { UrlResolver } from '@core/utils';
import { EndPoint } from '@core/constants';
import { AuthenticationService, ConnectionService } from '@core/services';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { retry } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AnalyticsService {
    constructor(
        private http: HttpClient,
        private connection: ConnectionService,
        private auth: AuthenticationService,
    ) {}

    public track(event: TrackingEventType, payload: any, organisationId?: number, eventId?: number): void {
        if (!environment.tracking) {
            return;
        }

        if (!payload) {
            payload = {};
        }
        payload.callTime = new Date();
        payload.onlineCall = this.connection.online;

        if (this.auth.isAnonymous) {
            payload.anonymousUserCode = this.auth.claims?.anonymous_code;
        }

        const entry: DataEntry = {
            eventType: event,
            organisationId,
            eventId,
            payload
        };

        // If currently offline schedule the tracking event for as soon as internet is restored
        if (!this.connection.online) {
            this.connection.onlineState$.pipe(filter(x => x), take(1)).subscribe(
                () => { this._send(entry); }
            );
        } else {
            this._send(entry);
        }

    }

    private _send(entry: DataEntry): void {
        const url = UrlResolver.getUrl(EndPoint.tracking, 'track');
        this.http.post(url, entry).pipe(retry(3), take(1)).subscribe();
    }
}

interface DataEntry {
    eventType: TrackingEventType;
    organisationId?: number;
    eventId?: number;
    payload: any;
}

export type TrackingEventType = 'WikiTabOpen' | 'OpenPdfFile' | 'OpenDropdown' | 'OpenContactDetails'
    | 'ActivateContact' | 'InjectedGlossaryOpen' | 'GlossaryWordOpen' | 'FAQCategoryOpen'
    | 'FAQQuestionOpen' | 'EmbeddedMapOpen' | 'WikiTabSession' | 'ExternalLinkClick'
    | 'VideoWatched';
