import { MatDialog } from '@angular/material/dialog';
import { GlobalsService, AlertService, ConnectionService, LoggingService } from '@core/services';
import { concatMap, filter, take, takeUntil } from 'rxjs/operators';
import { Priority, ChatMessage } from '@chat/models';
import { ChatDataService, SignalRService } from '@chat/services';
import { ActivatedRoute, Router } from '@angular/router';
import {
    Component, OnInit, HostListener, ElementRef, ViewChildren, QueryList, ViewChild, AfterViewChecked, OnDestroy, ChangeDetectorRef
} from '@angular/core';
import { DateUtils } from '@core/utils';
import { ResendMessageDialogComponent } from './sub-components';
import { trigger, transition, style, animate } from '@angular/animations';
import { Subject, Subscription, timer } from 'rxjs';
import { errorLogTap } from '@core/handlers';

@Component({
    templateUrl: './chat-group.component.html',
    styleUrls: ['./chat-group.component.scss'],
    animations: [trigger(
        'toBottomButtonAnimation',
        [
            transition(':enter', [
                style({ right: '-35px' }),
                animate('0.2s ease-out', style({ right: '0' }))
            ], { params: { target_height: '70px' } }),
            transition(':leave', [
                style({ right: '0' }),
                animate('0.2s ease-out', style({ right: '-35px' }))
            ])
        ]
    )]
})
export class ChatGroupComponent implements OnInit, AfterViewChecked, OnDestroy {
    @ViewChild('noConnectionBar') noConnectionBar?: ElementRef;
    @ViewChild('input') input?: ElementRef;
    @ViewChildren('message') items!: QueryList<ElementRef>;

    initialLoad = true;
    loading = false;
    fadeIn = false;
    reachedTop = false;
    dialogOpen = false;
    offline = false;

    organisationId!: number;
    eventId!: number;
    groupId!: number;

    groupName = '';
    priority: Priority = 'Normal';
    isOwner = false;
    readAny = false;
    messages: ChatMessage[] = [];

    chatBarHeight = 50;
    headerHeight = 70;
    headerTime!: Date;

    shouldScrollBottom = false;
    shouldScrollOffset = false;
    scrollOffset = 0;

    inputFocused = false;
    startingSignalR = true;
    hasSignalRConnection = false;
    canUseLiveChat = true;

    message = '';

    private destroy$ = new Subject<boolean>();

    private readonly PAGE_SIZE = 30;
    private readonly MINIMAL_MESSAGE_HEIGHT = 72;
    private readonly INFINITE_SCROLL_MESSAGE_OFFSET = 20;
    private readonly SCROLL_TOP_MARGIN = 50;

    private _canCallScroll = true;
    private _messagePoll?: Subscription;

    constructor(
        private data: ChatDataService, private route: ActivatedRoute,
        private alert: AlertService, private router: Router, private globals: GlobalsService,
        private dialog: MatDialog, private signalR: SignalRService,
        private cdr: ChangeDetectorRef, private connection: ConnectionService,
        private logging: LoggingService,
    ) { }

    get canScrollToBottom(): boolean {
        if (this.shouldScrollBottom || this.initialLoad) {
            return false;
        }

        const scrollPosition = document.documentElement.scrollTop + document.documentElement.clientHeight;
        const firstMessageOffset = document.documentElement.scrollHeight - (this.MINIMAL_MESSAGE_HEIGHT * 5);
        return scrollPosition < firstMessageOffset;
    }

    get hasNoConnection(): boolean {
        return (!this.canUseLiveChat || (!this.startingSignalR && !this.hasSignalRConnection));
    }

    get oldestMessage(): Date | undefined {
        const oldestMessage = this.messages[0];

        if (oldestMessage) {
            return oldestMessage.sentOn;
        }

        return undefined;
    }

    get newestMessage(): number | undefined {
        const newestMessage = this.messages[this.messages.length - 1];

        if (newestMessage) {
            return newestMessage.id;
        }

        return undefined;
    }

    get unreadMessages(): number {
        return this.messages.filter(x => x.isUnreadForUser).length;
    }

    get headerOffset(): number {
        let offset = 65;

        if (this.headerTime) {
            offset += 35;
        }

        if (this.noConnectionBar) {
            offset += this.noConnectionBar.nativeElement.clientHeight;
        }

        return offset;
    }

    get isIOS(): boolean {
        return [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ].includes(navigator.platform);
    }

    @HostListener('window:scroll', ['$event'])
    onScroll(event: Event): void {
        if (this.dialogOpen) {
            return;
        }

        this.headerHeight = Math.max(0, 70 - window.scrollY);

        const document = event.target as HTMLDocument;
        const element = document.documentElement;
        const atExtreme = element.scrollTop === 0 ||
            element.scrollTop + element.clientHeight === element.scrollHeight;

        if ((this._canCallScroll || atExtreme)) {
            this._canCallScroll = false;
            setTimeout(() => {
                this._canCallScroll = true;
            }, 100);
            this._handleInfiniteScroll(element);
            this._calculateHeaderTime();
        }
    }

    ngOnInit(): void {
        this.globals.changeHeader({
            titleKey: 'chat.text.chat_groups'
        });
        this.globals.hideFooter();

        this.eventId = this.route.snapshot.params.eventId;
        this.organisationId = this.route.snapshot.params.organisationId;
        this.groupId = this.route.snapshot.params.groupId;

        this._loadPage();

        this.signalR.connectionAllowed$.pipe(takeUntil(this.destroy$)).subscribe((allowed) => {
            this.canUseLiveChat = allowed;

            if (!this.canUseLiveChat) {
                this._startMessagePolling(5000);
            }
        });

        this.signalR.connectionEstablished$.pipe(takeUntil(this.destroy$)).subscribe((established) => {
            this.startingSignalR = false;
            this.hasSignalRConnection = established;

            if (!this.hasSignalRConnection) {
                this._startMessagePolling(3000);

                setTimeout(() => {
                    this.signalR.establishConnection(this.organisationId, this.eventId, this.groupId);
                }, 30000);
            } else {
                this._stopMessagePolling();
            }
        });

        this.signalR.messageReceived$.pipe(
            takeUntil(this.destroy$),
            filter(x => x.connectionId !== this.signalR.connectionId)).subscribe(
            (message) => {
                if (!this.messages.some(x => x.id === message.id)) {
                    this.messages.push(message);
                    this.cdr.detectChanges();

                    if (!this.canScrollToBottom) {
                        this.shouldScrollBottom = true;
                    }
                }
            });

        this.signalR.establishConnection(this.organisationId, this.eventId, this.groupId);

        this.offline = !this.connection.online;
        this.connection.onlineState$.pipe(takeUntil(this.destroy$))
            .subscribe((connected) => this.offline = !connected);
    }

    ngAfterViewChecked(): void {
        if (this.shouldScrollOffset) {
            document.documentElement.scrollTop = document.documentElement.scrollTop - this.scrollOffset;
            this.shouldScrollOffset = false;
        }


        if (this.shouldScrollBottom) {
            this.scrollToBottom();
            this.shouldScrollBottom = false;
        }
    }

    ngOnDestroy(): void {
        this.signalR.destroy();
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    sendMessage(): void {
        if (!this.message || this.offline) {
            return;
        }

        const sentMessage: ChatMessage = {
            id: 0,
            sentOn: new Date(),
            sentBy: 'user',
            message: this.message,
            isOwnerMessage: this.isOwner,
            isSentByUser: true,
            isUnreadForUser: false,
            sending: true,
            sendingFailed: false
        };
        this.messages.push(sentMessage);

        (this.hasSignalRConnection
            ? this.signalR.sendMessage(this.message)
            : this.data.sendChatMessage(this.organisationId, this.eventId, this.groupId, this.message))
            .pipe(take(1), errorLogTap(this.logging, this.organisationId))
            .subscribe({
                next: (result) => {
                    sentMessage.id = result.id;
                    sentMessage.sentOn = result.sentOn;
                    sentMessage.sending = false;
                },
                error: () => {
                    sentMessage.sending = false;
                    sentMessage.sendingFailed = true;
                }
            });

        this.message = '';
        this.cdr.detectChanges();
        this.shouldScrollBottom = true;
    }

    resendMessage(message: ChatMessage): void {
        if (!message.sendingFailed) {
            return;
        }

        this.dialogOpen = true;
        const dialogRef = this.dialog.open(ResendMessageDialogComponent,
            {
                data: message,
                panelClass: 'chat-message-dialog',
                backdropClass: 'darkened-backdrop'
            });

        dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
            if (result === 'delete') {
                const index = this.messages.indexOf(message);

                if (index > -1) {
                    this.messages.splice(index, 1);
                }
            } else if (result === 'resend') {
                const index = this.messages.indexOf(message);

                if (index > -1) {
                    this.messages.splice(index, 1);
                }

                this.message = message.message;
                this.sendMessage();
            }

            this.dialogOpen = false;
        });
    }

    backToOverview(): void {
        this.data.markChatAsRead(this.organisationId, this.eventId, this.groupId)
            .pipe(take(1)).subscribe();

        this.router.navigate([this.organisationId, this.eventId, 'chat']);
    }

    scrollToBottom(): void {
        document.documentElement.scrollTo({ top: document.documentElement.scrollHeight, behavior: 'smooth' });
        setTimeout(() => {
            document.documentElement.scrollTop = document.documentElement.scrollHeight;
        }, 300);
    }

    hasDateBreak(message: ChatMessage, index: number): boolean {
        const previousMessage = this.messages[index - 1];

        if (previousMessage) {
            return !DateUtils.IsSameDate(message.sentOn, previousMessage.sentOn);
        }

        return false;
    }

    hasUnreadBreak(message: ChatMessage, index: number): boolean {
        if (!message.isUnreadForUser || !this.readAny) {
            return false;
        }

        const previousMessage = this.messages[index - 1];

        if (previousMessage && !previousMessage.isUnreadForUser) {
            return true;
        }

        return false;
    }

    chatBarResize(newHeight: number): void {
        const heightDifference = this.chatBarHeight - newHeight;
        this.chatBarHeight = newHeight;

        // Adjust our scroll positioning so the user does not overlap the bottom message.
        if (heightDifference !== 0) {
            this.scrollOffset = heightDifference;
            this.shouldScrollOffset = true;
        }
    }

    private addMessagesToTop(messages: ChatMessage[]): void {
        // Store the scroll positions before the messages are added.
        const preScrollHeight = document.documentElement.scrollHeight;
        const preScrollOffset = window.pageYOffset;

        // Add the messages and ensure angular recalculates sizing.
        this.messages.unshift(...messages);
        this.cdr.detectChanges();

        // If the preScroll and postScroll offsets are the same adjust by delta
        const postScrollOffset = window.pageYOffset;
        if (preScrollOffset && postScrollOffset && preScrollOffset === postScrollOffset) {
            const postScrollHeight = document.documentElement.scrollHeight;
            const scrollDelta = postScrollHeight - preScrollHeight;

            window.scrollBy(0, scrollDelta);
        }
    }

    private _handleInfiniteScroll(element: Element): void {
        if (this.loading || this.initialLoad || this.reachedTop || this.shouldScrollBottom || this.dialogOpen || this.offline) {
            return;
        }

        if (element.scrollTop < this.MINIMAL_MESSAGE_HEIGHT * this.INFINITE_SCROLL_MESSAGE_OFFSET) {
            this._loadPage();
        }
    }

    private _loadPage(): void {
        this.loading = true;

        this.data.getChatMessages(this.organisationId, this.eventId, this.groupId,
            this.PAGE_SIZE, this.oldestMessage).pipe(take(1))
            .subscribe({
                next: (result) => {
                    this.loading = false;

                    this.groupName = result.groupName;
                    this.priority = result.priority;
                    this.isOwner = result.isOwner;

                    if (result.messages && result.messages.length > 0) {
                        this.readAny = result.hasReadAny;
                        this.addMessagesToTop(result.messages);
                    }

                    if (!result.messages || result.messages.length < this.PAGE_SIZE) {
                        this.reachedTop = true;
                    }

                    if (this.initialLoad) {
                        this.scrollToBottom();
                        setTimeout(() => {
                            this.shouldScrollBottom = true;
                            this.fadeIn = true;
                        }, 100);
                        this.initialLoad = false;
                    }
                },
                error: (error) => {
                    if (error.status === 404) {
                        this.alert.postMessage('chat.error.group_not_found', 'Error', 3000);
                    } else {
                        this.alert.postMessage('chat.error.loading_group', 'Error', 3000);
                    }

                    this.router.navigate([this.organisationId, this.eventId, 'chat']);
                }
            });
    }

    private _calculateHeaderTime(): void {
        if (!this.items) {
            return;
        }

        for (let i = 0; i < this.items.length; i++) {
            const blockElement = this.items.find((_, index) => index === i);
            if (!blockElement) { continue; }

            const elementPosition = blockElement.nativeElement.getBoundingClientRect().bottom - this.SCROLL_TOP_MARGIN;

            if (elementPosition >= 0) {
                const message = this.messages[i];
                if (message) {
                    this.headerTime = message.sentOn;
                    return;
                }
            }
        }
    }

    private _startMessagePolling(delay: number): void {
        this._messagePoll = timer(delay)
            .pipe(
                take(1),
                errorLogTap(this.logging, this.organisationId),
                concatMap(() => this.data.getNewMessages(this.organisationId, this.eventId, this.groupId, this.newestMessage))
            ).subscribe({
                next: (result) => {
                    if (!this.canScrollToBottom && result.length > 0) {
                        this.shouldScrollBottom = true;
                    }

                    this.messages.push(...result);
                    this._startMessagePolling(delay);
                },
                error: (error) => {
                    if (error.status === 404) {
                        this.alert.postMessage('chat.error.group_not_found', 'Error', 3000);
                    } else {
                        this.alert.postMessage('chat.error.loading_group', 'Error', 3000);
                    }

                    this.router.navigate([this.organisationId, this.eventId, 'chat']);
                }
            });
    }

    private _stopMessagePolling(): void {
        if (this._messagePoll) {
            this._messagePoll.unsubscribe();
            this._messagePoll = undefined;
        }
    }
}
