import { GuidedTourService } from './services/guided-tour.service';
import { Router } from '@angular/router';
import { SessionHttpInterceptor, DateHttpInterceptor, ErrorLogHttpInterceptor } from '@core/interceptors';
import { AuthorizationGuard, FeatureToggleGuard } from '@core/guards';
import {
    AuthenticationService, GeneralDataService, ImageDataService,
    GlobalsService, CelebrateService, FeatureToggleService, AlertService,
    PushService, ConnectionService, DocumentDataService, GlossaryInjectionService, AnalyticsService,
    WhitelabelService, MediaDataService, LoggingService,
} from '@core/services';
import { ErrorHandler, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';
import { TranslateModule, TranslateLoader, TranslatePipe, MissingTranslationHandler } from '@ngx-translate/core';
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpLoaderFactory, jwtOptionsFactory, MissingTranslationFactory } from '@core/factories';
import { SecurePipe } from '@shared/pipes';
import { ErrorLogHandler } from '@core/handlers';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        JwtModule.forRoot({
            jwtOptionsProvider: {
                provide: JWT_OPTIONS,
                useFactory: jwtOptionsFactory,
                deps: [AuthenticationService]
            }
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader, useFactory: HttpLoaderFactory, deps: [HttpClient],
            },
            useDefaultLang: false,
            missingTranslationHandler: {
                provide: MissingTranslationHandler, useFactory: MissingTranslationFactory, deps: [HttpClient]
            }
        })
    ],
    providers: [
        AlertService,
        PushService,
        ConnectionService,
        AuthorizationGuard,
        FeatureToggleGuard,
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: {
                appearance: 'outline'
            }
        },
        {
            provide: HTTP_INTERCEPTORS,
            multi: true,
            useClass: DateHttpInterceptor
        },
        {
            provide: HTTP_INTERCEPTORS,
            multi: true,
            useClass: SessionHttpInterceptor,
            deps: [
                AuthenticationService,
                AlertService,
                Router
            ]
        },
        {
            provide: HTTP_INTERCEPTORS,
            multi: true,
            useClass: ErrorLogHttpInterceptor,
            deps: [
                LoggingService
            ]
        },
        {
            provide: ErrorHandler,
            useClass: ErrorLogHandler,
            deps: [
                LoggingService
            ]
        },
        GeneralDataService,
        ImageDataService,
        DocumentDataService,
        GlobalsService,
        TranslatePipe,
        CelebrateService,
        FeatureToggleService,
        DatePipe,
        SecurePipe,
        GlossaryInjectionService,
        GuidedTourService,
        AnalyticsService,
        WhitelabelService,
        MediaDataService,
        LoggingService,
    ]
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        if (parentModule) {
            throw new Error('Core Module has already been loaded.');
        }
    }
}
