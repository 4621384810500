import { animate, style, transition, trigger } from '@angular/animations';
import { Component, HostListener, OnDestroy, OnInit, ViewChildren, QueryList, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AnalyticsService, AuthenticationService, ConnectionService, GlobalsService, LoggingService } from '@core/services';
import { Subject, Subscription, timer } from 'rxjs';
import { takeUntil, take, concatMap } from 'rxjs/operators';
import { FaqCategory, GlossaryTerm, IWikiTabModel, TabType, ProcedureContent, PlanningContent, OtherContent } from '@wiki/models';
import { WikiDataService } from '@wiki/services';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { BriefingSectionType, StylingModel } from '@core/models';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent, ConfirmDialogData, ConfirmDialogResult } from '@shared/components';
import { errorLogTap } from '@core/handlers';

@Component({
    templateUrl: './wiki-view.component.html',
    styleUrls: ['./wiki-view.component.scss'],
    animations: [trigger(
        'slideInFromSide',
        [
            transition(':enter', [
                style({ right: '-75px' }),
                animate('0.4s ease-in', style({ right: '1rem' }))
            ]),
            transition(':leave', [
                style({ right: '1rem' }),
                animate('0.4s ease-in', style({ right: '-75px' }))
            ])
        ]
    )]
})
export class WikiViewComponent implements OnInit, OnDestroy {
    @ViewChildren('tab', { read: ElementRef })
        tabs!: QueryList<ElementRef<HTMLElement>>;

    @ViewChild('tabList')
        tabList!: ElementRef<HTMLElement>;

    @ViewChild('glossaryTab', { read: ElementRef })
        glossaryTabRef!: ElementRef<HTMLElement>;

    @ViewChild('faqTab', { read: ElementRef })
        faqTabRef!: ElementRef<HTMLElement>;

    readonly DATE_FORMAT = 'mediumDate';
    readonly ANIMATION_DELAY = 500;

    chatPollingFailCount = 0;

    destroy$: Subject<boolean> = new Subject<boolean>();
    offlineNoCache = false;

    styling!: StylingModel;

    organisationId!: number;
    eventId!: number;
    eventName!: string;

    wikiTabs!: IWikiTabModel[];
    renderedTabs!: IWikiTabModel[];
    selectedWikiTabIndex?: number;
    previousWikiTabIndex?: number;

    glossaryTerms?: GlossaryTerm[];
    glossaryLastChangedOn?: Date;

    faqCategories?: FaqCategory[];
    faqLastChangedOn?: Date;

    headerHeight = 70;
    noConnectionBarOffset?: number;
    planningHeaderTime!: Date;

    currentTabSession?: WikiTabSession;

    moving = true;

    loading = true;
    animating = false;
    error = false;

    onOverview = true;
    onGlossary = false;
    onFaq = false;
    notesOpen = false;
    showingPlanningSearchbar = false;

    filterFlags = false;

    hasUnreadChat = false;

    private readonly _pollingDelay = 10000;
    private _chatNotificationPoll?: Subscription;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private globals: GlobalsService,
        private data: WikiDataService,
        private connection: ConnectionService,
        private sanitizer: DomSanitizer,
        private analytics: AnalyticsService,
        private dialog: MatDialog,
        public auth: AuthenticationService,
        private logging: LoggingService,
    ) {
        globals.changeHeader({ titleKey: 'wiki.text.event_wiki', buttonName: 'x' });
        globals.hideFooter();
    }

    get currentTab(): IWikiTabModel | undefined {
        if (this.selectedWikiTabIndex === undefined) {
            return undefined;
        }

        return this.renderedTabs[this.selectedWikiTabIndex];
    }

    // Icon is slightly misaligned on ios
    get isIOS(): boolean {
        return /iPad|iPhone|iPod/.test(navigator.userAgent);
    }

    get hasGlossaryTerms(): boolean {
        return this.glossaryTerms !== undefined && this.glossaryTerms.length !== 0;
    }

    get hasFAQCategories(): boolean {
        return this.faqCategories !== undefined && this.faqCategories.length !== 0;
    }

    get colorOneText(): string {
        return this.styling.colorOneText === 'Dark' ? 'black' : 'white';
    }

    get colorTwoText(): string {
        return this.styling.colorTwoText === 'Dark' ? 'black' : 'white';
    }

    @HostListener('window:scroll', ['$event'])
    onScroll(): void {
        if (this.noConnectionBarOffset) {
            this.headerHeight = Math.max(0, 70 + this.noConnectionBarOffset - window.scrollY);
        } else {
            this.headerHeight = Math.max(0, 70 - window.scrollY);
        }
    }

    @HostListener('window:click')
    onClick(): void {
        this.globals.hideTooltips();
    }

    ngOnInit(): void {
        if (!this.auth.isAnonymous) {
            this.globals.onHeaderButtonClick()
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                () => {
                    this.moving = true;

                    // Use timeout to place it on the stack after the change detection cycle.
                    setTimeout(() => {
                        this.router.navigate([this.organisationId, this.eventId]);
                    }, 0);
                }
            );
        }
        else {
            this.globals.onHeaderButtonClick()
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                () => {
                    const confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
                        data: new ConfirmDialogData(
                            'account.dialog.confirm_anonymous_leave',
                            undefined,
                            'primary',
                            undefined,
                            false,
                            'general.text.confirm',
                        )
                    });
            
                    confirmDialogRef.afterClosed().pipe(take(1)).subscribe(
                        (result: ConfirmDialogResult) => {
                            if (result === 'continue') {
                                this.auth.revokeAuthentication();
                            }
                        }
                    );
                }
            );
        }

        this.globals.appVisibilityChanged$
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                (visible) => {
                    if (!visible) {
                        this._endTabSession();
                    }
                    else {
                        if (this.selectedWikiTabIndex !== undefined) {
                            const foundTab = this.wikiTabs[this.selectedWikiTabIndex];
                            if (foundTab) {
                                this.currentTabSession = {
                                    wikiTabId: foundTab.id,
                                    wikiTabTitle: foundTab.title,
                                    startTime: new Date()
                                };
                            }
                        }
                    }
                });

        // Wait with calculating the header position until after the opening animation is done.
        setTimeout(() => {
            this.moving = false;
        }, this.ANIMATION_DELAY);

        this.route.params.pipe(take(1)).subscribe(params => {
            this.organisationId = params.organisationId;
            this.eventId = params.eventId;

            this.globals.getStyling(this.organisationId, this.eventId).pipe(take(1)).subscribe(styling => {
                this.styling = styling;
            });

            this._loadWikiTabs();
        });

        this.route.fragment.pipe(takeUntil(this.destroy$)).subscribe(
            (fragment) => {
                if (fragment && this.wikiTabs && this.wikiTabs.length > +fragment) {
                    this.onClickTab(+fragment);
                } else if (!fragment) {
                    this.onClickToOverview();
                }
            }
        );

        if (navigator.onLine) {
            this._startChatPolling(0);
        }

        this.connection.onlineState$.pipe(takeUntil(this.destroy$))
            .subscribe(connected => {
                if (this.offlineNoCache && connected) {
                    this._loadWikiTabs();
                    this.offlineNoCache = false;
                }

                if (connected) {
                    this._startChatPolling(0);
                } else {
                    this._stopChatPolling();
                }
            });

        if (this.connection.connectionBarSize) {
            this.noConnectionBarOffset = this.connection.connectionBarSize;
            this.onScroll();
        }

        this.connection.connectionBarSize$.pipe(takeUntil(this.destroy$))
            .subscribe(size => { this.noConnectionBarOffset = size; this.onScroll(); });
    }

    ngOnDestroy(): void {
        this._endTabSession();
        this.globals.hideTooltips();
        this.hasUnreadChat = false;
        this._stopChatPolling();
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    onClickOverviewTab(id: number): void {
        const foundIndex = this.wikiTabs.findIndex(x => x.id === id);

        if (foundIndex !== undefined) {
            this.onOverview = false;
            this.onClickTab(foundIndex, true);
        }
    }

    onClickToOverview(): void {
        this._endTabSession();

        this.onOverview = true;
        this.onGlossary = false;
        this.onFaq = false;
        this.filterFlags = false;
        this.animating = true;
        this.selectedWikiTabIndex = undefined;
        this.globals.hideTooltips();
        this._loadWikiTabs();
        setTimeout(() => {
            this.animating = false;
        }, this.ANIMATION_DELAY);

        this.router.navigate([], { relativeTo: this.route, fragment: undefined });
    }

    onClickToGlossary(): void {
        this._endTabSession();

        this.onOverview = false;
        this.onGlossary = true;
        this.onFaq = false;
        this.filterFlags = false;
        this.animating = true;
        this.selectedWikiTabIndex = undefined;
        this.globals.hideTooltips();
        setTimeout(() => {
            this.animating = false;
        }, this.ANIMATION_DELAY);

        this.glossaryTabRef?.nativeElement.scrollIntoView({
            behavior: 'smooth',
            inline: 'center',
            block: 'center'
        });

        this.router.navigate([], { relativeTo: this.route, fragment: undefined });
    }

    onClickToFaq(): void {
        this._endTabSession();

        this.onOverview = false;
        this.onGlossary = false;
        this.onFaq = true;
        this.filterFlags = false;
        this.animating = true;
        this.selectedWikiTabIndex = undefined;
        this.globals.hideTooltips();
        setTimeout(() => {
            this.animating = false;
        }, this.ANIMATION_DELAY);

        setTimeout(() => {
            this.faqTabRef?.nativeElement.scrollIntoView({
                behavior: 'smooth',
                inline: 'center',
                block: 'center'
            });
        }, 0);

        this.router.navigate([], { relativeTo: this.route, fragment: undefined });
    }

    onClickTab(index: number, fromOverview = false): void {
        if (index === this.selectedWikiTabIndex) {
            return;
        }
        const foundTab = this.wikiTabs[index];
        this.filterFlags = false;
        this.onGlossary = false;
        this.onFaq = false;
        this.showingPlanningSearchbar = false;

        this.globals.hideTooltips();

        if (foundTab) {
            if (this.currentTabSession) {
                this._endTabSession();
            }

            this.currentTabSession = {
                wikiTabId: foundTab.id,
                wikiTabTitle: foundTab.title,
                startTime: new Date()
            };

            this.analytics.track('WikiTabOpen', {
                wikiTabId: foundTab.id,
                wikiTabTitle: foundTab.title,
                openedFromOverview: fromOverview
            }, this.organisationId, this.eventId);
        }

        if (foundTab && this.renderedTabs.indexOf(foundTab) < 0) {
            this.renderedTabs[index] = foundTab;
            setTimeout(() => {
                this.previousWikiTabIndex = this.selectedWikiTabIndex;
                this.selectedWikiTabIndex = index;
                this._scrollToSelectedTab();
                this.animating = true;
                setTimeout(() => {
                    this.animating = false;
                }, this.ANIMATION_DELAY);
            }, 100);
        }
        else {
            this.previousWikiTabIndex = this.selectedWikiTabIndex;
            this.selectedWikiTabIndex = index;
            this._scrollToSelectedTab();
            this.animating = true;
            setTimeout(() => {
                this.animating = false;
            }, this.ANIMATION_DELAY);
        }

        if (this.route.snapshot.fragment && +this.route.snapshot.fragment !== index) {
            window.history
                .pushState({ additionalInformation: 'Navigated wiki' }, 'Catchphrase', window.location.href.split('#')[0] + `#${index}`);
        }

        setTimeout(() => {
            const selectedTab = this.tabs.find(x => +(x.nativeElement.dataset.index || 0) === this.selectedWikiTabIndex);
            selectedTab?.nativeElement.scrollIntoView({
                behavior: 'smooth',
                inline: 'center',
                block: 'center'
            });
        }, 0);
    }

    getExpectedContent<T>(tab: IWikiTabModel | undefined): T {
        if (!tab || !tab.content) {
            throw new Error('no wiki tab');
        }

        return tab.content as unknown as T;
    }

    getTabTitle(tabName: string): string {
        if (!tabName) {
            return '';
        }

        if (tabName.length > 12) {
            return tabName.substring(0, 12) + '...';
        }

        return tabName;
    }

    getIconForTabType(type: TabType): string {
        switch (type) {
        case 'Contact':
            return 'users';
        case 'Other':
            return 'layout';
        case 'Planning':
            return 'clock';
        case 'Procedure':
            return 'file-text';
        }
    }

    goToChat(): void {
        this._endTabSession();
        this.router.navigate([this.organisationId, this.eventId, 'chat']);
    }

    isActive(index: number): boolean {
        return this.selectedWikiTabIndex === index;
    }

    private _loadWikiTabs(): void {
        this.loading = true;
        this.data.getWikiTabs(this.organisationId, this.eventId).pipe(take(1)).subscribe({
            next: (response) => {
                this.eventName = response.eventName;
                this.wikiTabs = response.wikiTabs;
                this.glossaryTerms = response.glossaryTerms;
                this._getLastChangedGlossary();
                this.faqCategories = response.faqCategories;
                this._getLastChangedFaq();
                this.renderedTabs = new Array(this.wikiTabs.length);
                this._sanitizeTabs();

                if (this.wikiTabs.length === 0 && this.glossaryTerms.length === 0 && this.faqCategories.length === 0) {
                    this.router.navigate([this.organisationId, this.eventId]);
                }

                if (this.route.snapshot.fragment) {
                    const fragment = +this.route.snapshot.fragment;

                    if (fragment && this.wikiTabs && this.wikiTabs.length > fragment) {
                        this.onOverview = false;
                        this.onClickTab(fragment);
                    }
                }

                this.loading = false;
            }, error: () => {
                this.loading = false;

                if (!this.connection.online) {
                    this.offlineNoCache = true;
                    return;
                }

                this.error = true;

                setTimeout(() => {
                    this.router.navigate([this.organisationId, this.eventId]);
                }, 2000);
            }
        });
    }

    private _scrollToSelectedTab(): void {
        const selectedTab = this.tabs.find(x => +(x.nativeElement.dataset.index || 0) === this.selectedWikiTabIndex);
        selectedTab?.nativeElement.scrollIntoView({
            behavior: 'smooth',
            inline: 'center',
            block: 'center'
        });
    }

    private _startChatPolling(delay = 10000): void {
        this._chatNotificationPoll = timer(delay)
            .pipe(
                take(1),
                concatMap(() => this.globals.pollUserChatMessages(this.organisationId, this.eventId)),
                errorLogTap(this.logging, this.organisationId)
            ).subscribe({
                next: (result) => {
                    this.hasUnreadChat = result;

                    this.chatPollingFailCount = 0;
                    this._startChatPolling(this._pollingDelay);
                }, error: () => {
                    this.chatPollingFailCount++;

                    if (this.chatPollingFailCount >= 5) {
                        // Timeout for 10 minutes before retrying.
                        setTimeout(() => {
                            this._startChatPolling();
                        }, 60 * this._pollingDelay);
                    }
                }
            });
    }

    private _stopChatPolling(): void {
        this._chatNotificationPoll?.unsubscribe();
        this._chatNotificationPoll = undefined;
    }

    private _sanitizeTabs(): void {
        if (this.glossaryTerms) {
            this.glossaryTerms.forEach(x => x._sanitizedDescription = this._trustContent(x.description));
        }

        if (this.faqCategories) {
            this.faqCategories.forEach(x => x.items.forEach(y => y._sanitizedAnswer = this._trustContent(y.answer)));
        }

        this.wikiTabs.forEach(x => {
            x.sanitizedIntroductionText = this._trustContent(x.introductionText);

            switch (x.type) {
            case 'Other':
                const otherContent = x.content as OtherContent;
                otherContent.sections.forEach(y => {
                    if (y.type === BriefingSectionType.text) {
                        y._sanitizedContent = this._trustContent(y.content);
                    }
                });
                break;
            case 'Planning':
                const planningContent = x.content as PlanningContent;
                planningContent.blocks.forEach(y => y._sanitizedDetails = this._trustContent(y.details));
                break;
            case 'Procedure':
                const procedureContent = x.content as ProcedureContent;
                procedureContent.procedures.forEach(y => y._sanitizedDetails = this._trustContent(y.details));
                break;
            default:
                // Contacts don't need sanitizing because no styling is involved
                break;
            }
        });
    }

    private _endTabSession(): void {
        if (this.currentTabSession) {
            const sessionData = this.currentTabSession;
            sessionData.endTime = new Date();
            this.analytics.track('WikiTabSession', sessionData, this.organisationId, this.eventId);
        }
        this.currentTabSession = undefined;
    }

    private _trustContent(content: string | undefined): SafeHtml {
        if (content) {
            return this.sanitizer.bypassSecurityTrustHtml(content);
        }
        return '';
    }

    private _getLastChangedGlossary(): void {
        if (!this.glossaryTerms || this.glossaryTerms.length === 0) {
            return;
        }

        this.glossaryLastChangedOn = this.glossaryTerms.reduce((a, b) => a.lastSavedOn > b.lastSavedOn ? a : b).lastSavedOn;
    }

    private _getLastChangedFaq(): void {
        if (!this.faqCategories || this.faqCategories.length === 0) {
            return;
        }

        this.faqLastChangedOn = this.faqCategories.reduce((a, b) => {
            if (!a.publishDate) {
                return b;
            }

            if (!b.publishDate) {
                return a;
            }

            return a.publishDate > b.publishDate ? a : b;
        }).publishDate;
    }
}

export interface WikiTabSession {
    wikiTabId: number;
    wikiTabTitle: string;
    startTime: Date;
    endTime?: Date;
}
