import { EndPoint } from '@core/constants';
import { Injectable } from '@angular/core';
import { GeneralDataService } from '@core/services/general-data.service';
import { Observable, of } from 'rxjs';
import { map, share, switchMap } from 'rxjs/operators';

@Injectable()
export class FeatureToggleService extends GeneralDataService {
    reportsActive = false;
    private readonly FEATURE_TOGGLE_CACHE_DURATION = 1000 * 60 * 60 * 24; // One day
    private _observables: { [stringifiedKey: string]: Observable<boolean> } = {};

    isFeatureActive(organisationId: number, feature: Feature): Observable<boolean> {
        const storageKey = `feature-key-${organisationId}-${feature}`;

        const cachedResult = sessionStorage.getItem(storageKey);

        if (cachedResult !== null) {
            const cache = JSON.parse(cachedResult);

            if (cache && cache.active && cache.timestamp && new Date(cache.timestamp)
                && new Date().getTime() - new Date(cache.timestamp).getTime() <= this.FEATURE_TOGGLE_CACHE_DURATION) {
                return of(cache.active);
            }
        }

        if (this._observables[storageKey]) {
            return this._observables[storageKey];
        }

        this._observables[storageKey] = this.getGenericItem<FeatureResult>(EndPoint.organisation, `feature/${organisationId}/${feature}`)
            .pipe(share(), map(result => {
                sessionStorage.setItem(`feature-${feature}-${organisationId}`,
                    JSON.stringify({ active: result.active, timestamp: new Date() }));
                return result.active;
            }));

        return this._observables[storageKey];
    }

    isReportsActive(organisationId: number): Observable<boolean> {
        const storageKey = `feature-reports-${organisationId}-active`;
        const cachedResult = sessionStorage.getItem(storageKey);

        if (cachedResult !== null) {
            const cache = JSON.parse(cachedResult);

            if (cache && cache.active && cache.timestamp && new Date(cache.timestamp)
                && new Date().getTime() - new Date(cache.timestamp).getTime() <= this.FEATURE_TOGGLE_CACHE_DURATION) {
                this.reportsActive = cache.active;
                return of(cache.active);
            }
        }

        if (this._observables[storageKey]) {
            return this._observables[storageKey];
        }

        this._observables[storageKey] = this.isFeatureActive(organisationId, 'Reports').pipe(share(), switchMap(result => {
            if (result) {
                this.reportsActive = result;
                return of(result);
            } else {
                return this.getGenericItem<{ show: boolean }>(EndPoint.reports, `${organisationId}/show`)
                    .pipe(map(innerResult => {
                        sessionStorage.setItem(`feature-reports-${organisationId}-active`,
                            JSON.stringify({ active: innerResult.show, timestamp: new Date() }));
                        this.reportsActive = innerResult.show;
                        return innerResult.show;
                    }));
            }
        }));

        return this._observables[storageKey];
    }
}

interface FeatureResult {
    active: boolean;
}

export type Feature = 'Wiki' | 'Checklists' | 'LiveChat' | 'PdfUpload' | 'Reports' | 'GlossaryInjection' | 'ReportLocations'
    | 'Videos';
