import { trigger, transition, query, style, animate } from '@angular/animations';

export const fadeInAnimation = trigger('fadeInAnimation', [
    transition('* <=> *', [
        query(':enter',
            [
                style({ opacity: 0 }),
                animate('0.5s ease-out',
                    style({ opacity: 1 }))],
            { optional: true })
    ])
]);
