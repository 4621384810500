import { AuthorizationGuard } from '@core/guards';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { AccountRoutingModule } from './account-routing.module';
import { AccountDataService, ValidCodeGuard } from '@account/services';
import {
    RegisterComponent, InviteComponent, RequestNewInviteComponent,
    ConfirmComponent, AccountComponent, InviteLinkModalComponent,
    SettingsPanelComponent, UsedInviteComponent, ActivateCmsAccountComponent,
} from '@account/components';

@NgModule({
    declarations: [
        RegisterComponent,
        InviteComponent,
        RequestNewInviteComponent,
        ConfirmComponent,
        AccountComponent,
        InviteLinkModalComponent,
        SettingsPanelComponent,
        UsedInviteComponent,
        ActivateCmsAccountComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        AccountRoutingModule
    ],
    exports: [
        SettingsPanelComponent
    ],
    providers: [
        AccountDataService,
        ValidCodeGuard,
        AuthorizationGuard
    ]
})
export class AccountModule { }
