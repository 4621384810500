import { CelebrateService } from '@core/services';
import { Component, OnInit, OnDestroy, Inject, PLATFORM_ID, Renderer2, ElementRef } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'cp-celebration',
    templateUrl: './celebration.component.html',
    styleUrls: ['./celebration.component.scss']
})
export class CelebrationComponent implements OnInit, OnDestroy {
    canvas?: HTMLCanvasElement;

    confettiCanvas?: any;

    confettiLib?: any;
    celebrate$: Observable<number>;
    destroy = new Subject();
    destroy$ = this.destroy.asObservable();

    constructor(
        @Inject(PLATFORM_ID) private platformID: Object,
        private renderer2: Renderer2,
        private celebrateService: CelebrateService,
        private elementRef: ElementRef) {
        this.celebrate$ = this.celebrateService.celebrate$;
    }

    ngOnInit(): void {
        if (isPlatformBrowser(this.platformID)) {
            this.celebrate$.pipe(takeUntil(this.destroy$))
                .subscribe((duration) =>
                    this.celebrate(duration));
        }
    }
    ngOnDestroy(): void {
        this.destroy.next(true);
    }

    async importCanvas(): Promise<void> {
        this.confettiLib = await import('canvas-confetti');
        this.canvas = this.renderer2.createElement('canvas');
    }

    celebrate(duration: number): void {
        let checkCanvasConfettiExists = async () => Promise.resolve(); // set this to resolve regardless if confetti already exists
        if (!this.confettiCanvas) { // if not already imported, replace with importing function
            checkCanvasConfettiExists = this.importCanvas;
        }
        checkCanvasConfettiExists.call(this) // bind to 'this' as the importCanvas function will need the correct 'this'
            .then(() => {
                this.renderer2.appendChild(this.elementRef.nativeElement, this.canvas); // append the canvas

                this.confettiCanvas = this.confettiLib.create(this.canvas, { resize: true }); // create the confetti canvas
                const end = Date.now() + duration; // set the end time
                const interval = setInterval(() => {
                    if (Date.now() > end) { // if time reached then clear the interval
                        clearInterval(interval);

                        // Remove the canvas after the particles have despawned.
                        setTimeout(() => this.renderer2.removeChild(this.elementRef.nativeElement, this.canvas), 1000);
                    }
                    this.confettiCanvas({ // if time hasn't passed then call the start the confetti
                        startVelocity: 10,
                        spread: 360,
                        ticks: 600,
                        origin: {
                            x: Math.random(),
                            // since they fall down, start a bit higher than random
                            y: 0
                        }
                    });
                }, 200);
            });
    }
}
