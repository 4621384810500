<div class="map-container">
    <div class="map-header" [style.background-color]='styling?.colorTwo || "#161C42"'
        [class.darkText]='styling?.colorTwoText === "Dark"'>
        <div class="title">
            {{ content!.title }}
        </div>
        <div class="fullscreen" (click)="maximize()">
            <cp-icon name="maximize" size="small"></cp-icon>
        </div>
    </div>
    <iframe class="map" [class.wizzy]="content.mapType === 'Wizzy'"
        *ngIf="showMap && content && content.mapUrl"
        [src]="src" [height]="content!.height"></iframe>
</div>
