import { AnswerModel } from './answer-model';
import { QuestionContentType } from '@core/constants';

export class QuestionModel implements IQuestionModel {
    finished?: boolean;
    selectedAnswers?: number[];
    correctAnswers?: number[];
    feedback?: string;

    constructor(
        public questionType: QuestionContentType,
        public questionText: string,
        public answers: AnswerModel[],
        public multipleChoice: boolean,
        public position: number,
        public imageBlobId?: string,
    ) { }
}

export interface IQuestionModel {
    questionType: QuestionContentType;
    questionText: string;
    imageBlobId?: string;
    answers: AnswerModel[];
    multipleChoice: boolean;
    position: number;

    finished?: boolean;
    selectedAnswers?: number[];
    correctAnswers?: number[];
    feedback?: string;
}
