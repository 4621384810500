import { DateUtils } from '@core/utils';
import { MatDialog } from '@angular/material/dialog';
import { concatMap, take, takeUntil } from 'rxjs/operators';
import { EndPoint } from '@core/constants';
import {
    AlertService, AuthenticationService, ConnectionService, GeneralDataService, GlobalsService, GuidedTourService, LoggingService
} from '@core/services';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { OrganisationModel } from './models';
import { ScreensizeDialogComponent } from '@shared/components';
import { Subject, Subscription, timer } from 'rxjs';
import { errorLogTap } from '@core/handlers';

@Component({
    templateUrl: './organisation-overview.component.html',
    styleUrls: ['./organisation-overview.component.scss']
})
export class OrganisationOverviewComponent implements OnInit, AfterViewInit, OnDestroy {
    destroy$: Subject<boolean> = new Subject<boolean>();

    public readonly DATE_FORMAT = 'fullDate';

    singularEventOrganisations!: OrganisationModel[];
    ongoingEventOrganisations!: OrganisationModel[];

    offline = false;
    offlineNoCache = false;
    loading = true;

    private readonly ORGANISATION_POLLING_DELAY = 30000;
    private _organisationPoll?: Subscription;
    private _pollingFailCount = 0;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private auth: AuthenticationService,
        private data: GeneralDataService,
        public globals: GlobalsService,
        private dialog: MatDialog,
        private connection: ConnectionService,
        private alert: AlertService,
        private tour: GuidedTourService,
        private logging: LoggingService,
    ) {
        globals.hideHeaderAndFooter();
    }

    get firstName(): string | undefined {
        return this.auth.claims?.given_name;
    }

    ngOnInit(): void {
        this.offline = !this.connection.online;

        this._getOrganisations();

        this.connection.onlineState$.pipe(takeUntil(this.destroy$))
            .subscribe(connected => {
                if (connected && this.offline) {
                    this._getOrganisations();
                    this.offlineNoCache = false;
                }

                this.offline = !connected;
            });
    }

    ngAfterViewInit(): void {
        document.querySelector('body')?.classList.add('cp-blue');
    }

    ngOnDestroy(): void {
        document.querySelector('body')?.classList.remove('cp-blue');
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
        this._organisationPoll?.unsubscribe();
        this._organisationPoll = undefined;
    }

    onClickCard(organisation: OrganisationModel, isOngoing: boolean): void {
        // Only redirect for active or ongoing organisations
        if (organisation.amountPlannedEvents || isOngoing) {
            this.router.navigate([organisation.id]);
        }
    }

    daysLeft(organisation: OrganisationModel): number | undefined {
        if (organisation.daysLeftCalculated) {
            return organisation.daysLeft;
        }

        if (organisation.forceToday) {
            return 0;
        }

        if (!organisation.referenceDate) {
            return undefined;
        }

        organisation.daysLeft = DateUtils.CalculateDifferenceInDaysBetweenDates(organisation.referenceDate, new Date());
        organisation.daysLeftCalculated = true;
        return organisation.daysLeft;
    }

    private _getOrganisations(): void {
        this.data.getGenericItem<OrganisationModel[]>(EndPoint.account, 'organisations')
            .pipe(take(1)).subscribe({
                next: (result) => {
                    // If there is only one active organisation, it has active events and we didn't manually go here,
                    // redirect there immediately
                    if (result.length === 1 && result[0].amountPlannedEvents && !this.route.snapshot.queryParams.manual) {
                        this.router.navigate([result[0].id]);
                    }
                    else {
                        this.singularEventOrganisations = result.filter(x => !x.eventName || x.eventDate);
                        this.ongoingEventOrganisations = result.filter(x => x.eventName && !x.eventDate);
                        this.loading = false;

                        if (screen.width > 500 && !sessionStorage.getItem('screen-size-warning')) {
                            sessionStorage.setItem('screen-size-warning', 'true');
                            this.dialog.open(ScreensizeDialogComponent, {
                                panelClass: 'small-dialog'
                            });
                        }
                        this._pollOrganisations();

                        this.tour.addTourStep(1, 1, 1, 'tour.text.welcome_message');
                        this.tour.addTourStep(4, 1, 1, 'tour.text.settings_message', '#settings-button');
                        this.tour.startTour();
                    }
                },
                error: () => {
                    if (this.offline) {
                        this.offlineNoCache = true;
                    }
                    else {
                        this._pollOrganisations();
                    }
                    this.loading = false;
                }
            });
    }

    private _pollOrganisations(): void {
        this._organisationPoll = timer(this.ORGANISATION_POLLING_DELAY)
            .pipe(
                take(1),
                errorLogTap(this.logging),
                concatMap(() => this.data.getGenericItem<OrganisationModel[]>(EndPoint.account, 'organisations'),
            )).subscribe({
                next: (result) => {
                    // If there is only one active organisation, it has active events and we didn't manually go here,
                    // redirect there immediately
                    if (result.length === 1 && result[0].amountPlannedEvents && !this.route.snapshot.queryParams.manual) {
                        this.router.navigate([result[0].id]);
                    }
                    else {
                        const singularEventOrganisations = result.filter(x => !x.eventName || x.eventDate);
                        const ongoingEventOrganisations = result.filter(x => x.eventName && !x.eventDate);
                        if (singularEventOrganisations.some(x =>
                            !this.singularEventOrganisations.some(y => this._organisationEquals(x, y)))
                                || ongoingEventOrganisations.some(x =>
                                    !this.ongoingEventOrganisations.some(y => this._organisationEquals(x, y)))) {
                            this.singularEventOrganisations = singularEventOrganisations;
                            this.ongoingEventOrganisations = ongoingEventOrganisations;
                        }
                    }
                    this._pollOrganisations();
                }, error: () => {
                    this._pollingFailCount++;

                    if (this._pollingFailCount >= 5) {
                        this.alert.postMessage('account.error.exceeded_organisations_polling_fail_count', 'Error', 3000);

                        // Timeout for 5 minutes before retrying.
                        setTimeout(() => {
                            this._pollOrganisations();
                        }, 10 * this.ORGANISATION_POLLING_DELAY);
                    }
                }
            });
    }

    private _organisationEquals(x: OrganisationModel, y: OrganisationModel): boolean {
        return x.id === y.id
            && x.name === y.name
            && x.backgroundColour === y.backgroundColour
            && x.hasLogo === y.hasLogo
            && x.eventName === y.eventName
            && x.forceToday === y.forceToday
            && x.referenceDate === y.referenceDate
            && x.eventDate === y.eventDate
            && x.endDate === y.endDate
            && x.evaluationEndDate === y.evaluationEndDate
            && x.inEvaluationPeriod === y.inEvaluationPeriod
            && x.amountPlannedEvents === y.amountPlannedEvents
            && x.amountEvaluationTasks === y.amountEvaluationTasks
            && x.hasUnreadNotifications === y.hasUnreadNotifications;
    }
}
