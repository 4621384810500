import { ChatGroupComponent } from '@chat/components';
import { NoConnectionBarComponent } from '@shared/components';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil, filter, take, switchMap, first } from 'rxjs/operators';
import { HeaderModel, MaintenanceState } from '@core/models';
import {
    Component, OnInit, OnDestroy, ChangeDetectorRef,
    ViewChild, TemplateRef, HostListener, ApplicationRef
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GlobalsService, AuthenticationService, ConnectionService, WhitelabelService, LoggingService } from '@core/services';
import { concat, interval, Subject } from 'rxjs';
import { Router, RouterOutlet, RoutesRecognized } from '@angular/router';
import {
    slideInTopAnimation, slideInBottomAnimation, slideInBottomAnimationPositioned,
    slideOverBottom, slideOutBottom
} from '@core/animations';
import { OverlayContainer } from '@angular/cdk/overlay';
import { transition, trigger } from '@angular/animations';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { BriefingViewComponent } from '@briefing/components';
import { KnowledgeTestComponent } from '@knowledge-test/components';
import { EvaluationComponent } from '@evaluation/evaluation.component';
import { WikiViewComponent } from '@wiki/components';
import { SignInComponent } from './sign-in/components';
import { InviteComponent, RegisterComponent } from '@account/components';
import { MaintenanceDialogComponent } from './components';
import { errorLogTap } from '@core/handlers';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    animations: [
        slideInTopAnimation,
        slideInBottomAnimation,
        slideInBottomAnimationPositioned,
        trigger('routeAnimations', [
            transition('Wiki => Overview', slideOutBottom),
            transition('* => Wiki', slideOverBottom)
        ])
    ]
})
export class AppComponent implements OnInit, OnDestroy {
    @ViewChild('updateDialog') updateTemplate!: TemplateRef<unknown>;
    @ViewChild(NoConnectionBarComponent) noConnectionBar?: NoConnectionBarComponent;

    initialisingLanguage = false;
    animation = 'animation';
    isOffline = false;

    header: HeaderModel | undefined;
    showFooter = false;
    showSettings = false;

    organisationId?: number;
    eventId?: number;

    updateAvailable = false;
    updatePromptShown = false;
    isInTask = false;
    isOnSignInScreen = false;
    promptOnRoute = false;
    hideBanners = false;
    hideUpdate = false;
    updatingApp = false;
    maintenanceDialogActive = false;
    maintenanceState?: MaintenanceState;
    title = 'Catchphrase';

    private destroy$ = new Subject<boolean>();

    constructor(
        translate: TranslateService, private globals: GlobalsService, auth: AuthenticationService,
        appRef: ApplicationRef, private router: Router, overlayContainer: OverlayContainer,
        private dialog: MatDialog, private cdr: ChangeDetectorRef, private updates: SwUpdate,
        private connection: ConnectionService, private whitelabel: WhitelabelService,
        private logging: LoggingService,
    ) {
        this.initialisingLanguage = true;
        translate.setDefaultLang('gb');
        let chosenLanguage = localStorage.getItem('lang') || auth.claims?.language_code;
        if (!chosenLanguage) {
            const preferedLanguage = navigator.language.toUpperCase();
            // If the user has dutch selected as their prefered language default to dutch
            if (preferedLanguage === 'NL' || preferedLanguage === 'NL-NL' || preferedLanguage === 'NL-BE') {
                chosenLanguage = 'nl';
            }
        }

        translate.use(chosenLanguage || 'gb').pipe(take(1), errorLogTap(this.logging, this.organisationId))
            .subscribe({
                next: () => this.initialisingLanguage = false,
                error: () => this.initialisingLanguage = false
            });

        const overlayContainerClasses = ['cp-light', 'wrapper'];

        if (this.isWebkit) {
            overlayContainerClasses.push('webkit');
        }

        if (this.isSafari) {
            overlayContainerClasses.push('safari');
        }

        if (this.isIOS) {
            overlayContainerClasses.push('ios');
        }

        overlayContainer.getContainerElement()
            .classList.add(...overlayContainerClasses);

        const appIsStable$ = appRef.isStable.pipe(first(isStable => isStable === true));
        const everyTenMinutes$ = interval(10 * 60 * 1000);
        const everySixHours$ = interval(6 * 60 * 60 * 1000);

        // App no longer seems to be come stable, reinstate this when figured out
        const pollOnceStableSixHours$ = concat(appIsStable$, everySixHours$);
        const pollOnceStablTenMinutes$ = concat(appIsStable$, everyTenMinutes$);

        // Start polling maintenance every 10 minutes.
        pollOnceStablTenMinutes$.pipe(takeUntil(this.destroy$), switchMap(_ => this.globals.pollMaintenance()))
            .subscribe(maintenanceState => {
                this.maintenanceState = maintenanceState;

                // Show the maintenance dialog if we're not on the sign in screen and maintenance is active.
                if (this.maintenanceState.maintenanceActive && !this.isOnSignInScreen) {
                    this._activateMaintenanceDialog();
                }
            });

        if (updates.isEnabled) {
            pollOnceStableSixHours$.pipe(takeUntil(this.destroy$)).subscribe(() => {
                updates.checkForUpdate();
            });

            updates.versionUpdates.pipe(takeUntil(this.destroy$), filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'))
                .subscribe(_ => {
                    if (!this.isInTask) {
                        this._updatePrompt();
                    } else {
                        this.promptOnRoute = true;
                    }
                });

            updates.unrecoverable.pipe(takeUntil(this.destroy$)).subscribe(() => document.location.reload());

            updates.checkForUpdate();
        }
    }

    get showUpdateWindow(): boolean {
        return this.updateAvailable && !this.hideUpdate;
    }

    get isWebkit(): boolean {
        return navigator.userAgent.indexOf('Chrome') !== -1
            || this.isSafari
            || navigator.userAgent.indexOf('Opera') !== -1;
    }

    get isSafari(): boolean {
        return navigator.userAgent.indexOf('Safari') !== -1;
    }

    get isIOS(): boolean {
        return /iPad|iPhone|iPod/.test(navigator.userAgent);
    }

    get isStandaloneiOS(): boolean {
        const standalone = 'standalone';

        const navigator: any = window.navigator;

        return ('standalone' in window.navigator) && navigator[standalone];
    }

    @HostListener('window:offline')
    setNetworkOffline(): void {
        this.connection.setOffline();
    }

    @HostListener('window:online')
    setNetworkOnline(): void {
        this.connection.setOnline();
    }

    @HostListener('document:visibilitychange')
    onVisibilityChanged() {
        if (document.visibilityState === 'visible') {
            this.globals.appVisible();
        }
        else {
            this.globals.appNotVisible();
        }
    }

    ngOnInit(): void {
        this.globals.headerChanged$.pipe(takeUntil(this.destroy$))
            .subscribe(newHeader => { this.header = newHeader; this.cdr.markForCheck(); });

        this.globals.footerChanged$.pipe(takeUntil(this.destroy$))
            .subscribe(value => { this.showFooter = value; this.cdr.markForCheck(); });

        this.globals.settingsChanged$.pipe(takeUntil(this.destroy$))
            .subscribe(value => this.showSettings = value);

        this.isOffline = !this.connection.online;
        setTimeout(() => {
            this.connection.setConnectionBarSize(this.noConnectionBar?.height);
        }, 200);

        this.whitelabel.forceSubdomainAcive();

        this.connection.onlineState$.pipe(takeUntil(this.destroy$)).subscribe(connected => {
            this.isOffline = !connected;
            setTimeout(() => {
                this.connection.setConnectionBarSize(this.noConnectionBar?.height);
            });
        });

        this.router.events
            .pipe(
                filter(event => event instanceof (RoutesRecognized)),
                takeUntil(this.destroy$)
            )
            .subscribe(event => {
                const routeEvent = event as RoutesRecognized;
                const url = routeEvent.url;
                const splitUrl = url.split('/');

                if (splitUrl.length > 1 && !isNaN(+splitUrl[1])) {
                    this.organisationId = +splitUrl[1];
                } else if (splitUrl.length > 2 && splitUrl[1].toLowerCase() === 'test') {
                    this.organisationId = +splitUrl[2];
                } else {
                    this.organisationId = undefined;
                }

                if (splitUrl.length > 2 && splitUrl[1].toLowerCase() !== 'test' && !isNaN(+splitUrl[2])) {
                    this.eventId = +splitUrl[2];
                } else {
                    this.eventId = undefined;
                }
            });

        this.globals.appVisible();
        this.globals.lockScreenOrientation();
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    prepareRoute(outlet: RouterOutlet): string {
        return outlet && outlet.activatedRouteData && outlet.activatedRouteData[this.animation];
    }

    updateApp(): void {
        this.updatingApp = true;
        this.updates.activateUpdate().then(() => document.location.reload())
            .catch(() => document.location.reload())
            .finally(() => document.location.reload());
    }

    resolveActivatedComponent(event: unknown): void {
        this.isInTask = event instanceof (BriefingViewComponent)
            || event instanceof (KnowledgeTestComponent)
            || event instanceof (SignInComponent)
            || event instanceof (RegisterComponent)
            || event instanceof (InviteComponent)
            || event instanceof (ChatGroupComponent);

        // Ensure that any user that logs into the app while maintenance is active gets the maintenance dialog.
        if (this.maintenanceState && this.maintenanceState.maintenanceActive && !this.maintenanceDialogActive
            && this.isOnSignInScreen && !(event instanceof (SignInComponent))) {
            this._activateMaintenanceDialog();
        }

        this.isOnSignInScreen = event instanceof (SignInComponent);

        if (!this.isInTask && this.promptOnRoute) {
            this._updatePrompt();
        }

        this.hideBanners = this.isInTask;
        this.hideUpdate = this.hideBanners || event instanceof (WikiViewComponent)
            || event instanceof (EvaluationComponent);

        if (!this.maintenanceDialogActive) {
            // Call the maintenance manually on first load, after establishing on what screen we are.
            this.globals.pollMaintenance()
                .subscribe(maintenanceState => {
                    this.maintenanceState = maintenanceState;

                    // Show the maintenance dialog if we're not on the sign in screen and maintenance is active.
                    if (this.maintenanceState.maintenanceActive && !this.isOnSignInScreen) {
                        this._activateMaintenanceDialog();
                    }
                });
        }
    }

    private _updatePrompt(): void {
        if (!this.updatePromptShown) {
            this.updatePromptShown = true;
            const dialogRef = this.dialog.open(this.updateTemplate, {
                disableClose: true,
                backdropClass: 'darkened-backdrop'
            });
            this.promptOnRoute = false;

            dialogRef.afterClosed().pipe(take(1)).subscribe((result) => {
                this.updatePromptShown = false;
                if (result) {
                    this.updateApp();
                } else {
                    this.updateAvailable = true;
                }
            });
        }
    }

    private _activateMaintenanceDialog(): void {
        if (this.maintenanceDialogActive) {
            return;
        }

        const dialogRef = this.dialog.open(MaintenanceDialogComponent, {
            data: this.maintenanceState,
            autoFocus: false,
            closeOnNavigation: false,
            disableClose: true,
            hasBackdrop: true,
            restoreFocus: true
        });
        this.maintenanceDialogActive = true;

        dialogRef.afterClosed().pipe(take(1), takeUntil(this.destroy$)).subscribe(
            () => this.maintenanceDialogActive = false
        );
    }
}
