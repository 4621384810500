<a [routerLink]="fromInviteLink ? ['/'] : ['/account/invite']" queryParamsHandling="preserve" class="back-button">
    <button mat-icon-button color="primary">
        <cp-icon name='arrow-left' color='primary'></cp-icon>
    </button>
</a>
<div class="row justify-content-center">
    <div class="col-xl-4 col-lg-6 col-m-8 col-sm-12">
        <div class="text-center pt-5 col-12">
            <cp-logo maxWidth="50px" [translate]="'account.text.signup-to-catchphrase'">
                Sign up with Catchphrase
            </cp-logo>
        </div>

        <div class="d-flex" *ngIf="loading; else form">
            <cp-delayed-spinner class="m-auto"></cp-delayed-spinner>
        </div>

        <ng-template #form>
            <div class="pt-4">
                <div class="row text-center pb-2">
                    <span class="transparent-text col-10 offset-1" [translate]="'account.text.register_message'">
                        Sign up using the form below
                    </span>
                </div>

                <form [formGroup]="registerForm" (ngSubmit)="register()">
                    <mat-form-field class="col-10 offset-1">
                        <mat-label [translate]="'general.text.preferred_language'">
                            Preferred Language
                        </mat-label>
                        <mat-select formControlName="language" (selectionChange)='setLanguage($event)'>
                            <mat-option [value]='"gb"'>
                                English (EU)
                            </mat-option>
                            <mat-option [value]='"us"'>
                                English (US)
                            </mat-option>
                            <mat-option [value]='"nl"'>
                                Nederlands (Dutch)
                            </mat-option>
                            <mat-option [value]='"es"'>
                                Español (Spanish)
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field class="col-10 offset-1">
                        <mat-label [translate]="'general.text.username'">
                            Username
                        </mat-label>
                        <input matInput formControlName="username" maxlength='50' autocomplete="username" />
                        <mat-error *ngIf="usernameControl.touched && usernameControl.errors">
                            <span *ngIf="usernameControl.errors?.required"
                                [translate]="'account.text.username_required'">
                                Please enter a username
                            </span>
                            <span *ngIf="usernameControl.errors?.usernameInUse"
                                [translate]="'account.text.username_in_use'">
                                That username is already in use
                            </span>
                            <span *ngIf='usernameControl.errors?.maxlength'
                                [translate]="'account.error.username_max_size'">
                                You can enter at most 50 characters for your username
                            </span>
                            <span *ngIf='usernameControl.errors?.pattern'
                                [translate]="'account.error.invalid_username'">
                                Your username can only contain letters, numbers, and the special characters !, -, _, @,
                                . or ?
                            </span>
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="col-10 offset-1">
                        <mat-label [translate]="'general.text.email'">
                            E-mailaddress
                        </mat-label>
                        <input matInput formControlName="email" maxlength='256' (input)="onBlurEmailInput()"
                            autocomplete="email" />
                        <mat-error *ngIf="emailControl.touched && emailControl.errors">
                            <span *ngIf="emailControl.errors?.required" [translate]="'account.text.email_required'">
                                Please enter your e-mailaddress
                            </span>
                            <span *ngIf="emailControl.errors?.email" [translate]="'account.text.email_invalid'">
                                Please enter a valid e-mailaddress
                            </span>
                            <span *ngIf="emailControl.errors?.emailInUse" [translate]="'account.text.email_in_use'">
                                That e-mailaddress is already in use
                            </span>
                            <span *ngIf='emailControl.errors?.maxlength' [translate]="'account.error.email_max_size'">
                                You can enter at most 256 characters for your e-mailaddress
                            </span>
                        </mat-error>
                    </mat-form-field>

                    <div class="confirm-field-container" [class.hidden]="!showEmailConfirmField">
                        <mat-form-field class="col-10 offset-1">
                            <mat-label [translate]="'account.text.confirm_email'">
                                Confirm e-mailaddress
                            </mat-label>
                            <input matInput formControlName="confirmEmail" [errorStateMatcher]="emailFieldMatcher"
                                (blur)="onBlurConfirmEmailInput()" autocomplete="off" />
                            <mat-error *ngIf="confirmEmailControl.touched">
                                <span *ngIf="registerForm.errors?.emailsDontMatch"
                                    [translate]="'account.text.confirm_email_doesnt_match'">
                                    E-mailaddresses do not match
                                </span>
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <mat-form-field class='col-10 offset-1 phone-field' floatLabel="always"
                        [class.large-error-offset]='phonenumberControl.errors?.validatePhoneNumber'>
                        <mat-label [translate]="'general.text.phone'">
                            Phone number
                        </mat-label>
                        <cp-intl-tel-input class='cp-tel-input' [preferredCountries]="['nl', 'gb', 'us', 'es', 'de']"
                            format='national' [enableSearch]='true' name='phonenumber' formControlName='phonenumber'
                            [searchPlaceholder]='"general.text.search" | translate'>
                        </cp-intl-tel-input>
                        <mat-error *ngIf="phonenumberControl.touched && phonenumberControl.errors">
                            <span [translate]='"account.text.phone_invalid"'
                                [translateParams]='{ format: phoneInput.selectedCountry?.placeHolder || "" }'
                                *ngIf='phonenumberControl.errors?.validatePhoneNumber'>
                                The number you entered is not a valid phone number for the selected country. Example of
                                accepted format:
                            </span>
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="col-10 offset-1">
                        <mat-label [translate]="'account.text.first_name'">
                            First Name
                        </mat-label>
                        <input matInput formControlName="firstName" maxlength='100' autocomplete="given-name" />
                        <mat-error *ngIf="firstNameControl.touched && firstNameControl.errors">
                            <span [translate]="'account.error.first_name_required'"
                                *ngIf='firstNameControl.errors?.required'>
                                A first name is required
                            </span>
                            <span [translate]="'account.error.first_name_max_size'"
                                *ngIf='firstNameControl.errors?.maxlength'>
                                You can enter at most 100 characters for your first name
                            </span>
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="col-10 offset-1">
                        <mat-label [translate]="'general.text.middle_name'">
                            Middle Name
                        </mat-label>
                        <input matInput formControlName="middleName" maxlength='100' autocomplete="additional-name" />
                        <mat-error *ngIf="middleNameControl.touched && middleNameControl.errors">
                            <span [translate]="'account.error.middle_name_max_size'"
                                *ngIf='middleNameControl.errors?.maxlength'>
                                You can enter at most 100 characters for your middle name
                            </span>
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="col-10 offset-1">
                        <mat-label [translate]="'account.text.last_name'">
                            Last Name
                        </mat-label>
                        <input matInput formControlName="lastName" maxlength='100' autocomplete="family-name" />
                        <mat-error *ngIf="lastNameControl.touched && lastNameControl.errors">
                            <span [translate]="'account.error.last_name_required'"
                                *ngIf='lastNameControl.errors?.required'>
                                A last name is required
                            </span>
                            <span [translate]="'account.error.last_name_max_size'"
                                *ngIf='lastNameControl.errors?.maxlength'>
                                You can enter at most 100 characters for your last name
                            </span>
                        </mat-error>
                    </mat-form-field>

                    <cp-password-field formControlName="password" required hideRequiredMarker [validate]='true'
                        [colSize]="10" [colOffset]="1" (changed)="onBlurPasswordInput()">
                    </cp-password-field>

                    <div class="confirm-field-container" [class.hidden]="!showPasswordConfirmField">
                        <cp-password-field formControlName="confirmPassword" hideRequiredMarker confirmField
                            [colSize]="10" [colOffset]="1" [labelKey]="'account.text.confirm_password'"
                            (blurred)="onBlurConfirmPasswordInput()">
                        </cp-password-field>
                    </div>

                    <div *ngIf="registeringFailed" class="row text-center pb-4 warn-text" role="alert">
                        <div class="col-10 offset-1">
                            <span [translate]="'account.text.registration_failed'">
                                Something went wrong with your registration. Please
                                wait a few minutes and try again.
                            </span>
                        </div>
                    </div>

                    <div class="col-10 offset-1">
                        <button mat-raised-button [loading]="registering" class="cp-button cp-button-glow col-12"
                            color="accent">
                            <span [translate]="'registration.button.signup'">
                                Sign Up
                            </span>
                        </button>
                    </div>
                </form>

                <div class="row text-center mt-4 mb-5">
                    <div class="col-10 offset-1 transparent-text">
                        <span [translate]="'account.text.register_terms_of_services_message'">
                            By signing up for Catchphrase, you agree to our
                        </span>
                        <a href="https://catchphrase.io/en/terms-and-conditions" target="_blank">
                            <span [translate]="'general.text.terms_of_services'">
                                Terms of Services
                            </span>
                        </a>.
                        <span [translate]="'account.text.register_privacy_statement_message'">
                            Look at our
                        </span>
                        <a href="https://catchphrase.io/en/privacy-policy" target="_blank">
                            <span [translate]="'general.text.privacy_statement'">
                                Privacy Statement
                            </span>
                        </a>.
                    </div>
                </div>
            </div>
        </ng-template>
    </div>
</div>
