<ng-container *ngIf="content && !invalidSection">
    <div *ngIf="loading" class="d-flex justify-content-center align-items-center">
        <mat-spinner color='primary'></mat-spinner>
    </div>
    <div class='video-container py-3 text-center' [class.d-none]='loading'>
        <div class='inner-video-container'>
            <cp-video *ngIf="videoId" [organisationId]="organisationId" [videoId]="videoId || 0" [wikiTabId]="wikiTabId"
            [eventId]="eventId" (loaded)="loading = false" (errored)="invalidSection = true">
            </cp-video>
        </div>
    </div>
</ng-container>
