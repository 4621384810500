import { IconModule, CatchphraseIconComponent } from './icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import {
    LogoComponent, ToggleablePasswordInputFieldComponent, VerificationCodeInputComponent,
    ImageComponent, RadialProgressComponent, HeaderComponent, SettingsButtonComponent,
    FooterComponent, DelayedSpinnerComponent, TimelineIndicatorComponent, CelebrationComponent,
    ConfirmDialogComponent, ScreensizeDialogComponent, NoConnectionBarComponent, NoConnectionMessageComponent,
    ImageTextSectionComponent, PdfSectionComponent, GlossaryInjectionTooltipComponent, GuidedTourComponent,
    DividerSectionComponent, DropdownSectionComponent, ImageViewerOverlayComponent, OverlayService,
    PdfViewerOverlayComponent, VideoComponent,
} from '@shared/components';
import {
    AutosizeDirective, MatButtonLoadingDirective, OfflineDisableDirective, GlossaryInjectionDirective,
} from '@shared/directives';
import { SecurePipe, LocalizedDatePipe } from './pipes';
import { MatDialogModule } from '@angular/material/dialog';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatRippleModule } from '@angular/material/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSelectModule } from '@angular/material/select';
// TODO: breaking change for angular v17
import { MatLegacySliderModule as MatSliderModule } from '@angular/material/legacy-slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTabsModule } from '@angular/material/tabs';
import { MatRadioModule } from '@angular/material/radio';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { OverlayModule } from '@angular/cdk/overlay';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IntlTelInputComponent, IntlTelInputModule } from './intl-tel-input';

@NgModule({
    declarations: [
        LogoComponent,
        ToggleablePasswordInputFieldComponent,
        VerificationCodeInputComponent,
        MatButtonLoadingDirective,
        CatchphraseIconComponent,
        ImageComponent,
        SecurePipe,
        LocalizedDatePipe,
        RadialProgressComponent,
        HeaderComponent,
        FooterComponent,
        DelayedSpinnerComponent,
        TimelineIndicatorComponent,
        CelebrationComponent,
        ConfirmDialogComponent,
        ScreensizeDialogComponent,
        AutosizeDirective,
        OfflineDisableDirective,
        NoConnectionBarComponent,
        NoConnectionMessageComponent,
        SettingsButtonComponent,
        ImageTextSectionComponent,
        GlossaryInjectionDirective,
        PdfSectionComponent,
        GlossaryInjectionTooltipComponent,
        GuidedTourComponent,
        DividerSectionComponent,
        DropdownSectionComponent,
        ImageViewerOverlayComponent,
        PdfViewerOverlayComponent,
        VideoComponent,
    ],
    imports: [
        CommonModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        IconModule,
        MatProgressSpinnerModule,
        MatDialogModule,
        ScrollingModule,
        RouterModule,
        MatRippleModule,
        IntlTelInputModule,
        MatSnackBarModule,
        MatSelectModule,
        MatTooltipModule,
        MatSliderModule,
        MatRadioModule,
        NgxExtendedPdfViewerModule,
        OverlayModule,
    ],
    providers: [
        OverlayService,
    ],
    exports: [
        CommonModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        LogoComponent,
        MatIconModule,
        MatProgressSpinnerModule,
        ToggleablePasswordInputFieldComponent,
        MatButtonLoadingDirective,
        VerificationCodeInputComponent,
        CatchphraseIconComponent,
        ImageComponent,
        SecurePipe,
        MatDialogModule,
        ScrollingModule,
        MatProgressBarModule,
        RadialProgressComponent,
        FooterComponent,
        HeaderComponent,
        DelayedSpinnerComponent,
        MatRippleModule,
        IntlTelInputComponent,
        MatSnackBarModule,
        MatSelectModule,
        TimelineIndicatorComponent,
        MatSliderModule,
        CelebrationComponent,
        MatSlideToggleModule,
        MatCheckboxModule,
        MatTooltipModule,
        MatTabsModule,
        LocalizedDatePipe,
        ScreensizeDialogComponent,
        AutosizeDirective,
        OfflineDisableDirective,
        NoConnectionBarComponent,
        NoConnectionMessageComponent,
        SettingsButtonComponent,
        MatRadioModule,
        ImageTextSectionComponent,
        GlossaryInjectionDirective,
        PdfSectionComponent,
        GlossaryInjectionTooltipComponent,
        GuidedTourComponent,
        DividerSectionComponent,
        DropdownSectionComponent,
        VideoComponent,
    ]
})
export class SharedModule { }
