<div class='mt-2'>
    <mat-form-field class="cp-light-thin-form-field">
        <mat-label [translate]="'general.text.search'">
            Search...
        </mat-label>
        <input matInput [(ngModel)]="filter">
    </mat-form-field>
</div>
<div class='terms-list'>
    <div *ngFor='let group of getFilteredGroups()' class='term-group' [class.active]='group.expanded'>
        <div class='group-header d-flex p-2' (click)='toggleGroup(group)'>
            <div class='w-50 d-flex'>
                <div class='pl-2 title'>
                    {{ group.groupName }}
                </div>
                <div class='counter pl-1 my-auto'>
                    ({{ getFilteredTerms(group).length }})
                </div>
            </div>
            <div class='w-50'>
                <div class='float-right'>
                    <cp-icon size='small' class='chevron' [rotated]='group.expanded' name='chevron-down'></cp-icon>
                </div>
            </div>
        </div>
        <ng-container *ngIf='group.expanded'>
            <div class='term my-3' *ngFor='let term of getFilteredTerms(group)' [class.active]='term.expanded'>
                <div class='term-header mb-1' (click)='onClickTermHeader(term)'>
                    <div class='col-10'>
                        <div class='title my-auto'>
                            {{ term.term }}
                        </div>
                    </div>
                    <div class='col-2 pr-2 button-container'>
                        <div class='float-right'>
                            <cp-icon size='small' class='chevron' [rotated]='term.expanded' name='chevron-down'>
                            </cp-icon>
                        </div>
                    </div>
                </div>
                <div *ngIf='term.expanded' class='term-content col-12'>
                    <div [innerHTML]='term._sanitizedDescription' class='description mb-2'></div>
                    <div *ngIf='term.imageBlobId' class='mb-2'>
                        <cp-image [blobId]='term.imageBlobId' [organisationId]='organisationId' [rounded]='true'
                            [swCache]='true'></cp-image>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>
