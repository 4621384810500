import { AbstractControl, ValidationErrors, AsyncValidatorFn } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AccountDataService } from '@account/services';

export function UniqueEmailValidator(
    data: AccountDataService, excludeSelf = false, invitationCode?: string, organisationId?: number): AsyncValidatorFn {
    return (control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> =>
        data.isUniqueEmail(control.value, excludeSelf, invitationCode, organisationId).pipe(
            map(isValid => (isValid ? null : { emailInUse: true })),
            catchError(() => Promise.resolve(null))
        );
}
