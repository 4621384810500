import { Component } from '@angular/core';
import { DarkBodyComponent } from '@global/base-components';
import { GlobalsService } from '@core/services';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountDataService } from '@account/services';

@Component({
    templateUrl: './used-invite.component.html',
    styleUrls: ['./used-invite.component.scss']
})
export class UsedInviteComponent extends DarkBodyComponent {
    constructor(
        globals: GlobalsService,
        private route: ActivatedRoute,
        private data: AccountDataService,
        private router: Router
    ) {
        super();
        globals.hideHeaderAndFooter();
    }

    goHome(): void {
        this.router.navigate(['']);
    }
}
