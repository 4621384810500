import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EndPoint } from '@core/constants';
import { UrlResolver } from '@core/utils';
import { environment } from '@env';
import { take } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class WhitelabelService {
    constructor(private http: HttpClient) {}

    get whitelabelSubdomain(): string | undefined {
        const domain = window.location.host;

        if (domain.endsWith(environment.whitelabelHostSuffix)) {
            // Whitelabel subdomain should always be the first subdomain in the list
            return domain.split('.')[0];
        }
        return undefined;
    }

    forceSubdomainAcive(): void {
        if (environment.envName === 'local') {
            return;
        }

        const subdomain = this.whitelabelSubdomain;

        if (!subdomain) {
            return;
        }

        const cache = localStorage.getItem(`whitelabel-${subdomain}`);

        if (cache !== null) {
            const cacheValue = coerceBooleanProperty(cache);

            if (cacheValue) {
                return;
            } else {
                window.location.href = environment.host;
                return;
            }
        }

        this.http.get<{ whitelabelActive: boolean }>(UrlResolver.getUrl(EndPoint.organisation, `whitelabel/${subdomain}`)).pipe(take(1))
            .subscribe({
                next: (result) => {
                    if (result.whitelabelActive) {
                        localStorage.setItem(`whitelabel-${subdomain}`, result.whitelabelActive.toString());
                    } else {
                        window.location.href = environment.host;
                        return;
                    }},
                error: () => {
                    window.location.href = environment.host;
                    return;
                }});
    }
}
