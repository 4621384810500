<a [routerLink]="['/sign-in']" queryParamsHandling="preserve" class="back-button">
    <button mat-icon-button color="primary">
        <cp-icon name='arrow-left' color='primary'></cp-icon>
    </button>
</a>
<div class="row justify-content-center">
    <div class="col-xl-4 col-lg-6 col-m-8 col-sm-12">
        <div class="text-center pt-5 col-12">
            <cp-logo maxWidth="50px" [translate]="'account.text.request_username'">
                Request your username
            </cp-logo>
        </div>

        <div class="pt-4">
            <div class="row text-center pb-2">
                <span class="transparent-text col-10 offset-1" *ngIf='!requested'
                    [translate]="'account.text.request_username_message'">
                    Enter your email below and we will send you a mail with your usernames.
                </span>
                <div class='col-10 offset-1' *ngIf='requested'>
                    <span [translate]="'account.text.usernames_requested'">
                        If the email you submitted is known to us, you will receive an email with your username(s)
                        shortly.
                    </span>
                    <div class='mt-3'>
                        <cp-icon name='check-circle' size='large' color='primary'></cp-icon>
                    </div>
                </div>
            </div>

            <form [formGroup]='usernameForgotForm' (submit)='submitRequest()' *ngIf='!requested'>
                <mat-form-field class="col-10 offset-1">
                    <mat-label [translate]="'general.text.email'">
                        Email
                    </mat-label>
                    <input #emailField matInput formControlName="email">
                </mat-form-field>

                <div class='col-10 offset-1' *ngIf='error'>
                    <div class='alert alert-danger'>
                        <span [translate]='"account.text.username_service_unavailable"'>
                            Something went wrong during your username request, please try again later.
                        </span>
                    </div>
                </div>

                <div class="col-10 offset-1">
                    <button mat-raised-button class="cp-button cp-button-glow col-12" color='accent'
                        [loading]='submitting'>
                        <span [translate]="'general.button.submit'">
                            Submit
                        </span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
