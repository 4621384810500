import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { slideInLeft, slideInRight } from '@core/animations';
import { trigger, transition } from '@angular/animations';

@Component({
    selector: 'app-account',
    templateUrl: './account.component.html',
    styleUrls: ['./account.component.scss'],
    animations: [
        trigger('routeAnimations', [
            transition('InvitationPage => RegistrationPage', slideInRight),
            transition('RegistrationPage => InvitationPage', slideInLeft),
            transition('RegistrationPage => ConfirmationPage', slideInRight),
        ])]
})
export class AccountComponent {
    animation = 'animation';

    prepareRoute(outlet: RouterOutlet): string {
        return outlet && outlet.activatedRouteData && outlet.activatedRouteData[this.animation];
    }

}
