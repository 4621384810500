import {
    ElementRef,
    HostListener,
    Directive,
    Input,
    NgZone, OnDestroy, OnChanges, AfterContentChecked, Output, EventEmitter
} from '@angular/core';

const MAX_LOOKUP_RETRIES = 3;

// Taken from https://github.com/chrum/ngx-autosize/ and adapted for our use
@Directive({
    selector: '[autosize]'
})
export class AutosizeDirective implements OnDestroy, OnChanges, AfterContentChecked {
    @Input() maxRows?: number;
    @Input() onlyGrow = false;
    @Input() useImportant = false;
    @Input() fakeLineheight?: number;

    @Output() resized = new EventEmitter<number>();

    private _minRows?: number;

    private retries = 0;
    private textAreaEl?: HTMLTextAreaElement;

    private _oldContent?: string;
    private _oldWidth?: number;

    private _windowResizeHandler?: () => void;
    private _destroyed = false;

    constructor(
        public element: ElementRef,
        private _zone: NgZone
    ) {
        if (this.element.nativeElement.tagName !== 'TEXTAREA') {
            this._findNestedTextArea();

        } else {
            this.textAreaEl = this.element.nativeElement;

            if (this.textAreaEl) {
                this.textAreaEl.style.overflow = 'hidden';
                this._onTextAreaFound();
            }
        }
    }

    @Input()
    set minRows(value: number | undefined) {
        this._minRows = value;
        if (this.textAreaEl && value) {
            this.textAreaEl.rows = value;
        }
    }

    @HostListener('input', ['$event.target'])
    onInput(): void {
        this.adjust();
    }

    ngOnDestroy(): void {
        this._destroyed = true;
        if (this._windowResizeHandler) {
            window.removeEventListener('resize', this._windowResizeHandler, false);
        }
    }

    ngAfterContentChecked(): void {
        this.adjust();
    }

    ngOnChanges(): void {
        this.adjust(true);
    }

    _findNestedTextArea(): void {
        this.textAreaEl = this.element.nativeElement.querySelector('TEXTAREA');

        if (!this.textAreaEl && this.element.nativeElement.shadowRoot) {
            this.textAreaEl = this.element.nativeElement.shadowRoot.querySelector('TEXTAREA');
        }

        if (!this.textAreaEl) {
            if (this.retries >= MAX_LOOKUP_RETRIES) {
                console.warn('ngx-autosize: textarea not found');

            } else {
                this.retries++;
                setTimeout(() => {
                    this._findNestedTextArea();
                }, 100);
            }
            return;
        }

        this.textAreaEl.style.overflow = 'hidden';
        this._onTextAreaFound();

    }

    _onTextAreaFound(): void {
        this._addWindowResizeHandler();
        setTimeout(() => {
            this.adjust();
        });
    }

    _addWindowResizeHandler(): void {
        this._windowResizeHandler = Debounce(this, () => {
            this._zone.run(() => {
                this.adjust();
            });
        }, 200);

        this._zone.runOutsideAngular(() => {
            if (this._windowResizeHandler) {
                window.addEventListener('resize', this._windowResizeHandler, false);
            }
        });
    }

    adjust(inputsChanged = false): void {
        if (!this._destroyed && this.textAreaEl && this.textAreaEl.parentNode) {

            const currentText = this.textAreaEl.value;

            if (
                inputsChanged === false &&
                currentText === this._oldContent &&
                this.textAreaEl.offsetWidth === this._oldWidth
            ) {
                return;
            }

            this._oldContent = currentText;
            this._oldWidth = this.textAreaEl.offsetWidth;

            const clone = this.textAreaEl.cloneNode(true) as HTMLTextAreaElement;
            const parent = this.textAreaEl.parentNode;

            if (!clone) {
                return;
            }

            clone.style.width = this.textAreaEl.offsetWidth + 'px';
            clone.style.visibility = 'hidden';
            clone.style.position = 'absolute';
            if (this.fakeLineheight) {
                clone.style.lineHeight = `${this.fakeLineheight}px`;
            }
            clone.textContent = currentText;

            parent.appendChild(clone);

            clone.style.overflow = 'hidden';
            clone.style.height = 'auto';

            let height = clone.scrollHeight;

            // add into height top and bottom borders' width
            const computedStyle = window.getComputedStyle(clone, null);
            height += parseInt(computedStyle.getPropertyValue('border-top-width'), 10);
            height += parseInt(computedStyle.getPropertyValue('border-bottom-width'), 10);

            // add into height top and bottom paddings width
            height += parseInt(computedStyle.getPropertyValue('padding-top'), 10);
            height += parseInt(computedStyle.getPropertyValue('padding-bottom'), 10);


            const oldHeight = this.textAreaEl.offsetHeight;
            const willGrow = height > oldHeight;

            if (this.onlyGrow === false || willGrow) {
                const lineHeight = this._getLineHeight();
                const rowsCount = height / lineHeight;

                if (this._minRows && this._minRows >= rowsCount) {
                    height = this._minRows * lineHeight;

                } else if (this.maxRows && this.maxRows <= rowsCount) {
                    // never shrink the textarea if onlyGrow is true
                    const maxHeight = this.maxRows * lineHeight;
                    height = this.onlyGrow ? Math.max(maxHeight, oldHeight) : maxHeight;
                    this.textAreaEl.style.overflow = 'auto';

                } else {
                    this.textAreaEl.style.overflow = 'hidden';
                }

                const heightStyle = height + 'px';
                const important = this.useImportant ? 'important' : '';

                this.textAreaEl.style.setProperty('height', heightStyle, important);

                this.resized.emit(height);
            }

            parent.removeChild(clone);
        }
    }

    private _getLineHeight(): number {
        if (!this.textAreaEl) {
            return 0;
        }

        if (this.fakeLineheight) {
            return this.fakeLineheight;
        }

        let lineHeight = parseInt(this.textAreaEl.style.lineHeight, 10);
        if (isNaN(lineHeight) && window.getComputedStyle) {
            const styles = window.getComputedStyle(this.textAreaEl);
            lineHeight = parseInt(styles.lineHeight, 10);
        }

        if (isNaN(lineHeight)) {
            const fontSize = window.getComputedStyle(this.textAreaEl, null).getPropertyValue('font-size');
            lineHeight = Math.floor(parseInt(fontSize.replace('px', ''), 10) * 1.5);
        }

        return lineHeight;
    }
}


function Debounce(scope: any, func: () => void, wait: number, immediate = false): () => void {

    let timeout: any;
    return () => {
        const context = scope;
        const later = () => {
            timeout = undefined;
            if (!immediate) {
                func.apply(context, []);
            }
        };
        const callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) {
            func.apply(context, []);
        }
    };
}
