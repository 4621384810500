import { EndPoint } from '@core/constants';
import { GeneralDataService } from '@core/services';
import { IEvaluationData, EvaluationViewResponseModel } from '@evaluation/models';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class EvaluationDataService extends GeneralDataService {
    getEvaluationView(organisationId: number, eventId: number, evaluationTaskId: number): Observable<EvaluationViewResponseModel> {
        return this.getGenericItem(EndPoint.tasks, `${organisationId}/${eventId}/evaluation/${evaluationTaskId}`);
    }

    finishEvaluation(organisationId: number, eventId: number, evaluationTaskId: number, evaluationData: IEvaluationData): Observable<void> {
        return this.postGenericItem(
            EndPoint.tasks, `${organisationId}/${eventId}/evaluation/${evaluationTaskId}/finish`, evaluationData);
    }
}
