<div class='collapsed-info' [class.active]='isExpanded' (click)='onClickHeader()'>
    <div class='content' [class.active]='isExpanded'>
        <div class='title my-auto'>
            <span>
                {{ section._content?.title }}
            </span>
        </div>
        <div class='subtitle my-auto'>
            <span *ngIf='section._content?.subtitle'>
                {{ section._content?.subtitle }}
            </span>
        </div>
    </div>
    <div class='buttons'>
        <div class='button'>
            <cp-icon size='small' class='chevron' [class.flipped]='isExpanded' name='chevron-down'>
            </cp-icon>
        </div>
    </div>
</div>
<div class='expanded-content' [class.pt-2]='inBriefing' [class.d-none]='!isExpanded'>
    <ng-content></ng-content>
</div>
