import { slideInLeft, slideInRight } from '@core/animations';
import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { trigger, transition } from '@angular/animations';
import { GlobalsService } from '@core/services';

@Component({
    templateUrl: './sign-in.component.html',
    styleUrls: ['./sign-in.component.scss'],
    animations: [
        trigger('routeAnimations', [
            transition('SignInPage => ForgotPassword', slideInRight),
            transition('ForgotPassword => SignInPage', slideInLeft),
        ])]
})
export class SignInComponent {
    animation = 'animation';

    constructor(globals: GlobalsService) {
        globals.hideHeaderAndFooter();
    }

    prepareRoute(outlet: RouterOutlet): string {
        return outlet && outlet.activatedRouteData && outlet.activatedRouteData[this.animation];
    }
}
