import { TranslatePipe } from '@ngx-translate/core';
import { ThemePalette } from '@angular/material/core';
import { Component, OnInit, Input, HostListener } from '@angular/core';
import { TimelineIndicatorPosition } from './models';
import { DateUtils } from '@core/utils';

@Component({
    selector: 'cp-timeline-indicator',
    templateUrl: './timeline-indicator.component.html',
    styleUrls: ['./timeline-indicator.component.scss']
})
export class TimelineIndicatorComponent implements OnInit {
    @Input() size = 14;
    @Input() finishDay?: Date;
    @Input() color?: ThemePalette;
    @Input() completed = false;

    @Input() label = 'event.text.your_timeline';
    @Input() countdownLabel = 'event.text.until_next_event';

    pips!: TimelinePip[];
    private _defaultIndicatorPositionProfile: TimelineIndicatorPosition[] = [
        {
            iconName: 'flag',
            position: 0,
            iconTooltip: 'timeline.tooltip.start_message'
        },
        {
            iconName: 'file-text',
            position: 8,
            iconTooltip: 'timeline.tooltip.seven_day_message'
        },
        {
            iconName: 'file-check',
            position: 11,
            iconTooltip: 'timeline.tooltip.two_day_message'
        },
        {
            iconName: 'flag',
            position: 13,
            filled: true,
            iconTooltip: 'timeline.tooltip.end_message'
        }
    ];
    @Input() indicators: TimelineIndicatorPosition[] = this._defaultIndicatorPositionProfile;

    constructor(private translate: TranslatePipe) { }

    get daysLeft(): number {
        if (this.finishDay) {
            const dateDiff = DateUtils.CalculateDifferenceInDaysBetweenDates(this.finishDay, new Date());

            // Never print negative days
            return Math.max(dateDiff, 0);
        }

        return 0;
    }

    get positionOnSlider(): number {
        return this.size - this.daysLeft;
    }

    @HostListener('document:click') onClick(): void {
        this.pips.forEach(x => x.isActive = false);
    }

    ngOnInit(): void {
        this.pips = this._createPipsFromIndicators();
    }

    getPipThumbClass(index: number): { [klass: string]: boolean } {
        const isPreviousPip = this.isPreviousPip(index);

        return {
            'pip-thumb': true,
            previous: isPreviousPip,
            // 'Today' also uses future styling
            future: !isPreviousPip,
            primary: this.color === 'primary',
            warn: this.color === 'warn'
        };
    }

    isPreviousPip(index: number): boolean {
        return index < this.positionOnSlider;
    }

    activatePip(pip: TimelinePip): void {
        setTimeout(() => {
            pip.isActive = true;
        }, 0);
    }

    getTooltipTranslation(pip: TimelinePip): string {
        if (pip.iconTooltip) {
            const translation: string = this.translate.transform(pip.iconTooltip);

            return translation.replace(/\\n/g, '\n');
        }

        return '';
    }

    private _createPipsFromIndicators(): TimelinePip[] {
        const result: TimelinePip[] = [];
        for (let i = 0; i < this.size; i++) {
            const indicator = this.indicators.find(_indicator => _indicator.position === i);

            if (indicator) {
                result.push({
                    isIndicator: true,
                    indicatorIcon: indicator.iconName,
                    iconFilled: indicator.filled,
                    iconTooltip: indicator.iconTooltip,
                    isActive: false
                });
            } else {
                result.push({ isIndicator: false, isActive: false });
            }
        }
        return result;
    }

}

interface TimelinePip {
    isIndicator: boolean;
    indicatorIcon?: string;
    iconFilled?: boolean;
    iconTooltip?: string;
    isActive: boolean;
}

export type TimelineIndicatorColor = 'primary' | 'warn' | 'default';
