import {
    Component, Directive, ViewChildren, QueryList, TemplateRef,
    AfterViewInit, ChangeDetectorRef, Input, Output, EventEmitter } from '@angular/core';
import { QuestionResponse, IEvaluationPoint } from '@evaluation/models';

@Directive({selector: 'ng-template'})
export class InputCandidateDirective {}

@Component({
    selector: 'cp-input-picker',
    templateUrl: './input-picker.component.html',
    styleUrls: ['./input-picker.component.scss']
})
export class InputPickerComponent implements AfterViewInit {
    @Input()
        questionResponse!: QuestionResponse;

    @Input()
        eventName!: string;

    @Input()
        evaluationPoint!: IEvaluationPoint;

    @Input()
        error!: boolean;

    @Output()
        touched = new EventEmitter<void>();

    @ViewChildren('input1, input2')
    // Should be able to contain varying types of components

        inputs!: QueryList<TemplateRef<any>>;

    // Should be able to contain varying types of components

    chosenInput!: TemplateRef<any>;

    constructor(private cdr: ChangeDetectorRef) { }

    ngAfterViewInit(): void {
        this.chosenInput = this.inputs.toArray()[Math.floor(Math.random() * this.inputs.length)];

        this.cdr.detectChanges();
    }
}
