import { trigger, transition, query, style, stagger, animate } from '@angular/animations';

export const fadeInListAnimation = trigger('fadeInListAnimation', [
    transition('* <=> *', [
        query(':enter',
            [
                style({ opacity: 0, 'margin-top': '100px' }),
                stagger(
                    '0.3s',
                    animate('0.5s ease-out',
                        style({ opacity: 1, 'margin-top': '0px' })))],
            { optional: true })
    ])
]);
