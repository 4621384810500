<div class="progress-container" [style.width.px]="size" [style.height.px]="size">
    <div class="shadow-overlay layer"></div>
    <svg class="inner-circle layer">
        <circle cx="50%" cy="50%" r="35" [class.transparent]='transparentLabel'></circle>
    </svg>
    <mat-progress-spinner class="empty layer" [diameter]="size" value="100" strokeWidth="15"></mat-progress-spinner>
    <ng-container *ngIf="sortedBars">
        <div *ngIf="label !== 'none'" class="label layer" [class.flex-column]='label === "content"' [ngSwitch]='label'>
            <ng-container *ngSwitchCase='"percentage"'>
                <span class="value">{{sortedBars[0].value}}</span>
                <span class="percentage">%</span>
            </ng-container>

            <ng-container *ngSwitchCase='"decimal"'>
                <span class="value">
                    {{sortedBars[0].value / 10}}
                </span>
            </ng-container>

            <ng-container *ngSwitchCase='"custom"'>
                <span class="value">
                    {{ customLabel }}
                </span>
            </ng-container>

            <ng-container *ngSwitchCase='"content"'>
                <ng-content></ng-content>
            </ng-container>
        </div>
        <mat-progress-spinner *ngIf="sortedBars[0]" class="layer" [ngClass]="getColorClass(sortedBars[0])"
            [diameter]="size" [value]="currentValues[0]" strokeWidth="15"></mat-progress-spinner>
        <mat-progress-spinner *ngIf="sortedBars[1]" class="layer" [ngClass]="getColorClass(sortedBars[1])"
            [diameter]="size" [value]="currentValues[1]" strokeWidth="15"></mat-progress-spinner>
        <mat-progress-spinner *ngIf="sortedBars[2]" class="layer" [ngClass]="getColorClass(sortedBars[2])"
            [diameter]="size" [value]="currentValues[2]" strokeWidth="15"></mat-progress-spinner>
    </ng-container>
</div>
