<div class='chat-message'>
    <div class='name'>
        <span [translate]='"chat.text.sending_failed"'>
            Sending message failed
        </span>
    </div>
    <div class='message'>
        <span>
            {{ message.message }}
        </span>
    </div>
</div>

<button class='resend-button' mat-dialog-close='resend'>
    <cp-icon name='refresh-cw' class='pr-1' size='x-small'></cp-icon>
    <span [translate]='"chat.text.resend_message"'>
        Resend the message
    </span>
</button>
<button class='delete-button' mat-dialog-close='delete'>
    <cp-icon name='trash' class='pr-1' size='x-small'></cp-icon>
    <span [translate]='"chat.text.delete_message"'>
        Delete the message
    </span>
</button>
