export interface ITask {
    taskId: number;
    title: string;
    type: TaskType;
    progressPercentage: number;
    isCompleted: boolean;
    deadlineOn?: Date;
    isUrgent: boolean;
}

export enum TaskType {
    Briefing,
    Evaluation,
    KnowledgeTest
}

export interface TasksForLandingPage {
    tasks: ITask[];
    totalTasks: number;
}
