import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AccountDataService } from '@account/services/account-data.service';

@Injectable({
    providedIn: 'root'
})
/**
 * Router Guard service that checks if the token in the querystring is a valid invitationCode
 * If not will redirect the user to component where they can request a new invite
 */
export class ValidCodeGuard  {
    constructor(
        private data: AccountDataService,
        private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, _: RouterStateSnapshot): Observable<boolean> | boolean {
        return new Observable<boolean>((observer) => {
            if (route.queryParams.organisationId) {
                // This gets checked in the components itself because the validation call returns additional data
                observer.next(true);
            }
            else {
                this.data.validateCode(route.queryParams.token).subscribe({
                    next: (result) => {
                        if (result) {
                            observer.next(true);
                        }
                        else {
                            this.router.navigate(['/account/request-invite'], { queryParams: route.queryParams });
                            observer.next(false);
                        }
                    },
                    error: (err) => {
                        if (err.error?.errors?.invitation?.used) {
                            this.router.navigate(['/account/used-invite'], { queryParams: route.queryParams });
                        } else {
                            this.router.navigate(['/account/request-invite'], { queryParams: route.queryParams });
                        }
                        observer.next(false);
                    }});
            }
        });
    }
}
