import { Injectable } from '@angular/core';
import { GeneralDataService } from './general-data.service';
import { EndPoint } from '@core/constants';
import { GlossaryTerm } from '@core/models';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ConnectionService } from '.';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class GlossaryInjectionService extends GeneralDataService {
    private readonly DAY_IN_MS = 86400 * 1000;

    private _terms: GlossaryTerm[] = [];

    constructor(private connection: ConnectionService, http: HttpClient) {
        super(http);
    }

    getGlossaryTermsOverview(organisationId: number, eventId: number): Observable<GlossaryTerm[]> {
        // If we already have a local terms list, simply return it
        if (this._terms.length) {
            // if offline, only inject terms with locally stored descriptions
            if (!this.connection.online) {
                return of(this._terms.filter(x => x.description));
            }
            return of(this._terms);
        }
        else {
            // If we don't have a local terms list, check if there's one in the cache and return that if it's not outdated
            const cachedTerms = localStorage.getItem(`glossary_terms_${organisationId}_${eventId}`);
            if (cachedTerms) {
                const parsedCache = JSON.parse(cachedTerms);
                if (parsedCache.terms as GlossaryTerm[] && Date.now() - parsedCache.timestamp < this.DAY_IN_MS) {
                    this._terms = parsedCache.terms;
                    // if offline, only inject terms with locally stored descriptions
                    if (!this.connection.online) {
                        return of(this._terms.filter(x => x.description));
                    }
                    return of(this._terms);
                }
            }

            // If there's no cache or it's outdated, retrieve from backend and update the cache
            return this.getGenericItem<GlossaryTerm[]>(EndPoint.wiki, `glossary/${organisationId}/${eventId}/injection`)
                .pipe(switchMap(response => {
                    this._terms = response;
                    const cacheEntry = { terms: response, timestamp: Date.now() };
                    localStorage.setItem(`glossary_terms_${organisationId}_${eventId}`, JSON.stringify(cacheEntry));
                    return of(this._terms);
                }));
        }
    }

    getTermDefinition(organisationId: number, eventId: number, termId: number): Observable<GlossaryTerm> {
        let foundTerm: GlossaryTerm | undefined;
        // If term already has a description locally, return it
        if (this._terms.length) {
            foundTerm = this._terms.find(x => x.id === termId);
            if (foundTerm?.description) {
                return of(foundTerm);
            }
        }

        return this.getGenericItem<GlossaryTerm>(EndPoint.wiki, `glossary/${organisationId}/${eventId}/${termId}`)
            .pipe(switchMap(term => {
                if (foundTerm) {
                    this._terms = this._terms.filter(x => x.id !== termId);
                    this._terms.push(term);
                }
                else {
                    this._terms.push(term);
                }
                const cacheEntry = { terms: this._terms, timestamp: Date.now() };
                localStorage.setItem(`glossary_terms_${organisationId}_${eventId}`, JSON.stringify(cacheEntry));
                return of(term);
            }));
    }
}
