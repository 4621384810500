import { Component, Input, ViewChild, ElementRef, Output, EventEmitter, OnInit } from '@angular/core';
import { IBriefingSectionModel } from '@core/models';

@Component({
    selector: 'cp-video-section',
    templateUrl: './video-section.component.html',
    styleUrls: ['./video-section.component.scss']
})
export class VideoSectionComponent implements OnInit {
    @Input()
        section!: IBriefingSectionModel;

    @Input()
        organisationId!: number;

    @Input()
        briefingId!: number;

    @Input()
        eventId!: number;

    @Input()
        index!: number;

    @Input()
        finished!: boolean;

    @Output()
        videoFinished: EventEmitter<number> = new EventEmitter<number>();

    @ViewChild('videoPlayer') 
        _videoPlayer!: ElementRef<HTMLVideoElement>;

    loading = true;
    invalidSection = false;

    videoId?: number;

    ngOnInit(): void {
        if (!this.section.content) {
            this.invalidSection = true;
        }

        const videoId = parseInt(this.section.content || '', 10);

        if (Number.isNaN(videoId)) {
            this.invalidSection = true;
        }
        else {
            this.videoId = videoId;
        }
    }

    onVideoEnded(): void {
        this.videoFinished.emit(this.index);
    }
}
