<div class="px-3">
    <div class='introduction-text' *ngIf='introductionText' [innerHTML]="introductionText" glossary-injection
        [organisationId]="organisationId" [eventId]="eventId" [pageInformation]="pageTrackingInformation">
    </div>
    <div class='sections-list'>
        <div *ngFor="let section of content.sections; let index = index" class='pt-3'
            [class.dropdown-section]="section.type === sectionType.dropdown">
            <div [ngSwitch]="section.type">
                <div *ngSwitchCase="sectionType.text" [innerHTML]="section._sanitizedContent" class="text-section mb-2"
                    glossary-injection [organisationId]="organisationId" [eventId]="eventId"
                    [pageInformation]="pageTrackingInformation">
                </div>
                <cp-image *ngSwitchCase="sectionType.image" [imageClasses]='imageClasses' [code]='code'
                    [blobId]='section.content || ""' [swCache]='true' [organisationId]="organisationId"
                    [showWarningOnError]="true" [width]="'100%'" [canOverlayZoom]='true'>
                </cp-image>
                <cp-image-text-section *ngSwitchCase="sectionType['image-text']" [section]="section"
                    [organisationId]="organisationId" [eventId]="eventId" [code]='code'
                    [pageInformation]="pageTrackingInformation">
                </cp-image-text-section>
                <ng-container *ngIf='canViewPdfs'>
                    <cp-pdf-section *ngSwitchCase="sectionType.pdf" [organisationId]='organisationId'
                        [isOffline]='isOffline' [content]='sectionAsPdfSection(section)._content'
                        (opened)="onOpenPdf($event)">
                    </cp-pdf-section>
                </ng-container>
                <ng-container *ngIf='canViewVideos'>
                    <cp-video-section *ngSwitchCase="sectionType.video" [organisationId]='organisationId'
                        [eventId]="eventId" [wikiTabId]="wikiTabId" [content]='section.content || ""'>
                    </cp-video-section>
                </ng-container>
                <cp-divider-section *ngSwitchCase="sectionType.divider" [section]="section">
                </cp-divider-section>
                <cp-map-section *ngSwitchCase="sectionType.map" [content]="sectionAsMapSection(section)._content"
                    [organisationId]="organisationId" [eventId]="eventId" [code]="code" [wikiTabId]="wikiTabId">
                </cp-map-section>
                <cp-external-link-section *ngSwitchCase="sectionType['external-link']"
                    [content]="sectionAsExternalLinkSection(section)._content" [eventId]="eventId"
                    [organisationId]="organisationId" [wikiTabId]="wikiTabId">
                </cp-external-link-section>
                <cp-dropdown-section *ngSwitchCase="sectionType.dropdown" [section]="sectionAsDropdownSection(section)"
                    (opened)="onOpenDropdown($event)" (toggled)="onDropdownToggle($event)">
                    <div *ngFor="let childSection of getChildSections(index)" [ngSwitch]="childSection.type"
                        class='pt-3'>
                        <div *ngSwitchCase="sectionType.text" [innerHTML]="childSection._sanitizedContent"
                            class="text-section mb-2" glossary-injection [organisationId]="organisationId"
                            [eventId]="eventId" [pageInformation]="pageTrackingInformation">
                        </div>
                        <cp-image *ngSwitchCase="sectionType.image" [imageClasses]='imageClasses' [code]='code'
                            [blobId]='childSection.content || ""' [swCache]='true' [organisationId]="organisationId"
                            [showWarningOnError]="true" [width]="'100%'" [canOverlayZoom]='true'>
                        </cp-image>
                        <cp-image-text-section *ngSwitchCase="sectionType['image-text']" [section]="childSection"
                            [organisationId]="organisationId" [eventId]="eventId" [code]='code'
                            [pageInformation]="pageTrackingInformation">
                        </cp-image-text-section>
                        <ng-container *ngIf='canViewPdfs'>
                            <cp-pdf-section *ngSwitchCase="sectionType.pdf" [organisationId]='organisationId'
                                [isOffline]='isOffline' [content]='sectionAsPdfSection(childSection)._content'
                                (opened)="onOpenPdf($event)">
                            </cp-pdf-section>
                        </ng-container>
                        <ng-container *ngIf='canViewVideos'>
                            <cp-video-section *ngSwitchCase="sectionType.video" [organisationId]='organisationId'
                                [eventId]="eventId" [wikiTabId]="wikiTabId" [content]='childSection.content || ""'>
                            </cp-video-section>
                        </ng-container>
                        <cp-divider-section *ngSwitchCase="sectionType.divider" [section]="childSection">
                        </cp-divider-section>
                        <cp-map-section *ngSwitchCase="sectionType.map"
                            [showMap]="isDropdownOpen(sectionAsMapSection(section)._content?.identifier)"
                            [content]="sectionAsMapSection(childSection)._content" [organisationId]="organisationId"
                            [eventId]="eventId" [code]="code" [wikiTabId]="wikiTabId">
                        </cp-map-section>
                        <cp-external-link-section *ngSwitchCase="sectionType['external-link']"
                            [content]="sectionAsExternalLinkSection(childSection)._content" [eventId]="eventId"
                            [organisationId]="organisationId" [wikiTabId]="wikiTabId">
                        </cp-external-link-section>
                    </div>
                </cp-dropdown-section>
            </div>
        </div>
    </div>
</div>
