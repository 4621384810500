<div class='mt-2'>
    <mat-form-field class="cp-light-thin-form-field">
        <mat-label [translate]="'general.text.search'">
            Search...
        </mat-label>
        <input matInput [(ngModel)]="filter">
    </mat-form-field>
</div>

<div class='alert alert-info' *ngIf='(!filteredCategories || filteredCategories.length === 0) && filter'>
    <span [translate]='"wiki.text.no_questions_found"'>
        No questions fit your current search, please try broadening your search query to find questions.
    </span>
</div>

<div class='categories-list'>
    <div *ngFor='let category of filteredCategories' class='category' [class.active]='category.expanded'>
        <div class='category-header d-flex p-2' (click)='toggleCategory(category)'>
            <div class='col-10 px-0'>
                <div class='pl-2 title'>
                    {{ category.title }}
                </div>
            </div>
            <div class='col-2 px-0 d-flex'>
                <div class='ml-auto ml-auto category-toggle'>
                    <cp-icon size='small' class='chevron' [rotated]='category.expanded' name='chevron-down'></cp-icon>
                </div>
            </div>
        </div>
        <ng-container *ngIf='category.expanded'>
            <div class='question m-3' *ngFor='let item of filteredItems(category)' [class.active]='item.expanded'>
                <div class='question-header mb-2 pb-2' (click)='toggleItem(category, item)'>
                    <div>
                        <div class='title my-auto' glossary-injection [organisationId]="organisationId" [eventId]="eventId"
                        [pageInformation]="getTrackingInformation(category, item)">
                            {{ item.question }}
                        </div>
                    </div>
                    <div class='pr-0 button-container ml-auto'>
                        <div class='float-right'>
                            <cp-icon size='small' class='chevron' [rotated]='item.expanded' name='chevron-down'>
                            </cp-icon>
                        </div>
                    </div>
                </div>
                <div *ngIf='item.expanded' class='question-content col-12'>
                    <div [innerHTML]='item._sanitizedAnswer' class='description mb-2'
                        glossary-injection [organisationId]="organisationId" [eventId]="eventId"
                        [pageInformation]="getTrackingInformation(category, item)">
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>
