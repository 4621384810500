<div class='loading-container' *ngIf='loadingTabs'>
    <cp-delayed-spinner class='m-auto'></cp-delayed-spinner>
</div>

<div class='content' [class.hidden]='loadingTabs'>
    <div class='tab-header d-flex justify-content-around' *ngIf='showTabs'>
        <button class='tab-button mr-3' [class.active]='currentTab === "Briefings"' (click)='onClickBriefings()'>
            <span [translate]='"general.text.assignments"'>
                Assignments
            </span>
        </button>

        <button class='tab-button' [class.active]='currentTab === "Checklist"' (click)='onClickChecklist()'>
            <span [translate]='"general.text.checklist"'>
                Checklist
            </span>
        </button>
    </div>

    <mat-tab-group class='invisible-tabs h-100 no-gutters' [selectedIndex]='selectedTabIndex'>
        <mat-tab label='briefings'>
            <div class='container'>
                <cp-task-overview (tasksLoaded)='tasksLoaded($event)'></cp-task-overview>
            </div>
        </mat-tab>
        <mat-tab label='checklist'>
            <cp-checklist-overview></cp-checklist-overview>
        </mat-tab>
    </mat-tab-group>
</div>
