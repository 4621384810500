// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    envName: 'dev',
    production: false,
    useServiceWorker: true,
    useCaptcha: false,
    vapidKey: 'BNOIGrSMPmLKyT417dnVpOZ-znYaTicbPJfMHdVTlrgbOxj41tGCp1-YHlWv9PgMKu1lGn2DiXlJgVDHAifJda4',
    apiLink: 'https://catchphrase-dev.westeurope.cloudapp.azure.com/api',
    cmsLink: 'https://catchphrase-dev.westeurope.cloudapp.azure.com/cms',
    admin: 'https://admin.dev.catchphrase.io',
    host: 'https://catchphrase-dev.westeurope.cloudapp.azure.com',
    whitelabelHostSuffix: '.whitelabel.dev.catchphrase.io',
    tracking: true,
    whiteListJwt: [
        'catchphrase-dev.westeurope.cloudapp.azure.com'
    ]
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
