<div mat-dialog-content>
    <div>
        <span [translate]="confirmTranslationCode"
        [translateParams]='{ organisationName: data.organisationName, eventName: data.eventName, roleName: data.roleName }'></span>
    </div>
    <div>
        <span *ngIf="errorSaving" class="text-danger" [translate]="'account.text.account_link_error_message'">
            Something went wrong while trying to link your account. Please try again later.
        </span>
    </div>
</div>
<div mat-dialog-actions>
    <button mat-raised-button class="cp-button" color="accent" (click)="onClickCancel()">
        <span [translate]="'general.button.cancel'">
            Cancel
        </span>
    </button>
    <button mat-raised-button class="cp-button" color="primary" [loading]="saving" (click)="onClickConfirm()">
        <span [translate]="'general.button.confirm'">
            Confirm
        </span>
    </button>
</div>
