<div class="image-container text-center" [class.loading]='loading' [style]='getContainerStyle()'
    (click)='openOverlay()'>
    <div *ngIf="showOverlay" class="overlay"></div>
    <img #image [src]='safeImageUrl' class='img-fluid' (load)='onImageLoad()' (error)="OnImageLoadError()"
        [ngClass]='getClasses()' [style]='getStyle()' [class.cp-rounded]='rounded'>
    <div class="image-overlay d-flex justify-content-center align-items-center" *ngIf='loading'>
        <div *ngIf='hasPlaceholder'>
            <ng-content></ng-content>
        </div>
        <div [class.spinner-container]='!hasPlaceholder' [class.placeholder-spinner]='hasPlaceholder'>
            <mat-spinner [class.position-absolute]='hasPlaceholder' *ngIf='spinnerDiameter; else noWidth'
                [diameter]='spinnerDiameter' color='primary'></mat-spinner>
            <ng-template #noWidth>
                <mat-spinner [class.position-absolute]='hasPlaceholder' color='primary'></mat-spinner>
            </ng-template>
        </div>
    </div>
    <div class="alert alert-warning" *ngIf="errorLoading && showWarningOnError">
        <span [translate]="'image.text.load_error'">
            There was an error loading an image
        </span>
    </div>
</div>
