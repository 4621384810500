<div class='wiki-note-container' [class.off-screen]='!showing' [class.animating]='animating'>
    <header class='wiki-note-header' [style.top.px]='headerHeight'>
        <a class='back-button' (click)='onClickBack()'>
            <cp-icon name='arrow-left' color='accent' size='small'></cp-icon>
            <span>
                {{ wikiTabTitle }}
            </span>
        </a>
    </header>
    <div class='note'>
        <textarea matInput cdkTextareaAutosize class='wiki-note-input' [(ngModel)]='currentNote'
            (ngModelChange)='onNoteChanged($event)'></textarea>
    </div>
</div>
<button class='wiki-button' (click)='onClickWikiButton()' [class.inactive]='showing' *ngIf='enabled'>
    <cp-icon name='edit' color='white' size='small' strokeSize='small'></cp-icon>
</button>
