<ng-container *ngIf="quizPage">
    <div *ngFor="let question of quizPage?.questions; let questionIndex = index" class="question"
        [ngClass]="getQuizClasses(questionIndex)">
        <div *ngIf="question.imageBlobId" class="question-image-container w-100 d-flex justify-content-center">
            <cp-image [blobId]="question.imageBlobId" [organisationId]="organisationId" width="100%" height="auto"
            [canOverlayZoom]='true'></cp-image>
        </div>
        <div class='px-3 mt-2 pt-2 question-wrapper'>
            <div class="small-text">Question {{question.position + 1}} of {{quizPage?.questions?.length}}</div>
            <div class="mb-3" [innerHTML]="trustContent(question.questionText)"></div>
            <div class="mb-1 small-text font-italic">
                <span
                    [innerHTML]="(question.multipleChoice ? 'general.text.multiple_answers_possible' : 'general.text.only_one_answer_possible') | translate">
                </span>
            </div>
            <div class="row" [ngClass]="{'selection-made': question?.selectedAnswers?.length || 0 > 0}"
                [ngSwitch]="question.questionType">
                <ng-container *ngSwitchCase="questionTypes.Text">
                    <div class="col-12 text-answer " *ngFor="let answer of question.answers; let answerIndex = index">
                        <button mat-stroked-button class="cp-button mb-2 w-100 multiline-button"
                            [disableRipple]='question.finished === true'
                            (click)="selectAnswer(questionIndex, answerIndex)"
                            [ngClass]="{'selected': isSelected(questionIndex, answerIndex), 'wrong': answer.isCorrect === false, 'correct': answer.isCorrect === true}">
                            <div class='answer-text' [innerHTML]="answer.content"></div>
                        </button>
                        <div *ngIf="answer.isCorrect === true || isSelected(questionIndex, answerIndex)"
                            class="correct-indicator" [class.correct]='answer.isCorrect'
                            [class.selected]='isSelected(questionIndex, answerIndex)'>
                            <cp-icon color="white" name="check" size="small"></cp-icon>
                        </div>
                        <div *ngIf="answer.isCorrect === false" class="correct-indicator wrong">
                            <cp-icon color="white" name="x" size="small"></cp-icon>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngSwitchCase="questionTypes.Image">
                    <div class="col-6 image-answer-container mb-3"
                        *ngFor="let answer of question.answers; let answerIndex = index">
                        <a class="image-answer" (click)="selectAnswer(questionIndex, answerIndex)" matRipple
                            [matRippleDisabled]='question.finished === true'
                            [class.selected]='isSelected(questionIndex, answerIndex)'
                            [class.wrong]='answer.isCorrect === false' [class.correct]='answer.isCorrect'>
                            <cp-image [blobId]="answer.content" [organisationId]="organisationId"
                                height="calc(50vw - 30px)" width="100%"></cp-image>
                            <div *ngIf="answer.isCorrect || isSelected(questionIndex, answerIndex)"
                                class="correct-indicator" [class.correct]='answer.isCorrect'
                                [class.selected]='isSelected(questionIndex, answerIndex)'>
                                <cp-icon color="white" name="check" size="x-small"></cp-icon>
                            </div>
                            <div *ngIf="answer.isCorrect === false" class="correct-indicator wrong">
                                <cp-icon color="white" name="x" size="x-small"></cp-icon>
                            </div>
                        </a>
                    </div>
                </ng-container>
            </div>
            <div *ngIf="!question.finished; else feedbackRef" class="d-flex justify-content-center">
                <button mat-flat-button class="cp-button mt-3" color="primary" (click)="confirmAnswer()"
                    [loading]="answering" [disabled]="!isConfirmEnabled(questionIndex)">
                    <span [translate]="'briefing.button.confirm_answer'">
                        Confirm your answer
                    </span>
                </button>
            </div>
            <ng-template #feedbackRef>
                <ng-container *ngIf="question.feedback">
                    <div class="small-text mt-3">
                        <span [translate]="'briefing.text.feedback'">
                            Feedback
                        </span>
                    </div>
                    <div [innerHTML]="question.feedback"></div>
                </ng-container>
            </ng-template>
        </div>
    </div>
</ng-container>
