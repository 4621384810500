import { ErrorHandler } from '@angular/core';
import { LoggingService } from '@core/services';
import { tap } from 'rxjs';

export class ErrorLogHandler implements ErrorHandler {
    constructor(
        private logging: LoggingService
    ) { }
    
    handleError(error: any) {
        this.logging.logError(error);
        throw new Error(error);
    }
}

export function errorLogTap<T>(logging: LoggingService, organisationId?: number) {
    return tap<T>({
        error: (error: any) => {
            logging.logError(error, organisationId)
        }
    });
}
