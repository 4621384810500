export interface StylingModel {
    hasLogo: boolean;

    colorOne?: string;
    colorOneText?: TextColor;

    colorTwo?: string;
    colorTwoText?: TextColor;

    useEventLogo: boolean;
}

export type TextColor = 'Dark' | 'Light';
