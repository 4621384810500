import { Component, Input, OnInit } from '@angular/core';
import { DividerSectionContent, DividerSectionModel } from './models';

@Component({
    selector: 'cp-divider-section',
    templateUrl: './divider-section.component.html',
    styleUrls: ['./divider-section.component.scss']
})
export class DividerSectionComponent implements OnInit {

    @Input()
        section!: DividerSectionModel;

    @Input()
        inBriefing = false;

    content?: DividerSectionContent;

    ngOnInit(): void {
        this._parseComplexContent();
    }

    private _parseComplexContent(): void {
        const sectionModel = this.section as DividerSectionModel;
        sectionModel._content = JSON.parse(sectionModel.content || '{}');
        this.content = sectionModel._content;
    }
}
