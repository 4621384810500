<ng-container *ngIf="loading; else loadError">
    <div class="spinner-container d-flex justify-content-center align-items-center">
        <cp-delayed-spinner></cp-delayed-spinner>
    </div>
</ng-container>

<ng-template #loadError>
    <ng-container *ngIf="errorLoading; else content">
        <div class="mt-5 alert alert-danger text-center">
            <span [translate]="'evaluation.text.evaluation_load_error_message'">
                Something went wrong while trying to load your evaluation task. You will be sent back to the task
                overview shortly. Please try again later!
            </span>
        </div>
    </ng-container>
</ng-template>

<ng-template #content>
    <div class="d-flex flex-column page-container start-page" [class.off-screen]='pageIndex!==0'>
        <cp-start-page [organisationId]='organisationId' [eventId]='eventId' [eventName]='eventName'
            [deadline]='deadline' [organisationStyling]='organisationStyling'
            (evaluationStartClicked)='startEvaluation()'></cp-start-page>
    </div>
    <div class="d-flex flex-column page-container" [class.off-screen]='pageIndex !== 1' [class.future]='pageIndex < 1'>
        <cp-mood-indicator [evaluationData]='evaluationData' [error]='errorSaving' [eventName]='eventName'
            (touched)='pagesTouched[1] = true;'>
        </cp-mood-indicator>
    </div>
    <div *ngFor="let point of evaluationPoints; let index = index" class="d-flex flex-column page-container"
        [class.off-screen]='pageIndex !== (2 + index)' [class.future]='pageIndex < (2 + index)'>
        <div class="question-page">
            <cp-input-picker [questionResponse]='evaluationData.questionResponses[index]' [evaluationPoint]='point'
                [error]='errorSaving' *ngIf="point.hasNumberRating" [eventName]='eventName'
                (touched)='pagesTouched[2 + index] = true;'></cp-input-picker>
            <div class="input-form" *ngIf="point.hasOpenFeedback">
                <div class="label">
                    <span [translate]="'evaluation.text.open_feedback'">
                        Open feedback
                    </span>
                </div>
                <mat-form-field class="mt-2 w-100" appearance="outline">
                    <textarea matInput [placeholder]='"evaluation.text.open_answer_placeholder" | translate' class="textarea-input"
                        [(ngModel)]="evaluationData.questionResponses[index].feedback"
                        (ngModelTouched)="setTouchedConditional(2 + index, point)"></textarea>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="d-flex flex-column page-container" [class.off-screen]='pageIndex !== openFeedbackPageIndex'
        [class.future]='pageIndex < openFeedbackPageIndex' *ngIf="hasOpenFeedbackPage">
        <cp-open-feedback-page [evaluationData]='evaluationData' [error]='errorSaving' [eventName]='eventName'
            [active]='pageIndex === openFeedbackPageIndex' [generalFeedback]="allowGeneralFeedback"
            [followup]="allowFollowup" [requireFollowupReason]="requireFollowupReason">
        </cp-open-feedback-page>
    </div>
    <div class="d-flex flex-column page-container" [class.off-screen]='pageIndex !== thankYouPageIndex'
        [class.future]='pageIndex < thankYouPageIndex'>
        <cp-thank-you-page (finishEvaluation)='finishEvaluation()' [loading]='finishing' [error]='errorSaving'>
        </cp-thank-you-page>
    </div>
</ng-template>

<footer class="view-footer pt-3" [class.off-screen]="pageIndex === 0 || pageIndex === thankYouPageIndex"
    [class.transitioning]="changingPages">
    <nav class="d-flex justify-content-center mb-3">
        <button mat-stroked-button class="cp-button d-flex justify-content-center mr-5 px-3"
            (click)="goToPreviousPage()" [disabled]="!isPreviousButtonEnabled()">
            <cp-icon name="arrow-left" size="small"></cp-icon>
            <span [translate]="'briefing.button.previous_screen'" class="d-inline-block ml-1">
                Previous
            </span>
        </button>
        <button mat-raised-button class="cp-button d-flex justify-content-center" color="primary"
            (click)="goToNextPage()" [disabled]="!isNextButtonEnabled()" [loading]="saving">
            <span [translate]="'briefing.button.next_screen'" class="d-inline-block mr-1">
                Next
            </span>
            <cp-icon name="arrow-right" size="small"></cp-icon>
        </button>
    </nav>
    <div class="w-100 d-flex justify-content-center px-1">
        <div *ngFor="let pip of progressPipLengthArray; let i = index" class="progress-pip"
            [style.width]="progressPipWidth">
            <div class="progress-pip-inner" [class.active]="pageIndex === i" [class.past]="(pageIndex || 0) > i"></div>
        </div>
    </div>
</footer>
