import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ComponentRef, Injectable } from '@angular/core';
import { ImageViewerOverlayComponent } from './image-viewer/image-viewer.component';
import { PdfViewerOverlayComponent } from './pdf-viewer/pdf-viewer.component';

@Injectable({
    providedIn: 'root'
})
export class OverlayService {

    constructor(
        private overlay: Overlay,
    ) { }

    public OpenImageOverlay(): ComponentRef<ImageViewerOverlayComponent> {
        const overlayRef = this._createOverlayRef();
        const componentPortal = new ComponentPortal(ImageViewerOverlayComponent);
        const overlayCompRef = overlayRef.attach(componentPortal);
        return overlayCompRef;
    }

    public OpenPdfOverlay(): ComponentRef<PdfViewerOverlayComponent> {
        const overlayRef = this._createOverlayRef();
        const componentPortal = new ComponentPortal(PdfViewerOverlayComponent);
        const overlayCompRef = overlayRef.attach(componentPortal);
        return overlayCompRef;
    }

    public ResetUserZoom(): void {
        const viewportMeta = document.querySelector<HTMLMetaElement>('meta[name="viewport"]');
        if (viewportMeta) {
            viewportMeta.content = 'width=device-width, minimum-scale=1.0, maximum-scale=1.0, initial-scale=1.0';
        }

        setTimeout(() => {
            const viewportMeta2 = document.querySelector<HTMLMetaElement>('meta[name="viewport"]');
            if (viewportMeta2) {
                viewportMeta2.content = 'width=device-width, initial-scale=1.0';
            }
        });
    }

    private _createOverlayRef(): OverlayRef {
        const positionStrategy = this.overlay.position()
            .global()
            .centerHorizontally()
            .centerVertically();

        const overlayConfig = new OverlayConfig({
            hasBackdrop: true,
            backdropClass: 'black-backdrop',
            scrollStrategy: this.overlay.scrollStrategies.block(),
            positionStrategy
        });

        return this.overlay.create(overlayConfig);
    }
}
