import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ANIMATION_MODULE_TYPE } from '@angular/platform-browser/animations';
import { RadialProgressBarModel } from './models/radial-progress-bar-model';

@Component({
    selector: 'cp-radial-progress',
    templateUrl: './radial-progress.component.html',
    styleUrls: ['./radial-progress.component.scss'],
    providers: [
        { provide: ANIMATION_MODULE_TYPE, useValue: 'BrowserAnimations' },
    ],
})
export class RadialProgressComponent implements OnInit, OnChanges {
    @Input() size!: number;
    @Input() bars!: RadialProgressBarModel[];
    @Input() label!: RadialProgressLabel;
    @Input() customLabel?: string;
    @Input() transparentLabel = false;

    sortedBars!: RadialProgressBarModel[];

    currentValues: number[] = [];

    private readonly ANIMATE_MINIMUM_VALUE = 0.001;

    ngOnInit(): void {
        this._sortBars();
        this._initializeValues();

        setTimeout(() => {
            this._setValues();
        }, 500);
    }

    ngOnChanges(): void {
        this._sortBars();
        this._initializeValues();

        setTimeout(() => {
            this._setValues();
        }, 500);
    }

    getColorClass(bar: RadialProgressBarModel): string {
        switch (bar.color) {
        case 'green': return 'green';
        case 'red': return 'red';
        case 'orange': return 'orange';
        case 'blue': return 'blue';
        default: return 'green';
        }
    }

    private _setValues(): void {
        if (this.currentValues === undefined) {
            return;
        }

        this.currentValues.forEach((_, index, array) => {
            if (this.sortedBars[index]) {
                array[index] = this.sortedBars[index].value;
            }
        });
    }

    private _initializeValues(): void {
        this.bars.forEach(bar => {
            if (bar.value) {
                // Doesn't animate from 0
                this.currentValues.push(this.ANIMATE_MINIMUM_VALUE);
            }
            else {
                this.currentValues.push(0);
            }
        });
    }

    private _sortBars(): void {
        // Sort the bars so the longest gets drawn at the bottom
        this.sortedBars = this.bars.sort((a, b) => b.value - a.value);
    }
}

export type RadialProgressLabel = 'percentage' | 'decimal' | 'custom' | 'content' | 'none';
