export interface EventLandingInfo {
    eventName: string;
    eventDate?: Date;
    endDate?: Date;
    videoId?: number;
    hasTime: boolean;
    isUrgent: boolean;
    totalProgress: number;
    hasScore: boolean;
    hasProgress: boolean;
    averageScore?: number;
}
