import { OverviewEvent } from './overview-event.model';

export interface EventOverview {
    videoId?: number;
    imageUrl?: string;

    upcomingEvents: OverviewEvent[];
    evaluationEvents: OverviewEvent[];
    finishedEvents: OverviewEvent[];
}
