import { takeUntil } from 'rxjs/operators';
import { GlobalsService, AlertService, ConnectionService } from '@core/services';
import { Component, Input, OnChanges, OnInit, SimpleChanges, Output, EventEmitter, OnDestroy } from '@angular/core';
import { WikiDataService } from '@wiki/services';
import { Subject } from 'rxjs';

@Component({
    selector: 'cp-wiki-notes',
    templateUrl: './wiki-notes.component.html',
    styleUrls: ['./wiki-notes.component.scss']
})
export class WikiNotesComponent implements OnInit, OnChanges, OnDestroy {
    @Input()
        organisationId!: number;

    @Input()
        eventId!: number;

    @Input()
        wikiTabId!: number;

    @Input()
        wikiTabTitle!: string;

    @Input()
        headerHeight!: number;

    @Input()
        enabled = true;

    @Output()
        notesOpen: EventEmitter<boolean> = new EventEmitter<boolean>();

    currentNote!: string;

    offline = false;
    showing = false;
    animating = false;

    private destroy$ = new Subject<boolean>();

    private saveTimeout?: any;

    private readonly DEBOUNCE_TIME = 3000;

    private notes: { [tabId: number]: string } = {};

    private _noteToSave?: { id: number; note: string };

    constructor(
        private data: WikiDataService,
        private alert: AlertService,
        private globals: GlobalsService,
        private connection: ConnectionService
    ) { }

    ngOnInit(): void {
        this.offline = !this.connection.online;
        this.connection.onlineState$.pipe(takeUntil(this.destroy$))
            .subscribe(connected => {
                this.offline = !connected;

                if (!this.offline && this._noteToSave) {
                    this._delayedSaveNote();
                }
            });

        if (this.organisationId !== undefined && this.eventId !== undefined && this.wikiTabId !== undefined && this.enabled) {
            this.data.getWikiTabNote(this.organisationId, this.eventId, this.wikiTabId, this.offline).subscribe((response) => {
                this.notes[this.wikiTabId] = response.note;
                this.currentNote = response.note;
            });
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.wikiTabId && changes.wikiTabId.previousValue !== changes.wikiTabId.currentValue) {
            if (this.notes[changes.wikiTabId.currentValue] === undefined) {
                this.data.getWikiTabNote(this.organisationId, this.eventId, this.wikiTabId, this.offline).subscribe((response) => {
                    this.notes[changes.wikiTabId.currentValue] = response.note;
                    this.currentNote = response.note;
                });
            }
            else {
                this.currentNote = this.notes[changes.wikiTabId.currentValue];
            }
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    onClickBack(): void {
        if (this.showing === false) {
            return;
        }

        this.showing = false;
        this.globals.changeHeader({ titleKey: 'wiki.text.event_wiki', buttonName: 'x' });
        this.notesOpen.emit(false);

        this.animating = true;

        setTimeout(() => {
            this.animating = false;
        }, 500);

        if (this.currentNote) {
            if (this.saveTimeout) {
                clearTimeout(this.saveTimeout);
            }
            this._saveNote(this.currentNote);
        }
    }

    onClickWikiButton(): void {
        if (this.showing === false && this.enabled) {
            this.showing = true;
            this.globals.changeHeader({ titleKey: 'wiki.text.event_wiki' });
            this.globals.hideTooltips();
            window.scroll(0, 0);
            setTimeout(() => {
                this.notesOpen.emit(true);
            }, 500);
        }
    }

    onNoteChanged(newNote: string): void {
        if (newNote) {
            if (this.saveTimeout) {
                clearTimeout(this.saveTimeout);
            }

            this.saveTimeout = setTimeout(() => {
                this._saveNote(newNote);
            }, this.DEBOUNCE_TIME);
        }
    }

    private _saveNote(newNote: string): void {
        this.data.postWikiTabNote(this.organisationId, this.eventId, this.wikiTabId, newNote, this.offline).subscribe({
            next: () => {
                this.notes[this.wikiTabId] = newNote;

                if (this.offline) {
                    this._noteToSave = { id: this.wikiTabId, note: newNote };
                }
            },
            error: () => {
                this.alert.postMessage('wiki.error.tab_note_save_failed', 'Error', 3000);
            }
        });
    }

    private _delayedSaveNote(): void {
        if (this._noteToSave) {
            this.data.postWikiTabNote(this.organisationId, this.eventId, this._noteToSave.id, this._noteToSave.note, false).subscribe(
                () => {
                    if (this._noteToSave) {
                        this.notes[this._noteToSave.id] = this._noteToSave.note;
                    }

                    this._noteToSave = undefined;
                }
            );
        }
    }
}
