import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthenticationService } from '@core/services';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class EmailAlreadyConfirmedGuard  {
    constructor(private auth: AuthenticationService, private router: Router) { }

    canActivate(_: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return from(this.auth.isLoggedIn()).pipe(
            map((authorized: boolean) => {
                if (authorized) {
                    if (this.auth.claims?.email_verified) {
                        this.router.navigate(['']);
                        return false;
                    }
                }
                return true;
            })
        );
    }
}
