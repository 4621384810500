<div class="row mb-2">
    <ng-container *ngIf="content?.HasTextOnRight">
        <ng-container *ngTemplateOutlet="image"></ng-container>
    </ng-container>
    <div [innerHTML]="safeText" class="image-text-section col-6" [class.right]="content?.HasTextOnRight"
        glossary-injection [organisationId]="organisationId" [eventId]="eventId"
        [pageInformation]="pageInformation">
    </div>
    <ng-container *ngIf="!content?.HasTextOnRight">
        <ng-container *ngTemplateOutlet="image"></ng-container>
    </ng-container>
</div>
<ng-template #image>
    <div class="col-6 image-container" [class.left]="content?.HasTextOnRight">
        <cp-image [imageClasses]='imageClasses' [code]='code'
        [blobId]='this.content?.ImageBlobId || ""' [organisationId]="organisationId"
        [showWarningOnError]="true" [width]="'100%'" [canOverlayZoom]='true'></cp-image>
    </div>
</ng-template>