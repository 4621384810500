import { Component, Input } from '@angular/core';
import { ThemePalette } from '@angular/material/core';

@Component({
    selector: 'cp-mini-timeline-slider',
    templateUrl: './mini-timeline-slider.component.html',
    styleUrls: ['./mini-timeline-slider.component.scss']
})
export class MiniTimelineSliderComponent {
    @Input()
        daysLeft!: number;

    @Input()
        color: SliderColor = 'blue';


    readonly SIZE = 14;
    readonly MIN = 1;

    pips = new Array(this.SIZE);

    get positionOnSlider(): number {
        return Math.max(this.SIZE - this.daysLeft, 0);
    }

    getClass(): { [klass: string]: boolean } {
        return {
            primary: this.color === 'primary',
            blue: this.color === 'blue',
        };
    }
}

export type SliderColor = ThemePalette | 'blue';
