<div *ngIf='guidedTourActive && currentStep'>
    <div class='guided-tour-mask'></div>
    <div class='guided-tour-spotlight' [style.top.px]="overlayTop" [style.left.px]="overlayLeft"
        [style.height.px]="overlayHeight" [style.width.px]="overlayWidth" (click)="handleSpotlightClick()"></div>
    <div #tourtip class="tour-step" [class.tour-step-below]="currentStep.belowHighlight"
        [class.tour-step-above]="!currentStep.belowHighlight" [class.tour-step-page]="!currentStep.selector"
        [style.top.px]="topPosition" [style.left.px]="leftPosition" [style.transform]="transform">
        <div *ngIf="currentStep.selector" [style.left.px]="arrowLeftPosition" class="tour-arrow"></div>
        <div class="tour-block">
            <div class="tour-content" [innerHTML]="currentStep.textKey | translate"></div>
            <div class="tour-buttons">
                <button class="skip-button" (click)="onSkipClick()">
                    <span [translate]="'general.text.skip'">
                        Skip
                    </span>
                </button>
                <div class="nav-buttons">
                    <button class="back-button mr-2" (click)="onPreviousClick()" *ngIf="showPrevious">
                        <cp-icon name="arrow-left" size="x-small"></cp-icon>
                    </button>
                    <button class="next-button" (click)="onNextClick()" *ngIf="!currentStep.nextDisabled">
                        <cp-icon [name]="nextButtonIcon" size="x-small"></cp-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
