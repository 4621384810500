import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, ConnectionService, GlobalsService } from '@core/services';
import { DateUtils } from '@core/utils';
import { TranslatePipe } from '@ngx-translate/core';
import { OverviewDataService } from '@overview/services';
import { LocalizedDatePipe } from '@shared/pipes';
import { Subscription, timer, Subject } from 'rxjs';
import { concatMap, take, takeUntil } from 'rxjs/operators';
import { NotificationModel } from './models';

@Component({
    templateUrl: './notifications-overview.component.html',
    styleUrls: ['./notifications-overview.component.scss']
})
export class NotificationsOverviewComponent implements OnInit, OnDestroy {
    public readonly DATE_FORMAT = 'longDate';
    public readonly TIME_FORMAT = 'shortTime';

    organisationId!: number;
    eventId!: number;

    eventName!: string;
    eventDate?: Date;
    eventEndDate?: Date;
    eventHasTime = false;

    urgentNotifications: NotificationModel[] = [];
    unreadNotifications: NotificationModel[] = [];
    readNotifications: NotificationModel[] = [];

    totalReadNotifications!: number;

    pageIndex = 1;
    pollingFailCount = 0;

    loading = true;
    errorLoading = false;
    loadingMore = false;

    offline = false;
    offlineOnLoad = false;

    private destroy$ = new Subject<boolean>();

    private timeout$: any;
    private _notificationPoll?: Subscription;

    private readonly READ_NOTIFICATIONS_PAGE_SIZE = 5;
    private readonly POLLING_DELAY = 10000;

    constructor(
        globals: GlobalsService,
        private route: ActivatedRoute,
        private router: Router,
        private data: OverviewDataService,
        private translatePipe: TranslatePipe,
        private alert: AlertService,
        private connection: ConnectionService,
    ) {
        globals.showFooter();
        globals.changeHeader({ titleKey: 'notifications.text.notifications' });
    }

    get showLoadMore(): boolean {
        return this.totalReadNotifications > this.readNotifications.length && !this.offline;
    }

    get hasNoNotifications(): boolean {
        return this.totalReadNotifications === 0 && this.urgentNotifications.length === 0 && this.unreadNotifications.length === 0;
    }

    get showEndDate(): boolean {
        if (!this.eventDate || !this.eventEndDate) {
            return false;
        }

        return !DateUtils.IsSameDate(this.eventDate, this.eventEndDate);
    }

    ngOnInit(): void {
        this.organisationId = this.route.snapshot.params.organisationId;
        this.eventId = this.route.snapshot.params.eventId;

        this.offline = !this.connection.online;
        this.offlineOnLoad = this.offline;

        if (!this.offlineOnLoad) {
            this._loadNotifications();
        }

        this.connection.onlineState$.pipe(takeUntil(this.destroy$)).subscribe(
            (result) => {
                if (result && this.offlineOnLoad) {
                    this._loadNotifications();
                    this.offlineOnLoad = false;
                }

                this.offline = !result;

                if (this.offline) {
                    this._notificationPoll?.unsubscribe();
                    this._notificationPoll = undefined;
                } else {
                    this._pollNotifications();
                }
            }
        );
    }

    ngOnDestroy(): void {
        if (this.timeout$) {
            clearTimeout(this.timeout$);
        }

        this._notificationPoll?.unsubscribe();
        this._notificationPoll = undefined;

        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    archiveUrgentNotification(notificationId: number): void {
        const archivedNotificationId = this.urgentNotifications.findIndex(x => x.id === notificationId);
        if (archivedNotificationId >= 0) {
            const archivedNotification = this.urgentNotifications.splice(archivedNotificationId, 1);
            if (archivedNotification.length > 0) {
                this.readNotifications.unshift(archivedNotification[0]);
            }
        }
    }

    archiveUnreadNotification(notificationId: number): void {
        const archivedNotificationId = this.unreadNotifications.findIndex(x => x.id === notificationId);
        if (archivedNotificationId >= 0) {
            const archivedNotification = this.unreadNotifications.splice(archivedNotificationId, 1);
            if (archivedNotification.length > 0) {
                this.readNotifications.unshift(archivedNotification[0]);
            }
        }
    }

    loadMoreReadNotifications(): void {
        this.loadingMore = true;
        const loadedUnreadCount = this.urgentNotifications.length + this.unreadNotifications.length;
        this.data.loadMoreReadNotifications(this.organisationId, this.eventId, this.pageIndex,
            this.READ_NOTIFICATIONS_PAGE_SIZE, loadedUnreadCount)
            .pipe(take(1)).subscribe((response) => {
                this.readNotifications = this.readNotifications.concat(response);
                this.pageIndex++;
                this.loadingMore = false;
            }, () => {
                this.alert.postMessage('notifications.error.loading_more', 'Error');
                this.loadingMore = false;
            });
    }

    onClickCtaButton(ctaParams: string[]): void {
        let fragment = ctaParams.filter(x => x[0] === '#')[0];

        if (fragment) {
            fragment = fragment.substring(1);
            this.router.navigate(ctaParams.filter(x => x[0] !== '#'), { fragment });
        } else {
            this.router.navigate(ctaParams);
        }
    }

    // Translate pipe takes any type as parameter

    getTranslation(key: string, params?: any): string {

        let paramsObj: any = {};

        if (params) {
            try {
                paramsObj = JSON.parse(params);
            }
            catch {}
        }

        if (params && (key === 'reports.notification.new_report_invite_content_with_deadline'
            || key === 'reports.notification.report_invite_reminder_content_with_deadline')) {
            if (paramsObj.groupDeadline) {
                paramsObj.groupDeadline = new Date(paramsObj.groupDeadline).toLocaleDateString();
                params = JSON.stringify(paramsObj);
            }
        }
        return this.translatePipe.transform(key, paramsObj);
    }

    private _loadNotifications(): void {
        this.data.getNotificationOverview(this.organisationId, this.eventId)
            .pipe(take(1)).subscribe((response) => {
                this.eventName = response.eventName;
                this.eventDate = response.eventDate;
                this.eventEndDate = response.eventEndDate;
                this.eventHasTime = response.eventHasTime;

                this.urgentNotifications = response.urgentNotifications;
                this.unreadNotifications = response.unreadNotifications;
                this.readNotifications = response.readNotifications;

                this.totalReadNotifications = response.totalReadNotifications;

                this._pollNotifications();

                this.loading = false;
            }, () => {
                this.errorLoading = true;
                this.loading = false;

                this.timeout$ = setTimeout(() => {
                    this.router.navigate([this.organisationId, this.eventId]);
                }, 3000);
            });
    }

    private _pollNotifications(): void {
        this._notificationPoll = timer(this.POLLING_DELAY)
            .pipe(
                take(1),
                concatMap(() => this.data.pollNotifications(this.organisationId, this.eventId))
            ).subscribe(
                (result) => {
                    this.urgentNotifications = result.urgentNotifications.concat(this.urgentNotifications);
                    this.unreadNotifications = result.unreadNotifications.concat(this.unreadNotifications);

                    this.pollingFailCount = 0;
                    this._pollNotifications();
                }, () => {
                    this.pollingFailCount++;

                    if (this.pollingFailCount >= 5) {
                        this.alert.postMessage('notifications.error.exceeded_polling_fail_count', 'Error', 3000);

                        // Timeout for 10 minutes before retrying.
                        setTimeout(() => {
                            this._pollNotifications();
                        }, 60 * this.POLLING_DELAY);
                    }
                });
    }
}
