import { AlertService, ConnectionService } from '@core/services';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DateUtils } from '@core/utils';
import { TranslatePipe } from '@ngx-translate/core';
import { ChecklistItemModel, ChecklistModel } from '@overview/models';
import { OverviewDataService } from '@overview/services';
import { take, takeUntil } from 'rxjs/operators';
import { ConfirmDialogComponent, ConfirmDialogResult, ConfirmDialogData } from '@shared/components';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';

@Component({
    selector: 'cp-checklist-overview',
    templateUrl: './checklist-overview.component.html',
    styleUrls: ['./checklist-overview.component.scss']
})
export class ChecklistOverviewComponent implements OnInit, OnDestroy {
    destroy$: Subject<boolean> = new Subject<boolean>();

    public readonly DATE_FORMAT = 'mediumDate';

    public organisationId!: number;
    public eventId!: number;

    public checklists!: ChecklistModel[];

    filter = '';

    loading = false;
    errorLoading = false;
    isOffline = false;
    offlineNoCache = false;

    constructor(
        private data: OverviewDataService,
        private route: ActivatedRoute,
        private translatePipe: TranslatePipe,
        private alert: AlertService,
        private dialog: MatDialog,
        private connection: ConnectionService,
    ) { }

    get isIOS(): boolean {
        return [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ].includes(navigator.platform);
    }

    get filteredChecklists(): ChecklistModel[] {
        return this.checklists.filter(x => this.getFilteredItems(x).length > 0);
    }

    ngOnInit(): void {
        this.organisationId = this.route.snapshot.params.organisationId;
        this.eventId = this.route.snapshot.params.eventId;

        this.loading = true;

        this.isOffline = !this.connection.online;

        this.connection.onlineState$.pipe(takeUntil(this.destroy$))
            .subscribe((connected) => {
                this.isOffline = !connected;
                if (connected && this.offlineNoCache) {
                    this._getChecklists();
                    this.offlineNoCache = false;
                }
            });

        this._getChecklists();
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    checkItem(item: ChecklistItemModel, checklist: ChecklistModel, event: Event): boolean {
        event.stopPropagation();
        item.checked = !item.checked;
        this._sendCheckRequest(checklist, item);
        return false;
    }

    onClickCompletedItem(item: ChecklistItemModel, checklist: ChecklistModel, event: Event): boolean {
        event.stopPropagation();
        item.checked = !item.checked;

        if (item.checked === false) {
            const confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
                data: new ConfirmDialogData(
                    'checklist.text.confirm_uncheck_item',
                    undefined,
                    'warn',
                    undefined,
                    false,
                    'general.button.yes'
                )
            });

            confirmDialogRef.afterClosed()
                .pipe(take(1))
                .subscribe(
                    (result: ConfirmDialogResult) => {
                        if (result === 'continue') {
                            this._sendCheckRequest(checklist, item);
                        }
                        else {
                            item.checked = !item.checked;
                        }
                    }
                );
        }
        else {
            this._sendCheckRequest(checklist, item);
        }

        return false;
    }

    getDateString(date: Date): string | null {
        if (DateUtils.IsSameDate(date, new Date())) {
            return this.translatePipe.transform('general.text.today');
        }

        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);

        if (DateUtils.IsSameDate(date, tomorrow)) {
            return this.translatePipe.transform('general.text.tomorrow');
        }

        // Let the date pipe handle it
        return null;
    }

    getFilteredItems(checklist: ChecklistModel) {
        const normalisedFilter = this.filter.toUpperCase();

        return checklist.items.filter(x => x.title?.toUpperCase().includes(normalisedFilter)
            || x.responsiblePerson?.toUpperCase().includes(normalisedFilter));
    }

    getActiveItems(checklist: ChecklistModel): ChecklistItemModel[] {
        return this.getFilteredItems(checklist).filter(x => !x.isChecked).sort((a, b) => this._sortChecklistItems(a, b));
    }

    getCompletedItems(checklist: ChecklistModel): ChecklistItemModel[] {
        return this.getFilteredItems(checklist).filter(x => x.isChecked).sort((a, b) => this._sortChecklistItems(a, b));
    }

    openItem(checklist: ChecklistModel, item: ChecklistItemModel): void {
        if (this.hasContent(item)) {
            checklist.itemOpened = true;
            item.opened = true;
        }
    }

    closeItem(checklist: ChecklistModel, item: ChecklistItemModel): void {
        if (this.hasContent(item)) {
            checklist.itemOpened = false;
            item.opened = false;
        }
    }

    toggleChecklist(checklist: ChecklistModel): void {
        checklist.opened = !checklist.opened;

        if (!checklist.opened) {
            checklist.items.forEach(item => {
                item.isChecked = item.checked;
            });
        }
    }

    hasContent(item: ChecklistItemModel): boolean {
        return !!item.content || !!item.pdfId || (!!item.images && item.images.length > 0);
    }

    private _getChecklists(): void {
        this.data.getChecklists(this.organisationId, this.eventId)
            .pipe(take(1))
            .subscribe({
                next: data => {
                    this.checklists = data;
                    this._initializeItems();
                    this.loading = false;
                },
                error: () => {
                    this.loading = false;

                    if (this.connection.online) {
                        this.errorLoading = true;
                    } else {
                        this.offlineNoCache = true;
                    }
                }
            });
    }

    private _initializeItems(): void {
        if (this.checklists.length === 1) {
            this.checklists[0].opened = true;
        }

        this.checklists.forEach(checklist => {
            checklist.items.forEach(item => {
                item.checked = item.isChecked;
            });
        });
    }

    private _sendCheckRequest(checklist: ChecklistModel, checklistItem: ChecklistItemModel): void {
        this._updateCheckedAmounts(checklist, checklistItem);
        this.data.checkChecklistItem(this.organisationId, this.eventId, checklist.id, checklistItem.id, checklistItem.checked)
            .pipe(take(1))
            .subscribe({
                next: () => { },
                error: () => {
                    this.alert.postMessage('checklist.error.could_not_check_checklist_item', 'Error', 3000, 'general.text.ok');
                    checklistItem.checked = !checklistItem.checked;
                    this._updateCheckedAmounts(checklist, checklistItem);
                }
            });
    }

    private _updateCheckedAmounts(checklist: ChecklistModel, item: ChecklistItemModel): void {
        if (item.checked) {
            checklist.amountCompleted++;
            checklist.amountToDo--;
            if (item.isUrgent) {
                checklist.amountUrgent--;
            }
        } else {
            checklist.amountCompleted--;
            checklist.amountToDo++;
            if (item.isUrgent) {
                checklist.amountUrgent++;
            }
        }
    }

    private _sortChecklistItems(item1: ChecklistItemModel, item2: ChecklistItemModel): number {
        const aDate = new Date(item1.dueDate);
        if (item1.dueTime) {
            aDate.setHours(item1.dueTime.getHours(), item1.dueTime.getMinutes());
        } else {
            aDate.setHours(23, 59, 59, 59);
        }

        const bDate = new Date(item2.dueDate);
        if (item2.dueTime) {
            bDate.setHours(item2.dueTime.getHours(), item2.dueTime.getMinutes());
        } else {
            bDate.setHours(23, 59, 59, 59);
        }

        // If dates are the same, sort alphabetically by title
        if (aDate.getTime() === bDate.getTime()) {
            if (item1.title === item2.title) {
                return item1.id - item2.id;
            }
            if (item1.title === undefined) {
                return 1;
            }
            if (item2.title === undefined) {
                return -1;
            }
            const aTitle = item1.title?.toUpperCase();
            const bTitle = item2.title?.toUpperCase();
            return (aTitle < bTitle) ? -1 : ((aTitle > bTitle) ? 1 : 0)
        }

        return aDate.getTime() - bDate.getTime();
    }
}
