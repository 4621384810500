import { SafeUrl } from '@angular/platform-browser';

export class ChecklistModel {
    opened = true;
    itemOpened = false;

    constructor(
        public id: number,
        public eventId: number,
        public title: string,
        public items: ChecklistItemModel[],
        public amountToDo: number,
        public amountUrgent: number,
        public amountCompleted: number,
    ) { }
}

export class ChecklistItemModel {
    opened = false;
    checked = false;

    constructor(
        public id: number,
        public dueDate: Date,
        public isChecked: boolean,
        public isUrgent: boolean,
        public dueTime?: Date,
        public title?: string,
        public responsiblePerson?: string,
        public content?: string,
        public pdfId?: number,
        public pdfTitle?: string,
        public images?: ChecklistImageModel[],
    ) { }
}

export interface ChecklistCountModel {
    toDo: number;
    urgent: number;
    completed: number;
}

export interface ChecklistImageModel {
    position: number;
    blobId: string;
    placeholder: string;

    loading?: boolean;
    imageUrl?: SafeUrl;
}
