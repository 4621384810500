import { ThemePalette } from '@angular/material/core';
import { Component, Input } from '@angular/core';

@Component({
    selector: 'cp-icon',
    templateUrl: './catchphrase-icon.component.html',
    styleUrls: ['./catchphrase-icon.component.scss']
})
export class CatchphraseIconComponent {
    @Input() name!: string;
    @Input() color: IconColor;
    @Input() size: IconSize = 'medium';
    @Input() strokeSize: StrokeSize = 'default';
    @Input() fill: IconColor;
    @Input() rotated = false;

    getClass(): { [klass: string]: boolean } {
        return {
            'icon-xxsmall': this.size === 'xx-small',
            'icon-xsmall': this.size === 'x-small',
            'icon-small': this.size === 'small',
            'icon-medium': this.size === 'medium',
            'icon-large': this.size === 'large',
            'stroke-small': this.strokeSize === 'small',
            'fill-warn': this.fill === 'warn',
            'fill-white': this.fill === 'white',
            'fill-current': this.fill === 'currentColor',
            transparent: this.color === 'transparent',
            'white-transparent': this.color === 'white-transparent',
            white: this.color === 'white',
            grey: this.color === 'grey',
            primary: this.color === 'primary',
            warn: this.color === 'warn',
            black: this.color === 'black'
        };
    }
}

export type IconSize = 'xx-small' | 'x-small' | 'small' | 'medium' | 'large';
export type IconColor = ThemePalette | 'transparent' | 'white-transparent' | 'white' | 'black' | 'grey' | 'currentColor';
export type StrokeSize = 'small' | 'default';
