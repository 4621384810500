import { IBriefingSectionModel, BriefingSectionType } from '@core/models';

export class DropdownSectionModel implements IBriefingSectionModel {
    type: BriefingSectionType = BriefingSectionType.dropdown;

    content?: string;
    _content?: DropdownSectionContent;

    constructor(content?: string) {
        if (!content) {
            this._content = {
                identifier: '',
                title: '',
                subtitle: '',
                childSections: [],
            };
        } else {
            this._content = JSON.parse(content);
        }
    }
}

export interface DropdownSectionContent {
    identifier?: string;
    title: string;
    subtitle: string;
    childSections: IBriefingSectionModel[];
}
