import { Component, Input } from '@angular/core';

@Component({
    selector: 'cp-logo',
    templateUrl: './logo.component.html',
    styleUrls: ['./logo.component.scss']
})
export class LogoComponent {
    @Input() color: LogoColor = 'primary';
    @Input() maxWidth!: string;
    @Input() maxHeight!: string;
    @Input() translate!: string;
    // Necessary any because translate parameters have unknown signature

    @Input() translateParams?: any;
}


export declare type LogoColor = 'primary' | 'white' | undefined;
