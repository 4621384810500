import { StringGenerator, UrlResolver } from '@core/utils';
import { UserClaims, StringModel } from '@core/models';
import { EndPoint } from '@core/constants';
import { Injectable, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserManager, User } from 'oidc-client-ts';
import { userManagerSettingsFactory } from '@core/factories';
import { ConnectionService } from './connection.service';
import { WhitelabelService } from './whitelabel.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    private manager!: UserManager;
    private user: User | null = null;

    constructor(private http: HttpClient, private connection: ConnectionService, zone: NgZone, private whitelabel: WhitelabelService,
        private translate: TranslateService
    ) {
        zone.runOutsideAngular(() => {
            this.manager = new UserManager(userManagerSettingsFactory());
            this.manager.getUser().then(user => {
                this.user = user;
            });

            // Ensure we update the user after a renew event.
            this.manager.events.addUserLoaded(() => {
                this.manager.getUser().then(user => {
                    this.user = user;

                    if (this.claims?.language_code && this.translate.currentLang !== this.claims.language_code) {
                        this.translate.use(this.claims.language_code);
                    }
                });
            });
        });
    }

    get claims(): UserClaims | undefined {
        return this.user?.profile;
    }

    get userId(): string | undefined {
        return this.claims?.sub;
    }

    get isAnonymous(): boolean {
        return this.claims !== undefined && !!this.claims.anonymous_code;
    }

    get accessToken(): string | undefined {
        return this.user?.access_token;
    }

    get hasSession(): boolean {
        return !!this.user;
    }

    get hasActiveSession(): boolean {
        return !!this.user && !this.user.expired;
    }

    private get signinQueryParams(): Record<string, string | number | boolean> | undefined {
        if (this.whitelabel.whitelabelSubdomain) {
            return { whitelabel: this.whitelabel.whitelabelSubdomain };
        }

        return undefined;
    }

    async isLoggedIn(): Promise<boolean> {
        if (this.user === null) {
            this.user = await this.manager.getUser();
        }
        return this.user !== null && (!this.connection.online || !this.user.expired);
    }

    async attemptSilentSignIn(): Promise<User | null> {
        const user = await this.manager.signinSilent({
            extraQueryParams: this.signinQueryParams
        });

        if (user) {
            this.user = user;
        }

        return user;
    }

    startAuthentication(returnRoute?: string): Promise<void> {
        let state = undefined;

        // If we need a return route set it here.
        if (returnRoute) {
            state = StringGenerator.generateRandomString();
            localStorage.setItem(state, returnRoute);
        }

        return this.manager.signinRedirect({
            state,
            extraQueryParams: this.signinQueryParams
        });
    }

    async completeAuthentication(): Promise<string> {
        const user = await this.manager.signinRedirectCallback();
        this.user = user;

        // Check if a return route was stored for the state.
        if (user.state) {
            const returnRoute = localStorage.getItem(user.state as string);
            if (returnRoute) {
                localStorage.removeItem(user.state as string);
                return returnRoute;
            }
        }

        return '';
    }

    revokeAuthentication(): Promise<void> {
        this.clearStaleAuthentication();
        return this.manager.signoutRedirect();
    }

    clearStaleAuthentication(): void {
        this.manager.clearStaleState();
    }

    clearStaleUser(): Promise<void> {
        return this.manager.removeUser();
    }

    public login(username: string, password: string, returnUrl: string, recaptchaToken?: string,
        twoFactorToken?: string, recaptchaVerifiedToken?: string): Observable<StringModel | TwoFaRequiredModel> {
        return this.http.post<StringModel | TwoFaRequiredModel>(UrlResolver.getUrl(EndPoint.identity), {
            username,
            password,
            rememberLogin: true,
            returnUrl,
            recaptchaToken,
            twoFactorToken,
            recaptchaVerifiedToken
        }, {
            withCredentials: true
        });
    }

    anonymousLogin(inviteLinkCode: string, organisationId: number, returnUrl: string): Observable<StringModel> {
        return this.http.post<StringModel>(UrlResolver.getUrl(EndPoint.identity) + '/anonymous', {
            organisationId,
            inviteLinkCode,
            returnUrl
        }, {
            withCredentials: true
        });
    }
}

export interface TwoFaRequiredModel {
    twoFactorRequired: boolean;
    captchaVerifiedToken: string;
}
