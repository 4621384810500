<ng-template #input1>
    <cp-star-input [questionResponse]='questionResponse' (touched)='touched.emit()'>
    </cp-star-input>
</ng-template>

<div class='header'>
    <div class='supertitle' *ngIf='eventName'>
        <span>
            {{ eventName }}
        </span>
    </div>
    <div class='title'>
        <span>
            {{ evaluationPoint.name }}
        </span>
    </div>
    <div class='text'>
        <span>
            {{ evaluationPoint.description }}
        </span>
    </div>
</div>

<ng-container [ngTemplateOutlet]="chosenInput"></ng-container>

<div class="alert alert-danger" *ngIf='error'>
    <span [translate]="'evaluation.text.evaluation_feedback_saving_error_message'">
        Something went wrong while trying to save your feedback. Please try again!
    </span>
</div>
