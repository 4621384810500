<div class="tel-input-container">
    <button type="button" mat-button [matMenuTriggerFor]="menu" class="country-selector" (click)="openMenu($event)">
        <span class="country-selector-flag flag" [ngClass]="selectedCountry!.flagClass"></span>
        <span class="country-selector-code">+{{selectedCountry!.dialCode}}</span>
    </button>
    <mat-menu #menu="matMenu" (onMenuOpen)='fixDisappearIOSBug()'>
        <ng-template matMenuContent>
            <input *ngIf="enableSearch" class="country-search" [(ngModel)]="searchCriteria" type="text"
                [placeholder]="searchPlaceholder" (click)="$event.stopPropagation()"/>
            <button type="button" mat-menu-item class="country-list-button"
                *ngFor="let country of preferredCountriesInDropDown" (click)="onCountrySelect(country, focusable)">
                <div class="icon-wrapper">
                    <div class="flag" [ngClass]="country.flagClass"></div>
                </div>
                <div class="label-wrapper">{{country.name}} +{{country.dialCode}}</div>
            </button>
            <mat-divider *ngIf="preferredCountriesInDropDown?.length"></mat-divider>
            <ng-container *ngFor="let country of allCountries">
                <button type="button" mat-menu-item class="country-list-button" *ngIf="country | search:searchCriteria"
                    (click)="onCountrySelect(country, focusable)">
                    <div class="icon-wrapper">
                        <div class="flag" [ngClass]="country.flagClass"></div>
                    </div>
                    <div class="label-wrapper">{{country.name}} +{{country.dialCode}}</div>
                </button>
            </ng-container>
        </ng-template>
    </mat-menu>

    <input matInput type="tel" autocomplete="off" [ngClass]="cssClass" (blur)="onTouched()" autocomplete="tel-national"
        (keypress)="onInputKeyPress($event)" [(ngModel)]="phoneNumber" (ngModelChange)="onPhoneNumberChange()"
        [errorStateMatcher]="errorStateMatcher" [disabled]="disabled" #focusable>
</div>
