export type EndPointType = 'account' | 'identity' | 'translation'
    | 'tasks' | 'image' | 'organisation'
    | 'events' | 'wiki' | 'checklists'
    | 'chat' | 'notifications' | 'briefing'
    | 'documents' | 'reports' | 'tracking'
    | 'video';

export class EndPoint {
    static get account(): EndPointType { return 'account'; }
    static get identity(): EndPointType { return 'identity'; }
    static get translation(): EndPointType { return 'translation'; }
    static get tasks(): EndPointType { return 'tasks'; }
    static get image(): EndPointType { return 'image'; }
    static get organisation(): EndPointType { return 'organisation'; }
    static get events(): EndPointType { return 'events'; }
    static get wiki(): EndPointType { return 'wiki'; }
    static get checklists(): EndPointType { return 'checklists'; }
    static get chat(): EndPointType { return 'chat'; }
    static get notifications(): EndPointType { return 'notifications'; }
    static get briefing(): EndPointType { return 'briefing'; }
    static get documents(): EndPointType { return 'documents'; }
    static get reports(): EndPointType { return 'reports'; }
    static get video(): EndPointType { return 'video'; }
    static get tracking(): EndPointType { return 'tracking'; }
}
