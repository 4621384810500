import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { EndPoint } from '@core/constants';
import { GeneralDataService } from '@core/services';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ValidatePassword } from '@account/validators';
import { DarkBodyComponent } from '@global/base-components';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent extends DarkBodyComponent implements OnInit {
    @ViewChild('passwordField')
        passwordField!: ElementRef;

    passwordControl: UntypedFormControl;
    confirmPasswordControl: UntypedFormControl;
    passwordForgotForm: UntypedFormGroup;

    passwordReset = false;
    resetting = false;
    incorrectToken = false;
    incorrectPassword = false;

    userId!: string;
    resetCode!: string;

    get resetFailed(): boolean {
        return this.incorrectToken || this.incorrectPassword;
    }

    constructor(
        fb: UntypedFormBuilder,
        private data: GeneralDataService,
        private route: ActivatedRoute,
        private router: Router) {
        super();

        this.passwordControl = fb.control('', [
            Validators.required,
            Validators.minLength(6),
            ValidatePassword(),
        ]);
        this.confirmPasswordControl = fb.control('');

        this.passwordForgotForm = fb.group(
            {
                password: this.passwordControl,
                confirmPassword: this.confirmPasswordControl,
            },
            {
                validators: [
                    this.checkPasswordConfirm,
                ]
            }
        );

    }

    ngOnInit(): void {
        this.userId = this.route.snapshot.queryParams.uid;
        this.resetCode = this.route.snapshot.queryParams.reset_code;

        if (!this.userId || !this.resetCode) {
            this.router.navigate(['/sign-in/forgot-password']);
        }
    }

    checkPasswordConfirm(group: UntypedFormGroup): { [key: string]: boolean } | null {
        const password = group.get('password');
        const confirmPassword = group.get('confirmPassword');

        return password?.value === confirmPassword?.value
            ? null
            : { passwordsDontMatch: true };
    }

    resetPassword(): void {
        if (this.resetting) {
            return;
        }

        if (!this.passwordForgotForm.valid) {
            this.passwordField.nativeElement.focus();
            return;
        }

        this.resetting = true;
        this.incorrectToken = false;
        this.passwordReset = false;
        this.incorrectPassword = false;

        this.data.postGenericItemWithResponse(EndPoint.account, 'password/reset', {
            resetCode: this.resetCode,
            userId: this.userId,
            newPassword: this.passwordControl.value
        }).pipe(take(1)).subscribe({
            next: () => {
                this.resetting = false;
                this.passwordReset = true;
            },
            error: (response: HttpErrorResponse) => {
                if (response.status === 400 && response.error && response.error.length && (response.error as any[]).some(x => x?.Code === "invalid_password")) {
                    this.resetting = false;
                    this.incorrectPassword = true;
                }
                else {
                    this.resetting = false;
                    this.incorrectToken = true;
                }
            }
        });
    }
}
