<div class='container'>
    <div class='title' [class.mt-3]='!imageNotFound' [class.mt-5]='imageNotFound'>
        <span [translate]='"general.text.youre_the_best"'>
            You're the best!
        </span>
    </div>

    <div class='message'>
        <span [translate]='"evaluation.text.evaluation_finished_message"'>
            Thank you for sharing your feedback with us! See you soon!
        </span>
    </div>

    <div class="d-flex flex-column px-4 mb-4 mt-5">
        <button mat-raised-button class="cp-button cp-button-glow mb-2" color="primary" (click)="onClickFinish()"
            [loading]='loading' connection-dependant>
            <span [translate]="'evaluation.button.finish'">
                Finish evaluation
            </span>
        </button>
    </div>

    <div class="alert alert-danger" *ngIf='error'>
        <span [translate]="'evaluation.text.evaluation_finish_error_message'">
            Something went wrong while trying to finish your evaluation. Please try again!
        </span>
    </div>
</div>

<cp-celebration></cp-celebration>
