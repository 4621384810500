<div *ngIf='styling && !loadingReports' class='footer' [style.background-color]='styling.colorTwo'
    [class.darkText]='styling.colorTwoText === "Dark"' [class.lightText]='styling.colorTwoText === "Light"'>
    <div class='link-list' [class.standalone-ios]='standaloneiOS'>
        <button mat-button [routerLink]='["/", organisationId, eventId]' [routerLinkActive]='"active"' id="home-button"
            [routerLinkActiveOptions]='{exact: true}'>
            <cp-icon strokeSize='small' name='home'></cp-icon>
        </button>
        <button mat-button [routerLink]='["/", organisationId, eventId, "tasks"]' [routerLinkActive]='"active"' id="task-button"
            [routerLinkActiveOptions]='{exact: true}'>
            <cp-icon strokeSize='small' name='check-square'></cp-icon>
        </button>
        <button mat-button [routerLink]='["/", organisationId, eventId, "notifications"]' [routerLinkActive]='"active"' id="notification-button"
        [routerLinkActiveOptions]='{exact: true}'>
            <cp-icon strokeSize='small' name='bell' [class.showMessageLabel]='hasUnreadNotifications'></cp-icon>
        </button>
        <button mat-button [routerLink]='["/", organisationId, eventId, "chat"]' [routerLinkActive]='"active"' id="chat-button"
            [routerLinkActiveOptions]='{exact: true}'>
            <cp-icon strokeSize='small' name='message-circle' [class.showMessageLabel]='hasUnreadChatMessages'>
            </cp-icon>
        </button>
        <button mat-button (click)='onSettingsClick()' *ngIf='!showReports; else reportButton'>
            <cp-icon strokeSize='small' name='settings'></cp-icon>
        </button>
        <ng-template #reportButton>
            <button mat-button [routerLink]='["/", organisationId, eventId, "reports"]' [routerLinkActive]='"active"' id="report-button"
                [routerLinkActiveOptions]='{exact: true}'>
                <cp-icon strokeSize='small' name='alert-triangle' [class.showMessageLabel]='hasReportNotifications'>
                </cp-icon>
            </button>
        </ng-template>
    </div>
</div>
