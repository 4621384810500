import { Component, ViewChild, ElementRef } from '@angular/core';

@Component({
    selector: 'cp-no-connection-bar',
    templateUrl: './no-connection-bar.component.html',
    styleUrls: ['./no-connection-bar.component.scss']
})
export class NoConnectionBarComponent {
    @ViewChild('noConnectionBar') noConnectionBar?: ElementRef;

    get height(): number {
        if (this.noConnectionBar) {
            return this.noConnectionBar.nativeElement.clientHeight;
        }

        return 0;
    }
}
