import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeUrl } from '@angular/platform-browser';
import { EMPTY_IMAGE } from '@core/constants';
import { DocumentDataService, ImageDataService } from '@core/services';
import { ChecklistImageModel, ChecklistItemModel } from '@overview/models';
import { OverlayService } from '@shared/components';
import { SecurePipe } from '@shared/pipes';
import { take } from 'rxjs';

@Component({
    selector: 'cp-checklist-item-content',
    templateUrl: './checklist-item-content.component.html',
    styleUrls: ['./checklist-item-content.component.scss']
})
export class ChecklistItemContentComponent implements OnInit {

    @Input()
        organisationId!: number;

    @Input()
        item!: ChecklistItemModel;

    @Input()
        isOffline!: boolean;

    downloadingPdf = false;
    errorDownloadingPdf = false;
    private _pdf?: Blob;

    constructor(
        private documentData: DocumentDataService,
        private imageData: ImageDataService,
        private sanitizer: DomSanitizer,
        private securePipe: SecurePipe,
        private overlay: OverlayService,
    ) { }

    ngOnInit(): void {
        this._sortImages();
    }

    onClickPdf(item: ChecklistItemModel, event: MouseEvent): boolean {
        if (!item?.pdfId || this.isOffline || this.downloadingPdf) {
            return true;
        }

        event.preventDefault();
        event.stopPropagation();

        // If the pdf is already downloaded simply open the overlay again
        if (this._pdf) {
            this._openPdfOverlay(this._pdf);
            return false;
        }

        this.downloadingPdf = true;

        this.documentData.downloadPdf(this.organisationId, item.pdfId).pipe(take(1)).subscribe({
            next: response => {
                this._pdf = response;
                this._openPdfOverlay(this._pdf);
                this.downloadingPdf = false;
            }, error: () => {
                this.errorDownloadingPdf = true;
                this.downloadingPdf = false;
            }
        });

        return false;
    }

    onClickImage(image: ChecklistImageModel, event: MouseEvent): boolean {
        event.preventDefault();
        event.stopPropagation();

        if (image.imageUrl) {
            this._openImageOverlay(image.imageUrl);
            return false;
        }

        image.loading = true;

        const url = this.imageData.getCachedImageUrl(image.blobId, this.organisationId);

        this.securePipe.transform(url).subscribe((result) => {
            if (result !== EMPTY_IMAGE) {
                const trustedUrl = this.sanitizer.bypassSecurityTrustUrl(result);
                image.imageUrl = trustedUrl;
                this._openImageOverlay(image.imageUrl);
            }
            image.loading = false;
        });

        return false;
    }

    trustContent(content: string | undefined): SafeHtml {
        if (content) {
            return this.sanitizer.bypassSecurityTrustHtml(content);
        }
        return '';
    }

    private _sortImages(): void {
        if (!this.item.images || !this.item.images.length) {
            return;
        }

        this.item.images.sort((a, b) => (a.position || 0) - (b.position || 0));
    }

    private _openImageOverlay(imageUrl: SafeUrl | string): void {
        const overlayRef = this.overlay.OpenImageOverlay();
        overlayRef.instance.imageUrl = imageUrl;
        overlayRef.instance.closed.subscribe(() => {
            this.overlay.ResetUserZoom();
            overlayRef.destroy();
        });
    }

    private _openPdfOverlay(pdf: Blob): void {
        const overlayRef = this.overlay.OpenPdfOverlay();
        overlayRef.instance.pdf = pdf;
        overlayRef.instance.closed.subscribe(() => {
            this.overlay.ResetUserZoom();
            overlayRef.destroy();
        });
    }
}
