<cp-no-connection-message [shouldCache]='true' *ngIf='offlineNoCache; else loadingRef'></cp-no-connection-message>

<ng-template #loadingRef>
    <div class='loading-panel' *ngIf='loading; else checklistOverviewRef'>
        <cp-delayed-spinner class='m-auto'></cp-delayed-spinner>
    </div>
</ng-template>

<ng-template #checklistOverviewRef>
    <div class='checklist-container pb-1'>
        <div *ngIf='errorLoading; else noChecklistsRef'>
            <div class='mt-3 alert alert-danger'>
                <span [translate]='"checklist.error.could_not_load_checklists"'>
                    Your checklists could not be loaded at this moment, please try again later.
                </span>
            </div>
        </div>
        <ng-template #noChecklistsRef>
            <div *ngIf='!checklists || checklists.length === 0; else checklistsRef'
                class='alert alert-primary mt-3 mx-1'>
                <span [translate]='"checklist.text.no_checklists_message"'>
                    There are currently no checklists available for you.
                </span>
            </div>
            <ng-template #checklistsRef>
                <div class="search-container">
                    <mat-form-field class='cp-light-thin-form-field w-100'>
                        <mat-label [translate]="'general.text.search'">
                            Search...
                        </mat-label>
                        <input matInput type="text" [(ngModel)]='filter'>
                        <div matSuffix (click)='filter = ""'>
                            <cp-icon [name]='filter ? "x" : "search"' size='x-small'></cp-icon>
                        </div>
                    </mat-form-field>
                </div>
                <div class='checklist py-3 px-4' *ngFor='let checklist of filteredChecklists'
                    [class.item-opened]='checklist.itemOpened'>
                    <div class='checklist-header pb-3' (click)='toggleChecklist(checklist)'>
                        <div>
                            <span class='title'>
                                {{ checklist.title }}
                            </span>
                        </div>
                        <div class='checklist-details' *ngIf='checklist.amountToDo > 0; else allCompletedRef'>
                            <div class='amount-block'>
                                <span class='amount'>
                                    {{ checklist.amountToDo }}
                                </span>
                                <span [translate]='"general.text.to_do"' class='text'>
                                    to do
                                </span>
                            </div>
                            <div class='amount-block pl-2 mx-2' *ngIf='checklist.amountUrgent > 0'>
                                <cp-icon size='small' name='alert-circle' fill='warn' color='white'
                                    class='transparent-circle-icon'>
                                </cp-icon>
                                <span class='amount urgent'>
                                    {{ checklist.amountUrgent }}
                                </span>
                                <span [translate]='"general.text.urgent"' class='text'>
                                    urgent
                                </span>
                            </div>
                            <div class='amount-block pl-2 mx-2' *ngIf='checklist.amountCompleted > 0'>
                                <span class='amount'>
                                    {{ checklist.amountCompleted }}
                                </span>
                                <span [translate]='"general.text.completed"' class='text'>
                                    completed
                                </span>
                            </div>
                        </div>
                        <ng-template #allCompletedRef>
                            <div class='all-completed'>
                                <span [translate]='"general.text.all_completed"'>
                                    All completed
                                </span>
                            </div>
                        </ng-template>
                        <div class='open-button'>
                            <cp-icon size='small' class='chevron-up' [rotated]='checklist.opened' name='chevron-down'>
                            </cp-icon>
                        </div>
                    </div>
                    <div class='checklist-item-container' [class.hidden]='!checklist.opened'>
                        <div *ngFor='let item of getActiveItems(checklist); let index = index'
                            class='checklist-item mb-3' [class.active]='item.opened'
                            [class.urgent]='item.isUrgent && !item.checked'
                            (click)='item.opened ? closeItem(checklist, item) : openItem(checklist, item)'>
                            <div class='d-flex'>
                                <div>
                                    <button class='checkbox' [class.checked]='item.checked' [class.ios]='isIOS'
                                        connection-dependant (click)='checkItem(item, checklist, $event)'>
                                        <div class='icon-hider'></div>
                                        <cp-icon size='x-small' name='check' color='white'></cp-icon>
                                    </button>
                                </div>
                                <div class='item-container ml-2'>
                                    <div class='title-container'>
                                        <span class='title'>
                                            {{ item.title }}
                                        </span>
                                    </div>
                                    <div class='checklist-details'>
                                        <span class='due-date'>
                                            {{ getDateString(item.dueDate) ? getDateString(item.dueDate) : (item.dueDate | localizedDate: DATE_FORMAT) }}
                                        </span>
                                        <span class='due-time ml-1 pl-2' [class.pl-2]='item.dueTime'
                                            *ngIf='item.dueTime'>
                                            {{ item.dueTime | localizedDate:'shortTime'}}
                                        </span>
                                        <span class='responsible-person pl-2'>
                                            {{ item.responsiblePerson }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <ng-container *ngIf='hasContent(item)'>
                                <div class='buttons'>
                                    <div class='button'>
                                        <cp-icon size='small' class='chevron' [rotated]='item.opened'
                                            name='chevron-down'>
                                        </cp-icon>
                                    </div>
                                </div>
                                <cp-checklist-item-content [organisationId]="organisationId" [item]="item" [isOffline]="isOffline">
                                </cp-checklist-item-content>
                            </ng-container>
                        </div>
                        <div *ngIf='getCompletedItems(checklist).length > 0'>
                            <div class='amount-block pb-3' *ngIf='checklist.amountToDo > 0'>
                                <span class='amount'>
                                    {{ checklist.amountCompleted }}
                                </span>
                                <span [translate]='"general.text.completed"' class='text'>
                                    completed
                                </span>
                            </div>
                            <div *ngFor='let item of getCompletedItems(checklist); let index = index'
                                class='checklist-item mb-3' [class.active]='item.opened' [class.urgent]='item.isUrgent'
                                [class.completed]='item.checked'
                                (click)='item.opened ? closeItem(checklist, item) : openItem(checklist, item)'>
                                <div class='d-flex'>
                                    <div>
                                        <button class='checkbox' [class.checked]='item.checked' [class.ios]='isIOS'
                                            (click)='onClickCompletedItem(item, checklist, $event)' connection-dependant>
                                            <div class='icon-hider'></div>
                                            <cp-icon size='x-small' name='check' color='white'></cp-icon>
                                        </button>
                                    </div>
                                    <div class='item-container ml-2'>
                                        <div class='title-container'>
                                            <span class='title'>
                                                {{ item.title }}
                                            </span>
                                        </div>
                                        <div class='checklist-details'>
                                            <span class='due-date'>
                                                {{ getDateString(item.dueDate) ? getDateString(item.dueDate) : (item.dueDate | localizedDate: DATE_FORMAT) }}
                                            </span>
                                            <span class='due-time ml-1 pl-2' [class.pl-2]='item.dueTime'
                                                *ngIf='item.dueTime'>
                                                {{ item.dueTime | localizedDate:'shortTime'}}
                                            </span>
                                            <span class='responsible-person pl-2' *ngIf='item.responsiblePerson'>
                                                {{ item.responsiblePerson }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <ng-container *ngIf='hasContent(item)'>
                                    <div class='buttons'>
                                        <div class='button'>
                                            <cp-icon size='small' class='chevron' [rotated]='item.opened'
                                                name='chevron-down'>
                                            </cp-icon>
                                        </div>
                                    </div>
                                    <cp-checklist-item-content [organisationId]="organisationId" [item]="item" [isOffline]="isOffline">
                                    </cp-checklist-item-content>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </ng-template>
    </div>
</ng-template>
