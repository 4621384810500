import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { GeneralDataService } from '@core/services';
import { EndPoint } from '@core/constants';

@Injectable()
export class DocumentDataService extends GeneralDataService {
    downloadPdf(organisationId: number, pdfId: number): Observable<Blob> {
        return this.getGenericFile(EndPoint.documents, `pdf/${organisationId}/${pdfId}`);
    }
}
