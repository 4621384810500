import { DateUtils } from '@core/utils';
import { takeUntil } from 'rxjs/operators';
import { GlobalsService } from '@core/services';
import { MaintenanceState } from '@core/models';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
    templateUrl: './maintenance-dialog.component.html',
    styleUrls: ['./maintenance-dialog.component.scss']
})
export class MaintenanceDialogComponent implements OnInit, OnDestroy {
    private destroy$ = new Subject<boolean>();

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: MaintenanceState,
        private dialogRef: MatDialogRef<MaintenanceDialogComponent>,
        private globals: GlobalsService) { }

    get hoursLeft(): number {
        return Math.min(1, DateUtils.CalculateDifferenceInHoursBetweenDates(new Date(), this.data.maintenanceEndTime || new Date()));
    }

    ngOnInit(): void {
        this.globals.underMaintenance$.pipe(takeUntil(this.destroy$))
            .subscribe(result => {
                if (!result.maintenanceActive) {
                    this.dialogRef.close();
                } else {
                    this.data = result;
                }
            });
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
