import { style, query, animateChild, group, animate } from '@angular/animations';

export const slideInTop = [
    query(':enter, :leave', [
        style({
            position: 'absolute',
            left: 0,
            width: '100%'
        })
    ]),
    query(':leave', [
        style({ top: '0%' })
    ]),
    query(':enter', [
        style({ top: '-100%' })
    ]),
    query(':leave', animateChild()),
    group([
        query(':leave', [
            animate('300ms ease-out', style({ top: '100%' }))
        ]),
        query(':enter', [
            animate('300ms ease-out', style({ top: '0%' }))
        ])
    ]),
    query(':enter', animateChild()),
];

export const slideInBottom = [
    query(':enter, :leave', [
        style({
            position: 'absolute',
            left: 0,
            width: '100%'
        })
    ]),
    query(':leave', [
        style({ top: '0%' })
    ]),
    query(':enter', [
        style({ top: '100%' })
    ]),
    query(':leave', animateChild()),
    group([
        query(':leave', [
            animate('300ms ease-out', style({ top: '-100%' }))
        ]),
        query(':enter', [
            animate('300ms ease-out', style({ top: '0%' }))
        ])
    ]),
    query(':enter', animateChild()),
];

export const slideOverBottom = [
    query(':enter, :leave', [
        style({
            position: 'absolute',
            left: 0,
            width: '100%',
            opacity: 1,
        })
    ], { optional: true }),
    query(':leave', [
        style({ top: '70px' })
    ], { optional: true }),
    query(':enter', [
        style({ top: '100%' })
    ], { optional: true }),
    query(':leave', animateChild(), { optional: true }),
    group([
        query(':enter', [
            animate('500ms ease-out', style({ top: '70px' }))
        ], { optional: true }),
    ]),
    query(':enter', animateChild(), { optional: true }),
];

export const slideOutBottom = [
    query(':leave', [
        style({
            position: 'absolute',
            left: 0,
            width: '100%',
            top: '70px',
        })
    ], { optional: true }),
    query(':leave', animateChild(), { optional: true }),
    group([
        query(':leave', [
            animate('500ms ease-out', style({ top: '100%' }))
        ], { optional: true }),
    ]),
];

export const slideInLeft = [
    style({ position: 'relative' }),
    query(':enter, :leave', [
        style({
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%'
        })
    ]),
    query(':enter', [
        style({ left: '-100%' })
    ]),
    query(':leave', animateChild()),
    group([
        query(':leave', [
            animate('300ms ease-out', style({ left: '100%' }))
        ]),
        query(':enter', [
            animate('300ms ease-out', style({ left: '0%' }))
        ])
    ]),
    query(':enter', animateChild()),
];

export const slideInRight = [
    style({ position: 'relative' }),
    query(':enter, :leave', [
        style({
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%'
        })
    ]),
    query(':enter', [
        style({ left: '100%' })
    ]),
    query(':leave', animateChild()),
    group([
        query(':leave', [
            animate('200ms ease-out', style({ left: '-100%' }))
        ]),
        query(':enter', [
            animate('300ms ease-out', style({ left: '0%' }))
        ])
    ]),
    query(':enter', animateChild()),
];
