import { EventOverviewComponent } from '@overview/components';
import { AuthorizationGuard, FeatureToggleGuard } from '@core/guards';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ForceLogoutComponent, OrganisationOverviewComponent } from './components';

const routes: Routes = [
    { path: 'logout', component: ForceLogoutComponent },
    { path: 'sign-in', loadChildren: () => import('./sign-in/sign-in.module').then(m => m.SignInModule) },
    { path: 'account', loadChildren: () => import('./account/account.module').then(m => m.AccountModule) },
    { path: 'test/:organisationId/:code', loadChildren: () => import('./test/test.module').then(m => m.TestModule) },
    {
        path: ':organisationId',
        children: [
            {
                path: ':eventId',
                children: [
                    {
                        path: 'briefing',
                        loadChildren: () => import('./briefing/briefing.module').then(m => m.BriefingModule)
                    },
                    {
                        path: 'test',
                        loadChildren: () => import('./knowledge-test/knowledge-test.module').then(m => m.KnowledgeTestModule)
                    },
                    {
                        path: 'evaluation/:evaluationId',
                        loadChildren: () => import('./evaluation/evaluation.module').then(m => m.EvaluationModule)
                    },
                    {
                        path: 'wiki',
                        loadChildren: () => import('./wiki/wiki.module').then(m => m.WikiModule)
                    },
                    {
                        path: 'chat',
                        loadChildren: () => import('./chat/chat.module').then(m => m.ChatModule)
                    },
                    {
                        path: 'reports',
                        loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule),
                        canActivate: [FeatureToggleGuard],
                        data: { feature: 'Reports' }
                    },
                    {
                        path: '',
                        loadChildren: () => import('./overview/overview.module').then(m => m.OverviewModule),
                        data: { animation: 'Overview' }
                    }
                ]
            },
            {
                path: '',
                component: EventOverviewComponent,
                data: { animation: 'EventOverview' }
            }
        ],
        canActivate: [AuthorizationGuard]
    },
    {
        path: '',
        component: OrganisationOverviewComponent,
        canActivate: [AuthorizationGuard]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
