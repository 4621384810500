import { IntlTelInputComponent } from '@shared/intl-tel-input/intl-tel-input.component';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SearchPipe } from './search.pipe';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';



@NgModule({
    declarations: [
        SearchPipe,
        IntlTelInputComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        MatInputModule,
        MatMenuModule,
        MatButtonModule,
        MatDividerModule,
        ReactiveFormsModule
    ],
    exports: [
        IntlTelInputComponent
    ]
})
export class IntlTelInputModule { }
