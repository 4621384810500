import { ExternalLinkSectionContent } from '@wiki/models';
import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { AnalyticsService } from '@core/services';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmDialogComponent, ConfirmDialogData, ConfirmDialogResult } from '@shared/components';
import { take } from 'rxjs';

@Component({
    selector: 'cp-external-link-section',
    templateUrl: './external-link-section.component.html',
    styleUrls: ['./external-link-section.component.scss']
})
export class ExternalLinkSectionComponent {
    @ViewChild('dialog') dialogTemplate!: TemplateRef<unknown>;

    @Input()
    	content?: ExternalLinkSectionContent;

    @Input()
        eventId!: number;

    @Input()
        organisationId!: number;

    @Input()
        wikiTabId!: number;

    dialogRef?: MatDialogRef<unknown>;

    constructor(private dialog: MatDialog, private analytics: AnalyticsService) {}

    get validURL(): boolean {
    	if (!this.content || !this.content.url) {
    		return false;
    	}

        try {
            const url = new URL(this.content.url);
            return url.protocol === 'http:' || url.protocol === 'https:';
        } catch {
            return false;
        }
    }

    openLink(): void {
        if (!this.validURL) { return; }

        this.dialogRef = this.dialog.open(this.dialogTemplate, {
            disableClose: true,
            autoFocus: false,
            backdropClass: 'darkened-backdrop'
        });
    }

    linkResolved(opened: boolean): void {
        this.analytics.track('ExternalLinkClick',
            { wikiTabId: this.wikiTabId, openLink: opened }, this.organisationId, this.eventId);
        this.dialogRef?.close();
        this.dialogRef = undefined;
    }
}
