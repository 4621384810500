import { GlobalsService, AuthenticationService, AlertService, PushService, ConnectionService, GuidedTourService } from '@core/services';
import { AccountDataService } from '@account/services';
import { UniqueEmailValidator, ValidatePassword } from '@account/validators';
import { Component, OnInit, ViewChild, OnDestroy, ViewChildren } from '@angular/core';
import { Subject } from 'rxjs';
import { IntlTelInputComponent } from '@shared/intl-tel-input';
import { ToggleablePasswordInputFieldComponent } from '@shared/components';
import { UntypedFormControl, UntypedFormGroup, Validators, UntypedFormBuilder, AbstractControl } from '@angular/forms';
import { takeUntil, take } from 'rxjs/operators';
import { IAccountSettings } from '@account/models';
import { ConfirmEmailFieldErrorStateMatcher } from '@account/components/register';
import { TranslateService } from '@ngx-translate/core';
import { MatSelectChange } from '@angular/material/select';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '@shared/components';

@Component({
    selector: '[cp-settings-panel]',
    templateUrl: './settings-panel.component.html',
    styleUrls: ['./settings-panel.component.scss']
})
export class SettingsPanelComponent implements OnInit, OnDestroy {
    @ViewChild(IntlTelInputComponent) phoneInput!: IntlTelInputComponent;

    @ViewChildren(ToggleablePasswordInputFieldComponent) passwordInputs!: ToggleablePasswordInputFieldComponent[];

    confirmDialogRef?: MatDialogRef<ConfirmDialogComponent, any>;

    loadingSettings = false;
    loadingSettingsFailed = false;
    savingSettings = false;
    errorSaving = false;
    incorrectPassword = false;
    offline = false;
    offlineBeforeLoad = false;

    originalEmail!: string;
    originalPhone?: string;

    currentLanguage: string;

    changedEmail?: string;
    justChanged = false;
    confirmingNewEmail = false;
    confirmFailed = false;

    resendingEmailConfirmationCode = false;
    resendFailed = false;

    resettingChangedEmail = false;
    resetFailed = false;

    hasRequestedDeletion = false;

    firstNameControl: UntypedFormControl;
    middleNameControl: UntypedFormControl;
    lastNameControl: UntypedFormControl;

    phonenumberControl: UntypedFormControl;

    emailControl: UntypedFormControl;
    emailConfirmControl: UntypedFormControl;

    newPasswordControl: UntypedFormControl;
    newPasswordConfirmControl: UntypedFormControl;

    currentPasswordControl: UntypedFormControl;

    accountSettingsForm: UntypedFormGroup;

    emailFieldMatcher = new ConfirmEmailFieldErrorStateMatcher();

    private destroy$: Subject<boolean> = new Subject<boolean>();

    private _hasPushSubscription = false;

    constructor(
        fb: UntypedFormBuilder,
        private data: AccountDataService,
        private globals: GlobalsService,
        private alert: AlertService,
        private translate: TranslateService,
        private auth: AuthenticationService,
        private dialog: MatDialog,
        private push: PushService,
        private connection: ConnectionService,
        private tour: GuidedTourService
    ) {
        this.currentLanguage = translate.currentLang;

        this.firstNameControl = fb.control('', [Validators.required, Validators.maxLength(100)]);
        this.middleNameControl = fb.control('', [Validators.maxLength(100)]);
        this.lastNameControl = fb.control('', [Validators.required, Validators.maxLength(100)]);

        this.phonenumberControl = fb.control('');

        this.newPasswordControl = fb.control('');

        this.newPasswordConfirmControl = fb.control('');

        this.emailControl = fb.control('', {
            validators: [Validators.required, Validators.email, Validators.maxLength(256)],
            asyncValidators: [UniqueEmailValidator(data, true)],
        });
        this.emailConfirmControl = fb.control('');

        this.currentPasswordControl = fb.control('');

        this.accountSettingsForm = fb.group(
            {
                firstName: this.firstNameControl,
                middleName: this.middleNameControl,
                lastName: this.lastNameControl,

                email: this.emailControl,
                emailConfirm: this.emailConfirmControl,

                phonenumber: this.phonenumberControl,

                newPassword: this.newPasswordControl,
                newPasswordConfirm: this.newPasswordConfirmControl,

                currentPassword: this.currentPasswordControl,

                originalEmail: fb.control('')
            },
            {
                validators: [
                    this.checkEmailConfirm,
                    this.checkPasswordConfirm
                ]
            }
        );
    }

    get requiresPassword(): boolean {
        return this.originalPhone !== this.phonenumberControl.value
            || this.requiresConfirmPassword
            || this.requiresConfirmEmail;
    }

    get requiresConfirmPassword(): boolean {
        return this.newPasswordControl.value;
    }

    get requiresConfirmEmail(): boolean {
        return this.originalEmail.toUpperCase() !== this.emailControl.value.toUpperCase();
    }

    get showNotificationOptIn(): boolean {
        return this.push.hasPushNotificationEnabled && !this._hasPushSubscription;
    }

    get showNotificationOptOut(): boolean {
        return this.push.hasPushNotificationEnabled && this._hasPushSubscription;
    }

    get showResetTour(): boolean {
        return this.tour.hasProgress;
    }

    ngOnInit(): void {
        this.offline = !this.connection.online;
        this.offlineBeforeLoad = !this.connection.online;
        this.connection.onlineState$.pipe(takeUntil(this.destroy$))
            .subscribe(connected => {
                this.offline = !connected;

                if (!this.offline && this.offlineBeforeLoad) {
                    this.getSettings();
                    this.offlineBeforeLoad = false;
                }
            });

        if (!this.offline) {
            this.getSettings();
        }
        this.newPasswordControl.valueChanges
            .pipe(takeUntil(this.destroy$))
            .subscribe(value => {
                if (value) {
                    this.newPasswordControl.setValidators([Validators.minLength(6), ValidatePassword()]);
                    this.newPasswordConfirmControl.setValidators([Validators.required]);
                } else {
                    this.newPasswordControl.setValidators(null);
                    this.newPasswordConfirmControl.setValidators(null);
                }
                this.newPasswordControl.updateValueAndValidity({ emitEvent: false });
                this.newPasswordConfirmControl.updateValueAndValidity({ emitEvent: false });
            });

        this.push.hasPushNotificationRegistered().pipe(takeUntil(this.destroy$))
            .subscribe((enabled) => this._hasPushSubscription = enabled);

        this.translate.onLangChange.pipe(takeUntil(this.destroy$))
            .subscribe((event) => this.currentLanguage = event.lang);
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    getSettings(): void {
        this.loadingSettings = true;
        this.loadingSettingsFailed = false;

        this.data.getAccountSettings().pipe(take(1)).subscribe({
            next: settings => {
                this.originalEmail = settings.email;
                this.accountSettingsForm.get('originalEmail')?.setValue(this.originalEmail);

                this.changedEmail = settings.changedEmail;

                this.emailControl.setValue(settings.email);

                this.originalPhone = settings.phonenumber;
                this.phonenumberControl.setValue(settings.phonenumber);

                this.firstNameControl.setValue(settings.firstName);
                this.middleNameControl.setValue(settings.middleName);
                this.lastNameControl.setValue(settings.lastName);

                this.hasRequestedDeletion = settings.hasRequestedDeletion || false;

                this.loadingSettings = false;
            },
            error: () => {
                this.loadingSettingsFailed = true;
                this.loadingSettings = false;
            }
        });
    }

    submitSettings(): void {
        if (this.accountSettingsForm.invalid || this.savingSettings) {
            return;
        }

        this.savingSettings = true;
        this.errorSaving = false;
        this.incorrectPassword = false;

        const settings: IAccountSettings = {
            email: this.emailControl.value,
            phonenumber: this.phonenumberControl.value,
            firstName: this.firstNameControl.value,
            middleName: this.middleNameControl.value,
            lastName: this.lastNameControl.value,
            newPassword: this.newPasswordControl.value,
            currentPassword: this.currentPasswordControl.value
        };

        this.data.setAccountSettings(settings).pipe(take(1)).subscribe({
            next: () => {
                if (this.originalEmail !== settings.email) {
                    this.justChanged = true;
                    this.changedEmail = settings.email;
                }

                this.savingSettings = false;
                this.emailConfirmControl.setValue('');
                this.originalPhone = settings.phonenumber;
                this._clearPasswords();

                this.alert.postMessage('general.snackbar.settings_changed', 'Light', 1500, 'general.text.ok');
            },
            error: (error) => {
                this.savingSettings = false;

                if (error.status === 403) {
                    this.incorrectPassword = true;
                } else {
                    this.errorSaving = true;
                }

                this._clearPasswords();
            }
        });
    }

    checkPasswordConfirm(group: AbstractControl): { [key: string]: boolean } | null {
        const password = group.get('newPassword');
        const confirmPassword = group.get('newPasswordConfirm');

        return password?.value === confirmPassword?.value
            ? null
            : { passwordsDontMatch: true };
    }

    checkEmailConfirm(group: AbstractControl): { [key: string]: boolean } | null {
        const email = group.get('email');
        const confirmEmail = group.get('emailConfirm');
        const originalEmail = group.get('originalEmail');

        if (email?.value?.toUpperCase() === originalEmail?.value?.toUpperCase()) {
            return null;
        }

        return email?.value?.toUpperCase() === confirmEmail?.value?.toUpperCase()
            ? null
            : { emailsDontMatch: true };
    }

    onSubmittedCode(code: string): void {
        if (this.confirmingNewEmail || this.resendingEmailConfirmationCode || this.resettingChangedEmail) {
            return;
        }

        this.confirmFailed = false;
        this.confirmingNewEmail = true;

        this.data.confirmNewEmail(code).pipe(take(1)).subscribe({
            next: () => {
                this.confirmingNewEmail = false;
                this.emailControl.setValue(this.changedEmail);
                this.accountSettingsForm.get('originalEmail')?.setValue(this.changedEmail);
                this.originalEmail = this.changedEmail || '';
                this.changedEmail = undefined;
                this.accountSettingsForm.updateValueAndValidity();

                this.alert.postMessage('general.snackbar.email_updated', 'Light', 1500, 'general.text.ok');
            },
            error: () => {
                this.confirmFailed = true;
                this.confirmingNewEmail = false;
            }
        });
    }

    onRequestNewCode(): void {
        if (this.confirmingNewEmail || this.resendingEmailConfirmationCode || this.resettingChangedEmail) {
            return;
        }

        this.resendFailed = false;
        this.resendingEmailConfirmationCode = true;

        this.data.resendConfirmationEmailCode().pipe(take(1)).subscribe({
            next: () => {
                this.resendingEmailConfirmationCode = false;

                this.alert.postMessage('general.snackbar.new_code_sent', 'Light', 1500, 'general.text.ok');
            },
            error: () => {
                this.resendFailed = true;
                this.resendingEmailConfirmationCode = false;
            }
        });
    }

    onResetChangedEmail(): void {
        if (this.confirmingNewEmail || this.resendingEmailConfirmationCode || this.resettingChangedEmail) {
            return;
        }

        this.resetFailed = false;
        this.resettingChangedEmail = true;

        this.data.revertChangedEmail().pipe(take(1)).subscribe({
            next: () => {
                this.resettingChangedEmail = false;
                this.changedEmail = undefined;

                this.alert.postMessage('general.snackbar.email_reverted', 'Light', 1500, 'general.text.ok');
            },
            error: () => {
                this.resettingChangedEmail = false;
                this.resetFailed = true;
            }
        });
    }

    onOptInPushNotification(): void {
        this.push.activatePushNotifications();
        this._hasPushSubscription = true;
    }

    onOptOutPushNotification(): void {
        this.push.revokePushNotifications();
        this._hasPushSubscription = false;
    }

    resetTour(): void {
        this.tour.resetTour();
    }

    closeSettings(): void {
        this.globals.hideSettings();
    }

    setLanguage(code: MatSelectChange): void {
        this.translate.use(code.value);
        localStorage.setItem('lang', code.value);
        this.data.setSystemLanguage(code.value).pipe(take(1)).subscribe();
    }

    signout(): void {
        this.auth.revokeAuthentication();
    }

    private _clearPasswords(): void {
        this.newPasswordControl.setValue('');
        this.newPasswordConfirmControl.setValue('');
        this.currentPasswordControl.setValue('');
        this.passwordInputs.forEach(x => x.clear());
    }
}
