<header class="header-container" [style.background-color]='organisationStyling.colorTwo'
    [class.darkText]='organisationStyling.colorTwoText === "Dark"'
    [class.lightText]='organisationStyling.colorTwoText === "Light"' *ngIf="!briefingImageId; else imageHeader">
    <div class="header-content-container">
        <div class='title'>
            <span [translate]='"briefing.text.score_and_feedback_title"'>
                Your result and feedback
            </span>
        </div>
        <div class='subtitle'>
            <span [translate]='"briefing.text.score_and_feedback_subtitle"'>
                What went well and what did not go as well
            </span>
        </div>
    </div>
</header>
<ng-template #imageHeader>
    <header class='image-container'>
        <cp-image [blobId]='briefingImageId || ""' [organisationId]="organisationId" [width]="'100%'"
            [showOverlay]="true">
        </cp-image>

        <div class="header-content-container">
            <div class='header-content'>
                <div class='title'>
                    <span [translate]='"briefing.text.score_and_feedback_title"'>
                        Your result and feedback
                    </span>
                </div>
                <div class='subtitle'>
                    <span [translate]='"briefing.text.score_and_feedback_subtitle"'>
                        What went well and what did not go as well
                    </span>
                </div>
            </div>
        </div>
    </header>
</ng-template>

<div class='score-container'>
    <div class='score-block' *ngIf="hasScore">
        <h2>
            <span [translate]='"briefing.text.your_score_label"'>
                Your result for this briefing
            </span>
        </h2>
        <div class='d-flex justify-content-center'>
            <cp-radial-progress [size]="100" [bars]="progressBar" label='custom' [customLabel]='scoreAsTenScale'>
            </cp-radial-progress>
        </div>
    </div>

    <div *ngIf='error; else loader'>
        <div class='alert alert-warning'>
            Something went wrong loading your results, please come back to check them later.
        </div>
    </div>

    <ng-template #loader>
        <div *ngIf='loading; else feedbackBlock'
            class='loader-container d-flex justify-content-center align-items-center'>
            <cp-delayed-spinner></cp-delayed-spinner>
        </div>
        <ng-template #feedbackBlock>
            <div class='feedback-block' [style.padding-top.px]="hasScore ? 0 : 30">
                <div *ngIf='!incorrectQuestions || incorrectQuestions.length === 0; else feedback'>
                    <span [translate]='"briefing.text.no_questions_wrong_prompt"'>
                        Well done! You had answered all answers correctly! You are very well prepared.
                    </span>
                </div>

                <ng-template #feedback>
                    <div class='mb-3'>
                        <span [translate]='"briefing.text.few_questions_wrong_prompt"'>
                            You had a few questions wrong, please take some time to read through the information below.
                        </span>
                    </div>

                    <mat-accordion multi>
                        <mat-expansion-panel *ngFor='let question of incorrectQuestions'
                            class='cp-briefing-feedback-expansion-panel'>
                            <mat-expansion-panel-header>
                                <mat-panel-title class='w-100'>
                                    <span class='question' [innerHTML]='question.question'>
                                    </span>
                                </mat-panel-title>
                            </mat-expansion-panel-header>

                            <div class='question-feedback' *ngIf='question.feedback'>
                                <span [innerHTML]='question.feedback'></span>
                            </div>

                            <div class='correct-answers'>
                                <div class='pb-3'>
                                    <span
                                        [translate]='question.correctAnswers.length > 1 ? "briefing.text.correct_answers" : "briefing.text.correct_answer"'>
                                        Correct answer(s):
                                    </span>
                                </div>
                                <div class='row'>
                                    <div *ngFor='let answer of question.correctAnswers'
                                        [ngClass]='getAnswerClasses(answer, question, true)'>
                                        <span *ngIf='question.questionType === questionTypes.Text; else correctImage'
                                            [innerHTML]='answer'></span>

                                        <ng-template #correctImage>
                                            <div class='image-answer'>
                                                <cp-image [blobId]="answer" [organisationId]="organisationId"
                                                    height="calc(50vw - 78px)" width="100%"></cp-image>
                                            </div>
                                        </ng-template>
                                    </div>

                                </div>
                            </div>

                            <div class='chosen-answers'>
                                <div class='pb-3'>
                                    <span
                                        [translate]='question.chosenAnswers.length > 1 ? "briefing.text.chosen_answers" : "briefing.text.chosen_answer"'>
                                        Chosen answer(s):
                                    </span>
                                </div>
                                <div class='row'>
                                    <div *ngFor='let answer of question.chosenAnswers'
                                        [ngClass]='getAnswerClasses(answer, question, false)'>
                                        <span *ngIf='question.questionType === questionTypes.Text; else chosenImage'
                                            [innerHTML]='answer'></span>

                                        <ng-template #chosenImage>
                                            <div class='image-answer'>
                                                <cp-image [blobId]="answer" [organisationId]="organisationId"
                                                    height="calc(50vw - 78px)" width="100%"></cp-image>
                                            </div>
                                        </ng-template>
                                    </div>
                                </div>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>
                </ng-template>
            </div>
        </ng-template>
    </ng-template>

    <button mat-flat-button class="cp-button" color="primary" (click)="onClickNext()">
        <span [translate]='"general.button.next"'>
            Next
        </span>
    </button>
</div>
