import { AuthenticationService, FeatureToggleService } from '@core/services';
import { UrlResolver } from '@core/utils';
import { ChatMessage } from '@chat/models';
import { EventEmitter, Injectable } from '@angular/core';
import { HubConnection, HubConnectionBuilder, IHttpConnectionOptions } from '@microsoft/signalr';
import { EndPoint } from '@core/constants';
import * as signalR from '@microsoft/signalr';
import { take, map } from 'rxjs/operators';
import { from, Observable } from 'rxjs';


@Injectable()
export class SignalRService {
    messageReceived$ = new EventEmitter<ChatMessage>();
    connectionEstablished$ = new EventEmitter<boolean>();
    connectionAllowed$ = new EventEmitter<boolean>();

    private _hubConnection!: HubConnection;

    constructor(private auth: AuthenticationService, private features: FeatureToggleService) { }

    get connectionId(): string | undefined {
        return this._hubConnection?.connectionId || undefined;
    }

    establishConnection(organisationId: number, eventId: number, groupId: number): void {
        // On creating new connection ensure we destroy first
        this.destroy();

        this.features.isFeatureActive(organisationId, 'LiveChat')
            .pipe(take(1))
            .subscribe(
                (isActive) => {
                    if (isActive) {
                        this.connectionAllowed$.emit(true);
                        this._setupHubConnection(organisationId, eventId, groupId);
                    } else {
                        this.connectionAllowed$.emit(false);
                    }
                },
                () => {
                    this.connectionAllowed$.emit(false);
                });
    }

    sendMessage(message: string): Observable<ChatMessage> {
        if (this._hubConnection) {
            return from(this._hubConnection.invoke<ChatMessage>('SendMessage', message))
                .pipe(map(result => { result.sentOn = new Date(result.sentOn); return result; }));
        }

        throw new Error('No connection');
    }

    destroy(): void {
        if (this._hubConnection) {
            if (this._hubConnection.state === signalR.HubConnectionState.Connected) {
                this._hubConnection.send('LeaveGroup');
            }
            this._hubConnection.off('ReceiveMessage');
            this._hubConnection.stop();
        }
    }

    private _setupHubConnection(organisationId: number, eventId: number, groupId: number): void {
        const options: IHttpConnectionOptions = {
            accessTokenFactory: () => this.auth.accessToken || ''
        };

        this._hubConnection = new HubConnectionBuilder()
            .withUrl(
                UrlResolver.getUrl(EndPoint.chat, `live?organisationId=${organisationId}&eventId=${eventId}&groupId=${groupId}`), options)
            .build();

        this._hubConnection
            .start()
            .then(() => {
                this._setupListeners();
            }
            )
            .catch(_ => {
                this.connectionEstablished$.emit(false);
            });
    }

    private _setupListeners(): void {
        this._hubConnection.on('ReceiveMessage', (data: ChatMessage) => {
            data.sentOn = new Date(data.sentOn);
            data.isUnreadForUser = false;
            data.isSentByUser = data.senderId === this.auth.userId;
            this.messageReceived$.emit(data);
        });

        this._hubConnection.onclose(() => {
            this.connectionEstablished$.emit(false);
        });

        this._hubConnection.invoke('JoinGroup')
            .then(() => this.connectionEstablished$.emit(true))
            .catch(() => this.connectionEstablished$.emit(false));
    }
}
