import { take, takeUntil } from 'rxjs/operators';
import { ITaskOverview, ITask, TaskType } from '@overview/models';
import { OverviewDataService } from '@overview/services';
import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { fadeInListAnimation, fadeInAnimation } from '@core/animations';
import { ConnectionService } from '@core/services';
import { Subject } from 'rxjs';

@Component({
    selector: 'cp-task-overview',
    templateUrl: './task-overview.component.html',
    styleUrls: ['./task-overview.component.scss'],
    animations: [fadeInListAnimation, fadeInAnimation]
})
export class TaskOverviewComponent implements OnInit, OnDestroy {
    @Output()
        tasksLoaded = new EventEmitter<boolean>();

    readonly taskTypes = TaskType;

    offlineOnLoad = false;
    offline = false;
    loading = false;
    error = false;

    organisationId!: number;
    eventId!: number;

    taskOverview!: ITaskOverview;
    amountToDo = 0;
    amountUrgent = 0;
    amountCompleted = 0;

    private destroy$ = new Subject<boolean>();

    constructor(
        private route: ActivatedRoute, private router: Router, private data: OverviewDataService,
        private connection: ConnectionService) {
    }

    get uncompletedTasks(): ITask[] {
        if (this.taskOverview === undefined) {
            return [];
        }

        return this.taskOverview.tasks.filter(x => !x.isCompleted);
    }

    get completedTasks(): ITask[] {
        if (this.taskOverview === undefined) {
            return [];
        }

        return this.taskOverview.tasks.filter(x => x.isCompleted);
    }

    get eventImage(): string {
        return `event/${this.eventId}`;
    }

    ngOnInit(): void {
        this.organisationId = this.route.snapshot.params.organisationId;
        this.eventId = this.route.snapshot.params.eventId;

        this.offlineOnLoad = !this.connection.online;
        this.offline = this.offlineOnLoad;
        this.loading = true;

        if (!this.offlineOnLoad) {
            this._loadTasks();
            this.loading = false;
        } else {
            this.tasksLoaded.emit(true);
            this.loading = false;
        }

        this.connection.onlineState$.pipe(takeUntil(this.destroy$)).subscribe(
            (result) => {
                if (result && this.offlineOnLoad) {
                    this._loadTasks();
                    this.offlineOnLoad = false;
                }

                this.offline = !result;
            }
        );
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    openTask(task: ITask): void {
        if (this.offline) {
            return;
        }

        // Navigate after a delay to let the ripple complete.
        setTimeout(() => {
            switch (task.type) {
            case TaskType.Briefing:
                this.router.navigate([this.organisationId, this.eventId, 'briefing', task.taskId]);
                break;
            case TaskType.KnowledgeTest:
                this.router.navigate([this.organisationId, this.eventId, 'test', task.taskId]);
                break;
            case TaskType.Evaluation:
                this.router.navigate([this.organisationId, this.eventId, 'evaluation', task.taskId]);
                break;
            }
        }, 250);
    }

    getButtonKey(task: ITask): string {
        if (task.progressPercentage === 0) {
            return 'general.text.start';
        } else if (task.isCompleted) {
            return 'general.text.review';
        } else {
            return 'briefing.button.continue';
        }
    }

    getRippleColor(task: ITask): string {
        if (task.isUrgent) {
            // Transparent warn-red.
            return 'rgba(244, 67, 54, 0.2)';
        } else if (task.isCompleted) {
            // Transparent catchphrase-green.
            return 'rgba(130, 186, 122, 0.2)';
        } else {
            // Transparent black.
            return 'rgba(0, 0, 0, 0.2)';
        }
    }

    private _calculateAmounts(): void {
        this.taskOverview.tasks.forEach(element => {
            if (element.isCompleted) {
                this.amountCompleted = this.amountCompleted + 1;
            }
            else {
                this.amountToDo = this.amountToDo + 1;
            }

            if (element.isUrgent) {
                this.amountUrgent = this.amountUrgent + 1;
            }
        });
    }

    private _loadTasks(): void {
        this.data.getTasks(this.organisationId, this.eventId)
            .pipe(
                take(1)
            )
            .subscribe(
                data => {
                    this.taskOverview = data;
                    this._calculateAmounts();
                    this.loading = false;
                    this.tasksLoaded.emit(data.tasks.length > 0);
                },
                () => {
                    this.loading = false;
                    this.error = true;
                    this.tasksLoaded.emit(false);
                });
    }
}
