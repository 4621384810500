import { EndPointType, endpoints } from '@core/constants/endpoints';

export class UrlResolver {
    public static getUrl(endpoint: EndPointType, path?: string): string {
        const baseUrl = endpoints[endpoint];

        if (path) {
            return `${baseUrl}/${path}`;
        } else {
            return baseUrl;
        }
    }
}
