import { ConnectionService, GlobalsService } from '@core/services';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { EvaluationData, IEvaluationPoint } from '@evaluation/models';
import { ActivatedRoute, Router } from '@angular/router';
import { StylingModel } from '@core/models';
import { take, takeUntil } from 'rxjs/operators';
import { EvaluationDataService } from '@evaluation/services';
import { ThankYouPageComponent } from '@evaluation/components';
import { Subject } from 'rxjs';

@Component({
    templateUrl: './evaluation.component.html',
    styleUrls: ['./evaluation.component.scss']
})
export class EvaluationComponent implements OnInit, OnDestroy {
    @ViewChild(ThankYouPageComponent) thankYouPage?: ThankYouPageComponent;

    evaluationData!: EvaluationData;

    organisationStyling!: StylingModel;

    eventId!: number;
    organisationId!: number;
    evaluationTaskId!: number;
    allowGeneralFeedback: boolean = false;
    allowFollowup: boolean = false;
    requireFollowupReason: boolean = false;

    eventName!: string;
    deadline?: Date;

    pageIndex = 0;

    pagesTouched = [ true, false ];

    offline = false;

    loading = true;
    errorLoading = false;
    loadingImage = true;
    errorLoadingImage = false;
    saving = false;
    errorSaving = false;
    finishing = false;
    errorFinishing = false;
    changingPages = false;
    formInvalid = false;
    determiningValidity = false;

    progressPipLengthArray: void[] = [];
    progressPipWidth = '0px';

    evaluationPoints: IEvaluationPoint[] = [];

    private destroy$ = new Subject<boolean>();


    private timeout$!: any;

    private number_of_pages = 1;

    constructor(
        private globals: GlobalsService,
        private route: ActivatedRoute,
        private router: Router,
        private data: EvaluationDataService,
        private connection: ConnectionService
    ) {
        globals.hideFooter();

        this.organisationStyling = globals.stylingDefaults;
    }

    get hasOpenFeedbackPage(): boolean {
        return this.allowGeneralFeedback || this.allowFollowup;
    }

    get openFeedbackPageIndex(): number {
        return this.number_of_pages - 2;
    }

    get thankYouPageIndex(): number {
        return this.number_of_pages - 1;
    }

    ngOnInit(): void {
        this.eventId = this.route.snapshot.params.eventId;
        this.organisationId = this.route.snapshot.params.organisationId;
        this.evaluationTaskId = this.route.snapshot.params.evaluationId;

        this.evaluationData = new EvaluationData(this.evaluationTaskId);

        this.connection.onlineState$.pipe(takeUntil(this.destroy$)).subscribe(connected => this.offline = !connected);

        this.globals.getStyling(this.organisationId, this.eventId).pipe(take(1)).subscribe(styling => {
            this.organisationStyling = styling;
        });

        this.data.getEvaluationView(this.organisationId, this.eventId, this.evaluationTaskId).pipe(take(1))
            .subscribe({
                next: response => {
                    this.eventName = response.eventName;
                    this.evaluationPoints = response.evaluationPoints.sort((a, b) => a.position - b.position);
                    this.allowGeneralFeedback = response.allowGeneralFeedback;
                    this.allowFollowup = response.allowFollowup;
                    this.requireFollowupReason = response.requireFollowupReason;
                    this.evaluationPoints.forEach(point => {
                        this.pagesTouched.push(false);
                        this.evaluationData.questionResponses.push({
                            questionId: point.id,
                            rating: undefined,
                            feedback: undefined
                        });
                    });

                    this._calculatePages();
                    this.progressPipLengthArray = new Array(this.number_of_pages);
                    this.progressPipWidth = `${100 / this.number_of_pages}%`;
                    this.loading = false;
                }, error: () => {
                    this.errorLoading = true;
                    this.loading = false;

                    this.timeout$ = setTimeout(() => {
                        this.router.navigate([this.organisationId, this.eventId, 'tasks']);
                    }, 2000);
                }
            });
    }

    ngOnDestroy(): void {
        clearTimeout(this.timeout$);
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    startEvaluation(): void {
        this.pageIndex = 1;
    }

    goToNextPage(): void {
        this.pageIndex++;

        this.changingPages = true;
        this.timeout$ = setTimeout(() => {
            this.changingPages = false;

            // If we're on the final page, start confetti and complete the evaluation.
            if (this.pageIndex === this.thankYouPageIndex && this.thankYouPage) {
                this.thankYouPage.spawnConfetti(30000);

                this.finishing = true;
                this.data.finishEvaluation(this.organisationId, this.eventId, this.evaluationTaskId, this.evaluationData).pipe(take(1)).subscribe({
                    next: () => {
                        this.finishing = false;
                    }, error: () => {
                        this.finishing = false;
                        this.errorFinishing = true;
                    }
                });
            }
        }, 300);
    }

    goToPreviousPage(): void {
        if (this.pageIndex > 0) {
            this.pageIndex--;

            this.changingPages = true;
            this.timeout$ = setTimeout(() => {
                this.changingPages = false;
            }, 300);
        }
    }

    finishEvaluation(): void {
        this.router.navigate([this.organisationId, this.eventId, 'tasks']);
    }

    onFormValidityChanged(value: string): void {
        switch (value) {
        case 'VALID':
            this.formInvalid = false;
            this.determiningValidity = false;
            break;
        case 'PENDING':
            this.determiningValidity = true;
            break;
        case 'INVALID':
            this.formInvalid = true;
            this.determiningValidity = false;
        }
    }

    isPreviousButtonEnabled(): boolean {
        return !this.saving && this.pageIndex > 0;
    }

    isNextButtonEnabled(): boolean {
        return !this.offline && !this.saving && this.pageIndex < this.number_of_pages - 1 && !this.formInvalid &&
            (this.pagesTouched[this.pageIndex] || this.pageIndex === this.openFeedbackPageIndex) &&
            // If we're on the open feedback page only allow through if the user has filled in the required fields if any.
            (this.pageIndex !== this.openFeedbackPageIndex || !this.requireFollowupReason || !this.evaluationData.requireFollowup || !!this.evaluationData.followupReason);
    }

    setTouchedConditional(index: number, point: IEvaluationPoint): void {
        // We only care about open feedback being touched if there is no rating input.
        if (!point.hasNumberRating) {
            this.pagesTouched[index] = true;
        }
    }

    private _calculatePages(): void {
        // Baseline 3 for opening + mood + thank you.
        this.number_of_pages = 3 + this.evaluationPoints.length;
        if (this.allowGeneralFeedback || this.allowFollowup) {
            this.number_of_pages++;
        }
    }
}
