<div *ngIf='error; else loader'>
    <div class='image-container'>
        <img src='/assets/img/default_welcome.png' class='img-fluid' alt='welcome image'
            [style.background-color]='defaultImageColor' />
    </div>

    <div class='text-center mt-3'>
        <div>
            <span [translate]='"events.error.could_not_load_events"'>
                You currently have no active events for this organisation. You will be redirected to the organisation
                select screen.
            </span>
        </div>
    </div>
</div>

<ng-template #loader>
    <div *ngIf='loading || loadingVideo; else landingPage' class='loading-container'>
        <cp-delayed-spinner class='m-auto'></cp-delayed-spinner>
    </div>
</ng-template>

<ng-template #landingPage>
    <div *ngIf='overview.videoId && videoActive && !errorLoadingVideo; else headerImageRef' class='video-container'>
        <cp-video [organisationId]="organisationId" [videoId]="overview.videoId" [maxHeight]="'80vw'"
        (loaded)="loadingVideo = false" (errored)="errorLoadingVideo = true">
        </cp-video>
    </div>
    
    <ng-template #headerImageRef>
        <ng-container *ngIf="overview.imageUrl; else defaultRef">
            <div class='image-container' *ngIf='organisationId'>
                <cp-image [blobId]='overview.imageUrl || ""' [organisationId]='organisationId' [swCache]='true'
                    (loadingError)='overview.imageUrl = undefined' [height]="'80vw'"></cp-image>
            </div>
        </ng-container>
    </ng-template>
    
    <ng-template #defaultRef>
        <div class='image-container'>
            <img src='/assets/img/default_welcome.png' class='img-fluid' alt='welcome image'
                [style.background-color]='defaultImageColor' />
        </div>
    </ng-template>

    <div *ngIf='noEvents' class='text-center mt-3'>
        <div>
            <span [translate]='"events.text.no_events_for_this_org"'>
                You currently have no events for this organisation. Check back later!
            </span>
        </div>

        <button mat-raised-button color='primary' class='mt-2' (click)='toOrganisationSelection()'>
            <span [translate]='"events.button.back_to_organisation_selection"'>
                Back to organisation selection
            </span>
        </button>
    </div>

    <div @initialFadeIn>
        <div class='bottom-border col-12' *ngIf='showTimeline'>
            <cp-timeline-indicator [finishDay]='overview.upcomingEvents[0].eventStartDate'
                [color]='overview.upcomingEvents[0].isUrgent ? "warn" : "primary"'></cp-timeline-indicator>
        </div>

        <div class='events-container col-12' *ngIf='hasEvaluations'
            [class.bottom-border]="hasUpcomingEvents || hasFinishedEvents">
            <div class='header' [translate]='"general.text.events_in_evaluation"'>
                Events in evaluation period
            </div>

            <div class='event row' *ngFor='let event of overview.evaluationEvents; let index = index'
                [class.urgent]='event.isUrgent' [class.showMessageLabel]='event.hasUnreadNotifications'
                [routerLink]='[event.id]' [attr.id]="index === 0 ? 'first-eval-event': null" @fadeIn>
                <div class='col-10 pl-0 pr-1 event-container'>
                    <div class='event-title'>
                        {{ event.eventName }}
                    </div>
                    <div class='event-details' *ngIf='event.evaluationEndDate'>
                        <span [translate]='"general.text.evaluation_open_until"'>
                            Evaluations open until
                        </span>
                        <span class='indicator-text'>
                            {{ event.evaluationEndDate | localizedDate:'mediumDate' }}
                        </span>
                    </div>
                </div>
                <div class='col-2 px-0 icon-container'>
                    <cp-icon *ngIf='!event.isUrgent; else urgentIcon' size='small' name='star'>
                    </cp-icon>
                </div>
            </div>
        </div>

        <div class='events-container col-12' *ngIf='hasUpcomingEvents'
            [class.bottom-border]='hasFinishedEvents'>
            <div class='header' [translate]='"general.text.current_events"'>
                Current events
            </div>

            <div class='event row' *ngFor='let event of overview.upcomingEvents; let index = index;'
                [class.urgent]='event.isUrgent' [class.showMessageLabel]='event.hasUnreadNotifications'
                [routerLink]='[event.id]' [attr.id]="index === 0 ? 'first-event': null"
                [class.finished]='event.hasBriefings && event.progress === 100' @fadeIn>
                <div class='col-10 pl-0 pr-1 event-container'>
                    <div class='event-title'>
                        {{ event.eventName }}
                    </div>
                    <div class='event-details row mx-0'>
                        <div class='col-5 px-0' [class.invisible]='!event.eventStartDate'>
                            <span class='indicator-text'>
                                {{ event.eventStartDate | localizedDate:'mediumDate' }}
                                <span *ngIf='getShowEndDate(event); else startTimeRef'>
                                    - {{ event.endDate | localizedDate: 'mediumDate' }}
                                </span>
                                <ng-template #startTimeRef>
                                    <span *ngIf='event.hasTime'>
                                        | {{ event.eventStartDate | localizedDate: TIME_FORMAT }}
                                    </span>
                                </ng-template>
                            </span>
                        </div>
                        <div class='col-7 px-0 text-right'>
                            <ng-container *ngIf='event.hasBriefings; else noBriefings'>
                                <span class='indicator-text'>
                                    {{ event.progress }}%
                                </span>
                                <span [translate]='"general.text.completed"'>
                                    Completed
                                </span>
                            </ng-container>
                            <ng-template #noBriefings>
                                <div class='d-inline timing-text' *ngIf='event.eventStartDate'>
                                    <span *ngIf='getDaysLeft(event) > 1; else today'
                                        [innerHTML]='"general.text.days_left" | translate:{ daysLeft: getDaysLeft(event) }'>
                                        .. days left
                                    </span>

                                    <ng-template #today>
                                        <span class='today'
                                            [translate]='getDaysLeft(event) === 1 ? "general.text.tomorrow" : "general.text.today"'>
                                            Today/Tomorrow
                                        </span>
                                    </ng-template>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                </div>
                <div class='col-2 px-0 icon-container'>
                    <cp-icon *ngIf='!event.isUrgent; else urgentIcon' strokeSize='small' size='small' name='eye'>
                    </cp-icon>
                </div>
            </div>
        </div>

        <div class='events-container col-12' *ngIf='hasFinishedEvents'>
            <div class='header' [translate]='"general.text.finished_events"'>
                Finished events
            </div>

            <div class='event finished row' *ngFor='let event of overview.finishedEvents; let index = index;'
                [class.urgent]='event.isUrgent' [class.showMessageLabel]='event.hasUnreadNotifications'
                [routerLink]='[event.id]' [attr.id]="index === 0 ? 'first-event': null" @fadeIn>
                <div class='col-10 pl-0 pr-1 event-container'>
                    <div class='event-title'>
                        {{ event.eventName }}
                    </div>
                    <div class='event-details row mx-0'>
                        <div class='col-5 px-0' [class.invisible]='!event.eventStartDate'>
                            <span class='indicator-text'>
                                {{ event.eventStartDate | localizedDate:'mediumDate' }}
                                <span *ngIf='getShowEndDate(event); else startTimeRef'>
                                    - {{ event.endDate | localizedDate: 'mediumDate' }}
                                </span>
                                <ng-template #startTimeRef>
                                    <span *ngIf='event.hasTime'>
                                        | {{ event.eventStartDate | localizedDate: TIME_FORMAT }}
                                    </span>
                                </ng-template>
                            </span>
                        </div>
                    </div>
                </div>
                <div class='col-2 px-0 icon-container'>
                    <cp-icon *ngIf='!event.isUrgent; else urgentIcon' strokeSize='small' size='small' name='eye'>
                    </cp-icon>
                </div>
            </div>
        </div>
    </div>

    <ng-template #urgentIcon>
        <cp-icon size='small' name='alert-circle' fill='warn' color='white' class='transparent-circle-icon'></cp-icon>
    </ng-template>
</ng-template>

<cp-settings-button></cp-settings-button>
