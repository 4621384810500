import { Component,OnInit, OnChanges, Input, Output, ViewChild, EventEmitter, ElementRef, SimpleChanges, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AnalyticsService, MediaDataService } from '@core/services';
import { take } from 'rxjs';

@Component({
    selector: 'cp-video',
    templateUrl: './video.component.html',
    styleUrls: ['./video.component.scss']
})
export class VideoComponent implements OnInit, OnChanges, OnDestroy {
    @Input()
        organisationId!: number;

    @Input()
        videoId!: number;

    @Input()
        eventId?: number;

    @Input()
        briefingId?: number;

    @Input()
        wikiTabId?: number;

    @Input()
        canSeek = true;

    @Input()
        maxHeight = "250px";

    @Output()
        loaded = new EventEmitter<void>();

    @Output()
        errored = new EventEmitter<void>();

    @Output()
        finished = new EventEmitter<void>();

    @ViewChild('videoPlayer')
        _videoPlayer!: ElementRef<HTMLVideoElement>;

    videoLoaded = false;
    loadingVideo = true;
    loadingThumbnail = false;
    errorLoadingVideo = false;
    
    videoEventsSupportedByUserAgent: boolean;

    _videoUrl?: string;
    safeVideoUrl?: SafeResourceUrl;
    customThumbnail?: string;

    private _watchedTime = 0;
    private _startTime?: number;

    constructor(
        private data: MediaDataService,
        private sanitizer: DomSanitizer,
        private analytics: AnalyticsService,
    ) {
        const userAgent = navigator.userAgent;
        // If the user agent is the android stock browser, return false
        if (/Android/gi.test(userAgent) && !/Chrome/gi.test(userAgent)) {
            this.videoEventsSupportedByUserAgent = false;
            return;
        }
        // If the user agent is mobile safari browser, return false
        else if (/iPad|iPhone|iPod/gi.test(userAgent) &&    // On mobile iOS
        !/CriOS/.test(userAgent) && // and not Mobile Chrome on iOS
        !/FxiOS/.test(userAgent) && // and not Mobile Firefox on iOS
        !/OPiOS/.test(userAgent)) { // and not Mobile Opera on iOS
            this.videoEventsSupportedByUserAgent = false;
            return;
        }
        // If the user agent is opera mini or mobile, return false
        else if (/Opera Mini|OPR/gi.test(userAgent)) {
            this.videoEventsSupportedByUserAgent = false;
            return;
        }
        else {
            this.videoEventsSupportedByUserAgent = true;
        }
    }

    get loading(): boolean {
        return this.loadingThumbnail || this.loadingVideo || (this.videoEventsSupportedByUserAgent && !this.videoLoaded);
    }

    ngOnInit(): void {
        this._loadVideo(this.videoId);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.videoId && !changes.videoId.firstChange && changes.videoId?.previousValue !== changes.videoId?.currentValue) {
            this._loadVideo(changes.videoId.currentValue);
        }
    }

    ngOnDestroy(): void {
        if (this._videoUrl) {
            URL.revokeObjectURL(this._videoUrl);
        }
    }

    onVideoStarted(): void {
        this._startTime = this._videoPlayer.nativeElement.currentTime;
    }

    onVideoPaused(): void {
        if (this._startTime === undefined || this._startTime === null) {
            return;
        }

        const start = Math.round(this._startTime);
        const end = Math.round(this._videoPlayer.nativeElement.currentTime);
        const duration = Math.round(this._videoPlayer.nativeElement.duration);

        this.analytics.track('VideoWatched', {
            videoId: this.videoId,
            briefingId: this.briefingId,
            tabId: this.wikiTabId,
            startTime: start,
            stopTime: end,
            videoFinished: end === duration
        }, this.organisationId, this.eventId);
    }

    onVideoLoaded(): void {
        this.videoLoaded = true;
        this.loaded.emit();
    }

    onVideoSeeking(): void {
        // If seeking is not allowed, and the user scrolls forward, set the time back
        if (!this.canSeek && this._videoPlayer?.nativeElement) {
            if (this._videoPlayer?.nativeElement.currentTime > this._watchedTime) {
                this._videoPlayer.nativeElement.currentTime = this._watchedTime;
            }
        }
    }

    onTimeUpdate(): void {
        if (!this.finished && this._videoPlayer?.nativeElement && !this._videoPlayer.nativeElement.seeking 
        && this._videoPlayer.nativeElement.currentTime > this._watchedTime) {
            this._watchedTime = this._videoPlayer.nativeElement.currentTime;
        }
    }

    onVideoEnded(): void {
        this.finished.emit();
    }

    private _loadVideo(videoId: number): void {
        if (!videoId) {
            return;
        } 

        this.loadingVideo = true;
        this.loadingThumbnail = true;
        this.errorLoadingVideo = false;

        this.data.getVideo(this.organisationId, videoId).pipe(take(1)).subscribe({
            next: (result) => {
                this._videoUrl = URL.createObjectURL(result);
                this.safeVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this._videoUrl);
                if (!this.videoEventsSupportedByUserAgent) {
                    setTimeout(() => {
                        if (!this.customThumbnail) {
                            this._videoPlayer.nativeElement.currentTime = 0.001;
                        }
                        this.loadingVideo = false;
                        this.loaded.emit();
                    }, 100);
                }
                else {
                    this.loadingVideo = false;
                }
            }, error: () => {
                this.errorLoadingVideo = true;
                this.loadingVideo = false;
                this.errored.emit();
            }
        });

        this.data.getVideoThumbnail(this.organisationId, videoId).pipe(take(1)).subscribe({
            next: (response) => {
                if (response) {
                    this.customThumbnail = response.thumbnail;
                    this._videoPlayer.nativeElement.currentTime = 0;
                }
                this.loadingThumbnail = false;
            }, error: () => {
                this.loadingThumbnail = false;
            }
        });
    }
}
