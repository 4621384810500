import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DropdownSectionModel } from './models/dropdown-section-content.model';

@Component({
    selector: 'cp-dropdown-section',
    templateUrl: './dropdown-section.component.html',
    styleUrls: ['./dropdown-section.component.scss']
})
export class DropdownSectionComponent {

    @Input()
        section!: DropdownSectionModel;

    @Input()
        inBriefing = false;

    @Output()
        opened = new EventEmitter<{id?: string; title?: string}>();

    @Output()
        toggled = new EventEmitter<{id?: string; state: boolean}>();

    isExpanded = false;

    onClickHeader() {
        this.isExpanded = !this.isExpanded;
        this.toggled.emit({id: this.section._content?.identifier, state: this.isExpanded });

        if (this.isExpanded && this.section._content?.identifier) {
            this.opened.emit({id: this.section._content?.identifier, title: this.section._content?.title});
        }
    }
}
