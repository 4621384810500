import { environment } from '@env';
import { EndPoint } from '@core/constants';
import { UrlResolver } from '@core/utils';
import { UserManagerSettings, WebStorageStateStore } from 'oidc-client-ts';

export function userManagerSettingsFactory(): UserManagerSettings {
    let host = environment.host;
    const domain = window.location.origin;

    // Check if the current host is different from the original host
    if (host != domain) {
        try {
            const url = new URL(domain);
            // If it's different check whether it is allowed in the whitelabel domain
            if (url.hostname.endsWith(environment.whitelabelHostSuffix)) {
                host = domain;
            }
        } catch {}
    }

    return {
        authority: UrlResolver.getUrl(EndPoint.identity),
        client_id: 'frontend',
        redirect_uri: host + '/sign-in/callback',
        post_logout_redirect_uri: host,
        response_type: 'code',
        scope: 'openid profile cpapi cpresource',
        filterProtocolClaims: true,
        loadUserInfo: true,
        userStore: new WebStorageStateStore({ store: window.localStorage }),
        automaticSilentRenew: true,
        silent_redirect_uri: host + '/silent-renew.html',
        includeIdTokenInSilentRenew: true
    };
}
