import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LoggingService } from '@core/services';
import { UrlResolver } from '@core/utils';
import { EndPoint } from '@core/constants';
import { environment } from '@env';

export class ErrorLogHttpInterceptor implements HttpInterceptor {
    constructor(
        private logging: LoggingService
    ) { }

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            tap((event: HttpEvent<any>) => {
                if (event instanceof HttpErrorResponse && event.url !== UrlResolver.getUrl(EndPoint.tracking, 'error')) {
                    this.logging.logError(event.error, this.extractOrgId(event.url), event.url || undefined);
                }
            })
        );
    }

    private extractOrgId(url: string | null): number | undefined {
        if (!url) {
            return undefined;
        }

        let pathParts = url.split('/');
        // Remove all parts that are part of the baseUrl
        pathParts = pathParts.filter(x => x && !x.includes('http') && !x.includes('catchphrase') && !x.includes('localhost') && x !== 'api');

        // Because local services are differentiated by port rather than url, they have one fewer url part
        let idIndex = environment.envName === 'local' ? 0 : 1;
        if (pathParts.length < idIndex + 1) {
            return undefined;
        }

        let id = pathParts[idIndex];
        // Some endpoints start with these strings in front of the organisation id, so we have to look one index further
        if (id === 'pdf' || id === 'feature' || id === 'glossary' || id === 'qr' || id === 'test') {
            if (pathParts.length < idIndex + 2) {
                return undefined;
            }
            id = pathParts[idIndex+1];
        }
        
        const idNumber = parseInt(id, 10);
        if (!isNaN(idNumber)) {
            return idNumber
        }

        return undefined;
    }
}
