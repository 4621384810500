<div *ngIf='initialLoad; else content' class='loading-container'>
    <cp-delayed-spinner class='m-auto'></cp-delayed-spinner>
</div>

<ng-template #content>
    <header class='header' [style.top.px]='headerHeight'>
        <div class='title'>
            <cp-icon name='arrow-left' size='small' class='cursor-pointer my-auto pr-3' (click)='backToOverview()'>
            </cp-icon>
            <span class='my-auto'>
                {{ groupName }}
            </span>
        </div>
        <div class='no-connection-bar' [class.no-live-chat]='!canUseLiveChat' *ngIf='hasNoConnection'
            [class.no-connection]='offline || (!hasSignalRConnection && !startingSignalR)' #noConnectionBar>
            <span *ngIf='offline; else normalNoConnection' [translate]='"general.text.no_internet_connection_bar"'>
            </span>
            <ng-template #normalNoConnection>
                <span [translate]='!canUseLiveChat ? "chat.text.no_live_chat" : "chat.text.could_not_connect_to_live_chat"'>
                </span>
            </ng-template>
        </div>
        <div class='active-date' *ngIf='headerTime'>
            <span class='day'>
                {{ headerTime | localizedDate:'EEEE'}}
            </span>
            <span class='date'>
                {{ headerTime | localizedDate:'longDate'}}
            </span>
        </div>
    </header>

    <div class='content' [class.urgent]='priority === "High"' [class.fade-in]='fadeIn'
        [style.padding-top.px]='headerOffset' [style.margin-bottom.px]='chatBarHeight + 55'>
        <div class='small-loader' *ngIf='loading'>
            <cp-delayed-spinner class='m-auto' [diameter]='20'></cp-delayed-spinner>
        </div>
        <div class='empty-message' *ngIf='messages.length === 0'>
            <!-- Remove with #2123 -->
            <span [translate]='"chat.text.no_messages_in_group_yet"'>
                There are no messages in this chat yet. Start typing to get the conversation going!
            </span>
        </div>
        <div *ngFor='let message of messages; let index = index' class='position-relative' #message>
            <div *ngIf='hasDateBreak(message, index)' class='date-break'>
                <span class='day'>
                    {{ message.sentOn | localizedDate:'EEEE'}}
                </span>
                <span class='date'>
                    {{ message.sentOn | localizedDate:'longDate'}}
                </span>
            </div>
            <div *ngIf='hasUnreadBreak(message, index)' class='unread-break'>
                <span [translate]='unreadMessages > 1 ? "chat.text.unread_messages" : "chat.text.unread_message"'
                    [translateParams]='{ unreadMessages: unreadMessages }'>
                    x unread messages
                </span>
            </div>
            <div class='chat-message' [class.is-user]='message.isSentByUser' [class.is-owner]='message.isOwnerMessage'
                [class.unread]='readAny && message.isUnreadForUser' (click)='resendMessage(message)'>
                <div class='name' [class.failed]='message.sendingFailed'>
                    <span *ngIf='message.sendingFailed; else nameRef'>
                        <span [translate]='"chat.text.sending_failed"'>
                            Sending message failed
                        </span>
                        <cp-icon class='pl-1' name='refresh-cw' size='x-small'></cp-icon>
                    </span>
                    <ng-template #nameRef>
                        <i class="material-icons-outlined owner-icon pr-1"
                            *ngIf='message.isOwnerMessage'>local_police</i>
                        <span *ngIf='message.isSentByUser; else name' [translate]='"general.text.you"'>
                            You
                        </span>
                        <ng-template #name>
                            <span>{{ message.sentBy }}</span>
                        </ng-template>
                    </ng-template>
                </div>
                <div class='message'>
                    <span class='text' [class.failed]='message.sendingFailed'>
                        {{ message.message }}
                    </span>
                    <span class='time'>
                        <span *ngIf='message.sending; else sent'>
                            <cp-icon class='message-sending' name='clock' size='x-small'></cp-icon>
                        </span>
                        <ng-template #sent>
                            <span *ngIf='message.sendingFailed; else time'>
                                <cp-icon class='message-failed' name='alert-circle' size='x-small'></cp-icon>
                            </span>
                            <ng-template #time>
                                <span>
                                    {{ message.sentOn | localizedDate: 'shortTime' }}
                                </span>
                            </ng-template>
                        </ng-template>
                    </span>
                </div>
            </div>
        </div>
    </div>

    <div class='bottom-scroller' *ngIf='canScrollToBottom' (click)='scrollToBottom()'
        [style.bottom.px]='chatBarHeight + 70' [@toBottomButtonAnimation]>
        <cp-icon name='arrow-down-circle' class='m-auto' size='small'></cp-icon>
    </div>

    <div class='message-bar' [class.focused]='inputFocused'>
        <form class='d-flex w-100'>
            <textarea class='message-input' type="text" [(ngModel)]='message' autosize [minRows]='1'
                [maxRows]='5' [fakeLineheight]='20' [ngModelOptions]='{ standalone: true }'
                (resized)='chatBarResize($event)' (focus)='inputFocused = true' (blur)='inputFocused = false'
                #input></textarea>

            <button class='send-button ml-auto mt-auto' [disabled]='!message || offline' [class.disabled]='!message || offline'
                (click)='sendMessage()'>
                <cp-icon name='send' size='x-small'></cp-icon>
            </button>
        </form>
    </div>
</ng-template>
