import { IEvaluationData, RGB } from '@evaluation/models';

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'cp-mood-indicator',
    templateUrl: './mood-indicator.component.html',
    styleUrls: ['./mood-indicator.component.scss']
})
export class MoodIndicatorComponent implements OnInit {

    @Input() evaluationData!: IEvaluationData;

    @Input() eventName!: string;

    @Input() error!: boolean;

    @Output() touched = new EventEmitter<void>();

    private _green: RGB;
    private _gray: RGB;
    private _red: RGB;

    constructor() {
        this._green = new RGB(130, 186, 122);
        this._gray = new RGB(186, 188, 200);
        this._red = new RGB(244, 67, 54);
    }

    // any because style accepts anything

    get color(): { [klass: string]: any } {
        const color = this._calculateColor().toString;

        return {
            'background-color': color,
            color
        };
    }

    // any because style accepts anything

    get inputStyling(): { [klass: string]: any } {
        const color = this._calculateColor().toString;

        return {
            color
        };
    }
    get yOffset(): number {
        const scale = 20;

        let percentage = 0;

        if (this.evaluationData.mood !== undefined) {
            if (this.evaluationData.mood >= 50) {
                // Because we need to go up on the y-axis we actually need to invert the sign
                percentage = (this.evaluationData.mood - 50) * -2;
            } else {
                // Because on 'sad' the offset needs to be further down on a lower mood we invert the percentage
                percentage = (100 - this.evaluationData.mood * 2);
            }
        }

        return scale * (percentage / 100);
    }

    get mouthPath(): string {
        const x1Position = 152;
        const x2Position = 182.4;
        const distanceScale = 20;

        const baseY = 250;

        if (this.evaluationData.mood !== undefined) {
            if (this.evaluationData.mood > 50) {
                const p1x = x1Position;
                const p1y = baseY + (this.yOffset * 2);

                const p2x = x2Position;
                const p2y = baseY + (this.yOffset * 2);

                // Calculate where we are on the offset scale
                const percentage = (this.evaluationData.mood - 50) * 2;

                // Distance of the control point
                const offset = distanceScale * (percentage / 100);

                // Location of the control points;
                const c1x = x1Position;
                const c1y = p1y + offset;

                const c2x = x2Position;
                const c2y = p2y + offset;

                return `M${p1x} ${p1y} C${c1x},${c1y} ${c2x},${c2y} ${p2x} ${p2y}`;
            // For the 'sad' face we need to move the actual points, and keep the control points static.
            } else {
                const c1x = x1Position;
                const c1y = baseY + (this.yOffset * 0.5);

                const c2x = x2Position;
                const c2y = baseY + (this.yOffset * 0.5);

                // Calculate where we are on the offset scale
                const percentage = 100 - this.evaluationData.mood * 2;

                // Distance of the control point
                const offset = distanceScale * (percentage / 100);

                // Location of the mouth corners.
                const p1x = x1Position;
                const p1y = c1y + offset;

                const p2x = x2Position;
                const p2y = c2y + offset;

                return `M${p1x} ${p1y} C${c1x},${c1y} ${c2x},${c2y} ${p2x} ${p2y}`;
            }
        }

        return `M${x1Position},${baseY} ${x2Position},${baseY}`;
    }

    ngOnInit(): void {
        // Default to neutral
        if (!this.evaluationData.mood) {
            this.evaluationData.mood = 50;
        }
    }

    private _calculateColor(): RGB {
        if (this.evaluationData.mood !== undefined) {
            if (this.evaluationData.mood === 0) {
                return this._red;
            } else if (this.evaluationData.mood === 100) {
                return this._green;
            } else if (this.evaluationData.mood > 50) {
                // Calculate the percentage between gray (50) and green (100)
                const percentage = (this.evaluationData.mood - 50) * 2;
                return this._gray.calculateMidPoint(this._green, percentage);
            } else if (this.evaluationData.mood < 50) {
                // Calculate the percentage between red (0) and gray(50);
                const percentage = this.evaluationData.mood * 2;
                return this._red.calculateMidPoint(this._gray, percentage);
            }
        }

        return this._gray;
    }
}
