import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SafeHtml } from '@angular/platform-browser';
import { AnalyticsService, ConnectionService } from '@core/services';
import { ConfirmDialogComponent, ConfirmDialogData, ConfirmDialogResult } from '@shared/components';
import { ContactBlock, ContactsContent } from '@wiki/models';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'cp-contact-wiki-tab-view',
    templateUrl: './contact-wiki-tab-view.component.html',
    styleUrls: ['./contact-wiki-tab-view.component.scss']
})
export class ContactWikiTabViewComponent implements OnInit, OnDestroy {
    @Input()
        code?: string;

    @Input()
        content!: ContactsContent;

    @Input()
        organisationId!: number;

    @Input()
        eventId!: number;

    @Input()
        wikiTabId!: number;

    @Input()
        introductionText?: SafeHtml;

    filter = '';
    offline = false;
    sortedContent!: ContactBlock[];
    private destroy$ = new Subject<boolean>();

    constructor(
        private dialog: MatDialog,
        private connection: ConnectionService,
        private analytics: AnalyticsService,
    ) { }

    get filteredContacts(): ContactBlock[] {
        if (!this.filter || !this.sortedContent) {
            return this.sortedContent;
        }

        const normalisedFilter = this.filter.toUpperCase();

        return this.sortedContent.filter(contact => {
            let contactFullName = '';

            if (contact.middleName) {
                contactFullName = `${contact.firstName} ${contact.middleName} ${contact.lastName}`;
            } else {
                contactFullName = `${contact.firstName} ${contact.lastName}`;
            }

            return contactFullName.toUpperCase().includes(normalisedFilter);
        });
    }

    get pageTrackingInformation(): unknown {
        return {
            type: 'wiki',
            wikiTabId: this.wikiTabId
        };
    }

    ngOnInit(): void {
        this._sortContent();

        this.offline = !this.connection.online;

        this.connection.onlineState$.pipe(takeUntil(this.destroy$))
            .subscribe((connected) => this.offline = !connected);
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    onClickEmergencyButton(): void {
        if (this.content?.emergencyNumber === undefined) {
            return;
        }

        const confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: new ConfirmDialogData(
                'wiki.text.contact_confirm_emergency_text',
                undefined,
                'warn',
                undefined,
                false,
                'wiki.button.confirm_emergency_text',
                'general.button.no',
            ),
            panelClass: 'small-dialog'
        });

        confirmDialogRef.afterClosed().pipe(take(1))
            .subscribe(
                (result: ConfirmDialogResult) => {
                    if (result === 'continue') {
                        window.location.href = `tel:${this.content.emergencyNumber}`;
                    }
                }
            );
    }

    toggleContact(contact: ContactBlock): void {
        contact.isExpanded = !contact.isExpanded;

        if (contact.isExpanded) {
            this.analytics.track('OpenContactDetails', {
                wikiTabId: this.wikiTabId,
                contactName: this.constructNameForContact(contact)
            }, this.organisationId, this.eventId);
        }
    }

    onClickOtherContactButton(event: UIEvent, contact: ContactBlock): void {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(contact.other || '');
        }

        // Propagation stopped in generic contact method
        this.onClickContactMethod(event, contact, 'Other');
    }

    onClickContactMethod(event: UIEvent, contact: ContactBlock, type: string): void {
        this.analytics.track('ActivateContact', {
            wikiTabId: this.wikiTabId,
            contactName: this.constructNameForContact(contact),
            contactMethodType: type
        }, this.organisationId, this.eventId);

        event.stopPropagation();
    }

    constructNameForContact(contact: ContactBlock): string {
        let result = '';

        if (contact.firstName) {
            result = contact.firstName;

            if (contact.middleName || contact.lastName) {
                result = result + ' ';
            }
        }

        if (contact.middleName) {
            result = result + contact.middleName;

            if (contact.lastName) {
                result = result + ' ';
            }
        }

        if (contact.lastName) {
            result = result + contact.lastName;
        }

        return result;
    }

    getTelephoneURL(phoneNumber: string): string {
        return `tel:${phoneNumber}`;
    }

    getMailURL(mailAddress: string): string {
        return `mailto:${mailAddress}`;
    }

    private _sortContent(): void {
        if (!this.content.contacts) {
            this.sortedContent = [];
            return;
        }

        this.sortedContent = this.content.contacts.sort((a, b) => a.lastName.localeCompare(b.lastName));
    }

}
