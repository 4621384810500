<div *ngIf="loading; else error" class="spinner-container d-flex justify-content-center align-items-center">
    <cp-delayed-spinner></cp-delayed-spinner>
</div>

<ng-template #error>
    <div *ngIf="errorLoading; else content" class="d-flex flex-column justify-content-center">
        <span class="warn-text text-center d-inline-block mt-5" [translate]="'briefing.text.briefing_load_error'">
            Something went wrong while trying to load your briefing, you will be redirected to the overview page.
        </span>
    </div>
</ng-template>

<ng-template #content>
    <div class="content-container row">
        <div class="title-page" [class.off-screen]="currentPageIndex !== undefined || !onTitlePage">
            <a (click)="goBack()" class="back-button">
                <button mat-icon-button>
                    <cp-icon name='arrow-left'
                        [color]='organisationStyling.colorTwoText === "Dark" && (!briefingView.imageBlobId || errorLoadingTitleImage) ? "black" : "white"'>
                    </cp-icon>
                </button>
            </a>
            <div class="d-flex flex-column">
                <header class="header-container" [style.background-color]='organisationStyling.colorTwo'
                    [class.darkText]='organisationStyling.colorTwoText === "Dark"'
                    [class.lightText]='organisationStyling.colorTwoText === "Light"'
                    *ngIf="!briefingView.imageBlobId || errorLoadingTitleImage; else imageHeader">
                    <div class="header-content-container p-5">
                        <h3>Briefing</h3>
                        <h1 class="mt-3">{{briefingView.title}}</h1>
                        <h2 class="subtitle mt-1">{{briefingView.subTitle}}</h2>
                    </div>
                    <div class="deadline-container" *ngIf="briefingView.deadlineOn">
                        <h3 class="float-left ml-5">
                            <span [translate]='"general.text.finish_before"'>
                                Finish before
                            </span>
                        </h3>
                        <h3 class="float-right text-right mr-5">{{briefingView.deadlineOn | localizedDate:DATE_FORMAT}}
                        </h3>
                    </div>
                </header>
                <ng-template #imageHeader>
                    <header class='image-container'>
                        <cp-image [blobId]='briefingView.imageBlobId || ""' [organisationId]="organisationId"
                            [width]="'100%'" [showOverlay]="true" (loadingError)="errorLoadingTitleImage = true">
                        </cp-image>

                        <div class='full-header-content-container'>
                            <div class="header-content-container p-5">
                                <h3>Briefing</h3>
                                <h1 class="mt-3">{{briefingView.title}}</h1>
                                <h2 class="subtitle mt-1">{{briefingView.subTitle}}</h2>
                            </div>
                            <div class="deadline-container" *ngIf="briefingView.deadlineOn">
                                <h3 class="float-left ml-5">
                                    <span [translate]='"general.text.finish_before"'>
                                        Finish before
                                    </span>
                                </h3>
                                <h3 class="float-right text-right mr-5">
                                    {{briefingView.deadlineOn | localizedDate:DATE_FORMAT}}</h3>
                            </div>
                        </div>
                    </header>
                </ng-template>
                <div class="bottom-container">
                    <div class="d-flex align-items-center justify-content-center py-5">
                        <div class="progress-tracker">
                            <span class="progress-tracker-text" [translate]='"general.text.progress"'>Your
                                progress</span>
                        </div>
                        <cp-radial-progress [size]="100" [bars]="progressBar" label='percentage'>
                        </cp-radial-progress>
                    </div>
                    <div *ngIf="briefingView.showMediaWarning" class="alert alert-warning d-flex align-items-center"
                        role="alert">
                        <cp-icon name="alert-triangle" class="mr-2"></cp-icon>
                        <span [translate]="'briefing.text.briefing_media_warning'">
                            This briefing contains a lot of media. To conserve data it is recommended to only view it on
                            a wi-fi connection.
                        </span>
                    </div>
                    <div class="d-flex flex-column px-4 mb-4">
                        <button *ngIf="!hasStarted; else hasStartedButtons" mat-raised-button
                            class="cp-button cp-button-glow mb-2" [loading]="loadingPages" color="primary"
                            (click)="startBriefing()">
                            <span [translate]="'briefing.button.start_briefing'">
                                Start briefing
                            </span>
                        </button>
                        <ng-template #hasStartedButtons>
                            <ng-container *ngIf='hasFinished; else hasNotFinishedButtons'>
                                <button mat-raised-button class="cp-button cp-button-glow mb-2" [loading]="loadingPages"
                                    color="primary" (click)="startBriefing()">
                                    <span [translate]="'briefing.button.restart_briefing'">
                                        Restart briefing
                                    </span>
                                </button>
                                <button mat-raised-button class="cp-button cp-button-glow mb-2" color="accent"
                                    (click)="goToResults()">
                                    <span [translate]='"general.button.view_results"'>
                                        View results
                                    </span>
                                </button>
                            </ng-container>
                            <ng-template #hasNotFinishedButtons>
                                <button mat-raised-button class="cp-button cp-button-glow mb-2" [loading]="loadingPages"
                                    color="primary" (click)="resumeBriefing()">
                                    <span [translate]="'briefing.button.resume_briefing'">
                                        Resume briefing
                                    </span>
                                </button>
                                <button mat-raised-button class="cp-button mb-2" [loading]="loadingPages" color="accent"
                                    (click)="restartBriefing()">
                                    <span [translate]="'briefing.button.restart_briefing'">
                                        Restart briefing
                                    </span>
                                </button>
                            </ng-template>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
        <div class='top-bar' [style.background-color]='organisationStyling.colorOne'
            [class.off-screen]="currentPageIndex === undefined" [class.transitioning]="changingPages"
            [class.darkText]='organisationStyling.colorOneText === "Dark"'
            [class.lightText]='organisationStyling.colorOneText === "Light"'>
            <a (click)="goBack()">
                <cp-icon name='arrow-left' size='small'>
                </cp-icon>
            </a>

            <span>
                {{ briefingView.title }}
            </span>
        </div>
        <div class="view-container" [class.position-fixed]="transitioningTitle"
            [class.off-screen]="currentPageIndex === undefined">
            <div class='no-connection-bar' *ngIf='offline'>
                <cp-no-connection-bar></cp-no-connection-bar>
            </div>
            <div class="position-relative">
                <div #page *ngFor="let page of loadedPages" class="col-12 page"
                    [ngClass]="getPageClasses(page.position)">
                    <div class="view-content row flex-column" [ngSwitch]="page.type">
                        <cp-briefing-page-view *ngSwitchCase="'Briefing'" [briefingPage]="page"
                            [organisationId]="organisationId" [organisationStyling]='organisationStyling'
                            [eventId]="eventId" [briefingId]="briefingView.briefingId"
                            [showVideos]="showVideos"
                            (videosFinished)="onVideosFinished(page.position)">
                        </cp-briefing-page-view>
                        <cp-quiz-page-view *ngSwitchCase="'Quiz'" [quizPage]="page" [organisationId]="organisationId"
                            [briefingTaskId]="briefingTaskId" [isQuizVisible]="currentPageIndex === page.position"
                            [transitioningFeedback]='transitioningFeedback'>
                        </cp-quiz-page-view>
                    </div>
                </div>
            </div>
        </div>
        <div class="feedback-page" [class.off-screen]="currentPageIndex !== undefined || !onFeedbackPage">
            <cp-score-page *ngIf='onFeedbackPage' [organisationId]=' organisationId' [taskId]='briefingTaskId'
                [organisationStyling]='organisationStyling' [briefingImageId]='briefingView.imageBlobId'
                (goToNext)="goToFeedbackPage()">
            </cp-score-page>
        </div>
        <div class="evaluation-page" [class.off-screen]="currentPageIndex !== undefined || !onEvaluationPage">
            <cp-evaluation-page *ngIf='onEvaluationPage' [organisationId]=' organisationId' [taskId]='briefingTaskId'
                [eventId]='eventId' [organisationStyling]='organisationStyling'
                [briefingImageId]='briefingView.imageBlobId' [evaluationRating]='briefingView.evaluationRating'
                [evaluationReason]='briefingView.evaluationReason'>
            </cp-evaluation-page>
        </div>
        <footer class="view-footer pt-3 pb-4" [class.off-screen]="currentPageIndex === undefined"
            [class.transitioning]="changingPages">
            <nav class="d-flex justify-content-center mb-3">
                <button mat-stroked-button class="cp-button d-flex justify-content-center px-3" (click)="goToPreviousPage()"
                    [disabled]="!isPreviousButtonEnabled(currentPageIndex)">
                    <cp-icon name="arrow-left" size="small"></cp-icon>
                    <span [translate]="'briefing.button.previous_screen'" class="d-inline-block ml-1">
                        Previous
                    </span>
                </button>
                <div class="page-indicator mx-3">
                    <span><span class="current-page-index">{{(this.currentPageIndex || 0) + 1}}</span> /
                        {{briefingView.totalPages}}</span>
                </div>
                <button mat-raised-button class="cp-button d-flex justify-content-center" color="primary"
                    (click)="goToNextPage()" [disabled]="!isNextButtonEnabled(currentPageIndex)">
                    <span [translate]="'briefing.button.next_screen'" class="d-inline-block mr-1">
                        Next
                    </span>
                    <cp-icon name="arrow-right" size="small"></cp-icon>
                </button>
            </nav>
            <div class="w-100 d-flex justify-content-center px-1">
                <div *ngFor="let pip of progressPipLengthArray; let i = index" class="progress-pip"
                    [style.width]="progressPipWidth">
                    <div class="progress-pip-inner" [class.active]="currentPageIndex === i"
                        [class.past]="(currentPageIndex || 0) > i"></div>
                </div>
            </div>
        </footer>
    </div>
</ng-template>
