import { Router, ActivatedRoute } from '@angular/router';
import { TranslatePipe } from '@ngx-translate/core';
import { AuthenticationService } from '@core/services';
import { ChatGroup } from '@chat/models';
import { Component, Input } from '@angular/core';
import { DateUtils } from '@core/utils';

@Component({
    selector: 'cp-chat-card',
    templateUrl: './chat-card.component.html',
    styleUrls: ['./chat-card.component.scss']
})
export class ChatCardComponent {
    @Input()
        chatGroup!: ChatGroup;

    @Input()
        eventId!: number;

    @Input()
        disabled = false;

    constructor(
        private auth: AuthenticationService,
        private translatePipe: TranslatePipe,
        private router: Router,
        private route: ActivatedRoute) { }

    get profileLetter(): string {
        if (this.chatGroup.priority === 'High') {
            return '!';
        }

        return this.chatGroup.groupName[0];
    }

    get senderName(): string {
        if (!this.chatGroup.latestSender) {
            return '';
        }

        return this.auth.userId === this.chatGroup.latestSenderId
            ? this.translatePipe.transform('general.text.you')
            : this.chatGroup.latestSender;
    }

    get wasSentToday(): boolean {
        if (!this.chatGroup.latestSentOn) {
            return false;
        }

        return DateUtils.IsSameDate(new Date(), this.chatGroup.latestSentOn);
    }

    get wasSentYesterday(): boolean {
        if (!this.chatGroup.latestSentOn) {
            return false;
        }

        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);

        return DateUtils.IsSameDate(yesterday, this.chatGroup.latestSentOn);
    }

    openGroup(): void {
        if (this.disabled) {
            return;
        }

        this.router.navigate([this.chatGroup.groupId], { relativeTo: this.route });
    }
}
