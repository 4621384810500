<div class='close-button'>
    <button mat-icon-button (click)='closed.emit()'>
        <cp-icon name='x' color='white'></cp-icon>
    </button>
</div>
<div [class.invisible]="loadingPdf">
    <ngx-extended-pdf-viewer [src]="pdf" height='90vh' [sidebarVisible]="false"
        [showToolbar]='false' backgroundColor='#000000' (pdfLoaded)='loadingPdf = false'>
    </ngx-extended-pdf-viewer>
</div>
