import { Component, OnInit, Input, Output, EventEmitter, ViewChildren, QueryList, ElementRef, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

export enum VerificationCodeInputType {
    numerical,
    alphanumerical,
    alphabetical
}

@Component({
    selector: 'cp-verification-code-input',
    templateUrl: './verification-code-input.component.html',
    styleUrls: ['./verification-code-input.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class VerificationCodeInputComponent implements OnInit {
    @Input() length = 6;

    @Input() inputType = VerificationCodeInputType.alphanumerical;

    @Output() submittedCode = new EventEmitter<string>();

    @ViewChildren('inputField') inputElements!: QueryList<ElementRef>;

    inputValues: string[] = [];

    formGroup!: UntypedFormGroup;

    private _value = '';

    constructor() { }

    @Input()
    get value(): string { return this.getValue(); }
    set value(value: string) { this._value = value; }

    ngOnInit(): void {
        this._value = this.cleanValue(this._value);
        this.initializeInputs(this._value);
    }

    clearValue(): void {
        this._value = '';
        Object.keys(this.formGroup.controls).forEach(key => {
            this.formGroup.get(key)?.setValue('');
        });
    }

    cleanValue(value: string): string {
        // Clean up the input value if any was provided
        if (value) {
            value = value.replace(/^[^a-zA-Z0-9]/g, '');

            switch (this.inputType) {
            case VerificationCodeInputType.numerical:
                value = value.replace(/[a-zA-Z]/g, '');
                break;
            case VerificationCodeInputType.alphabetical:
                value = value.replace(/[0-9]/g, '');
                break;
            }

            if (value.length > this.length) {
                value = this.value.substring(0, this.length - 1);
            }
        }

        return value;
    }

    initializeInputs(value: string): void {
        this.inputValues = new Array(this.length).fill('');
        this.formGroup = new UntypedFormGroup({});

        const validators = [Validators.required];
        switch (this.inputType) {
        case VerificationCodeInputType.alphanumerical:
            validators.push(Validators.pattern(/[a-z0-9]/i));
            break;
        case VerificationCodeInputType.numerical:
            validators.push(Validators.pattern(/[0-9]/));
            break;
        case VerificationCodeInputType.alphabetical:
            validators.push(Validators.pattern(/[a-z]/i));
            break;
        }

        this.inputValues.forEach((_, index) => {
            let initialValue = '';
            if (value.charAt(index)) {
                initialValue = value.charAt(index);
                this.inputValues[index] = initialValue;
            }
            this.formGroup.addControl(index.toString(), new UntypedFormControl(initialValue, validators));
        });
    }

    onKeyUp(event: KeyboardEvent): void {
        const inputElement = event.target as HTMLInputElement;
        const formControl = this.formGroup.controls[+inputElement.id];

        if (event.key.length === 1 && event.key.match(/[a-zA-Z0-9]/)) {
            formControl.setValue(event.key);
        }
        else {
            if (event.key === 'Backspace' && formControl.value === '') {
                if (formControl.value === '') {
                    const previousInput = this.findPreviousInput(inputElement);
                    if (previousInput) {
                        previousInput.focus();
                        return;
                    }
                }
            } else {
                // This makes it so that symbols will immediately disappear
                formControl.setValue('');
            }
        }

        // If we have a valid input or the space is pressed, focus on the next element
        if (event.key === ' ' || (event.key.length === 1 && event.key.match(/[a-zA-Z0-9]/))) {
            const nextInput = this.findNextInput(inputElement);
            if (nextInput) {
                nextInput.focus();
            }
        }

        if (this.formGroup.valid) {
            this.submittedCode.emit(this.value);
        }
    }

    onPaste(event: ClipboardEvent): boolean {
        event.preventDefault();
        event.cancelBubble = true;
        const inputElement = event.target as HTMLInputElement;
        // Always start pasting from the first element.
        let inputId = 0;
        let i = 0;
        let formControl = this.formGroup.controls[inputId];
        const pastedText = event.clipboardData?.getData('text');

        if (pastedText) {
            while (formControl && pastedText.charAt(i)) {
                formControl.setValue(pastedText.charAt(i));

                inputId++;
                i++;
                formControl = this.formGroup.controls[inputId];
            }

            // Blur so that the key up of the paste event does not get triggered.
            inputElement.blur();

            // Submit if valid
            if (this.formGroup.valid) {
                this.submittedCode.emit(this.value);
            }
        }

        return false;
    }

    findNextInput(input: HTMLInputElement): HTMLElement | null {
        const currentId = +input?.id;
        if (currentId != null) {
            // If the last element is focused, wrap around
            if (currentId === this.length - 1) {
                return null;
            }
            else {
                return this.inputElements.toArray()[currentId + 1].nativeElement;
            }
        }
        return null;
    }

    findPreviousInput(input: HTMLInputElement): HTMLElement | null {
        const currentId = +input?.id;
        if (currentId != null) {
            // If the last element is focused, wrap around
            if (currentId === 0) {
                return this.inputElements.first.nativeElement;
            }
            else {
                return this.inputElements.toArray()[currentId - 1].nativeElement;
            }
        }
        return null;
    }

    getValue(): string {
        let value = '';
        for (const i of Object.keys(this.formGroup.controls)) {
            if (this.formGroup.controls[i].value) {
                value = value.concat(this.formGroup.controls[i].value);
            }
            else {
                value = value.concat(' ');
            }
        }
        return value;
    }

}
