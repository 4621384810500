import { OverviewDataService } from '@overview/services';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { CoreModule } from '@core/core.module';
import { HttpClientModule } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { SharedModule } from '@shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OrganisationOverviewComponent, MiniTimelineSliderComponent, MaintenanceDialogComponent, ForceLogoutComponent } from './components';
import { AccountModule } from '@account/account.module';

import localeNL from '@angular/common/locales/nl';
import localeEN from '@angular/common/locales/en-GB';
import localeES from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
import { TwoFactorDialogComponent } from './sign-in/components';

registerLocaleData(localeNL);
registerLocaleData(localeEN);
registerLocaleData(localeES);

@NgModule({
    declarations: [
        AppComponent,
        OrganisationOverviewComponent,
        MiniTimelineSliderComponent,
        MaintenanceDialogComponent,
        ForceLogoutComponent,
        TwoFactorDialogComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        CoreModule,
        HttpClientModule,
        SharedModule,
        AccountModule,
        ServiceWorkerModule.register('ngsw-custom.js', { enabled: environment.useServiceWorker })
    ],
    providers: [
        OverviewDataService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
