import { style, animate, trigger, transition } from '@angular/animations';

export const slideInTopAnimation = trigger(
    'slideInTopAnimation',
    [
        transition(':enter', [
            style({ 'max-height': '0px' }),
            animate('0.2s ease-out', style({ 'max-height': '{{ target_height }}' }))
        ], { params: { target_height: '70px'}}),
        transition(':leave', [
            style({ 'max-height': '{{ target_height }}' }),
            animate('0.2s ease-out', style({ 'max-height': '0px' }))
        ], { params: { target_height: '70px' }})
    ]
);

export const slideInBottomAnimation = trigger(
    'slideInBottomAnimation',
    [
        transition(':enter', [
            style({ 'max-height': '0px' }),
            animate('0.2s ease-out', style({ 'max-height': '{{ target_height }}' }))
        ], { params: { target_height: '50px'}}),
        transition(':leave', [
            style({ 'max-height': '{{ target_height }}' }),
            animate('0.2s ease-out', style({ 'max-height': '0px' }))
        ], { params: { target_height: '50px' }})
    ]
);

export const slideInBottomAnimationPositioned = trigger(
    'slideInBottomAnimationPositioned',
    [
        transition(':enter', [
            style({ top: '100vh' }),
            animate('0.5s ease-out', style({ top: '0' }))
        ], { params: { target_height: '50px'}}),
        transition(':leave', [
            style({ top: '0' }),
            animate('0.5s ease-out', style({ top: '100vh' }))
        ])
    ]
);
