import { takeUntil } from 'rxjs/operators';
import { Directive, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { ConnectionService } from '@core/services';
import { Subject } from 'rxjs';

@Directive({
    selector: '[connection-dependant]'
})
export class OfflineDisableDirective implements OnInit, OnDestroy {
    private destroy$ = new Subject<boolean>();
    private isOffline = false;

    constructor(private connection: ConnectionService) {
    }

    @HostBinding('disabled')
    get isDisabled(): boolean {
        return this.isOffline;
    }

    ngOnInit(): void {
        this.isOffline = !navigator.onLine;
        this.connection.onlineState$.pipe(takeUntil(this.destroy$))
            .subscribe(connected => this.isOffline = !connected);
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
