<ng-container *ngIf="briefingPage">
    <div class="briefing-page-container">
        <div>
            <div *ngIf='!briefingPage.imageBlobId || errorLoadingTitleImage; else imageHeader'
                class='header-container p-3 d-flex flex-column' [style.backgroundColor]='organisationStyling.colorTwo'
                [class.darkText]='organisationStyling.colorTwoText === "Dark"'
                [class.lightText]='organisationStyling.colorTwoText === "Light"'>
                <div class='title'>
                    {{ briefingPage.title }}
                </div>

                <div class='subtitle' *ngIf='briefingPage.subtitle'>
                    {{ briefingPage.subtitle }}
                </div>
            </div>
        </div>

        <ng-template #imageHeader>
            <div class='image-container'>
                <cp-image [blobId]='briefingPage.imageBlobId || ""' [organisationId]="organisationId" [width]="'100vw'"
                    [showOverlay]="true" (loadingError)="errorLoadingTitleImage = true" [code]='code'></cp-image>

                <div class='p-3 title-container'>
                    <div class='text-container'>
                        <div class='title'>
                            {{ briefingPage.title }}
                        </div>

                        <div class='subtitle' *ngIf='briefingPage.subtitle'>
                            {{ briefingPage.subtitle }}
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>

        <div class="px-3 pt-3 section-wrapper">
            <div *ngFor="let section of briefingPage.sections; let index = index" class="p-2"
            [class.dropdown-section]='section.type === sectionType.dropdown'>
                <div [ngSwitch]="section.type">
                    <div *ngSwitchCase="sectionType.text" [innerHTML]="section._sanitizedContent"
                        glossary-injection [organisationId]="organisationId" [eventId]="eventId"
                        [pageInformation]="pageTrackingInformation" class="text-section mb-2"></div>
                    <cp-image *ngSwitchCase="sectionType.image" [imageClasses]='imageClasses' [code]='code'
                        [blobId]='section.content || ""' [organisationId]="organisationId"
                        [showWarningOnError]="true" [width]="'100%'" [canOverlayZoom]='true'></cp-image>
                    <ng-container *ngIf="showVideos">
                        <cp-video-section *ngSwitchCase="sectionType.video" [section]='section'
                            [index]='index' [finished]="briefingPage?.finished || false" [organisationId]="organisationId"
                            [eventId]='eventId' [briefingId]="briefingId" (videoFinished)="onVideoFinished(index)">
                        </cp-video-section>
                    </ng-container>
                    <cp-image-text-section *ngSwitchCase="sectionType['image-text']" [section]="section"
                        [organisationId]="organisationId" [eventId]="eventId" [code]='code'
                        [pageInformation]="pageTrackingInformation">
                    </cp-image-text-section>
                    <cp-divider-section *ngSwitchCase="sectionType.divider" [section]="section" [inBriefing]="true">
                    </cp-divider-section>
                    <cp-dropdown-section *ngSwitchCase="sectionType.dropdown" [section]="sectionAsDropdownSection(section)"
                    [inBriefing]="true">
                        <div *ngFor="let childSection of getChildSections(index)" [ngSwitch]="childSection.type">
                            <div *ngSwitchCase="sectionType.text" [innerHTML]="childSection._sanitizedContent"
                                glossary-injection [organisationId]="organisationId" [eventId]="eventId"
                                [pageInformation]="pageTrackingInformation" class="text-section mb-2">
                            </div>
                            <cp-image *ngSwitchCase="sectionType.image" [imageClasses]='imageClasses' [code]='code'
                                [blobId]='childSection.content || ""' [organisationId]="organisationId"
                                [showWarningOnError]="true" [width]="'100%'" [canOverlayZoom]='true'></cp-image>
                            <ng-container *ngIf="showVideos">
                                <cp-video-section *ngSwitchCase="sectionType.video" [section]='childSection'
                                    [index]='index' [finished]="briefingPage?.finished || false" [organisationId]="organisationId"
                                    [eventId]='eventId' [briefingId]="briefingId" (videoFinished)="onVideoFinished(index)">
                                </cp-video-section>
                            </ng-container>
                            <cp-image-text-section *ngSwitchCase="sectionType['image-text']" [section]="childSection"
                                [organisationId]="organisationId" [eventId]="eventId" [code]='code'
                                [pageInformation]="pageTrackingInformation">
                            </cp-image-text-section>
                            <cp-divider-section *ngSwitchCase="sectionType.divider" [section]="childSection" [inBriefing]="true">
                            </cp-divider-section>
                        </div>
                    </cp-dropdown-section>
                </div>
            </div>
        </div>
    </div>
</ng-container>
