import { BriefingSectionType, IBriefingSectionModel, StylingModel } from '@core/models';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { IBriefingPageModel } from '@briefing/models';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { BriefingPage } from '@test/models';
import { DividerSectionModel, DropdownSectionModel, ImageTextSectionModel } from '@shared/components';

@Component({
    selector: 'cp-briefing-page-view',
    templateUrl: './briefing-page-view.component.html',
    styleUrls: ['./briefing-page-view.component.scss']
})
export class BriefingPageViewComponent implements OnInit {
    @Input()
        briefingPage?: IBriefingPageModel | BriefingPage | undefined;
    @Input()
        briefingId!: number;
    @Input()
        organisationId!: number;
    @Input()
        eventId!: number;
    @Input()
        organisationStyling!: StylingModel;
    @Input()
        code?: string;
    @Input()
        showVideos?: boolean;

    @Output()
        videosFinished: EventEmitter<void> = new EventEmitter<void>();

    sectionType = BriefingSectionType;

    videoStatuses: { position: number; isFinished: boolean }[] = [];

    errorLoadingTitleImage = false;

    dropdownChildSections: { index: number; sections?: IBriefingSectionModel[] }[] = [];

    constructor(
        private sanitizer: DomSanitizer
    ) { }

    get imageClasses(): { [klass: string]: boolean } {
        return {
            'img-fluid': true,
            'py-3': true
        };
    }

    get pageTrackingInformation(): unknown {
        return {
            type: 'briefing',
            briefingId: this.briefingId,
            briefingPageNo: this.briefingPage?.position
        };
    }

    ngOnInit(): void {
        if (this.briefingPage) {
            if (!this.briefingPage.finished) {
                this._initializeVideoStatuses();
            }
        }

        this._parseComplexContent(this.briefingPage?.sections);
    }

    onVideoFinished(position: number): void {
        const finishedVideo = this.videoStatuses.find((videoStatus) => videoStatus.position === position);

        if (finishedVideo) {
            finishedVideo.isFinished = true;
        }

        const allVideosFinished = this.videoStatuses.every((videoStatus) => videoStatus.isFinished);

        if (allVideosFinished) {
            this.videosFinished.emit();
        }
    }

    trustContent(content: string | undefined): SafeHtml {
        if (content) {
            return this.sanitizer.bypassSecurityTrustHtml(content);
        }
        return '';
    }

    getChildSections(index: number): IBriefingSectionModel[] {
        return this.dropdownChildSections.find(x => x.index === index)?.sections || [];
    }

    sectionAsDropdownSection(section: IBriefingSectionModel): DropdownSectionModel {
        return new DropdownSectionModel(section.content);
    }

    private _parseComplexContent(sections?: IBriefingSectionModel[]): void {
        if (!sections) {
            return;
        }

        sections.forEach((x, index) => {
            switch (x.type) {
            case BriefingSectionType.text:
                x._sanitizedContent = this.trustContent(x.content);
                break;
            case BriefingSectionType['image-text']:
                const imageTextSection = x as ImageTextSectionModel;
                imageTextSection._content = JSON.parse(imageTextSection.content || '{}');
                break;
            case BriefingSectionType.divider:
                const dividerSection = x as DividerSectionModel;
                dividerSection._content = JSON.parse(dividerSection.content || '{}');
                break;
            case BriefingSectionType.dropdown:
                const dropdownSection = x as DropdownSectionModel;
                dropdownSection._content = JSON.parse(dropdownSection.content || '{}');
                this._parseComplexContent(dropdownSection._content?.childSections);
                this.dropdownChildSections.push({index, sections: dropdownSection._content?.childSections});
                break;
            }
        });
    }

    private _initializeVideoStatuses(): void {
        this.briefingPage?.sections?.forEach((section, index) => {
            if (section.type === BriefingSectionType.video) {
                this.videoStatuses.push({ position: index, isFinished: false });
            }
        });

        if (this.videoStatuses.length === 0) {
            this.videosFinished.emit();
        }
    }
}
