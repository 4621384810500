import { Component, Input } from '@angular/core';
import { AnalyticsService } from '@core/services';
import { FaqCategory, FaqCategoryItem } from '@wiki/models';

@Component({
    selector: 'cp-wiki-faq-view',
    templateUrl: './wiki-faq-view.component.html',
    styleUrls: ['./wiki-faq-view.component.scss']
})
export class WikiFaqViewComponent {
    @Input()
        organisationId!: number;

    @Input()
        eventId!: number;

    @Input()
        categories?: FaqCategory[];

    filter?: string;

    constructor(private analytics: AnalyticsService) { }

    get filteredCategories(): FaqCategory[] {
        if (!this.categories) {
            return [];
        }

        return this.categories?.filter(x => {
            const filteredItems = this.filteredItems(x);
            return filteredItems && filteredItems.length > 0;
        });
    }

    filteredItems(category: FaqCategory): FaqCategoryItem[] {
        if (!this.filter || !category.items || category.items.length === 0) {
            return category.items;
        }

        const normalisedFilter = this.filter.toUpperCase();
        return category.items.filter(item => item.question.toUpperCase().includes(normalisedFilter));
    }

    toggleCategory(category: FaqCategory): void {
        const currentState = category.expanded;
        if (currentState) {
            category.items.forEach(x => x.expanded = false);
        }
        category.expanded = !currentState;

        if (category.expanded) {
            this.analytics.track('FAQCategoryOpen', {
                categoryId: category.id,
                categoryTitle: category.title
            }, this.organisationId, this.eventId);
        }
    }

    toggleItem(category: FaqCategory, item: FaqCategoryItem): void {
        item.expanded = !item.expanded;

        if (item.expanded) {
            this.analytics.track('FAQQuestionOpen', {
                categoryId: category.id,
                categoryTitle: category.title,
                questionId: item.id,
                questionTitle: item.question
            }, this.organisationId, this.eventId);
        }
    }

    getTrackingInformation(category: FaqCategory, question: FaqCategoryItem): unknown {
        return {
            type: 'faq',
            faqCategoryId: category.id,
            questionId: question.id,
        };
    }
}
