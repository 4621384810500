<cp-no-connection-message *ngIf='offlineOnLoad; else loader'>
</cp-no-connection-message>

<ng-template #loader>
    <div class='loading-container' *ngIf='loading; else loadedRef'>
        <cp-delayed-spinner class='m-auto'></cp-delayed-spinner>
    </div>
</ng-template>

<ng-template #loadedRef>
    <header class='py-3' *ngIf='!errorLoading'>
        <div class='title mb-1'>
            <span>
                {{ eventName }}
            </span>
        </div>
        <div class='subtitle' *ngIf='eventDate'>
            {{ eventDate | localizedDate: DATE_FORMAT }}
            <span *ngIf='showEndDate; else startTimeRef'>
                - {{ eventEndDate | localizedDate: DATE_FORMAT }}
            </span>
            <ng-template #startTimeRef>
                <span *ngIf='eventHasTime'>
                    | {{ eventDate | localizedDate: TIME_FORMAT }}
                </span>
            </ng-template>
        </div>
    </header>

    <ng-container *ngIf='errorLoading; else contentRef'>
        <div class='alert alert-danger mt-5'>
            <span [translate]='"notifications.error.loading_overview"'>
                Something went wrong trying to load the notification overview. You will be sent back to the event
                landing page shortly.
            </span>
        </div>
    </ng-container>
    <ng-template #contentRef>
        <div *ngIf='hasNoNotifications; else notificationView'>
            <div class='alert alert-primary mt-3 mx-1'>
                <span [translate]='"notifications.text.no_notifications_message"'>
                    If we have any notifications for you they will show up in this screen. Currently we don''t have any
                    for this event, so check back later!
                </span>
            </div>
        </div>

        <ng-template #notificationView>
            <ng-container *ngIf='urgentNotifications && urgentNotifications.length > 0'>
                <div class='notifications-header mt-3 mx-1 pb-2'>
                    <div class='title'>
                        <span [translate]='"notifications.text.alert_s"'>
                            Alert(s)
                        </span>
                    </div>
                    <div class='counter'>
                        <span>
                            ({{urgentNotifications.length}})
                        </span>
                    </div>
                </div>
                <div *ngFor='let notification of urgentNotifications' class='notification mt-2 pb-2'>
                    <div class='w-100'>
                        <div class='d-flex' (click)='notification.opened = !notification.opened'>
                            <div class='side float-left d-flex justify-content-center align-items-center'>
                                <cp-icon name='bell' color='warn' size='small'>
                                </cp-icon>
                            </div>
                            <div class='title-container'>
                                <div class='title alert-title'>
                                    <span>
                                        {{ getTranslation(notification.titleKey, notification.titleParams) }}
                                    </span>
                                </div>
                                <div class='datetime'>
                                    <!-- Don't move these to a newline, whitespace messes up the layout -->
                                    <span class='mr-1'>{{ notification.sentOn | localizedDate: 'mediumDate' }}</span>
                                    <span
                                        class='time pl-1'>{{ notification.sentOn | localizedDate: 'shortTime' }}</span>
                                </div>
                            </div>
                            <div class='side float-right d-flex justify-content-center align-items-center'>
                                <div class='button'>
                                    <cp-icon size='small' class='chevron' [rotated]='notification.opened || false'
                                        name='chevron-down'>
                                    </cp-icon>
                                </div>
                            </div>
                        </div>
                        <div class='expanded-content mt-2' [class.hidden]='!notification.opened'>
                            <div class='mb-3'>
                                <span>
                                    {{ getTranslation(notification.contentKey, notification.contentParams) }}
                                </span>
                            </div>
                            <div class='buttons mb-3'>
                                <button mat-mini-fab (click)='archiveUrgentNotification(notification.id)'
                                    class='float-left archive-button'>
                                    <cp-icon size='x-small' color='white' name='archive'>
                                    </cp-icon>
                                </button>
                                <button mat-mini-fab color='primary' class='float-right cta-button'
                                    *ngIf='notification.ctaParams && notification.ctaParams.length > 0'
                                    (click)='onClickCtaButton(notification.ctaParams)'>
                                    <cp-icon size='small' color='white' name='arrow-right' class='icon-fix'>
                                    </cp-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container>
                <div class='notifications-header mt-3 mx-1 pb-2'>
                    <div class='title'>
                        <span [translate]='"notifications.text.unread"'>
                            Unread
                        </span>
                    </div>
                    <div class='counter'>
                        <span>
                            ({{unreadNotifications.length}})
                        </span>
                    </div>
                </div>
                <div *ngFor='let notification of unreadNotifications' class='notification mt-2 pb-2'>
                    <div class='w-100'>
                        <div class='d-flex' (click)='notification.opened = !notification.opened'>
                            <div class='side orange d-flex justify-content-center align-items-center'>
                                <cp-icon name='bell' color='currentColor' size='small'>
                                </cp-icon>
                            </div>
                            <div class='title-container'>
                                <div class='title'>
                                    <span>
                                        {{ getTranslation(notification.titleKey, notification.titleParams) }}
                                    </span>
                                </div>
                                <div class='datetime'>
                                    <!-- Don't move these to a newline, whitespace messes up the layout -->
                                    <span class='mr-1'>{{ notification.sentOn | localizedDate: 'mediumDate' }}</span>
                                    <span
                                        class='time pl-1'>{{ notification.sentOn | localizedDate: 'shortTime' }}</span>
                                </div>
                            </div>
                            <div class='side d-flex justify-content-center align-items-center'>
                                <div class='button'>
                                    <cp-icon size='small' class='chevron' [rotated]='notification.opened || false'
                                        name='chevron-down'>
                                    </cp-icon>
                                </div>
                            </div>
                        </div>
                        <div class='expanded-content mt-2' [class.hidden]='!notification.opened'>
                            <div class='mb-3'>
                                <span>
                                    {{ getTranslation(notification.contentKey, notification.contentParams) }}
                                </span>
                            </div>
                            <div class='buttons mb-3'>
                                <button mat-mini-fab (click)='archiveUnreadNotification(notification.id)'
                                    class='float-left archive-button'>
                                    <cp-icon size='x-small' color='white' name='archive'>
                                    </cp-icon>
                                </button>
                                <button mat-mini-fab color='primary' class='float-right cta-button'
                                    *ngIf='notification.ctaParams && notification.ctaParams.length > 0'
                                    (click)='onClickCtaButton(notification.ctaParams)'>
                                    <cp-icon size='small' color='white' name='arrow-right' class='icon-fix'>
                                    </cp-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container>
                <div class='notifications-header mt-3 mx-1 pb-2'>
                    <div class='title'>
                        <span [translate]='"notifications.text.read"'>
                            Read
                        </span>
                    </div>
                    <div class='counter read'>
                        <span>
                            ({{totalReadNotifications}})
                        </span>
                    </div>
                </div>
                <div *ngFor='let notification of readNotifications' class='notification mt-2 pb-2'>
                    <div class='w-100'>
                        <div class='d-flex' (click)='notification.opened = !notification.opened'>
                            <div class='side d-flex justify-content-center align-items-center'>
                                <cp-icon name='bell' color='grey' size='small'>
                                </cp-icon>
                            </div>
                            <div class='title-container'>
                                <div class='title'>
                                    <span>
                                        {{ getTranslation(notification.titleKey, notification.titleParams) }}
                                    </span>
                                </div>
                                <div class='datetime'>
                                    <!-- Don't move these to a newline, whitespace messes up the layout -->
                                    <span class='mr-1'>{{ notification.sentOn | localizedDate: 'mediumDate' }}</span>
                                    <span
                                        class='time pl-1'>{{ notification.sentOn | localizedDate: 'shortTime' }}</span>
                                </div>
                            </div>
                            <div class='side d-flex justify-content-center align-items-center'>
                                <div class='button'>
                                    <cp-icon size='small' class='chevron' [rotated]='notification.opened || false'
                                        name='chevron-down'>
                                    </cp-icon>
                                </div>
                            </div>
                        </div>
                        <div class='expanded-content mt-2' [class.hidden]='!notification.opened'>
                            <div class='mb-3'>
                                <span>
                                    {{ getTranslation(notification.contentKey, notification.contentParams) }}
                                </span>
                            </div>
                            <div class='buttons mb-3'
                                *ngIf='notification.ctaParams && notification.ctaParams.length > 0'>
                                <button mat-mini-fab color='primary' class='float-right cta-button'
                                    (click)='onClickCtaButton(notification.ctaParams)'>
                                    <cp-icon size='small' color='white' name='arrow-right' class='icon-fix'>
                                    </cp-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf='showLoadMore' class='w-100 d-flex align-items-center justify-content-center mt-1'>
                    <div *ngIf='loadingMore; else loadMoreButtonRef'>
                        <cp-delayed-spinner class='m-auto' [diameter]='25'></cp-delayed-spinner>
                    </div>
                    <ng-template #loadMoreButtonRef>
                        <div (click)='loadMoreReadNotifications()' class='load-more-button'>
                            <span [translate]='"general.button.load_more"'>
                                Load more
                            </span>
                        </div>
                    </ng-template>
                </div>
            </ng-container>
        </ng-template>
    </ng-template>
</ng-template>
