import { ImageDataService } from '@core/services';
import {
    Component, Input, Output, EventEmitter, ElementRef, ViewChild, OnDestroy, OnInit, OnChanges, SimpleChanges,
} from '@angular/core';
import { EMPTY_IMAGE } from '@core/constants/images';
import { SecurePipe } from '@shared/pipes';
import { OverlayService } from '../overlay';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
    selector: 'cp-image',
    templateUrl: './image.component.html',
    styleUrls: ['./image.component.scss']
})
export class ImageComponent implements OnInit, OnDestroy, OnChanges {
    @Input() blobId!: string;
    @Input() organisationId!: number;
    @Input() canOverlayZoom = false;
    @Input() showOverlay = false;
    @Input() imageClasses: { [klass: string]: boolean } = {};

    @Input() width?: string;
    @Input() height?: string;

    @Input() rounded = false;

    @Input() hasPlaceholder = false;

    @Input() exactSizing = false;

    @Input() spinnerDiameter?: number;

    @Input() overwriteContainerSize = false;

    @Input() showWarningOnError?: boolean;

    @Input() swCache = false;

    @Input() code?: string;

    @Output() loadingError: EventEmitter<void> = new EventEmitter<void>();

    @Output() loaded: EventEmitter<void> = new EventEmitter<void>();

    @ViewChild('image') image!: ElementRef<HTMLImageElement>;

    loading = true;
    errorLoading = false;
    showingOverlay = false;

    safeImageUrl?: SafeUrl | string;
    private _imageUrl?: string;

    constructor(
        private data: ImageDataService, private overlay: OverlayService,
        private securePipe: SecurePipe, private sanitizer: DomSanitizer,
    ) { }

    ngOnInit(): void {
        this.safeImageUrl = EMPTY_IMAGE;
        this._loadImage();
    }

    ngOnDestroy(): void {
        if (this._imageUrl) {
            URL.revokeObjectURL(this._imageUrl);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.blobId && changes.blobId.currentValue && changes.blobId.currentValue !== changes.blobId.previousValue) {
            this._loadImage();
        }
    }

    getStyle(): string {
        let styleString = '';

        if (this.exactSizing) {
            if (this.width) {
                styleString = `width: ${this.width}; `;
            }

            if (this.height) {
                styleString = `${styleString}height: ${this.height};`;
            }
        } else {
            if (this.width) {
                styleString = `max-width: ${this.width}; `;
            }

            if (this.height) {
                styleString = `${styleString}max-height: ${this.height};`;
            }
        }

        return styleString;
    }

    getClasses(): { [klass: string]: boolean } {
        const classes = this.imageClasses;
        classes['d-none'] = this.loading;
        return classes;
    }

    getContainerStyle(): string {
        let styleString = '';

        if (this.width && this.overwriteContainerSize) {
            styleString = `width: ${this.width}; `;
        }

        if (this.height && this.overwriteContainerSize) {
            styleString = `${styleString}height: ${this.height};`;
        }

        return styleString;
    }

    openOverlay(): void {
        if (this.canOverlayZoom && !this.loading && !this.errorLoading && this.safeImageUrl) {
            const dialogRef = this.overlay.OpenImageOverlay();
            dialogRef.instance.imageUrl = this.safeImageUrl;
            dialogRef.instance.closed.subscribe(() => {
                this.overlay.ResetUserZoom();
                dialogRef.destroy();
            });
        }
    }

    onImageLoad(): void {
        if (this.image?.nativeElement?.src !== EMPTY_IMAGE) {
            this.loading = false;
            this.errorLoading = false;
            this.loaded.emit();
        }
    }

    OnImageLoadError(): void {
        this.loading = false;
        this.errorLoading = true;
        this.safeImageUrl = undefined;
        this.loadingError.emit();
    }

    private _loadImage(): void {
        let url: string;

        if (this.code) {
            url = this.data.getAnonymousImageUrl(this.blobId, this.organisationId, this.code);
        } else if (this.swCache) {
            url = this.data.getCachedImageUrl(this.blobId, this.organisationId);
        } else {
            url = this.data.getImageUrl(this.blobId, this.organisationId);
        }

        this.securePipe.transform(url)
            .subscribe((result) => {
                if (!result) {
                    this.OnImageLoadError();
                    return;
                }

                if (result !== EMPTY_IMAGE) {
                    this._imageUrl = result;
                    this.safeImageUrl = this.sanitizer.bypassSecurityTrustUrl(this._imageUrl);
                    this.onImageLoad();
                }
            });
    }
}
