import { GlobalsService } from '@core/services';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { StylingModel } from '@core/models';

@Component({
    selector: 'cp-start-page',
    templateUrl: './start-page.component.html',
    styleUrls: ['./start-page.component.scss']
})
export class StartPageComponent {
    @Input() organisationId!: number;
    @Input() eventId!: number;
    @Input() eventName!: string;
    @Input() deadline?: Date;

    @Input() organisationStyling!: StylingModel;

    @Output() evaluationStartClicked: EventEmitter<void> = new EventEmitter<void>();

    readonly DATE_FORMAT = 'shortDate';
    loadingImage = false;
    errorLoadingImage = false;

    constructor(globals: GlobalsService) {
        globals.changeHeader({ titleKey: 'general.text.evaluation' });
    }

    get eventImage(): string {
        return `event/${this.eventId}`;
    }

    startEvaluation(): void {
        this.evaluationStartClicked.emit();
    }
}
