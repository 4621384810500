<div *ngIf='error; else loader'>
    <div class='alert alert-danger mt-3'>
        <span [translate]='"events.error.could_not_load_event_information"'>
            Something went wrong while trying to retrieve the event information. You will be redirected to the event
            overview.
        </span>
    </div>
</div>

<ng-template #loader>
    <div class='loader-container' *ngIf='loading; else landingPage'>
        <cp-delayed-spinner class='m-auto'></cp-delayed-spinner>
    </div>
</ng-template>

<ng-template #landingPage>
    <div class='event-container' [class.position-absolute]='animating'>
        <div *ngIf='videoId && videoActive && !errorLoadingVideo; else headerImageRef' class='video-container'
        [class.h-0]='loadingVideo' [class.invisible]='loadingVideo'>
            <cp-video [organisationId]="organisationId" [videoId]="videoId" [maxHeight]="'275px'"
            [eventId]="eventId" (loaded)="loadingVideo = false" (errored)="errorLoadingVideo = true">
            </cp-video>
        </div>
        <ng-template #headerImageRef>
            <header class='image-container' [class.h-0]='loadingImage' [class.invisible]='loadingImage'>
                <cp-image [blobId]='eventImage' [organisationId]="organisationId" (loaded)='loadingImage = false'
                    [width]="'100%'">
                </cp-image>
            </header>
        </ng-template>
        <div class='event-info' @fadeIn [class.mt-0]="videoId">
            <div class='event-name mt-4'>
                {{ event.eventName }}
            </div>
            <div class='event-date mt-1' *ngIf='event.eventDate'>
                {{ event.eventDate | localizedDate: DATE_FORMAT }}
                <span *ngIf='showEndDate; else startTimeRef'>
                    - {{ event.endDate | localizedDate: DATE_FORMAT }}
                </span>
                <ng-template #startTimeRef>
                    <span *ngIf='event.hasTime'>
                        | {{ event.eventDate | localizedDate: TIME_FORMAT }}
                    </span>
                </ng-template>
            </div>
            <div *ngIf="!videoId" class='row mt-3'>
                <div *ngIf='loadingImage && progressBar && event.hasProgress' class='col-6 bar-container'
                    [class.offset-3]='!scoreBar || !event.hasScore'>
                    <cp-radial-progress class='ml-auto' [class.mr-auto]='!scoreBar || !event.hasScore' [size]="100"
                        [bars]="progressBar" label='content'>
                        <div class='radial-label'>
                            <span [translate]='"general.text.progress"'>
                                Progress
                            </span>
                        </div>
                        <div class='radial-value'>
                            {{ event.totalProgress }}%
                        </div>
                    </cp-radial-progress>
                </div>
                <div *ngIf='loadingImage && scoreBar && event.hasScore' class='col-6 bar-container'
                    [class.offset-3]='!progressBar || !event.hasScore'>
                    <cp-radial-progress class='mr-auto' [size]="100" [bars]="scoreBar" label='content'
                        [class.ml-auto]='!progressBar || !event.hasProgress'>
                        <div class='radial-label'>
                            <span>
                                Score
                            </span>
                        </div>
                        <div class='radial-value score-value' [class.low-score]='(event.averageScore || 0) < 50'>
                            {{ scoreAsTenScale }}
                        </div>
                    </cp-radial-progress>
                </div>
            </div>
            <div class='mt-3' *ngIf='showTimeline'>
                <cp-timeline-indicator [finishDay]='event.eventDate' [color]='event.isUrgent ? "warn" : "primary"'
                    [completed]='completed' [countdownLabel]='"event.text.until_the_event"'>
                </cp-timeline-indicator>
            </div>
            <div class='mt-3 d-flex justify-content-center' *ngIf="hasWikiTabs">
                <button mat-flat-button class="cp-button wiki-button" (click)="openEventWiki()" id="wiki-button">
                    <cp-icon size='x-small' name='book-open'></cp-icon>
                    <span [translate]="'wiki.text.event_wiki'">
                        Event wiki
                    </span>
                </button>
            </div>
        </div>

        <cp-tasks-widget *ngIf='organisationId && eventId' [organisationId]='organisationId' [eventId]='eventId'>
        </cp-tasks-widget>
    </div>
</ng-template>
