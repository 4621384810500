<div class='introduction-text' *ngIf='introductionText' [innerHTML]="introductionText" glossary-injection
    [organisationId]="organisationId" [eventId]="eventId" [pageInformation]="pageTrackingInformation">
</div>
<div class='procedures-list'>
    <div class='procedure' *ngFor='let procedure of content.procedures; let index = index'>
        <div class='collapsed-info' [class.active]='procedure.isExpanded'
            (click)='procedure.isExpanded = !procedure.isExpanded'>
            <div class='content' [class.active]='procedure.isExpanded'>
                <div class='title my-auto'>
                    <span glossary-injection [organisationId]="organisationId" [eventId]="eventId"
                    [pageInformation]="pageTrackingInformation">
                        {{ procedure.title }}
                    </span>
                </div>
                <div class='subtitle my-auto'>
                    <span *ngIf='procedure.subtitle' glossary-injection [organisationId]="organisationId"
                    [eventId]="eventId"[pageInformation]="pageTrackingInformation">
                        {{ procedure.subtitle }}
                    </span>
                </div>
            </div>
            <div class='buttons' *ngIf='procedure.details'>
                <div class='button'>
                    <cp-icon size='small' class='chevron' [class.flipped]='procedure.isExpanded' name='chevron-down'>
                    </cp-icon>
                </div>
            </div>
        </div>
        <div class='expanded-content' *ngIf='procedure.isExpanded && procedure._sanitizedDetails'>
            <div class='procedure-view text-section' [innerHTML]='procedure._sanitizedDetails'
                glossary-injection [organisationId]="organisationId" [eventId]="eventId"
                [pageInformation]="pageTrackingInformation">
            </div>
        </div>
    </div>
</div>
