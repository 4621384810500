export interface TourStep {
    step: number;
    page: number;
    version: number;

    selector?: string;
    clickAction?: () => void;
    clickChangesPage: boolean;

    belowHighlight: boolean;
    nextDisabled: boolean;

    textKey: string;

    customPadding?: number;
}

export interface TourProgress {
    versionFinished?: number;
    pagesFinished: TourPageProgress[];
}

export interface TourPageProgress {
    page: number;
    fullyFinished: boolean;
    fullyFinishedVersion?: number;
    steps: number[];
}
