import { ChangeDetectorRef, Component, OnDestroy, ViewChild } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { GlossaryTerm } from '@core/models';
import { AnalyticsService, GlobalsService, GlossaryInjectionService } from '@core/services';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Component({
    templateUrl: './glossary-injection-tooltip.component.html',
    styleUrls: ['./glossary-injection-tooltip.component.scss']
})
export class GlossaryInjectionTooltipComponent implements OnDestroy {
    @ViewChild('tooltip')
        tooltip!: MatTooltip;

    organisationId!: number;
    eventId!: number;
    term!: GlossaryTerm;
    text!: string;
    pageInformation?: unknown;

    private destroy$ = new Subject<boolean>();

    constructor(
        private data: GlossaryInjectionService,
        private cdr: ChangeDetectorRef,
        private globals: GlobalsService,
        private analytics: AnalyticsService,
    ) {
        globals.onHideTooltips().pipe(takeUntil(this.destroy$)).subscribe(_ => {
            this.tooltip.hide();
            setTimeout(() => {
                this.cdr.detectChanges();
            }, 0);
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    public SetValues(organisationId: number, eventId: number, term: GlossaryTerm, text: string, pageInformation?: unknown): void {
        this.organisationId = organisationId;
        this.eventId = eventId;
        this.term = term;
        this.text = text;
        this.pageInformation = pageInformation;

        this.cdr.detectChanges();
    }

    getDescription(event: MouseEvent, tooltip: MatTooltip): boolean {
        event.preventDefault();
        event.stopPropagation();

        this.tooltip = tooltip;
        if (tooltip._isTooltipVisible()) {
            tooltip.hide();
            setTimeout(() => {
                this.cdr.detectChanges();
            }, 0);
            return false;
        }

        if (this.term.description) {
            tooltip.message = this.term.description || '';
            this._trackGlossaryOpen();
            this.globals.hideTooltips();
            tooltip.show();
            setTimeout(() => {
                this.cdr.detectChanges();
            }, 0);
            return false;
        }

        this.data.getTermDefinition(this.organisationId, this.eventId, this.term.id).pipe(take(1)).subscribe(result => {
            this.term.description = result.description;
            tooltip.message = this.term.description || '';
            this._trackGlossaryOpen();
            this.globals.hideTooltips();
            tooltip.show();
            setTimeout(() => {
                this.cdr.detectChanges();
            }, 0);
        });

        return false;
    }

    private _trackGlossaryOpen(): void {
        this.analytics.track('InjectedGlossaryOpen', {
            wordId: this.term.id,
            word: this.term.term,
            pageInformation: this.pageInformation,
        }, this.organisationId, this.eventId);
    }
}
